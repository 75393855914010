import React, { Component } from "react";
import {
  Button,
  Segment,
  Icon,
  Message,
  Modal,
  Table,
  Dropdown,
} from "semantic-ui-react";
import { Prompt } from "react-router-dom";
import { Formik, Form, FastField } from "formik";
import _ from "lodash";
import Page from "../../components/Page";
import { MaterialInput } from "../../components/Helpers";
import { get, post } from "../../util/api";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { MyDivider } from "../../components/FormikInput";
import { useTranslation } from "react-i18next";

export function TextInput(props) {
  return (
    <FastField name={props.name} style={{ width: "100%" }}>
      {({ field }) => (
        <MaterialInput
          css={`
            width: 100%;
            height: 22px;
          `}
          type={props.type || "text"}
          name={field.name}
          placeholder={props.placeholder || "..."}
          value={field.value || ""}
          onChange={field.onChange}
        />
      )}
    </FastField>
  );
}
export const FormDropdown = styled(Dropdown)`
  min-width: ${(props) => props.size || 200}px;
  //text-align: center !important;
  height: 22px;

  .text {
    font-weight: 400 !important;
  }
  .dropdown.icon {
    float: right;
  }
`;

export function DropdownInput(props) {
  const { t } = useTranslation();
  return (
    <FastField name={props.name}>
      {({ field, form }) => (
        <FormDropdown
          className="yt-line"
          placeholder={props.placeholder || t("select")}
          inline
          value={field.value}
          onChange={(e, { value }) => {
            form.setFieldValue(props.name, value);
          }}
          scrolling
          compact
          options={props.list}
        />
      )}
    </FastField>
  );
}
export function TableLine(props) {
  if (_.has(props, "single"))
    return (
      <Table.Row>
        <Table.Cell>{props.title || ""}</Table.Cell>
        <Table.Cell>{props.children}</Table.Cell>
      </Table.Row>
    );
  return (
    <Table.Row>
      <Table.Cell>{props.title || ""}</Table.Cell>
      <Table.Cell style={{ display: "flex" }}>
        {props.children}
        <p style={{ marginLeft: "10px" }}>{props.description || ""}</p>
      </Table.Cell>
    </Table.Row>
  );
}

class BotSettings extends Component {
  state = { loading: true };
  _form = React.createRef();

  componentDidMount() {
    this.load();
  }

  async handleSubmit(data, actions) {
    actions.setSubmitting(true);
    await post("/personalize/bot/save", { id: "config", data });
    this.setState({ data });
    actions.setValues(data);
    actions.resetForm(data);
  }

  async load() {
    let response = await get("/personalize/bot/get/config");
    this.setState({
      loading: false,
      data: _.pick((response && response.data) || {}, [
        "positive_message",
        "positive_message_payload",
        "feedback_positive_result_text",
        "negative_message",
        "another_question",
        "connect_to_agent",
        "_type",
        "forward_agent_card_message",
        "agent_forward_message",
        "order_notcome_message",
        "no_order_message",
        "feedback_payload",
        "contact_page_text",
        "contact_page_url",
        "contact_page_button",
        "order_wrong_product_text",
        "product_change_text",
        "product_change_url",
        "product_change_button",
        "login_url",
        "invoice_request_text",
        "giveback_reason_text",
        "time_check_url",
        "outtime_message",
        "productInfo_url",
        "forwardtogrouptext",
        "direct_forward_group_forward",
        "feedback_poorly_forward",
      ]),
    });
  }

  render() {
    let { t } = this.props;
    if (this.state.loading)
      return (
        <Segment className="bot-settings-wrap">
          <Page.Loader />
        </Segment>
      );
    const feedbacks = [
      { key: "none", value: "none", text: t("None") },
      { key: "feedback", value: "__feedback", text: t("showAnswer") },
      {
        key: "anotherquestion",
        value: "another_question",
        text: t("showAnotherQuestion"),
      },
      {
        key: "forwardagentwithanother",
        value: "forwardagentwithanother",
        text: t("customerSupport"),
      },
      {
        key: "timeforwardagentwithanother",
        value: "timeforwardagentwithanother",
        text: t("customerSupportHour"),
      },
    ];
    return (
      <Segment className="bot-settings-wrap">
        <MyDivider icon="text cursor" name={t("DefaultTexts")} />
        <Formik
          key={"settings"}
          initialValues={this.state.data}
          innerRef={this._form}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(form) => {
            let { dirty, isSubmitting } = form;
            //const is_start = values.type === 'start'values,;
            //const SAVE_TEXT = values.type === 'category' ? t('Category') : is_start ? t('StartingMessage') : t('Action');
            return (
              <Form
                autoComplete="off"
                css={`
                  background: inherit;
                  max-height: 100%;
                  min-height: 100%;
                  overflow: auto;
                  overflow-x: hidden;
                `}
              >
                <input
                  autoComplete="false"
                  name="hidden"
                  type="text"
                  className={"d-none"}
                />
                <Prompt
                  when={dirty}
                  message={(location) =>
                    `${t("AreYouSureWithoutSaving", {
                      pathname: location.pathname,
                    })}`
                  }
                />

                <Table definition>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={4}>
                        <Button
                          primary
                          fluid
                          size="small"
                          loading={isSubmitting}
                        >
                          {t("SaveConfig")}
                        </Button>
                      </Table.HeaderCell>
                      <Table.HeaderCell>{t("Value")}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <TableLine title={t("FeedBackPositive")}>
                      <TextInput
                        name="positive_message"
                        placeholder={t("FeedBackPositivePlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("FeedBackPositivePayload")}>
                      <TextInput
                        name="positive_message_payload"
                        placeholder={t("FeedBackPositivePayloadPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("FeedBackNegativePayload")}>
                      <TextInput
                        name="feedback_poorly_forward"
                        placeholder={t("FeedBackNegativePayloadPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("FeedBackPositiveAction")}>
                      <TextInput
                        name="feedback_positive_result_text"
                        placeholder={t("FeedBackPositiveActionPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("FeedBackNegative")}>
                      <TextInput
                        name="negative_message"
                        placeholder={t("FeedBackNegativePlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("AnotherQuestion")}>
                      <TextInput
                        name="another_question"
                        placeholder={t("AnotherQuestionPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ConnectToAgent")}>
                      <TextInput
                        name="connect_to_agent"
                        placeholder={t("ConnectToAgentPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("NoOrderMessage")}>
                      <TextInput
                        name="no_order_message"
                        placeholder={t("NoOrderMessagePlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("NoOrderPayload")}>
                      <DropdownInput
                        name="feedback_payload"
                        list={feedbacks}
                        placeholder={t("showAnswer")}
                      />
                    </TableLine>
                    <TableLine title={t("Type")}>
                      <TextInput name="_type" placeholder={"base"} />
                    </TableLine>
                    <TableLine title={t("ConnectToAgentCardMessage")}>
                      <TextInput
                        name="forward_agent_card_message"
                        placeholder={t("ConnectToAgentCardMessagePlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ForwardToAgentMessage")}>
                      <TextInput
                        name="agent_forward_message"
                        placeholder={t("ForwardToAgentMessagePlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("OrderNotcomeMessage")}>
                      <TextInput
                        name="order_notcome_message"
                        placeholder={t("OrderNotcomeMessagePlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ContactPageText")}>
                      <TextInput
                        name="contact_page_text"
                        placeholder={t("ContactPageTextPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ContactPageUrl")}>
                      <TextInput
                        name="contact_page_url"
                        placeholder={t("ContactPageUrlPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ContactPageButton")}>
                      <TextInput
                        name="contact_page_button"
                        placeholder={t("ContactPageButtonPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("OrderWrongProductText")}>
                      <TextInput
                        name="order_wrong_product_text"
                        placeholder={t("OrderWrongProductPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("LoginPageUrl")}>
                      <TextInput
                        name="login_url"
                        placeholder={t("LoginPageUrlPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ProductChangeText")}>
                      <TextInput
                        name="product_change_text"
                        placeholder={t("ProductChangeTextPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ProductGivebackTextMessage")}>
                      <TextInput
                        name="product_giveback_text"
                        placeholder={t("ProductGivebackTextPlaceholderNew")}
                      />
                    </TableLine>
                    <TableLine title={t("ProductChangeUrl")}>
                      <TextInput
                        name="product_change_url"
                        placeholder={t("ProductChangeUrlPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ProductChangeButton")}>
                      <TextInput
                        name="product_change_button"
                        placeholder={t("ProductChangeButtonPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ProductInvoiceText")}>
                      <TextInput
                        name="invoice_request_text"
                        placeholder={t("ProductInvoiceTextPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ProductGivebackText")}>
                      <TextInput
                        name="giveback_reason_text"
                        placeholder={t("ProductGivebackTextPlaceholder")}
                      />
                    </TableLine>

                    <TableLine title={t("TimeCheckUrlText")}>
                      <TextInput
                        name="time_check_url"
                        placeholder={t("TimeCheckUrlPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("OuttimeMessageText")}>
                      <TextInput
                        name="outtime_message"
                        placeholder={t("OuttimeMessagePlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("ProductInfoUrl")}>
                      <TextInput
                        name="productInfo_url"
                        placeholder={t("ProductInfoUrlPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("Forwardtogrouptext")}>
                      <TextInput
                        name="forwardtogrouptext"
                        placeholder={t("ForwardtogrouptextPlaceholder")}
                      />
                    </TableLine>
                    <TableLine title={t("DirectForwardGroup")}>
                      <TextInput
                        name="direct_forward_group_forward"
                        placeholder={t("DirectForwardGroupPlaceholder")}
                      />
                    </TableLine>
                  </Table.Body>
                </Table>
              </Form>
            );
          }}
        </Formik>
      </Segment>
    );
  }
}

function FactoryResetButton(props) {
  const [sending, setSending] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  async function onDelete() {
    if (sending) return;
    setSending(true);
    await get("/personalize/bot/hardreset");
    setSending(false);
    setOpen(false);
    //props.history.refresh();
  }
  let { t } = useTranslation();
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={
        <Button type="button" color="red" icon size="small">
          <Icon name="warning sign" /> {t("FactoryReset")}
        </Button>
      }
    >
      <Modal.Content>
        <Modal.Description>{t("AreYouSureFactoryReset")}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          {t("Cancel")}
        </Button>
        <Button
          content={t("FactoryReset")}
          icon="warning sign"
          loading={sending}
          color="red"
          onClick={onDelete}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default function SettingsPage(props) {
  let { t } = useTranslation();
  return (
    <div className="editor-full-screen">
      <BotSettings {...props} t={t} />
      <Segment className="bot-settings-wrap">
        <MyDivider icon="warning sign" name={t("FactoryReset")} />
        <Message
          negative
          header={t("YouUseResetBot")}
          list={[t("ProcessIsReversable")]}
        ></Message>
        <FactoryResetButton {...props} />
      </Segment>
    </div>
  );
}
