import React, { Component } from 'react';
import { Form, Segment, Icon } from 'semantic-ui-react';
import { post } from '../../../util/api';
import { updateProductSettings } from '../../../actions/access.actions';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { FormikInput, MiniContainer, FormikDropdown } from '../../../components/FormikInput';
import * as Yup from 'yup';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

function customizer(objValue, srcValue) {
	return _.isUndefined(objValue) || objValue === null ? srcValue : objValue;
}

var defaults = _.partialRight(_.assignWith, customizer);
class ProductSettings extends Component {
	state = {
		userInfo: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	handleSubmit = (values) => {
		if (this.state.userInfo.sending) return;
		this.setState({ userInfo: { sending: true } });
		this.setState({
			userInfo: { sending: false, errormessage: '', saved: 'Saved', color: 'teal', icon: 'check' },
		});
		this.props.updateProduct({ url: values.url });
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userInfo: { icon: 'save', color: 'blue', saved: 'Save' } });
		}, 2000);
		this.postData(values);
	};
	postData(values) {
		post('/personalize/postUrl', {
			username: values.settings.username,
			token: values.settings.token,
			type: values.type,
			url: values.settings.url,
		}).then((data) => {
			if (data.data.success) {
				window.location.reload();
			}
		});
	}

	render() {
		const initials = defaults({}, this.props.user, { personalize: this.props.product }, this.props.product);
		let { t, product } = this.props;
		const ecommerceFirms = [
			{
				text: 'T-Soft',
				value: 'tsoft',
				variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')],
			},
			{ text: 'Ticimax', value: 'ticimax', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: 'Inveon', value: 'inveon', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: 'Akinon', value: 'akinon', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: 'Faprika', value: 'faprika', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: 'IdeaSoft', value: 'ideasoft', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: 'Ikas', value: 'ikas', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },

			{ text: 'WooCommerce', value: 'woocommerce', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: 'Projesoft', value: 'projesoft', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: 'PlatinMarket', value: 'platinmarket', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] },
			{ text: t('Other'), value: 'base', variables: [t('Default Variables')] },
		];
		let floType = { text: 'Flo', value: 'flo', variables: [t('Page type'), t('Product info and visit tracking'), t('Basket info')] };
		if (product.type === 'flo') ecommerceFirms.push(floType);
		return (
			<Segment>
				<MiniContainer>
					<h3>
						<Icon name="asterisk" />
						{t('ProductInformation')}
					</h3>
					<Segment>
						<Formik
							initialValues={initials}
							onSubmit={this.handleSubmit}
							validationSchema={Yup.object().shape({
								'settings.url': Yup.string().url(t('UrlInvalid')).required(t('WebsiteIsRequired')),
							})}
						>
							{(props) => {
								const { values, handleChange, handleBlur } = props;
								return (
									<Form onSubmit={() => this.handleSubmit(values)}>
										<FormikInput
											id="url"
											label="Website"
											name="settings.url"
											icon="linkify"
											iconPosition="left"
											placeholder={t('EnteryourPlaceholder', { query: 'website (https://www.example.com)' })}
											type="url"
											required
											value={values.settings && values.settings.url}
											onChange={handleChange}
											Blur={handleBlur}
										/>
										<FormikDropdown
											name="type"
											label={t('EcommerceType')}
											list={ecommerceFirms}
											placeholder={t('SelectFirm')}
											value={values.type}
											onChange={handleChange}
										></FormikDropdown>
										<FormikInput
											id="username"
											label={t('UserName')}
											name="settings.username"
											icon="user"
											iconPosition="left"
											placeholder={t('EnteryourPlaceholder', { query: t('UserName') })}
											type="text"
											value={values.settings && values.settings.username}
											onChange={handleChange}
											Blur={handleBlur}
										/>
										<FormikInput
											id="token"
											label="Token"
											name="settings.token"
											icon="lock"
											iconPosition="left"
											placeholder={t('EnteryourPlaceholder', { query: 'Token' })}
											type="text"
											value={values.settings && values.settings.token}
											onChange={handleChange}
											Blur={handleBlur}
										/>
										<Form.Button
											fluid
											icon={this.state.userInfo.icon}
											type="submit"
											color={this.state.userInfo.color}
											content={t(this.state.userInfo.saved)}
											loading={this.state.userInfo.sending}
										/>
										<h3>{t('VariablesOf', { query: _.find(ecommerceFirms, { value: values.type }).text })}</h3>
										{_.map(_.find(ecommerceFirms, { value: values.type }).variables, function (it) {
											return <li style={{ listStyle: 'none' }}>{it}</li>;
										})}
									</Form>
								);
							}}
						</Formik>
					</Segment>
				</MiniContainer>
			</Segment>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj, product: state.product.personalize }),
	(dispatch) => ({ updateProduct: (settings) => dispatch(updateProductSettings('personalize', settings)) })
)(withTranslation()(ProductSettings));
