import React, { Component } from "react";
import {
  Icon,
  Segment,
  Form,
  Table,
  Button,
  Input,
  Label,
  Popup,
  Header,
} from "semantic-ui-react";
import Page from "../../components/Page";
import DraggableTableRow from "../../components/DragableTableRow";
import _ from "lodash";
import { get, post } from "../../util/api";
import RichTextEditor from "react-rte";
import { withTranslation } from "react-i18next";

class ActionTable extends Component {
  state = {
    saveButton: "Kaydet",
    images: [],
    imagesEN: [],
    pricing: [],
    pricingEN: [],
    limit: 1,
    image: "/icons/layout.svg",
    imageEN: "/icons/layout.svg",
    orderEN: null,
    orderTR: null,
    vote_amount: _.random(42, 50),
    loading: true,
    defaultPage: "home",
    previewType: "previewFalse",
    long_description: RichTextEditor.createEmptyValue(),
    long_descriptionEN: RichTextEditor.createEmptyValue(),
  };
  baseState = {
    title: "",
    titleEN: "",
    slug: "",
    slugEN: "",
    config: "",
    description: "",
    descriptionEN: "",
    //long_description:"",
    id: "",
    base: "",
    tsoft: "",
    ticimax: "",
    akinon: "",
    faprika: "",
    inveon: "",
    flo: "",
    ideasoft: "",
    ikas: "",
    woocommerce: "",
    platinmarket: "",
    projesoft: "",
    advance: "",
    images: [],
    imagesEN: [],
    pricing: [],
    pricingEN: [],
    it_work_coll: false,
    keyword: "",
    json_index: 0,
    test_url: "",
    limit: 1,
    previewType: "previewFalse",
    defaultPage: "home",
    image: "/icons/layout.svg",
    imageEN: "/icons/layout.svg",
    orderEN: null,
    orderTR: null,
    vote_amount: _.random(42, 50),
    loading: false,
    long_description: RichTextEditor.createEmptyValue(),
    long_descriptionEN: RichTextEditor.createEmptyValue(),
  };
  componentDidMount() {
    this.getList();
  }
  getList() {
    const { t } = this.props;
    let language = t("lang") || null;

    get("/campaign/template/listV2?admin=true").then((it) => {
      if (language) {
        this.setState({
          list: _.orderBy(it.data, [`settings.orders.${language}`], "asc"),
          loading: false,
        });
      } else {
        this.setState({
          list: _.orderBy(it.data, "order", "asc"),
          loading: false,
        });
      }
    });
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleArrayChange = (e, { name, value }) => {
    let images = this.state.images;
    images[name] = value;
    this.setState({ images });
  };
  handleArrayChangeEN = (e, { name, value }) => {
    let imagesEN = this.state.imagesEN;
    imagesEN[name] = value;
    this.setState({ imagesEN });
  };
  onChange = (long_description) => {
    this.setState({ long_description });
  };
  onChangeEN = (long_descriptionEN) => {
    this.setState({ long_descriptionEN });
  };
  toggle = (e, { name, value }) =>
    this.setState((prevState) => ({ [name]: !prevState[name] }));
  handleRemove = (index) => {
    let images = this.state.images;
    _.pullAt(images, [index]);
    this.setState({ images });
  };
  handleRemoveEN = (index) => {
    let imagesEN = this.state.imagesEN;
    _.pullAt(imagesEN, [index]);
    this.setState({ imagesEN });
  };
  handleArrayChangePrice = (e, { name, ex, value }) => {
    let pricing = this.state.pricing;
    pricing[name][ex] = value;
    this.setState({ pricing });
  };
  handleArrayChangePriceExtra = (e, { name, exname, ex, value }) => {
    let pricing = this.state.pricing;
    pricing[name][ex][exname] = value;
    this.setState({ pricing });
  };
  handleArrayChangePriceEN = (e, { name, ex, value }) => {
    let pricingEN = this.state.pricingEN;
    pricingEN[name][ex] = value;
    this.setState({ pricingEN });
  };
  handleArrayChangePriceExtraEN = (e, { name, exname, ex, value }) => {
    let pricingEN = this.state.pricingEN;
    pricingEN[name][ex][exname] = value;
    this.setState({ pricingEN });
  };

  handleObjectChangeSiteExtra = (e, { name, exname, ex, value }) => {
    let web_data = this.state.web_data;
    web_data[name] = value;
    this.setState({ web_data, [name]: value });
  };
  ObjectAddToggleValue = (e, { name, value }) => {
    let web_data = this.state.web_data;
    this.setState((prevState) => ({ [name]: !prevState[name], web_data }));
    let names = this.state[name];
    web_data[name] = !names;
  };

  handleRemovePrice = (index) => {
    let pricing = this.state.pricing;
    _.pullAt(pricing, [index]);
    this.setState({ pricing });
  };
  handleRemovePriceExtra = (index, oindex) => {
    let pricing = this.state.pricing;
    _.pullAt(pricing[index].extra, [oindex]);
    this.setState({ pricing });
  };

  handleRemovePriceEN = (index) => {
    let pricingEN = this.state.pricingEN;
    _.pullAt(pricingEN, [index]);
    this.setState({ pricingEN });
  };
  handleRemovePriceExtraEN = (index, oindex) => {
    let pricingEN = this.state.pricingEN;
    _.pullAt(pricingEN[index].extra, [oindex]);
    this.setState({ pricingEN });
  };
  handleSave = async () => {
    this.setState({ loadingSaveButton: true });
    let {
      title,
      titleEN,
      image,
      imageEN,
      orderTR,
      orderEN,
      slug,
      slugEN,
      description,
      descriptionEN,
      long_description,
      long_descriptionEN,
      limit,
      vote_amount,
      id,
      images,
      imagesEN,
      base,
      tsoft,
      ticimax,
      akinon,
      faprika,
      inveon,
      flo,
      ideasoft,
      ikas,
      woocommerce,
      projesoft,
      platinmarket,
      advance,
      defaultPage,
      pricing = [],
      pricingEN = [],
      previewType,
      web_data = {},
      _default,
    } = this.state;
    let data = {
      id,
      settings: {
        title: { tr: title, en: titleEN },
        slug: {
          tr: slug || _.kebabCase(title.replace(/\(.*\)|-/, "")),
          en: slugEN || _.kebabCase(titleEN.replace(/\(.*\)|-/, "")),
        },
        image: { tr: image, en: imageEN },
        orders: { tr: orderTR, en: orderEN },
        images: { tr: images, en: imagesEN },
        pricing: { tr: pricing, en: pricingEN },
        description: { tr: description, en: descriptionEN },
        long_description: {
          tr: long_description.toString("html"),
          en: long_descriptionEN.toString("html"),
        },
      },
      config: {
        base,
        tsoft,
        ticimax,
        akinon,
        faprika,
        inveon,
        flo,
        ideasoft,
        ikas,
        woocommerce,
        projesoft,
        platinmarket,
        _opt: {
          advance,
          preview: {
            previewType,
          },
        },
      },
      limit,
      vote_amount,
      default: _default || "def",
      vote_count: 10,
      web_data,
    };

    if (!data.id || data.id === "create") {
      data.settings.data = [];
    }
    if (defaultPage) {
      data.config._opt.preview.value = defaultPage;
    }
    await post("/campaign/action/saveV2", { data }).then((data) => {
      let result = data.data;
      let saved = result.success ? "Kaydedildi" : "Hata";
      this.setState({ loadingSaveButton: false, saveButton: saved });
    });
    this.getList();
    await setTimeout(() => this.setState({ saveButton: "Kaydet" }), 2000);
  };
  handleSort = async () => {
    const { t } = this.props;
    let language = t("lang") || "tr";

    this.setState({ loadingOrderButton: true });
    let { newList, listFrom, listTo, list } = this.state;
    let dragItem = list[listTo];
    console.log("lang ", dragItem);
    let beforeItem = list[listTo - 1];
    let afterItem = list[listTo + 1];
    let mainOrder =
      dragItem.settings &&
      dragItem.settings.orders &&
      dragItem.settings.orders[language];

    await post("/campaign/action/sortV2", {
      full: newList,
      order: [listFrom, listTo],
      language,
      value: [
        mainOrder,
        (beforeItem &&
          beforeItem.settings &&
          beforeItem.settings.orders &&
          beforeItem.settings.orders[language]) ||
          0,
        (afterItem &&
          afterItem.settings &&
          afterItem.settings.orders &&
          afterItem.settings.orders[language]) ||
          list.length,
      ],
    });
    /* console.log({
			full: newList,
			order: [listFrom, listTo],
			value: [mainOrder, (beforeItem && beforeItem.order) || 0, (afterItem && afterItem.order) || list.length],
		}); */
    this.getList();
    await this.setState({ loadingOrderButton: false });
  };
  handleEdit = (temp) => {
    let {
      config,
      limit,
      vote_amount,
      id,
      web_data = {},
      order,
      default: _default,
    } = temp;
    let {
      title,
      image,
      description,
      long_description,
      images,
      orders,
      pricing = [],
      slug,
    } = temp.settings;
    let {
      base = false,
      tsoft = false,
      ticimax = false,
      akinon = false,
      faprika = false,
      inveon = false,
      flo = false,
      ideasoft = false,
      ikas = false,
      woocommerce = false,
      projesoft = false,
      platinmarket = false,
    } = config;
    let advance = (config && config._opt && config._opt.advance) || false;
    let defaultPage =
      (config &&
        config._opt &&
        config._opt.preview &&
        config._opt.preview.value) ||
      false;
    let previewType =
      (config &&
        config._opt &&
        config._opt.preview &&
        config._opt.preview.previewType) ||
      "previewType";
    if (!_.isObject(images)) {
      images = { tr: [], en: [] };
    }

    this.setState({
      title: title["tr"],
      titleEN: title["en"],
      config,
      image: image["tr"],
      imageEN: image["en"],
      orderEN:
        orders && orders["en"]
          ? orders["en"]
          : order == 0
          ? order
          : order || null,
      orderTR:
        orders && orders["tr"]
          ? orders["tr"]
          : order == 0
          ? order
          : order || null,
      description: description["tr"],
      descriptionEN: description["en"],
      long_description: RichTextEditor.createValueFromString(
        long_description["tr"],
        "html"
      ),
      slug: slug["tr"],
      slugEN: slug["en"],
      long_descriptionEN: RichTextEditor.createValueFromString(
        long_description["en"],
        "html"
      ),
      limit,
      vote_amount,
      id,
      images: images["tr"],
      imagesEN: images["en"],
      base,
      tsoft,
      ticimax,
      akinon,
      faprika,
      inveon,
      flo,
      ideasoft,
      ikas,
      woocommerce,
      projesoft,
      platinmarket,
      advance,
      pricing: pricing["tr"],
      pricingEN: pricing["en"],
      defaultPage,
      previewType,
      _default,
      web_data,
      json_index: web_data["json_index"],
      test_url: web_data["test_url"],
      keyword: web_data["keyword"],
      // eslint-disable-next-line no-irregular-whitespace
      it_work_coll: web_data["it_work_coll"],
    });
  };
  fileUpload(file, name, title) {
    const url = "https://uploads.yapaytech.com/flow/uploadPermImage";
    const formData = new FormData();
    formData.append("files[]", file);
    let image_url = "";
    fetch(url, {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        image_url = data && data.files && data.files[0] && data.files[0].url;
        if (name === "image") {
          this.setState({ [name]: image_url });
        } else if (name === "imageEN") {
          this.setState({ [name]: image_url });
        } else {
          if (title == "EN") {
            let imagesEN = this.state.imagesEN;
            imagesEN[parseFloat(name)] = image_url;
            this.setState({ imagesEN });
          } else {
            let images = this.state.images;
            images[parseFloat(name)] = image_url;
            this.setState({ images });
          }
        }
      });
  }
  handleFileInput = (e, title) => {
    let file = e.target.files[0];
    let name = e.target.name;
    this.fileUpload(file, name, title);
  };
  async swap(a, b) {
    let { list } = this.state;
    //list[a] = list.splice(b, 1, list[a])[0];
    list.splice(a, 0, list.splice(b, 1)[0]);
    let newList = _.map(list, (it) => it.id);
    await this.setState({
      ...this.state,
      list,
      newList,
      listFrom: b,
      listTo: a,
    });
    await this.handleSort();
  }
  render() {
    let { t } = this.props;
    let {
      list,
      loading,
      title,
      titleEN,
      image,
      imageEN,
      slug,
      slugEN,
      description,
      descriptionEN,
      long_description,
      long_descriptionEN,
      vote_amount,
      limit,
      images,
      imagesEN,
      pricing,
      pricingEN,
      base,
      tsoft,
      ticimax,
      akinon,
      faprika,
      inveon,
      flo,
      ideasoft,
      ikas,
      woocommerce,
      projesoft,
      platinmarket,
      advance,
      loadingOrderButton,
      defaultPage,
      previewType,
      keyword,
      test_url,
      it_work_coll,
      json_index,
    } = this.state;
    let language = t("lang");
    if (loading) {
      return (
        <Segment>
          <Page.Loader></Page.Loader>
        </Segment>
      );
    }
    return (
      <Segment style={{ display: "flex", maxHeight: "93%" }}>
        <div style={{ width: "30%", maxHeight: "92%" }}>
          <div>
            <Table
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: "none",
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>
                    <Button
                      color="green"
                      onClick={() => this.setState(this.baseState)}
                    >
                      {t("add")}
                    </Button>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>Id</Table.HeaderCell>
                  <Table.HeaderCell width={11}>{t("Name")}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    {loadingOrderButton && (
                      <Icon
                        name={loadingOrderButton ? "spinner" : "check"}
                        loading={loadingOrderButton}
                      ></Icon>
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
          </div>
          <div
            className="table"
            style={{ overflowY: "auto", maxHeight: "100%" }}
          >
            <Table
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: "none",
              }}
            >
              <Table.Body>
                {_.map(list, (it, i) => {
                  let name = it.settings.title[language] || " - ";
                  return (
                    <DraggableTableRow
                      key={i}
                      i={i}
                      action={this.swap.bind(this)}
                    >
                      <Table.Cell width={2}>
                        <Button
                          color="blue"
                          onClick={() => this.handleEdit(it)}
                        >
                          {t("edit")}
                        </Button>
                      </Table.Cell>
                      <Table.Cell width={3}>{it.id}</Table.Cell>
                      <Table.Cell width={11}>{name}</Table.Cell>
                    </DraggableTableRow>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </div>
        <Segment
          className="edit"
          style={{
            marginTop: 0,
            marginLeft: "20px",
            width: "70%",
            overflowY: "auto",
          }}
        >
          <Form onSubmit={this.handleSave}>
            <div style={{ display: "flex" }}>
              <Form.Input
                required
                className="wi-90"
                fluid
                label={t("Name")}
                name="title"
                value={title}
                onChange={this.handleChange}
              ></Form.Input>
              <Form.Input
                required
                className="wi-90"
                fluid
                label={"EN " + t("Name")}
                name="titleEN"
                value={titleEN}
                onChange={this.handleChange}
              ></Form.Input>
              <Form.Button
                loading={this.state.loadingSaveButton}
                disabled={this.state.loadingSaveButton}
                type="submit"
                color="teal"
                style={{
                  display: "flex",
                  marginTop: "24px",
                  marginLeft: "10px",
                }}
              >
                {this.state.saveButton}
              </Form.Button>
            </div>
            <Form.Input
              fluid
              label={t("icon")}
              name="image"
              value={image}
              onChange={this.handleChange}
            >
              <Popup
                on="click"
                hideOnScroll
                position="bottom left"
                content={<img width={180} src={image} alt="img"></img>}
                trigger={
                  <Label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {t("icon")}
                  </Label>
                }
              ></Popup>
              <input />
              <div
                className="image-upload"
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label htmlFor="file-input_image">
                  <Icon
                    name="upload"
                    color="grey"
                    size="large"
                    style={{ cursor: "pointer" }}
                  ></Icon>
                </label>
                <Input
                  id="file-input_image"
                  type="file"
                  name={"image"}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => this.handleFileInput(e)}
                />
              </div>
            </Form.Input>
            <Form.Input
              fluid
              label={"EN " + t("icon")}
              name="imageEN"
              value={imageEN}
              onChange={this.handleChange}
            >
              <Popup
                on="click"
                hideOnScroll
                position="bottom left"
                content={<img width={180} src={imageEN} alt="img"></img>}
                trigger={
                  <Label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {t("icon")}
                  </Label>
                }
              ></Popup>
              <input />
              <div
                className="image-upload"
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label htmlFor="file-input_imageEN">
                  <Icon
                    name="upload"
                    color="grey"
                    size="large"
                    style={{ cursor: "pointer" }}
                  ></Icon>
                </label>
                <Input
                  id="file-input_imageEN"
                  type="file"
                  name={"imageEN"}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => this.handleFileInput(e)}
                />
              </div>
            </Form.Input>
            <Form.Field>
              <label>{t("media")}</label>
              {_.map(images, (it, i) => (
                <Input
                  key={i}
                  style={{ marginTop: "5px" }}
                  name={i}
                  value={images[i]}
                  onChange={this.handleArrayChange}
                >
                  <Popup
                    on="click"
                    hideOnScroll
                    position="bottom left"
                    content={
                      /cdnydm/.test(images[i]) ? (
                        <img width={720} src={images[i]} alt="img"></img>
                      ) : (
                        <iframe
                          id="video"
                          title="video"
                          width={720}
                          height={405}
                          src={`https://www.youtube.com/embed/${images[i]}`}
                        ></iframe>
                      )
                    }
                    trigger={
                      <Label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {t("imageLink")}
                        {" " + i}
                      </Label>
                    }
                  ></Popup>

                  <input />
                  <div
                    className="image-upload"
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor={"file-input_" + i}>
                      <Icon
                        name="upload"
                        color="grey"
                        size="large"
                        style={{ cursor: "pointer" }}
                      ></Icon>
                    </label>
                    <Input
                      id={"file-input_" + i}
                      type="file"
                      name={i}
                      key={i}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => this.handleFileInput(e, "TR")}
                    />
                  </div>
                  <Form.Button
                    icon
                    type="button"
                    onClick={() => this.handleRemove(i)}
                  >
                    <Icon name="trash"></Icon>
                  </Form.Button>
                </Input>
              ))}
              <Button
                icon
                size="tiny"
                type="button"
                style={{ marginTop: "5px" }}
                onClick={() => {
                  images.push("");
                  this.setState({ images });
                }}
              >
                <Icon name="plus"></Icon>
              </Button>
            </Form.Field>
            <Form.Field>
              <label>{"EN " + t("media")}</label>
              {_.map(imagesEN, (it, i) => (
                <Input
                  key={i}
                  style={{ marginTop: "5px" }}
                  name={i}
                  value={imagesEN[i]}
                  onChange={this.handleArrayChangeEN}
                >
                  <Popup
                    on="click"
                    hideOnScroll
                    position="bottom left"
                    content={
                      /cdnydm/.test(imagesEN[i]) ? (
                        <img width={720} src={imagesEN[i]} alt="img"></img>
                      ) : (
                        <iframe
                          id="video"
                          title="video"
                          width={720}
                          height={405}
                          src={`https://www.youtube.com/embed/${imagesEN[i]}`}
                        ></iframe>
                      )
                    }
                    trigger={
                      <Label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {t("imageLink")}
                        {" " + i}
                      </Label>
                    }
                  ></Popup>

                  <input />
                  <div
                    className="image-upload"
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor={"file-input_EN_" + i}>
                      <Icon
                        name="upload"
                        color="grey"
                        size="large"
                        style={{ cursor: "pointer" }}
                      ></Icon>
                    </label>
                    <Input
                      id={"file-input_EN_" + i}
                      type="file"
                      name={i}
                      key={i}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => this.handleFileInput(e, "EN")}
                    />
                  </div>
                  <Form.Button
                    icon
                    type="button"
                    onClick={() => this.handleRemoveEN(i)}
                  >
                    <Icon name="trash"></Icon>
                  </Form.Button>
                </Input>
              ))}
              <Button
                icon
                size="tiny"
                type="button"
                style={{ marginTop: "5px" }}
                onClick={() => {
                  imagesEN.push("");
                  this.setState({ imagesEN });
                }}
              >
                <Icon name="plus"></Icon>
              </Button>
            </Form.Field>
            <Form.Input
              fluid
              label={t("Slug")}
              name="slug"
              value={slug}
              onChange={this.handleChange}
            ></Form.Input>
            <Form.Input
              fluid
              label={"EN " + t("Slug")}
              name="slugEN"
              value={slugEN}
              onChange={this.handleChange}
            ></Form.Input>
            <Form.Input
              fluid
              label={t("shortDescription")}
              name="description"
              value={description}
              onChange={this.handleChange}
            ></Form.Input>
            <Form.Input
              fluid
              label={"EN " + t("shortDescription")}
              name="descriptionEN"
              value={descriptionEN}
              onChange={this.handleChange}
            ></Form.Input>
            <Form.Field>
              <label>{t("description")}</label>{" "}
              <RichTextEditor
                className="min-300h"
                value={long_description}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>{"EN " + t("description")}</label>{" "}
              <RichTextEditor
                className="min-300h"
                value={long_descriptionEN}
                onChange={this.onChangeEN}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("config")}</label>
              <Form.Group>
                <Form.Checkbox
                  label="Base"
                  name="base"
                  onChange={this.toggle}
                  checked={base}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="TSoft"
                  name="tsoft"
                  onChange={this.toggle}
                  checked={tsoft}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="Ticimax"
                  name="ticimax"
                  onChange={this.toggle}
                  checked={ticimax}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="Akinon"
                  name="akinon"
                  onChange={this.toggle}
                  checked={akinon}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="Faprika"
                  name="faprika"
                  onChange={this.toggle}
                  checked={faprika}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="Inveon"
                  name="inveon"
                  onChange={this.toggle}
                  checked={inveon}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="Flo"
                  name="flo"
                  onChange={this.toggle}
                  checked={flo}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="IdeaSoft"
                  name="ideasoft"
                  onChange={this.toggle}
                  checked={ideasoft}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="Ikas"
                  name="ikas"
                  onChange={this.toggle}
                  checked={ikas}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="WooCommerce"
                  name="woocommerce"
                  onChange={this.toggle}
                  checked={woocommerce}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="Projesoft"
                  name="projesoft"
                  onChange={this.toggle}
                  checked={projesoft}
                ></Form.Checkbox>
                <Form.Checkbox
                  label="PlatinMarket"
                  name="platinmarket"
                  onChange={this.toggle}
                  checked={platinmarket}
                ></Form.Checkbox>
              </Form.Group>
              <Form.Group>
                <Form.Checkbox
                  label="Advance"
                  name="advance"
                  onChange={this.toggle}
                  checked={advance}
                ></Form.Checkbox>
              </Form.Group>
            </Form.Field>
            <Form.Field>
              <label>{t("Preview")}</label>
              <Form.Group>
                <Form.Radio
                  label={t("previewAvail")}
                  name="previewType"
                  value="previewTrue"
                  checked={previewType === "previewTrue"}
                  onChange={this.handleChange}
                ></Form.Radio>
                <Form.Radio
                  label={t("noPreview")}
                  name="previewType"
                  value="previewFalse"
                  checked={previewType === "previewFalse"}
                  onChange={this.handleChange}
                ></Form.Radio>
                <Form.Radio
                  label={t("onlyForm")}
                  name="previewType"
                  value="onlyForm"
                  checked={previewType === "onlyForm"}
                  onChange={this.handleChange}
                ></Form.Radio>
              </Form.Group>
            </Form.Field>
            <Form.Field>
              <label>{t("defaultPage")}</label>
              <Form.Group>
                <Form.Radio
                  label={t("Home")}
                  name="defaultPage"
                  onChange={this.handleChange}
                  value="home"
                  checked={defaultPage === "home"}
                ></Form.Radio>
                <Form.Radio
                  label={t("Category")}
                  name="defaultPage"
                  onChange={this.handleChange}
                  value="category"
                  checked={defaultPage === "category"}
                ></Form.Radio>
                <Form.Radio
                  label={t("product")}
                  name="defaultPage"
                  onChange={this.handleChange}
                  value="product"
                  checked={defaultPage === "product"}
                ></Form.Radio>
                <Form.Radio
                  label={t("cart")}
                  name="defaultPage"
                  onChange={this.handleChange}
                  value="cart"
                  checked={defaultPage === "cart"}
                ></Form.Radio>
                <Form.Radio
                  label={t("empty")}
                  name="defaultPage"
                  onChange={this.handleChange}
                  value="empty"
                  checked={defaultPage === "empty"}
                ></Form.Radio>
              </Form.Group>
            </Form.Field>
            <Form.Field>
              <label>{t("Fiyatlandırma")}</label>
              {_.map(pricing, (it, i) => (
                <Form.Field
                  key={"pricing-" + i}
                  style={{
                    marginLeft: "85px",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <Form.Group>
                      <Form.Input
                        label={t("packageName")}
                        key={i + "name"}
                        style={{ marginTop: "5px" }}
                        name={i}
                        ex="name"
                        value={pricing[i].name}
                        onChange={this.handleArrayChangePrice}
                      >
                        <input />
                      </Form.Input>
                      <Form.Input
                        type="number"
                        label={t("price")}
                        key={i + "price"}
                        style={{ marginTop: "5px" }}
                        name={i}
                        ex="price"
                        value={pricing[i].price}
                        onChange={this.handleArrayChangePrice}
                      >
                        <input />
                      </Form.Input>
                      <Form.Input
                        type="number"
                        label="Limit"
                        key={i + "limit"}
                        style={{ marginTop: "5px" }}
                        name={i}
                        ex="limit"
                        value={pricing[i].limit}
                        onChange={this.handleArrayChangePrice}
                      >
                        <input />
                      </Form.Input>
                    </Form.Group>

                    {_.map(it.extra, (o, oi) => (
                      <div>
                        <Form.Field>
                          <Form.Input
                            type="text"
                            key={i + "limit"}
                            style={{ marginTop: "5px" }}
                            name={i}
                            exname={oi}
                            ex="extra"
                            value={pricing[i].extra[oi]}
                            onChange={this.handleArrayChangePriceExtra}
                          >
                            <Label
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {"Extra " + oi}{" "}
                            </Label>
                            <input />
                            <Form.Button
                              icon
                              type="button"
                              onClick={() => this.handleRemovePriceExtra(i, oi)}
                            >
                              <Icon name="trash"></Icon>
                            </Form.Button>
                          </Form.Input>
                        </Form.Field>
                      </div>
                    ))}
                    <Button
                      icon
                      size="tiny"
                      type="button"
                      style={{ marginTop: "5px" }}
                      onClick={() => {
                        pricing[i].extra.push("");
                        this.setState({ pricing });
                      }}
                    >
                      {t("addExtra")}
                    </Button>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "28px" }}>
                    <Form.Button
                      icon
                      type="button"
                      onClick={() => this.handleRemovePrice(i)}
                    >
                      {t("deletePackage")}
                    </Form.Button>
                  </div>
                </Form.Field>
              ))}
              <Button
                icon
                size="tiny"
                type="button"
                style={{ marginTop: "5px" }}
                onClick={() => {
                  pricing.push({
                    name:
                      pricing.length === 0
                        ? "start"
                        : pricing.length === 1
                        ? "grow"
                        : pricing.length === 2
                        ? "pro"
                        : "",
                    price: "",
                    limit: "",
                    extra: [],
                  });
                  this.setState({ pricing });
                }}
              >
                {t("addPackage")}
              </Button>
              <div>
                {_.map(pricingEN, (it, i) => (
                  <Form.Field
                    style={{
                      marginLeft: "85px",
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <Form.Group>
                        <Form.Input
                          label={"EN " + t("packageName")}
                          key={i + "name"}
                          style={{ marginTop: "5px" }}
                          name={i}
                          ex="name"
                          value={pricingEN[i].name}
                          onChange={this.handleArrayChangePriceEN}
                        >
                          <input />
                        </Form.Input>
                        <Form.Input
                          type="number"
                          label={"EN " + t("price")}
                          key={i + "price"}
                          style={{ marginTop: "5px" }}
                          name={i}
                          ex="price"
                          value={pricingEN[i].price}
                          onChange={this.handleArrayChangePriceEN}
                        >
                          <input />
                        </Form.Input>
                        <Form.Input
                          type="number"
                          label="EN Limit"
                          key={i + "limit"}
                          style={{ marginTop: "5px" }}
                          name={i}
                          ex="limit"
                          value={pricingEN[i].limit}
                          onChange={this.handleArrayChangePriceEN}
                        >
                          <input />
                        </Form.Input>
                      </Form.Group>

                      {_.map(it.extra, (o, oi) => (
                        <div>
                          <Form.Field>
                            <Form.Input
                              type="text"
                              key={i + "limit"}
                              style={{ marginTop: "5px" }}
                              name={i}
                              exname={oi}
                              ex="extra"
                              value={pricingEN[i].extra[oi]}
                              onChange={this.handleArrayChangePriceExtraEN}
                            >
                              <Label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"EN Extra " + oi}{" "}
                              </Label>
                              <input />
                              <Form.Button
                                icon
                                type="button"
                                onClick={() =>
                                  this.handleRemovePriceExtraEN(i, oi)
                                }
                              >
                                <Icon name="trash"></Icon>
                              </Form.Button>
                            </Form.Input>
                          </Form.Field>
                        </div>
                      ))}
                      <Button
                        icon
                        size="tiny"
                        type="button"
                        style={{ marginTop: "5px" }}
                        onClick={() => {
                          pricingEN[i].extra.push("");
                          this.setState({ pricingEN });
                        }}
                      >
                        {t("addExtra")}
                      </Button>
                    </div>
                    <div style={{ marginLeft: "20px", marginTop: "28px" }}>
                      <Form.Button
                        icon
                        type="button"
                        onClick={() => this.handleRemovePriceEN(i)}
                      >
                        {t("deletePackage")}
                      </Form.Button>
                    </div>
                  </Form.Field>
                ))}
                <Button
                  icon
                  size="tiny"
                  type="button"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    pricingEN.push({
                      name:
                        pricing.length === 0
                          ? "start"
                          : pricing.length === 1
                          ? "grow"
                          : pricing.length === 2
                          ? "pro"
                          : "",
                      price: "",
                      limit: "",
                      extra: [],
                    });
                    this.setState({ pricingEN });
                  }}
                >
                  {t("addPackage")}
                </Button>
              </div>
            </Form.Field>

            <Form.Input
              label={t("vote")}
              name="vote_amount"
              value={vote_amount}
              onChange={this.handleChange}
              type="number"
              style={{ width: "120px" }}
            ></Form.Input>
            <Form.Input
              label="Limit"
              name="limit"
              value={limit}
              onChange={this.handleChange}
              type="number"
              style={{ width: "120px" }}
            ></Form.Input>
            <Form.Field
              style={{ border: "1px solid #c0c0c0", padding: "18px" }}
            >
              <Header style={{ textAlign: "center" }}>
                www.collectaction.com
              </Header>
              <Form.Input
                label={t("seoTurkish")}
                key={"keyword"}
                name={"keyword"}
                value={keyword}
                onChange={this.handleObjectChangeSiteExtra}
              />
              <Form.Input
                label="JSON İndex"
                key={"json_index"}
                type="number"
                name={"json_index"}
                value={json_index}
                onChange={this.handleObjectChangeSiteExtra}
              />
              <Form.Input
                label="Test Url"
                key={"test_url"}
                name={"test_url"}
                value={test_url}
                onChange={this.handleObjectChangeSiteExtra}
              />
              <Form.Checkbox
                label={t("actiontry")}
                name="it_work_coll"
                onChange={this.ObjectAddToggleValue}
                checked={it_work_coll}
              ></Form.Checkbox>
            </Form.Field>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default withTranslation()(ActionTable);
