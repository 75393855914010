import React from "react";
import Page from "../../components/Page";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { TriggerForm } from "../../components/Selector";
import {
  Icon,
  Button,
  Responsive,
  Breadcrumb,
  Segment,
  Container,
  Header,
  Label,
  Dropdown,
} from "semantic-ui-react";
//import _ from 'lodash';
import { Link, Prompt, Redirect } from "react-router-dom";
import { get, post } from "../../util/api";
import {
  getFilterOptions,
  templateCache,
  templateCacheControl,
  SetUp,
  bootSub,
} from "../../components/CampaingHelpers";
import { Formik, Form } from "formik";
import { MaterialInput } from "../../components/Helpers";
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const DEFINITION_TYPE = "schedule";

function getTemplateVariation(template, var_id) {
  if (!template) return;
  if (!var_id && template.default) var_id = template.default;
  let found = _.find(template.data, { id: var_id });
  return found;
}

function merger(current, schema, isForm) {
  let def = schema && schema.__default;
  let params = def && def.params;

  if (
    !isForm &&
    current &&
    current.parameters &&
    current.parameters.parameters &&
    params
  ) {
    let ret = {};
    _.each(params, (val, key) => (ret[key] = val));
    _.each(current.parameters.parameters, (val, key) => (ret[key] = val));
    current.parameters.parameters = ret;
  }
  if (isForm && current && current.parameters && params) {
    let ret = {};
    _.each(params, (val, key) => (ret[key] = val));
    _.each(current.parameters, (val, key) => (ret[key] = val));
    current.parameters = ret;
  }
  return current || params;
}

const statusList = {
  active: { text: "Active", color: "green" },
  hidden: { text: "Hidden", color: "grey" },
  disabled: { text: "Disabled", color: "grey" },
};

const DateLabel = styled(Label)`
  margin: 0 !important;
  width: 100%;
  text-align: right;
  .detail {
    text-align: right;
    width: 40px;
  }
`;
class Actions extends React.Component {
  state = {
    temp_load: !!templateCacheControl(DEFINITION_TYPE),
    loading:
      (this.props.match.params.id && this.props.match.params.id !== "create") ||
      this.props.match.params.t_id,
    id: this.props.match.params.id || null,
    saving: false,
    modalOpen: false,
    current: null,
    name: "",
    actionname: "",
    status: "disabled",
    activeIndex: -1,
  };
  _form = React.createRef();
  _editor = React.createRef();

  save = () => {
    if (
      this._editor.current &&
      this._editor.current.state &&
      this._editor.current.state.schema
    )
      this._editor.current.save();
    this._form.current.submitForm();
  };

  action_to_save(data, name) {
    let { value, schema, widgetSchema } = data;
    if (schema.type === "popupActionTag")
      return [
        {
          ...value,
          name: name,
          parameters: {
            parameters: value.parameters.parameters,
            type: value.parameters.type || widgetSchema.value,
            component: value.parameters.component || widgetSchema.component,
            props: widgetSchema.props,
          },
          type: schema.value,
        },
      ];
    else
      return [
        {
          ...value,
          name: name,
          type: schema.value,
        },
      ];
  }

  handleSubmit = async (data, actions) => {
    actions.setSubmitting(true);
    let {
      who,
      when,
      where,
      trigger,
      tags,
      cancel,
      status,
      frequency,
      schedule_delay,
      push_mode,
    } = data;
    data.status = this.state.status;
    let obj = {
      name: this.state.name,
      status,
      who,
      tags,
      when,
      where,
      cancel,
      trigger,
      frequency,
      schedule_delay,
      _key: this.state._key,
      push_mode,
      type: "schedule",
    };
    this.setState({ saving: true });
    if (this.state.id && this.state.id !== "create") obj.id = this.state.id;
    else delete obj.id;

    let { success, data: response } = await post("/campaign/save", {
      data: obj,
      type: "schedule",
    }).then((resp) => resp.data);
    if (success && response) {
      if (!this.state.id || this.state.id === "create") {
        actions.resetForm(data);
        this.setState({ id: response.id });
        this.props.history.replace(`/campaigns/${response.id}`);
      }
    }
    this.setState({
      saving: false,
      current: obj,
      name: this.state.name || "",
    });
    actions.setSubmitting(false);
    actions.resetForm(data);
  };

  componentDidMount() {
    this.prepare();
  }

  async prepare() {
    let schedule_prepare =
      this.props &&
      this.props.product &&
      this.props.product.settings &&
      this.props.product.settings.waba_id;
    if (!this.state.temp_load) await SetUp(DEFINITION_TYPE, schedule_prepare);
    this.setState({ temp_load: true });
    if (this.state.id && this.state.id !== "create") await this.loadAction();
    else if (this.props.match.params.t_id) await this.loadTemplate();
  }

  setStateForTemplate(template, var_id) {
    let { title, config, limit, installed } = template;
    if (limit && installed && installed.length && installed.length >= limit)
      return this.setState({ redirect_template: template.id });
    let found_template = getTemplateVariation(template, var_id);
    let type =
      found_template.data.type || (config && config._type) || "campaign";
    this.setState({
      loading: false,
      name: title,
      current: this.fix_saved_data(found_template.data),
      _key: template.id,
      _var: found_template.id,
      _template: template,
      config,
      opt: found_template._opt || (config && config._opt) || {},
      type,
    });
  }

  async loadTemplate() {
    let data = await get("/campaign/template/" + this.props.match.params.t_id);
    if (data && data.success) {
      this.setStateForTemplate(data.data);
    } else this.setState({ loading: false });
  }

  fix_saved_data(data) {
    let { preData } = getFilterOptions();
    let clone = _.assignIn({}, data);
    if (!clone.push_mode) clone.push_mode = preData.push_mode;
    clone.tags = this.action_prepare(data.tags || []);
    return clone;
  }

  action_prepare(tags) {
    let { actionList } = templateCache();
    return _.map(tags, (currentValue) => {
      //console.log('prepare', currentValue);
      let schema = _.find(actionList, { value: currentValue.type });
      bootSub(schema);
      let value = merger(currentValue, schema, true);
      return value;
    });
  }

  async loadAction() {
    try {
      let data = await get("/campaign/schedule/getV2/" + this.state.id);
      let { name = "", status, data: others, _template, type } = data.data;
      let _opt = _template && _template.config && _template.config._opt;
      others.status = status;
      this.setState({
        loading: false,
        aid: data.data.id,
        name: name || `Action #${data.data.id}`,
        current: this.fix_saved_data(others),
        _key: others._key,
        type,
        status: data && data.data && data.data.status,
        opt: _opt || {},
      });
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  }

  onChange = (e, { value, name }) => {
    this.setState({ [name]: value });
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  onChangeStatus = (e, { value, name }) => {
    this.setState({ statusLoading: true });
    post("/campaign/save", { data: { id: this.state.id, status: value } }).then(
      () => {
        this.setState({ status: value, statusLoading: false });
      }
    );
  };
  header(t) {
    let { status, statusLoading, error } = this.state;

    let toggleStatus = status === "active" ? true : false;
    const options = [
      { key: "active", icon: "check", text: t("Active"), value: "active" },
      //	{ key: 'hidden', icon: 'hide', text: 'Hidden', value: 'hidden' },
      {
        key: "disabled",
        icon: "close",
        text: t("Disabled"),
        value: "disabled",
      },
    ];
    if (error) {
      return <Redirect to="/actions" />;
    }
    if (this.state.loading || !this.state.temp_load)
      return (
        <Breadcrumb size="big">
          <Breadcrumb.Section as={Link} to={`/actions`}>
            <Icon name="calendar alternate outline" />
            {t("Schedules")}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon={{ name: "asterisk", loading: true }} />
        </Breadcrumb>
      );
    return (
      <>
        <Breadcrumb size="big">
          <Breadcrumb.Section className="mobile-none" as={Link} to={`/actions`}>
            <Icon name="calendar alternate outline" />
            {t("Schedules")}
          </Breadcrumb.Section>
          <Breadcrumb.Divider className="mobile-none" icon="right chevron" />
          <Breadcrumb.Section>
            <MaterialInput
              className="actionName"
              size="mini"
              placeholder="Action Name"
              name="name"
              onChange={this.onChange}
              value={this.state.name}
            />
          </Breadcrumb.Section>
        </Breadcrumb>
        <div
          css={`
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            .button {
              white-space: nowrap;
            }
            @media screen and (max-width: ${Responsive.onlyMobile.maxWidth -
              1}px) {
              .button {
                padding: 11px !important;
              }
              padding-left: 5px;
              .hide-xs {
                display: none !important;
              }
            }
          `}
        >
          <div style={{ display: "flex", margin: "3px" }}>
            <span style={{ margin: "auto 5px auto", display: "flex" }}>
              {t("Status")}:
            </span>
            <Dropdown
              onChange={this.onChangeStatus}
              size="tiny"
              name="toggleStatus"
              direction="left"
              options={options}
              value={toggleStatus}
              icon={false}
              trigger={
                <DateLabel
                  color={statusList[status || "disabled"].color}
                  size="large"
                >
                  {statusLoading && <Icon name="asterisk" loading />}
                  {t("" + statusList[status || "disabled"].text)}
                </DateLabel>
              }
            />
            {/* <Checkbox toggle name="toggleStatus" defaultChecked={toggleStatus} value={toggleStatus} onChange={this.onChangeStatus}></Checkbox> */}
          </div>
          <Button size="small" onClick={() => this.props.history.goBack()}>
            {t("Cancel")}
          </Button>
          <Button
            primary
            size="small"
            loading={this.state.saving}
            onClick={this.save}
          >
            {t("SaveText", { text: t("Action") })}
            {/* 	<span className="hide-xs"> {t('Action')}</span> */}
          </Button>
        </div>
      </>
    );
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleOpenTrigger = () => this.setState({ modalOpenTrigger: true });
  handleClose = () =>
    this.setState({ modalOpen: false, modalOpenTrigger: false });

  render() {
    let { product, t } = this.props;
    let { config } = this.state;
    let ecommerceType =
      (product &&
        (product.type || (product.settings && product.settings.type))) ||
      "base";
    if (this.state.loading || !this.state.temp_load)
      return (
        <Page header={this.header(t)}>
          <Page.Loader />
        </Page>
      );
    let currentState = this.state.current;
    const { preData } = getFilterOptions();
    let canInstall = ecommerceType && config && config[ecommerceType];
    if (canInstall || !config) {
      return (
        <Page header={this.header(t)} flex>
          <Formik
            initialValues={{
              ...(currentState || preData),
              _key: this.state._key,
              action: this.state.action,
            }}
            innerRef={this._form}
            onSubmit={this.handleSubmit}
          >
            {({ values, dirty }) => {
              return (
                <Form
                  autoComplete="off"
                  css={`
                    background: inherit;
                    max-height: 100%;
                    min-height: 100%;
                    overflow: auto;
                    overflow-x: hidden;
                  `}
                >
                  <input
                    autoComplete="false"
                    name="hidden"
                    type="text"
                    className={"d-none"}
                  />
                  <Prompt
                    when={dirty}
                    message={(location) =>
                      `${t("AreYouSureWithoutSaving", {
                        pathname: location.pathname,
                      })}`
                    }
                  />
                  <TriggerForm
                    current={currentState}
                    t={t}
                    _editor={this._editor}
                  />
                </Form>
              );
            }}
          </Formik>
        </Page>
      );
    }
    return (
      <Page
        header={
          <Breadcrumb size="big">
            <Breadcrumb.Section
              className="mobile-none"
              as={Link}
              to={`/actions`}
            >
              <Icon name="calendar alternate outline" />
              {t("Schedules")}
            </Breadcrumb.Section>
            <Breadcrumb.Divider className="mobile-none" icon="right chevron" />
          </Breadcrumb>
        }
      >
        <Page.Wrap>
          <Container>
            <Segment>
              <Header>{t("dontInstall")}</Header>
              <p>{t("dontInstallText")}</p>
            </Segment>
          </Container>
        </Page.Wrap>
      </Page>
    );
  }
}
export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(Actions));
//{process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(values, null, 2)}</pre>}
