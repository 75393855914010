import React from "react";
import {
  Icon,
  Button,
  Image,
  Segment,
  Modal,
  Card,
  Checkbox,
} from "semantic-ui-react";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { SubSection, TwoStepButton } from "./FormParts";
import { templateCache, bootSub } from "./CampaingHelpers";
import "react-datepicker/dist/react-datepicker.css";
import { FastField as Field, Field as RawField, Formik } from "formik";
import { Preview, GenericPreview } from "./PopupPreview";
import { MyDivider } from "./FormikInput";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TriggerSelector } from "./Selector";

const EmptyType = (props) => (
  <div
    css={`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    `}
  >
    {props.t("SelectaTypetopreview")}
  </div>
);

class ActionFormEditorOld extends React.PureComponent {
  state = {};

  render() {
    const { currentValue, schema } = this.props;
    if (!schema) return null;
    return (
      <Formik initialValues={{ ...currentValue, schema }}>
        {({ values }) => {
          if (this.props.setSave) this.props.setSave(() => values);
          if (schema.preview_data)
            return (
              <div className="action-wrap">
                <div className="action-edit-wrap form-vertical">
                  {this.props.header}
                  <OptionsGenerator path="parameters" />
                </div>
                <GenericPreview schema={schema} />
              </div>
            );
          return (
            <div className="action-edit-wrap form-vertical">
              {this.props.header}
              <OptionsGenerator path="parameters" />
            </div>
          );
        }}
      </Formik>
    );
  }
}

export class ActionPopupEditor extends React.PureComponent {
  render() {
    const { widgetSchema, t, value } = this.props;
    const parameters_path = "action.value.parameters.parameters";
    let isCartOpen =
      value.parameters && value.parameters.type === "add-more-to-basket";
    let inputText =
      (value.parameters &&
        value.parameters.parameters &&
        value.parameters.parameters.text &&
        value.parameters.parameters.text.value) ||
      "test";
    return (
      <div className="action-edit-wrap">
        <div>
          <div className="popup-wrap">
            <div className="left-section">
              <div className="title-bar"></div>
              <div className="scroll-body" ref={this._container}>
                <MyDivider icon="options" name={t("Options")} />
                <div className="content form-vertical">
                  <WidgetOptionsGenerator path={parameters_path} t={t} />
                </div>
                <MyDivider icon="options" name={t("Conditions")} />
                <div className="content form-vertical">
                  <WidgetConditionOptionsGenerator
                    path={parameters_path}
                    t={t}
                  />
                </div>
                <p style={{ marginLeft: "20px" }}>
                  {widgetSchema && widgetSchema.condition_desc}
                </p>
                <Button
                  type="button"
                  css={`
                    margin: 0 20px 20px 20px !important;
                    display: block;
                  `}
                  compact
                  icon
                  style={{ display: "block" }}
                  labelPosition="left"
                  onClick={this.props.handleOpen}
                >
                  <Icon name="filter" />
                  {t("EditConditions")}
                </Button>
              </div>
            </div>
            <RawField name={parameters_path}>
              {({ field }) => (
                <Preview
                  inputText={inputText}
                  isCartOpen={isCartOpen}
                  schema={widgetSchema}
                  parameters={field.value}
                  opt={this.props.opt && this.props.opt.preview}
                  t={t}
                />
              )}
            </RawField>
          </div>
        </div>
      </div>
    );
  }
}

class WidgetOptionsGenerator extends React.PureComponent {
  render() {
    let { t } = this.props;
    return (
      <Field name={this.props.path || ""}>
        {({ field, form }) => {
          let data = form.values.action.widgetSchema;
          if (!data) return <EmptyType t={t} />;
          return (
            <SubSection
              data={data}
              path={field.name}
              deep={2}
              key={data.value}
              list={data.sub}
              condition={false}
            />
          );
        }}
      </Field>
    );
  }
}

class WidgetConditionOptionsGenerator extends React.PureComponent {
  render() {
    let { t } = this.props;
    return (
      <Field name={this.props.path || ""}>
        {({ field, form }) => {
          let data = form.values.action.widgetSchema;
          if (!data) return <EmptyType t={t} />;
          return (
            <SubSection
              data={data}
              path={field.name}
              deep={2}
              key={data.value}
              list={data.sub}
              condition={true}
              t={t}
            />
          );
        }}
      </Field>
    );
  }
}

class OptionsGenerator extends React.PureComponent {
  render() {
    let { t } = this.props;
    return (
      <Field name={this.props.path || ""}>
        {({ field, form }) => {
          let data =
            (form.values.action && form.values.action.schema) ||
            form.values.schema;
          if (!data) return <EmptyType t={t} />;
          return (
            <SubSection
              data={data}
              path={field.name}
              deep={2}
              key={data.value}
              list={data.sub}
            />
          );
        }}
      </Field>
    );
  }
}

export class ActionFormEditor extends React.PureComponent {
  state = {};

  render() {
    const { schema, t } = this.props;
    if (!schema) return null;
    return (
      <div className="action-edit-wrap form-vertical">
        <OptionsGenerator path="action.value.parameters" t={t} />
        <p style={{ marginLeft: "20px" }}>{schema && schema.condition_desc}</p>
      </div>
    );
  }
}
export class ActionFormNoPreviewEditor extends React.PureComponent {
  state = {};

  render() {
    const { widgetSchema, schema, t } = this.props;
    if (!schema) return null;
    return (
      <div className="action-edit-wrap">
        <div>
          <div className="popup-wrap">
            <div className="left-section">
              <div className="content">
                <div className="title-bar"></div>
                <div className="" ref={this._container}>
                  <OptionsGenerator path="action.value.parameters" t={t} />
                  <p style={{ marginLeft: "20px" }}>
                    {(schema && schema.condition_desc) ||
                      (widgetSchema && widgetSchema.condition_desc)}
                  </p>
                  <div
                    css={`
                      display: block !important;
                      margin: 10px 0px;
                    `}
                  >
                    <Button
                      type="button"
                      compact
                      icon
                      labelPosition="left"
                      onClick={this.props.handleOpen}
                    >
                      <Icon name="filter" />
                      {t("EditConditions")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Preview
              schema={widgetSchema}
              opt={this.props.opt && this.props.opt.preview}
              t={t}
              noPreview={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
export class ActionFormPreviewEditor extends React.PureComponent {
  state = {};

  render() {
    const { widgetSchema, schema, t, value } = this.props;
    const parameters_path = "action.value.parameters.parameters";
    /* let inputText =
			(value.parameters && value.parameters.parameters && value.parameters.parameters.text && value.parameters.parameters.text.value) || 'test'; */
    let actionType = value.parameters && value.type;
    let title_back =
      (value.parameters &&
        value.parameters.title_back &&
        value.parameters.title_back.value) ||
      t("backgroundTitle");
    let title_fore =
      (value.parameters &&
        value.parameters.title_fore &&
        value.parameters.title_fore.value) ||
      t("frontendTitle");
    let background_color =
      (value.parameters &&
        value.parameters.background_color &&
        value.parameters.background_color.value) ||
      "red";
    let position =
      (value.parameters &&
        value.parameters.position &&
        value.parameters.position.value) ||
      "up";
    let text_color =
      (value.parameters &&
        value.parameters.text_color &&
        value.parameters.text_color.value) ||
      "white";
    let isFaviconShow =
      value.parameters &&
      value.parameters.position &&
      value.parameters.position.value
        ? ""
        : "noShow";
    let show_number =
      (value.parameters &&
        value.parameters.show_number &&
        value.parameters.show_number.value) ||
      false;
    let show_on_title =
      (value.parameters &&
        value.parameters.show_on_title &&
        value.parameters.show_on_title.value) ||
      false;
    let animation =
      (value.parameters &&
        value.parameters.animation &&
        value.parameters.animation.value) ||
      "none";
    let title_back_basket =
      (value.parameters &&
        value.parameters.title_back_basket &&
        value.parameters.title_back_basket.value) ||
      t("dontForgetCart");
    let show_favicon =
      (value.parameters &&
        value.parameters.show_favicon &&
        value.parameters.show_favicon.value) ||
      false;
    let freecargotext =
      (value.parameters &&
        value.parameters.title &&
        value.parameters.title.value) ||
      t("freeCargo");
    let bannercolor =
      (value.parameters &&
        value.parameters.bg_color &&
        value.parameters.bg_color.value) ||
      "#E3A840";
    let height =
      (value.parameters &&
        value.parameters.height &&
        value.parameters.height.value) ||
      22;
    let text =
      (value.parameters &&
        value.parameters.text &&
        value.parameters.text.value) ||
      t("noCostCargo");
    let back_to_up_url =
      (value.parameters &&
        value.parameters.icon &&
        value.parameters.icon.value) ||
      "https://cdnydm.com/permedia/Pmi2kQU1vT3zTXpOs71EBA.png?size=24x24";

    if (!schema) return null;
    return (
      <div className="action-edit-wrap">
        <div>
          <div className="popup-wrap">
            <div className="left-section">
              <div className="content">
                <div className="title-bar"></div>
                <div className="" ref={this._container}>
                  <OptionsGenerator path="action.value.parameters" t={t} />
                  <p style={{ marginLeft: "20px" }}>
                    {(schema && schema.condition_desc) ||
                      (widgetSchema && widgetSchema.condition_desc)}
                  </p>
                  <div
                    css={`
                      display: block !important;
                      margin: 10px 0px;
                    `}
                  >
                    <Button
                      type="button"
                      compact
                      icon
                      labelPosition="left"
                      onClick={this.props.handleOpen}
                    >
                      <Icon name="filter" />
                      {t("EditConditions")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <RawField name={parameters_path}>
              {({ field }) => (
                <Preview
                  inputText={{
                    title_back_basket,
                    show_on_title,
                    back_to_up_url,
                    title_back,
                    title_fore,
                    background_color,
                    position,
                    isFaviconShow,
                    show_number,
                    show_favicon,
                    animation,
                    text_color,
                    actionType,
                    freecargotext,
                    bannercolor,
                    height,
                    text,
                  }}
                  schema={widgetSchema}
                  parameters={field.value}
                  opt={this.props.opt && this.props.opt.preview}
                  t={t}
                />
              )}
            </RawField>
          </div>
        </div>
      </div>
    );
  }
}

export class ActionFrom extends React.PureComponent {
  /* save = (name) => {
		if (this._save) {
			let data = this._save();
			if (data) {
				let saveData = {
					name: name,
					parameters: data.parameters,
					props: data.schema.props,
					type: data.schema.value,
					component: data.schema.component,
				};
				if (this.state.index === -1) this.props.push(saveData);
				else this.props.replace(this.state.index, saveData);
				//this.props.saveAction(saveData, this.state.index);
			}
			//this.props.helper({ parameters: data.parameters, type: data.schema.value, component: data.schema.component });
			this.setState({ schema: null, type: null, index: -1 });
			this.props.setPage();
		}
	}; */

  render() {
    let { t, opt } = this.props;
    let previewType = opt && opt.preview && opt.preview.previewType;
    if (this.props.schema !== null) {
      if (this.props.schema.type === "popup")
        return <ActionPopupEditor {...this.props} t={t} />;
      switch (previewType) {
        case "previewTrue":
          return <ActionFormPreviewEditor {...this.props} t={t} />;
        case "previewFalse":
          return <ActionFormPreviewEditor {...this.props} t={t} />;
        case "onlyForm":
          return <ActionFormEditor {...this.props} t={t} />;
        default:
          return <ActionFormPreviewEditor {...this.props} t={t} />;
      }
      /* if (!previewHidden) return <ActionFormPreviewEditor {...this.props} t={t} />;
			if (previewHidden) return <ActionFormNoPreviewEditor {...this.props} t={t} />;
			return <ActionFormEditor {...this.props} t={t} />; */
    } else return <div>{t("Missingaction")}</div>;
  }
}

const ActionCard = (props) => {
  let { data, select, entegrations } = props;
  let whatsappBusinessEntegration = !!entegrations["whatsapp-business"];
  //let whatsappEntegration = !!entegrations["whatsapp"];
  if (
    whatsappBusinessEntegration ||
    data.value == "sendWebPushTag" ||
    data.value == "sendMailAction" ||
    data.value == "whatsappWebMessage"
  ) {
    return (
      <Card
        css={`
          cursor: pointer;
        `}
        link
        onClick={() => select(data)}
      >
        <Image
          css={`
            height: 120px;
            max-height: 120px;
            object-fit: cover;
          `}
          src={data.image || "/images/white-image.png"}
        />
        <Card.Content
          header={data.text || data.title}
          description={data.description || ""}
        />
      </Card>
    );
  } else {
    return (
      <Card
        css={`
          filter: grayscale(100%);
        `}
      >
        <Image
          css={`
            height: 120px;
            max-height: 120px;
            object-fit: cover;
          `}
          src={data.image || "/images/white-image.png"}
        />
        <Card.Content
          header={data.text || data.title}
          description={data.description || ""}
        />
        <Card.Content extra>
          <p>
            Please{" "}
            <a
              href="/help"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              contact us
            </a>{" "}
            for using this action.
          </p>
        </Card.Content>
      </Card>
    );
  }
};

class AddAction extends React.PureComponent {
  render() {
    console.log("this.props", this.props);
    return (
      <Modal
        dimmer="inverted"
        open={this.props.open}
        onClose={this.props.close}
      >
        <Modal.Header>
          <Icon name="add" />
          Add Action
        </Modal.Header>
        <Modal.Content image scrolling>
          <Card.Group centered style={{ width: "100%" }}>
            {_.map(this.props.action_list, (data, i) => (
              <ActionCard
                key={i}
                data={data}
                select={this.props.select}
                entegrations={this.props.entegrations}
              />
            ))}
          </Card.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" as="div" onClick={this.props.close}>
            <Icon name="chevron left" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export class ActionFromOld extends React.PureComponent {
  state = {
    addModal: false,
    schema: null,
    index: -1,
  };

  bootData(data) {
    const parameters = {};
    /* if (data.value === 'popupActionTag') {
			let { widgetList } = templateCache();
			let realForm = bootSub({ type: 'form', sub: widgetList[0].sub });
			parameters.type = widgetList[0].value;
			parameters.parameters = realForm.default;
		} */
    _.each(data.sub, (param) => {
      if (param.type === "tabs" || param.type === "accordion") {
        _.each(param.tabs, (tab) => {
          bootSub(tab);
          if (tab.__name) parameters[tab.__name] = tab.def_obj || tab.__default;
        });
      } else {
        bootSub(param);
        parameters[param.__name] = param.def_obj || param.__default;
      }
    });
    return { type: data.value, parameters };
  }

  addAction(data, index = -1) {
    //this.props.setPage(data, index);
    this.setState({
      addModal: false,
      currentValue: this.bootData(data),
      schema: data,
      index,
    });
  }

  editAction(currentValue, index) {
    let { actionList } = templateCache();
    let schema = _.find(actionList, { value: currentValue.type });
    // eslint-disable-next-line
    if (!schema) {
      return this.props.helper.remove(index);
    }
    this.bootData(schema);
    //TODO Merge currentValue and defaults

    //if (!schema) console.warn('Can not find schema for ', currentValue.type);
    //this.props.setPage(currentValue, index);
    this.setState({ currentValue, schema, index });
  }

  save = (name) => {
    if (this._save) {
      let data = this._save();
      if (data) {
        let saveData = {
          parameters: data.parameters,
          props: data.schema.props,
          type: data.schema.value,
          component: data.schema.component,
          status: data.status,
        };
        if (this.state.index === -1) this.props.helper.push(saveData);
        else this.props.helper.replace(this.state.index, saveData);
        //this.props.saveAction(saveData, this.state.index);
      }
      //this.props.helper({ parameters: data.parameters, type: data.schema.value, component: data.schema.component });
      this.setState({ schema: null, type: null, index: -1 });
      //this.props.setPage();
    }
  };

  cancel = () => {
    this.setState({ schema: null, type: null, index: -1 });
    //this.props.setPage();
  };

  remove = () => {
    this.props.helper.remove(this.state.index);
    this.setState({ schema: null, type: null, index: -1 });
    //this.props.setPage();
  };

  setSave(fn) {
    this._save = fn;
  }

  onDragEnd = (result) => {
    //console.log(result);
    let from = result.source && result.source.index;
    let to = result.destination && result.destination.index;
    if ((to === 0 || to) && from !== to) this.props.helper.move(from, to);
  };

  toggleStatus(index, data) {
    let save_data = {};
    _.assignIn(save_data, data);
    save_data.status = data.status === false;
    this.props.helper.replace(index, save_data);
  }

  get action_list() {
    let { actionList } = templateCache();
    let current_list = this.props.actions || [];
    let control = {};
    _.each(current_list, (it) => {
      control[it.type] = true;
    });
    return _.filter(actionList, (it) => !control[it.value]);
  }

  render() {
    let { t } = this.props;
    if (this.state.schema !== null) {
      return (
        <ActionFormEditorOld
          header={
            <div className="action-header">
              <Button as="div" size="small" color="teal" onClick={this.save}>
                {t("SaveAction")}
              </Button>
              <Button as="div" size="small" onClick={this.cancel}>
                {t("Cancel")}
              </Button>
              <span>{this.state.schema.text || ""} Action</span>
              {this.state.index !== -1 && (
                <TwoStepButton
                  className="ml-auto"
                  onClick={this.remove}
                  text="Remove"
                  second="Remove Verify"
                />
              )}
            </div>
          }
          currentValue={this.state.currentValue}
          schema={this.state.schema}
          index={this.state.index}
          setSave={this.setSave.bind(this)}
          t={this.props.t}
        />
      );
    }

    let { actionKeyMap, Forms, entegrations } = templateCache();
    let { action_list } = this;
    return (
      <div className="action-form-out-wrap">
        <MyDivider name="Action Mode" icon="lightning" />
        <TriggerSelector data={Forms.push_mode} />
        <MyDivider name="Actions" icon="bullhorn" />
        <AddAction
          select={this.addAction.bind(this)}
          open={this.state.addModal}
          close={() => this.setState({ addModal: false })}
          action_list={action_list}
          entegrations={entegrations}
        />
        {this.props.actions.length ? (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="actions" type="list">
              {(provided) => {
                return (
                  <div
                    className="action-form-wrap"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {_.map(this.props.actions, (data, index) => {
                      let cls = actionKeyMap[data.type] || {};
                      return (
                        <Draggable
                          key={data.type}
                          draggableId={data.type}
                          index={index}
                        >
                          {(provided) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                className="action-body"
                                {...provided.draggableProps}
                              >
                                <Segment
                                  {...provided.dragHandleProps}
                                  onClick={() => this.editAction(data, index)}
                                >
                                  {cls.image && (
                                    <Image
                                      className="attached"
                                      src={cls.image}
                                    />
                                  )}
                                  <div>
                                    <div className="title">
                                      {cls.text}
                                      {/* data.name || `Action #${index + 1}` */}
                                      <Icon name="angle right" size="large" />
                                    </div>
                                    <div className="description">
                                      {data &&
                                        data.parameters &&
                                        data.parameters.type &&
                                        ` (${
                                          data &&
                                          data.parameters &&
                                          data.parameters.type
                                        })`}
                                    </div>
                                  </div>
                                </Segment>
                                <div className="status-wrap">
                                  <Checkbox
                                    toggle
                                    onChange={() =>
                                      this.toggleStatus(index, data)
                                    }
                                    checked={data.status !== false}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        ) : null}
        {action_list && action_list.length ? (
          <Button
            as="div"
            basic
            size="large"
            style={{ width: 145 }}
            color="black"
            compact
            onClick={() => this.setState({ addModal: true })}
          >
            <Icon name="add" />
            Add Action
          </Button>
        ) : null}
      </div>
    );
  }
}

/**
 * Multi Action Backup
 * 
 * 
				<Button as="div" basic size="large" style={{ width: 145 }} color="black" compact onClick={() => this.setState({ addModal: true })}>
					<Icon name="add" />
					Add Action
				</Button>
 */

/**
 * <>
						{_.map(this.props.actions, (data, index) => (
							<Card key={index} onClick={() => this.editAction(data, index)} link fluid color={enumColor[index % enumColor.length]}>
								<Card.Content header={(data.name || `Action #${index + 1}`) + ` (${data.type})`} />
							</Card>
						))}
					</>
 */
