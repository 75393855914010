import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { post } from '../../util/api';
import { Segment, Container, Message } from 'semantic-ui-react';
class Payment extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.checkoutHandler();
	}
	checkoutHandler = async () => {
		let link = await post('/personalize/payment/custompaddlelink', {
			title: new URLSearchParams(window.location.search).get('title'),
			custom_price: new URLSearchParams(window.location.search).get('price'),
		});
		try {
			let { product = {} } = this.props;
			let email = this.props.access.user.loggedUserObj.email;
			if (product && product.id) {
				this.setState({ loading: false });
				window.Paddle.Checkout.open({
					override: link.data.response.url,
					email,
					country: 'AZ',
					quantity: 1,
					successCallback: () => {
						this.setState({ messageOnScreen: 'Process is successful!', messageColor: 'green' });
					},
					closeCallback: () => {
						this.setState({ messageOnScreen: 'Your process has been cancelled, see you again soon!', messageColor: 'red' });
					},
				});
			}
		} catch (err) {
			console.error(err);
		}
	};
	render() {
		let { loading } = this.state;
		if (loading)
			return (
				<Page title="Ödeme">
					<Page.Loader></Page.Loader>
				</Page>
			);
		return (
			<Page title="Ödeme">
				<Container>
					{this.state.messageOnScreen ? (
						<Segment>
							<Message content={this.state.messageOnScreen} color={this.state.messageColor}></Message>
						</Segment>
					) : (
						''
					)}
				</Container>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.personalize,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(Payment);
