import React from 'react';
import { templateCache, bootSub } from './CampaingHelpers';
import _ from 'lodash';
import { MyDivider, FastFieldArray } from './FormikInput';
import { Segment, Icon, Button, Image, Modal } from 'semantic-ui-react';
import { Formik, FastField as Field } from 'formik';
import { Preview } from './PopupPreview';
import { SubSection, viewDefinitions } from './FormParts';

class YtAction extends React.PureComponent {
	_editor = React.createRef();

	setPage = () => {};

	render() {
		const { props } = this;
		return (
			<FastFieldArray name={props.data.path}>
				{({ helper, list }) => {
					return <ActionEditor {...helper} ref={this._editor} actions={list} setPage={this.setPage} />;
				}}
			</FastFieldArray>
		);
	}
}

class YtPopup extends React.PureComponent {
	setSave = (fn) => {
		let save = this.props._editor && this.props._editor.setSave;
		if (save)
			save(() => {
				let data = fn();
				return { parameters: data.parameters, props: data.schema.props, type: data.schema.value, component: data.schema.component };
			});
	};

	render() {
		let save = this.props._editor && this.props._editor.save;
		return (
			<Modal dimmer="inverted" open={true} className="insert-wrap">
				<Modal.Header>
					<Button basic icon color="red" onClick={save}>
						<Icon name="close" />
					</Button>
					Görüntü Düzenleyici
				</Modal.Header>
				<Modal.Content>
					<ActionPopupEditor
						currentValue={this.props.value}
						data={this.props.data}
						setSave={this.setSave}
					/>
				</Modal.Content>
			</Modal>
		);
	}
}
viewDefinitions.action = YtAction;
viewDefinitions.popup = YtPopup;
class OptionsGenerator extends React.PureComponent {
	render() {
		return (
			<Field name={this.props.path || ''}>
				{({ field, form }) => {
					let data = form.values.schema;
					if (!data) return null;
					return <SubSection data={data} path={field.name} deep={2} key={data.value} list={data.sub} />;
				}}
			</Field>
		);
	}
}

export class ActionEditor extends React.PureComponent {
	state = {
		addModal: false,
		schema: null,
		index: -1,
	};

	bootData(data) {
		const parameters = {};
		if (data.value === 'popupAction') {
			let { widgetList } = templateCache();
			let realForm = bootSub({ type: 'form', sub: widgetList[0].sub });

			parameters.type = widgetList[0].value;
			parameters.params = realForm.default;
		}
		_.each(data.sub, (param) => {
			bootSub(param);
			parameters[param.__name] = param.def_obj || param.default;
		});
		return { type: data.value, parameters };
	}

	addAction(data, index = -1) {
		this.props.setPage(data, index);
		this.setState({ addModal: false, currentValue: this.bootData(data), schema: data, index });
	}

	editAction(currentValue, index) {
		let { actionList } = templateCache();
		let schema = _.find(actionList, { value: currentValue.type });
		//if (!schema) console.warn('Can not find schema for ', currentValue.type);
		this.props.setPage(currentValue, index);
		this.setState({ currentValue, schema, index });
	}

	save = (name) => {
		if (this._save) {
			let data = this._save();
			if (data) {
				let saveData = {
					name: name,
					parameters: data.params,
					props: data.schema.props,
					type: data.schema.value,
					component: data.schema.component,
				};
				if (this.state.index === -1) this.props.push(saveData);
				else this.props.replace(this.state.index, saveData);
				//this.props.saveAction(saveData, this.state.index);
			}
			//this.props.helper({ parameters: data.params, type: data.schema.value, component: data.schema.component });
			this.setState({ schema: null, type: null, index: -1 });
			this.props.setPage();
		}
	};

	cancel = () => {
		this.setState({ schema: null, type: null, index: -1 });
		this.props.setPage();
	};

	remove = () => {
		this.props.remove(this.state.index);
		this.setState({ schema: null, type: null, index: -1 });
		this.props.setPage();
	};

	setSave(fn) {
		this._save = fn;
	}

	render() {
		if (this.state.schema !== null) {
			return (
				<ActionPopupEditor
					currentValue={this.state.currentValue}
					schema={this.state.schema}
					index={this.state.index}
					setSave={this.setSave.bind(this)}
				/>
			);
		}

		let { actionKeyMap, actionList } = templateCache();
		return (
			<div className="action-form-out-wrap">
				{/* <AddAction select={this.addAction.bind(this)} open={this.state.addModal} close={() => this.setState({ addModal: false })} /> */}
				{this.props.actions.length ? (
					<div className="action-form-wrap">
						{_.map(this.props.actions, (data, index) => {
							let cls = actionKeyMap[data.type] || {};
							return (
								<Segment key={index} onClick={() => this.editAction(data, index)}>
									{cls.image && <Image className="attached" src={cls.image} />}
									<div>
										<div className="title">
											{data.name || `Action #${index + 1}`}
											<Icon name="angle right" size="large" />
										</div>
										<div className="description">
											{cls.text}
											{data && data.params && data.params.type && ` (${data && data.params && data.params.type})`}
										</div>
									</div>
								</Segment>
							);
						})}
					</div>
				) : null}
				<Button as="div" basic size="large" style={{ width: 145 }} color="black" compact onClick={() => this.addAction(actionList[0])}>
					<Icon name="add" />
					Add Popup
				</Button>
			</div>
		);
	}
}

class ActionPopupEditor extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: Boolean(props.currentValue && props.currentValue && props.currentValue.type),
			type: props.currentValue && props.currentValue && props.currentValue.type,
		};
	}

	get schema() {
		const { data } = this.props;
		return this.state.type && _.find(data.list, { value: this.state.type });
	}

	render() {
		const { currentValue } = this.props;
		return (
			<Formik initialValues={{ parameters: currentValue && currentValue.parameters, schema: this.schema }}>
				{({ values }) => {
					if (this.props.setSave) this.props.setSave(() => values);
					return (
						<div className="action-edit-wrap">
							<div>
								<div className="popup-wrap">
									<div className="left-section">
										<div className="scroll-body">
											<MyDivider icon="options" name="Options" />
											<div className="content">
												<OptionsGenerator path="parameters" />
											</div>
										</div>
									</div>
									<Preview _default="empty" _disabledlist={true} schema={values.schema} parameters={values.parameters} />
								</div>
							</div>
						</div>
					);
				}}
			</Formik>
		);
	}
}
