import React, { Component } from 'react';
import { Segment, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MyDivider } from '../../components/FormikInput';

class BotScript extends Component {
	state = {
		testBtn: 'copy',
		liveBtn: 'copy',
	};
	handleClick(btn) {
		this.setState({ [btn]: 'copied' });
	}
	render() {
		let { t, config } = this.props;
		let { test_id /* , token = '832186a483e9246f20e187c781ae5ec5' */ } = config;
		let { testBtn } = this.state;
		let scriptCodeTest = `<script type="text/javascript">
    window.DialogTab||(function(d,c,script){
        d.getElementById(c)||(script=d.createElement('script'),
        script.id=c,script.async=true,
        script.src='//cdn.dialogtab.com/live/slim.js',
        script.onload=function(){
        new DialogTab().init({pid: '832186a483e9246f20e187c781ae5ec5',
            bot_input_disabled: true,
            __bot_token: '${test_id}'})},
        d.getElementsByTagName('head')[0].appendChild(script))
    })(document,'DialogTab');
</script>
`;
		return (
			<Segment className="bot-settings-wrap">
				<p>{t('scriptDesc')}</p>
				<MyDivider icon="code" name={t('TestScript')} />
				<div style={{ padding: '20px', background: '#EAEDF4', marginTop: '15px', marginBottom: '15px' }}>
					<div style={{ textAlign: 'end' }}>
						<CopyToClipboard text={scriptCodeTest}>
							<Button
								type="button"
								color={testBtn === 'copy' ? 'blue' : 'green'}
								style={{ marginLeft: 'auto' }}
								onClick={() => this.handleClick('testBtn')}
							>
								{t(testBtn)}
							</Button>
						</CopyToClipboard>
					</div>
					<pre readOnly name="code" id="code" cols="100" rows="10" style={{ margin: 0 }}>
						{scriptCodeTest}
					</pre>
				</div>
				{/* <div>
					<p>{t('scriptText')}</p>
				</div> */}
				{/* 	<MyDivider icon="code" name={t('LiveScript')} />
				<div style={{ padding: '20px', background: '#EAEDF4', marginTop: '15px', marginBottom: '15px' }}>
					<div style={{ textAlign: 'end' }}>
						<CopyToClipboard text={scriptCodeLive}>
							<Button
								type="button"
								color={liveBtn === 'copy' ? 'blue' : 'green'}
								style={{ marginLeft: 'auto' }}
								onClick={() => this.handleClick('liveBtn')}
							>
								{t(liveBtn)}
							</Button>
						</CopyToClipboard>
					</div>
					<pre readOnly name="code" id="code" cols="100" rows="10" style={{ margin: 0 }}>
						{scriptCodeLive}
					</pre>
				</div>
				<div>
					<p>{t('scriptTextLive')}</p>
				</div> */}
			</Segment>
		);
	}
}

export default withTranslation()(BotScript);
