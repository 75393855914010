import React, { Component } from 'react';
import { Button, Segment, Icon, Table, Dropdown, Form } from 'semantic-ui-react';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import Page from '../../components/Page';
import { get, post } from '../../util/api';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

/* export function TextInput(props) {
	return (
		<FastField name={props.name} style={{ width: '100%' }}>
			{({ field }) => (
				<MaterialInput
					css={`
						width: 100%;
						height: 22px;
					`}
					type={props.type || 'text'}
					name={field.name}
					placeholder={props.placeholder || '...'}
					value={field.value || ''}
					onChange={field.onChange}
				/>
			)}
		</FastField>
	);
} */
export const FormDropdown = styled(Dropdown)`
	min-width: ${(props) => props.size || 200}px;
	//text-align: center !important;
	height: 22px;

	.text {
		font-weight: 400 !important;
	}
	.dropdown.icon {
		float: right;
	}
`;

export function TableLine(props) {
	if (_.has(props, 'single'))
		return (
			<Table.Row>
				<Table.Cell>{props.title || ''}</Table.Cell>
				<Table.Cell>{props.children}</Table.Cell>
			</Table.Row>
		);
	return (
		<Table.Row>
			<Table.Cell>{props.title || ''}</Table.Cell>
			<Table.Cell>
				{props.children}
				<p style={{ marginLeft: '10px' }}>{props.description || ''}</p>
			</Table.Cell>
		</Table.Row>
	);
}

class BotSettings extends Component {
	state = {
		btnLoading: false,
		loading: true,
		didchange: false,
	};
	_form = React.createRef();

	componentDidMount() {
		this.load();
	}

	async handleSubmit() {
		this.setState({ btnLoading: true });
		let { config } = this.state;
		await post('/personalize/bot/postmicroproduct', { data: config });
		this.setState({ config, btnLoading: false, didchange: false });
	}

	async load() {
		let response = await get('/personalize/bot/getmicroproduct');
		this.setState({
			loading: false,
			first: true,
			config: response && response.data,
		});
	}
	handleArrayChange = (e, { name, i, value }) => {
		let config = this.state.config;
		config[i][name] = value;
		this.setState({ config, didchange: true });
	};
	handleRemove = (index) => {
		let config = this.state.config;
		_.pullAt(config, [index]);
		this.setState({ config, didchange: true });
	};
	render() {
		let { t } = this.props;
		let { config, btnLoading, didchange } = this.state;
		if (this.state.loading)
			return (
				<Segment className="bot-settings-wrap">
					<Page.Loader />
				</Segment>
			);
		return (
			<Segment className="bot-settings-wrap">
				<Prompt when={didchange} message={(location) => `${t('AreYouSureWithoutSaving', { pathname: location.pathname })}`} />
				<Form>
					<Table definition>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell width={4}>
									<Button
										color="teal"
										fluid
										size="small"
										type="button"
										style={{ marginLeft: 'auto' }}
										onClick={() => {
											if (config[0].id && config[0].key && config[0].desc) {
												config.unshift({ id: '', key: '', desc: '' });
											}
											this.setState({ config, didchange: true });
										}}
									>
										Ekle
									</Button>
								</Table.HeaderCell>
								<Table.HeaderCell style={{ display: 'flex', alignItems: 'center' }}>
									{t('Value')}
									<Button primary style={{ marginLeft: 'auto' }} size="small" loading={btnLoading} onClick={() => this.handleSubmit()}>
										{t('SaveConfig')}
									</Button>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{_.map(config, (it, i) => (
								<TableLine title={it.key} key={i}>
									<Form.Group>
										<Form.Input
											label="Id"
											name="id"
											required
											i={i}
											value={it.id}
											onChange={this.handleArrayChange}
											placeholder="Sipariş Durumu Id"
										></Form.Input>
										<Form.Input
											label="Başlık"
											name="key"
											required
											i={i}
											value={it.key}
											onChange={this.handleArrayChange}
											placeholder="Sipariş Durumu Başlık"
										></Form.Input>
										<Form.Field style={{ marginLeft: 'auto', marginTop: 'auto' }}>
											<Button icon type="button" onClick={() => this.handleRemove(i)}>
												<Icon name="trash"></Icon>
											</Button>
										</Form.Field>
									</Form.Group>
									<Form.TextArea
										label="Açıklama"
										name="desc"
										required
										i={i}
										value={it.desc}
										onChange={this.handleArrayChange}
										placeholder="Kartta yazacak olan yazı"
									></Form.TextArea>
								</TableLine>
							))}
						</Table.Body>
					</Table>
				</Form>
			</Segment>
		);
	}
}

export default function SettingsPage(props) {
	let { t } = useTranslation();
	return (
		<div className="editor-full-screen">
			<BotSettings {...props} t={t} />
		</div>
	);
}
