import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';

import ActionTable from './action_table';
import RequestTable from './request_table';
import FootprintTable from './footprint_table';
import UserTable from './user_table';
import InstalledTable from './installed_action';

export default (props) => {
	console.log('p', props);
	let { access } = props;
	const user = access.user;
	const limited_admin = user && user.loggedUserObj && user.loggedUserObj.extra && user.loggedUserObj.extra.limited_admin;
	let { t } = useTranslation();
	return (
		<Page disabled={true} flex>
			<Page.Wrap className="wrap-16 d-flex flex-column">
				<Menu className="settings" pointing secondary>
					{!limited_admin && (
						<>
							<Menu.Item as={NavLink} to="/yta" exact>
								<Icon name="table" />
								{t('Actions')}
							</Menu.Item>
							<Menu.Item as={NavLink} to="/yta/installed_table">
								<Icon name="table" />
								{t('Installed')}
							</Menu.Item>
							<Menu.Item as={NavLink} to="/yta/request_table">
								<Icon name="table" />
								{t('RequestAccess')}
							</Menu.Item>
							<Menu.Item as={NavLink} to="/yta/footprint_table">
								<Icon name="table" />
								{t('Footprint')}
							</Menu.Item>
						</>
					)}

					<Menu.Item as={NavLink} to="/yta/user_table">
						<Icon name="table" />
						{t('User')}
					</Menu.Item>
				</Menu>
				{!limited_admin ? (
					<Switch>
						<Route path="/yta/installed_table" component={InstalledTable} />
						<Route path="/yta/user_table" component={UserTable} />
						<Route path="/yta/footprint_table" component={FootprintTable} />
						<Route path="/yta/request_table" component={RequestTable} />
						<Route path="/yta" component={ActionTable} />
						<Redirect to="/yta" />
					</Switch>
				) : (
					<Switch>
						<Route path="/yta/user_table" component={UserTable} />
						<Redirect to="/yta/user_table" />
					</Switch>
				)}
			</Page.Wrap>
		</Page>
	);
};
