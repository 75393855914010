import * as ActionTypes from '../constants/actionTypes';

export const load_product = (product_type = 'personalize') => ({
	type: ActionTypes.PRODUCT_REQUESTED,
	product_type,
});

export const select_product = (product_type = 'personalize') => ({
	type: ActionTypes.PRODUCT_SELECTED,
	product_type,
});

export const product_set_temp = (product_type = 'personalize', data) => ({
	type: ActionTypes.PRODUCT_SET_TEMP,
	product_type,
	data,
});
