import React from "react";
import Page from "../../components/Page";
import {
  Message,
  Icon,
  Dropdown,
  Label,
  Loader,
  Button,
  Table,
  Header,
  Responsive,
  Segment,
  Popup,
  Modal,
} from "semantic-ui-react";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { get, post, log } from "../../util/api";
import { MaterialInput } from "../../components/Helpers";
//import { Goto } from '../../components/Url';
import _ from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import cs from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
//const axios = require("axios");
import "moment/locale/en-in";
import "moment/locale/tr";

const status = {
  active: { text: "Active", color: "green" },
  hidden: { text: "Hidden", color: "grey" },
  disabled: { text: "Disabled", color: "grey" },
  test: { text: "Test", color: "yellow" },
};

const DateLabel = styled(Label)`
  margin: 0 0 5px 0 !important;
  width: 100%;
  text-align: right;
  .detail {
    text-align: right;
    width: 40px;
  }
`;

const getName = (data = {}) => data.name || `action #${data.id}`;

const TemplateCard = ({ item = {}, centered }) => {
  return (
    <Segment
      as={Link}
      to={`/actions/create/template-${item.id}`}
      className={cs("template-card", { centered })}
    >
      <div className="icon-side">
        <img src={item.image || "/icons/layout.svg"} alt="" />
      </div>
      <div className="template-body">
        <div className="title">{item.title}</div>
        <div className="description">{item.description}</div>
      </div>
    </Segment>
  );
};
let temp_cache;
class Templates extends React.Component {
  state = {
    list: temp_cache,
    loading: !temp_cache,
  };

  componentDidMount() {
    if (!temp_cache)
      get("/campaign/template/listV2?type=campaign").then((it) => {
        temp_cache = it.data;
        this.setState({ list: it.data, loading: false });
      });
  }

  getlist() {
    let templates = this.state.list;
    let current = this.props.list || [];
    return _.filter(templates, (it) => {
      return !_.find(current, { _key: it.id });
    });
  }

  render() {
    return (
      <div className="template-wrap">
        {/* <TemplateCard centered={true} item={{ title: 'Blank Template', image: '/icons/blank-file.svg', description: '' }} /> */}
        {this.state.loading || this.props.loading ? (
          <Loader />
        ) : (
          _.map(this.getlist(), (item, i) => (
            <TemplateCard key={item.id} item={item} />
          ))
        )}
      </div>
    );
  }
}

class ActionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template_modal: false,
      results: [],
      search: "",
      searching: false,
      remove: false,
      column: null,
      data: "",
      direction: null,
      open: false,
      developer: (localStorage && localStorage.developer) || false,
      daily_usage: [],
      list: temp_cache,
      loading: !temp_cache,
      stepsEnabled: false,
      initialStep: 0,
      exitOnEsc: true,
      exitOnOverlayClick: true,
      hintsEnabled: false,
      hints: [{ hint: "Hint enabled", hintPosition: "middle-right" }],
    };
  }
  // state = {
  // 	loading: true,
  // 	template_modal: false,
  // 	results: [],
  // 	search: '',
  // 	searching: false,
  // 	remove: false,
  // 	column: null,
  // 	data: '',
  // 	direction: null,
  // 	open: false,
  // 	developer: (localStorage && localStorage.developer) || false,
  // 	daily_usage: [],
  // };

  _first = React.createRef();
  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  toggleSteps = () => {
    this.setState((prevState) => ({
      stepsEnabled: !prevState.stepsEnabled,
    }));
  };

  componentDidMount() {
    this.getList();
    this.returnHourlyUsage();
  }
  async getList() {
    let data = await get("/campaign/all/list");
    let actiondata = await get(
      "/campaign/template/listV2?type=campaign&developer=" +
        this.state.developer
    );
    let list = data.data || [];
    let ids = [];
    _.each(list, (e) => {
      ids.push(e.id);
    });
    let buildData = await post("/personalize/getBuildCampaign", {
      camp_ids: ids,
    });
    if (buildData && buildData.data && buildData.data.data) {
      _.each(list, (e, i) => {
        let id = e.id;
        e.finished = _.find(buildData.data.data, { cid: id });
      });
    }
    let actionList = actiondata.data;
    this.setState({
      loading: false,
      list,
      results: _.fill(Array(list.length), true),
      actionList,
    });
  }
  onChange = (e, { name, value }) => {
    if (name === "search") {
      this.setState({ search: value, searching: true });

      clearTimeout(this._search);
      if (!value)
        return this.setState({
          searching: false,
          search: "",
          results: _.fill(Array(this.state.list.length), true),
        });
      else this.setState({ search: value, searching: true });
      this._search = setTimeout(() => {
        const re = new RegExp(_.escapeRegExp(value), "i");
        const isMatch = (result) => re.test(result.name) || re.test(result.id);

        this.setState({
          searching: false,
          results: _.map(this.state.list, isMatch),
        });
      }, 300);
    } else this.setState({ [name]: value });
  };

  _toggle = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    let { value, index } = data;
    if (!value) return;
    let it;
    this.setState({
      list: _.map(this.state.list, (data, i) => {
        if (i !== index) return data;
        it = data;
        data.statusLoading = true;
        return data;
      }),
    });
    let obj = { id: it.id, status: value };
    post("/campaign/save", { data: obj }).then(() => {
      this.setState({
        list: _.map(this.state.list, (data, i) => {
          if (i !== index) return data;
          data.statusLoading = false;
          data.status = value;
          return data;
        }),
      });
    });
  };
  handleRemove = async () => {
    let selected = this.state.selected;
    this.setState({ remove: true });
    await post("/campaign/delete", {
      id: selected.id,
    }).then(() => {
      this.close();
      this.getList();
      let newlist = _.filter(this.state.list, function (it) {
        return it.id !== selected.id;
      });
      let is_recomentation = !!_.filter(newlist, function (it) {
        return it._type === "recommendation";
      }).length;
      if (!is_recomentation) {
        post("/personalize/recommendationSave", { force: false }).then(
          (data) => {}
        );
      }
    });
  };

  _refreshUpdate() {
    window.location.href = "/subscription";
  }

  async returnHourlyUsage() {
    let { product } = this.props;
    let { id: pid } = product;
    let now = moment();
    let daily_bucket = now.format("YYYY-MM-DD");
    //daily_bucket = '2022-03-07'; //todo delete
    let data = await log("/collectaction/getUserCampaignHourly", {
      pid,
      bucket: daily_bucket,
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error.message);
      });
    if (data && data.data && data.data.data) {
      this.setState({ daily_usage: data.data.data });
    }
  }

  closeConfigShow = (id) => () => {
    let selected = _.find(this.state.list, { id });
    this.setState({ open: true, remove: false, selected });
  };
  close = () => this.setState({ open: false });
  inside() {
    let { t, product } = this.props;
    let productType = product.type;
    let { actionList, daily_usage } = this.state;
    if (this.state.loading) return <Page.Loader />;
    const len = this.state.list.length;
    const checkActive = _.findIndex(this.state.list, { status: "active" });
    const { open, results } = this.state;
    const options = [
      { key: "active", icon: "check", text: t("Active"), value: "active" },
      //	{ key: 'hidden', icon: 'hide', text: 'Hidden', value: 'hidden' },
      { key: "test", icon: "eye", text: "Test", value: "test" },
      {
        key: "disabled",
        icon: "close",
        text: t("Disabled"),
        value: "disabled",
      },
    ];

    var steps = [
      {
        intro: t("actionTutorial"),
      },
      {
        intro: t("intro"),
        tooltipClass: "input",
        position: "right",
        element: "input",
      },
    ];
    var optionsV2 = {
      nextLabel: t("Next"),
      prevLabel: t("back"),
      skipLabel: t("finish"),
      doneLabel: t("Close"),
      buttonClass: "guiding",
      showProgress: true,
    };
    if (len)
      return (
        <Page.Wrap>
          <Button
            size="small"
            onClick={this.toggleSteps}
            color={"instagram"}
            style={{ top: "1%", right: "2%", position: "fixed" }}
          >
            {t("tutorial")}
          </Button>
          <MaterialInput
            value={this.state.search}
            onChange={this.onChange}
            name={t("Search")}
            loading={this.state.searching}
            icon="search"
            placeholder={t("SearchPlaceholder")}
          />
          <Modal open={open} onClose={this.close}>
            <Header
              icon="trash"
              content={`${t("Delete")} ${getName(this.state.selected)}`}
            />
            <Modal.Content>
              <p>{t("deleteAction")}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={this.close}>
                <Icon name="remove" /> {t("no")}
              </Button>
              <Button
                loading={this.state.remove}
                color="green"
                onClick={this.handleRemove}
              >
                <Icon name="checkmark" /> {t("yes")}
              </Button>
            </Modal.Actions>
          </Modal>
          <Table selectable compact>
            <Responsive
              as={Table.Header}
              minWidth={Responsive.onlyTablet.minWidth}
            >
              <Table.Row>
                <Table.HeaderCell width="4">{t("Name")}</Table.HeaderCell>
                <Table.HeaderCell width="2">{t("Type")}</Table.HeaderCell>
                <Table.HeaderCell width="4">{t("Updated")}</Table.HeaderCell>
                <Table.HeaderCell width="3">{t("dailyLimit")}</Table.HeaderCell>
                <Table.HeaderCell width="3" textAlign="right">
                  {t("Status")}
                </Table.HeaderCell>
              </Table.Row>
            </Responsive>
            <Table.Body>
              {_.includes(results, true) ? (
                _.map(this.state.list, (data, index) => {
                  if (!results[index]) {
                    return null;
                  }
                  return (
                    <Table.Row key={data.id}>
                      <Table.Cell selectable>
                        <Link
                          css={`
                            text-transform: capitalize;
                            @media (max-width: ${Responsive.onlyTablet
                                .minWidth - 1}px) {
                              padding-left: 0 !important;
                            }
                          `}
                          to={`/${
                            data._type === "schedule" ? "campaigns" : "actions"
                          }/${data.id}`}
                        >
                          {getName(data)}
                          {/* {console.log('ss', actionList, data, _.find(actionList, { id: data._key }))} */}
                          {data._type === "schedule" ||
                          (_.find(actionList, { id: data._key }) &&
                            (_.find(actionList, { id: data._key }).config[
                              productType
                            ] ||
                              _.find(actionList, { id: data._key }).config
                                .base)) ? (
                            ""
                          ) : (
                            <Popup
                              content={t("thisActionDontUseType", {
                                type: productType,
                              })}
                              trigger={
                                <Icon
                                  color="red"
                                  name="warning sign"
                                  style={{ marginLeft: "10px" }}
                                />
                              }
                            />
                          )}
                        </Link>
                        <Responsive
                          {...Responsive.onlyMobile}
                          css={`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            .ui.label {
                              margin: 0 !important;
                            }
                          `}
                        >
                          <Link
                            css={`
                              padding-left: 0 !important;
                              color: #333;
                            `}
                            to={`/actions/${data.id}`}
                          >
                            {t("Updated")}:{" "}
                            {moment(data.updated_at)
                              .locale(t("lang"))
                              .format("LL - HH:mm")}
                          </Link>
                          <Dropdown
                            onChange={this._toggle}
                            size="tiny"
                            direction="left"
                            index={index}
                            options={options}
                            value={data.status || "active"}
                            icon={false}
                            trigger={
                              <DateLabel
                                color={status[data.status || "active"].color}
                                size="tiny"
                              >
                                {data.statusLoading && (
                                  <Icon name="asterisk" loading />
                                )}
                                {t("" + status[data.status || "active"].text)}
                              </DateLabel>
                            }
                          />
                          <Button
                            color="red"
                            icon="trash"
                            alt={data.id}
                            onClick={this.closeConfigShow(data.id)}
                            style={{
                              margin: "0 0 0 4px",
                              fontSize: "10px",
                              padding: ".5833em .833em",
                            }}
                          />
                        </Responsive>
                      </Table.Cell>
                      <Table.Cell>
                        {data._type === "schedule"
                          ? t("Schedule")
                          : t("Action")}
                      </Table.Cell>
                      <Responsive
                        as={Table.Cell}
                        minWidth={Responsive.onlyTablet.minWidth}
                      >
                        {moment(data.updated_at)
                          .locale(t("lang"))
                          .format("LL - HH:mm")}
                      </Responsive>
                      <Table.Cell>
                        <a style={{ color: "black" }}>
                          {(daily_usage &&
                            _.find(daily_usage, { camp_id: "" + data.id }) &&
                            _.find(daily_usage, { camp_id: "" + data.id })
                              .total) ||
                            "0"}
                        </a>
                      </Table.Cell>
                      <Responsive
                        as={Table.Cell}
                        minWidth={Responsive.onlyTablet.minWidth}
                        textAlign="right"
                      >
                        {index === 0 && (
                          <Popup
                            header={t("dontHaveActiveCampaign")}
                            content={t("ClickToActivate")}
                            open={
                              checkActive === -1 && data.status === "disabled"
                            }
                            position="top center"
                            style={{ zIndex: 1 }}
                            context={this._first}
                          />
                        )}
                        {data.finished && data.finished.block && (
                          <Popup
                            trigger={
                              <Icon
                                size={"large"}
                                style={{
                                  paddingRight: "28px",
                                  cursor: "pointer",
                                }}
                                name="exclamation triangle"
                                color="red"
                                onClick={this._refreshUpdate}
                              />
                            }
                            content={t("dailyLimitUp")}
                            position="top right"
                          />
                        )}
                        <Dropdown
                          onChange={this._toggle}
                          size="big"
                          direction="left"
                          index={index}
                          options={options}
                          value={data.status || "active"}
                          icon={false}
                          trigger={
                            <Button
                              size="tiny"
                              color={status[data.status || "active"].color}
                            >
                              {data.statusLoading && (
                                <Icon name="asterisk" loading />
                              )}
                              {t("" + status[data.status || "active"].text)}
                              {index === 0 && (
                                <span className="ref-span" ref={this._first} />
                              )}
                            </Button>
                          }
                        />

                        <Button
                          color="red"
                          icon="trash"
                          size="tiny"
                          alt={data.id}
                          onClick={this.closeConfigShow(data.id)}
                        />
                      </Responsive>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell style={{ textAlign: "center" }} colSpan="3">
                    {t("NoDataShowing")}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {/* <Segment
						textAlign="center"
						css={`
							width: 600px;
							max-width: 100%;
							margin: 0 auto !important;
						`}
					>
						<p>{t('WatchTutorial')}</p>
						<Link to="/help/videos">
							<Icon name="video" /> {t('GotoTutorials')}
						</Link>
					</Segment> */}
          <Steps
            enabled={this.state.stepsEnabled}
            steps={steps}
            initialStep={this.state.initialStep}
            exitOnEsc={this.state.exitOnEsc}
            exitOnOverlayClick={this.state.exitOnOverlayClick}
            options={optionsV2}
            onExit={this.onExit}
          />
        </Page.Wrap>
      );
    return (
      <Page.Wrap>
        <Message
          css={`
            text-align: center;
          `}
          info
        >
          <Message.Header>{t("dontHaveActiveCampaign")}</Message.Header>
          <br></br>
          <p>
            <Button as={Link} to="/" primary>
              {t("CreateAction")}
            </Button>
          </p>
        </Message>
        {/* <div
					css={`
						text-align: center;
						margin-top: 60px;
					`}
				>
					<h3>{t('GetStartedWithCollectAction')}</h3>
					<div
						css={`
							max-width: 100%;
							width: 720px;
							margin: 10px auto;
						`}
					>
						<Embed
							css={`
								border-radius: 6px;
							`}
							iframe={{
								allowFullScreen: true,
								style: {},
								src:
									'//www.youtube.com/embed/uVtG0CXye1c?autohide=true&amp;amp;autoplay=0&amp;amp;color=%23444444&amp;amp;hq=true&amp;amp;jsapi=false&amp;amp;modestbranding=false&amp;amp;rel=1',
							}}
							defaultActive
						/>
					</div>
					<p style={{ marginTop: 50 }}>
						<h4>{t('CheckTutorialsPage')}</h4>
						<Goto to={`/help/videos`}>
							<Button primary>
								<Icon name="video" />
								{t('GotoTutorials')}
							</Button>
						</Goto>
					</p>
				</div>
		 */}{" "}
      </Page.Wrap>
    );
  }

  openModal = () => {
    this.setState({ template_modal: true });
  };

  render() {
    let { t } = this.props;
    return (
      <Page
        icon="bullhorn"
        title={t("Actions")}
        extra={
          <div
            css={`
              margin-left: auto;
            `}
          >
            {/* <Button size="small" color="blue" onClick={this.openModal}>
							Create Action
						</Button> */}

            <Modal
              dimmer="inverted"
              open={this.state.template_modal}
              className="insert-wrap template-modal"
              onClose={() => this.setState({ template_modal: false })}
            >
              <Modal.Header>
                <Button
                  basic
                  icon
                  color="red"
                  onClick={() => this.setState({ template_modal: false })}
                >
                  <Icon name="close" />
                </Button>
                <div>{t("Templates")}</div>
              </Modal.Header>
              <Modal.Content
                css={`
                  position: relative;
                  overflow: auto !important;
                `}
              >
                <Templates
                  list={this.state.list}
                  loading={this.state.loading}
                />
              </Modal.Content>
            </Modal>
          </div>
        }
      >
        {this.inside()}
      </Page>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(ActionList));
