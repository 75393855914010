import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Button, Label } from "semantic-ui-react";
import Page from "../../components/Page";
import { get } from "../../util/api";
import _, { result } from "lodash";
import { Link } from "react-router-dom";
import { MyDividerCenter } from "../../components/FormikInput";
//import TagList from './taglist.json';
import { withTranslation } from "react-i18next";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import "moment/locale/en-in";
import "moment/locale/tr";

const TemplateCard = ({ item = {}, t, i, type, isActiveAction }) => {
  item.rate = item.vote_count ? item.vote_amount / item.vote_count : 0;
  let language = t("lang");
  //let nu = 1;
  //let otherlist = [];
  /* let filterList = TagList;
	if (type === 'flo') {
		filterList = [
			{
				text: 'Flo',
				key: 'flo',
				value: 'flo',
				color: 'blue',
			},
		];
	} */
  /* let filterIndex = _.findIndex(filterList, function (it) {
		return it.value === type;
	}); */
  let borderColor =
    item.config[type] || item.config.base || "" ? "border-green" : "border-red";
  let isActive;
  if (isActiveAction > 0) {
    isActive = isActiveAction ? "border-green" : "";
  }
  let temptype = item.type === "schedule" ? "schedule" : "action";
  return (
    <div className="ac-card">
      <Segment
        as={Link}
        to={"/" + temptype + "?id=" + item.id}
        className={`template-card-menu ${
          isActiveAction > 0
            ? isActiveAction
              ? "border-green"
              : ""
            : borderColor
        }`}
      >
        <div className="box">
          <div className="icon-side">
            <img
              src={item.settings.image[language] || "/icons/layout.svg"}
              alt={item.id}
              width={120}
            />
          </div>
          <div className="template-body">
            <h2>{item.settings.title[language]}</h2>
            <div>
              <div className="template-body-action-loaded">
                <img
                  src="/images/star.svg"
                  alt="star"
                  width={14}
                  style={{ marginRight: "5px" }}
                ></img>
                <span>{item.rate || 3.2}</span>
                {isActiveAction > 0 ? (
                  <Label color="green">{t("installed")}</Label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <p className="max-lines">{item.settings.description[language]}</p>
            <br />
            {/* <div className="d-flex">
							{item.config[type] ? (
								<Label className="" color={filterList[filterIndex].color} key={filterList[filterIndex].value}>
									{filterList[filterIndex].text}
								</Label>
							) : (
								''
							)}
							{item.config[type]
								? function () {
										return nu++;
								  }
								: ''}
							{_.map(
								_.filter(filterList, function (o) {
									return o.value !== type;
								}),
								function (it, i) {
									if (item.config[it.key]) {
										nu++;
										if (nu <= 4) {
											return (
												<Label className="" color={it.color} key={i}>
													{it.text}
												</Label>
											);
										}
										if (nu > 4) {
											otherlist.push({ text: it.text });
										}
									}
								}
							)}
							{otherlist.length > 0 && (
								<Popup
									content={
										<div>
											{_.map(otherlist, function (a) {
												return <p>{a.text}</p>;
											})}
										</div>
									}
									position="bottom right"
									trigger={
										<Label className="" color="grey">
											...
										</Label>
									}
								/>
							)}
						</div>
				 */}{" "}
          </div>
        </div>
        <span className={"label-tag " + item.label}>{item.label || ""}</span>
      </Segment>
    </div>
  );
};

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listfilter:
        this.props.product && this.props.product.type === "flo" ? "flo" : "all",
      developer: (localStorage && localStorage.developer) || false,
      activeActions: [],
      stepsEnabled: false,
      initialStep: 0,
      exitOnEsc: true,
      exitOnOverlayClick: true,

      hintsEnabled: false,
      hints: [{ hint: "Hint enabled", hintPosition: "middle-right" }],
    };
  }
  // state = {
  // 	loading: true,
  // 	listfilter: this.props.product && this.props.product.type === 'flo' ? 'flo' : 'all',
  // 	developer: (localStorage && localStorage.developer) || false,
  // };
  componentDidMount() {
    const { t } = this.props;
    let language = t("lang") || null;
    get(
      "/campaign/template/listV2?type=campaign&developer=" +
        this.state.developer
    ).then((it) => {
      if (language) {
        this.setState({
          list: _.orderBy(it.data, [`settings.orders.${language}`], "asc"),
          loading: false,
        });
      } else {
        this.setState({
          list: _.orderBy(it.data, "order", "asc"),
          loading: false,
        });
      }
      // this.setState({
      //   list: _.orderBy(it.data, "order", "asc"),
      //   loading: false,
      // });
    });

    get("/campaign/all/list").then((res) => {
      setTimeout(() => {
        let listLength =
          this.state && this.state.list && this.state.list.length;
        if (listLength > 0) {
          let actionList = this.state.list;

          let result = actionList.filter((o1) =>
            res.data.some((o2) => o1.id === o2._key && o2.status !== "disabled")
          );
          this.setState({ activeActions: _.orderBy(result) });
        }
      }, 100);
    });
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  toggleSteps = () => {
    this.setState((prevState) => ({
      stepsEnabled: !prevState.stepsEnabled,
    }));
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  render() {
    let { loading, list, listfilter, activeActions } = this.state;
    let { t, product } = this.props;
    var steps = [
      {
        intro: t("homeTutorial1"),
        tooltipClass: ".ac-card .box:nth-child(1)",
        position: "right",
        element: ".ac-card .box:nth-child(1)",
      },
      {
        intro: t("homeTutorial2"),
        tooltipClass: ".header-item.item:nth-child(2)",
        position: "right",
        element: ".header-item.item:nth-child(2)",
      },
      {
        intro: t("homeTutorial3"),
        tooltipClass: ".header-item.item:nth-child(3)",
        position: "right",
        element: ".header-item.item:nth-child(3)",
      },
      {
        intro: t("homeTutorial4"),
        tooltipClass: ".header-item.item:nth-child(4)",
        position: "right",
        element: ".header-item.item:nth-child(4)",
      },
      {
        intro: t("homeTutorial5"),
        tooltipClass: ".header-item.item:nth-child(5)",
        position: "right",
        element: ".header-item.item:nth-child(5)",
      },
      {
        intro: t("homeTutorial6"),
        tooltipClass: ".header-item.item:nth-child(6)",
        position: "right",
        element: ".header-item.item:nth-child(6)",
      },
      {
        intro: t("homeTutorial7"),
        tooltipClass: ".header-item.item:nth-child(7)",
        position: "right",
        element: ".header-item.item:nth-child(7)",
      },
      {
        intro: t("homeTutorial8"),
        tooltipClass: ".header-item.item:nth-child(9)",
        position: "right",
        element: ".header-item.item:nth-child(9)",
      },
      {
        intro: t("homeTutorial9"),
        tooltipClass: ".header-item.item:nth-child(10)",
        position: "right",
        element: ".header-item.item:nth-child(10)",
      },
      {
        intro: t("homeTutorial10"),
        tooltipClass: ".header-item.item:nth-child(11)",
        position: "right",
        element: ".header-item.item:nth-child(11)",
      },
      {
        intro: t("homeTutorial11"),
        tooltipClass: ".header-item.item:nth-child(13)",
        position: "right",
        element: ".header-item.item:nth-child(13)",
      },
      {
        intro: t("homeTutorial12"),
        tooltipClass: ".template-wrap-menu:nth-child(n+2)",
        position: "left",
        element: ".template-wrap-menu:nth-child(n+2)",
      },
    ];
    var options = {
      nextLabel: t("Next"),
      prevLabel: t("back"),
      skipLabel: t("finish"),
      doneLabel: t("Close"),
      buttonClass: "guiding",
      showProgress: true,
    };

    let ecommerceType = product.type;
    // list = _.sortBy(list, function (it) {
    //   return !it.config[ecommerceType];
    // });
    let canIseeActiveActions;
    if (activeActions.length > 0) {
      list = list.filter((o1) => !activeActions.some((o2) => o1.id === o2.id));
      activeActions = _.sortBy(activeActions, function (it) {
        return it.config[ecommerceType] || it.config.base;
      });
      canIseeActiveActions = _.filter(activeActions, function (it) {
        return (
          it.config[ecommerceType] ||
          !it.config[ecommerceType] ||
          it.config.base
        );
      });
    }
    let canInstallList = _.filter(list, function (it) {
      return it.config[ecommerceType] || it.config.base;
    });
    let cantInstallList = _.filter(list, function (it) {
      return !it.config[ecommerceType] && !it.config.base;
    });

    /* let filterOptions = _.concat(
			{
				text: 'Tümü',
				key: 'all',
				value: 'all',
				color: 'green',
			},
			TagList
		);
		if (ecommerceType === 'flo') {
			filterOptions = [
				{
					text: 'Flo',
					key: 'flo',
					value: 'flo',
					color: 'blue',
				},
			];
		} */
    if (loading) {
      return (
        <Page title={t("Actions")} icon="home">
          <Page.Loader></Page.Loader>
        </Page>
      );
    }
    if (listfilter !== "all") {
      list = _.filter(list, function (it) {
        return it.config[listfilter];
      });
    }
    return (
      <Page title={t("Actions")} icon="home">
        <Button
          size="small"
          onClick={this.toggleSteps}
          color={"instagram"}
          style={{ top: "1%", right: "2%", position: "fixed" }}
        >
          {t("tutorial")}
        </Button>
        <Page.Wrap>
          <Segment>
            {this.state.activeActions.length > 0 ? (
              <div className="template-wrap-menu">
                <MyDividerCenter name={t("activeAction")} />
              </div>
            ) : (
              ""
            )}
            <div className="template-wrap-menu">
              {_.map(canIseeActiveActions, (item, i) => (
                <TemplateCard
                  key={item.id}
                  item={item}
                  i={i}
                  t={t}
                  type={ecommerceType}
                  isActiveAction={1}
                />
              ))}
            </div>
            {window.innerWidth < 600 ? (
              <div className="template-wrap-menu">
                <MyDividerCenter name={t("supportedActionV1")} />
                <MyDividerCenter name={t("Actions")} />
              </div>
            ) : (
              <div className="template-wrap-menu">
                <MyDividerCenter name={t("supportedAction")} />
              </div>
            )}

            <div className="template-wrap-menu">
              {_.map(canInstallList, (item, i) => (
                <TemplateCard
                  key={item.id}
                  item={item}
                  i={i}
                  t={t}
                  type={ecommerceType}
                />
              ))}
            </div>
            <div className="template-wrap-menu">
              <MyDividerCenter name={t("noSupportedAction")} />
            </div>
            <div className="template-wrap-menu">
              {_.map(cantInstallList, (item, i) => (
                <TemplateCard
                  key={item.id}
                  item={item}
                  i={i}
                  t={t}
                  type={ecommerceType}
                />
              ))}
            </div>
          </Segment>
          {/* 	{process.env.NODE_ENV !== 'production' && (
						<div>
							<pre>{JSON.stringify(list, null, 2)}</pre>
						</div>
					)} */}
        </Page.Wrap>
        <Steps
          enabled={this.state.stepsEnabled}
          steps={steps}
          initialStep={this.state.initialStep}
          exitOnEsc={this.state.exitOnEsc}
          exitOnOverlayClick={this.state.exitOnOverlayClick}
          options={options}
          onExit={this.onExit}
        />
      </Page>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(Templates));
