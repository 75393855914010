import React, { Component } from 'react';
import { Button, Icon, Header, Image, Message, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

var getParams = function(url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};
class CheckMail extends Component {
	render() {
		var query = getParams(window.location.href);
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<Container style={{ width: '500px' }}>
					<Button as={Link} to="/login" color="teal" compact>
						<Icon name="arrow left" />
						Giriş Sayfasına Git
					</Button>
					<Header as="h2" textAlign="center">
						<Image src="/images/collectaction.png" style={{ width: '6rem', margin: '10px' }} />
						<div style={{ fontSize: '18px' }}>
							<p>CollectAction</p>
						</div>
					</Header>
					<Message
						icon="mail"
						success
						header="Parola sıfırlama linki gönderildi."
						content={'Lütfen mailinizi kontrol edin ' + query.mail}
					/>
				</Container>
			</div>
		);
	}
}
export default CheckMail;
