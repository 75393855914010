import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { logout } from "../actions/access.actions";
import { useTranslation } from "react-i18next";
const MarginZero = { fontSize: "1.25em", opacity: 0.7 };

const HeaderItem = (props) => {
  if (props.onClick) {
    if (props.flag) {
      return (
        <div
          className="header-item item"
          onClick={props.onClick}
          name={props.name}
        >
          <img
            src={`/icons/flags/${props.flag}.png`}
            alt=""
            style={{ width: "18px" }}
          ></img>
          <br></br>
          <p className="title"> {props.name}</p>
        </div>
      );
    }
    return (
      <div
        className="header-item item"
        onClick={props.onClick}
        name={props.name}
      >
        <Icon name={props.icon} style={MarginZero} />

        <span className="title">{props.name}</span>
      </div>
    );
  }
  return (
    <NavLink className="header-item item" {...props}>
      <Icon name={props.icon} style={MarginZero} />
      <span className="title">{props.name}</span>
    </NavLink>
  );
};

function ChangeLanguage() {
  const { i18n } = useTranslation();
  return (
    <HeaderItem
      onClick={() => {
        i18n.changeLanguage(i18n.language === "tr" ? "en" : "tr");
        window.location.reload();
      }}
      flag={i18n.language === "tr" ? "tr" : "gb"}
      name={i18n.language === "tr" ? "TR" : "EN"}
    />
  );
}
const Header = (props) => {
  let { t } = useTranslation();
  let { user } = props;
  let { useSchedule, inf, comment } = user.extra || {};
  //todo user.host === "inf.collectaction.com"
  if (user.host === "influencer.collectaction.com") {
    return (
      <div className="ui vertical icon menu navbar">
        <Link className="item console-logo" to="/">
          <img src="/images/collectaction.png" alt="logo" />
        </Link>

        <HeaderItem exact to="/" name={"Influencer"} icon="mobile alternate" />
        <HeaderItem
          exact
          to="/help-influencer"
          name={t("help-inf")}
          icon="pencil alternate"
        />

        <div className="mt-auto" />
        <HeaderItem to="/help" name={t("Help")} icon="help" />
        {/* <HeaderItem to="/settings" name={t("Settings")} icon="setting" /> */}
        <div className="header-seperator"></div>
        <HeaderItem to="/account" name={t("MyAccount")} icon="user" />
        <HeaderItem
          onClick={props.logoutUser}
          name={t("Logout")}
          icon="log out"
        />
      </div>
    );
  } else {
    return (
      <div className="ui vertical icon menu navbar">
        <Link className="item console-logo" to="/">
          <img src="/images/collectaction.png" alt="logo" />
        </Link>
        <HeaderItem exact to="/" name={t("Actions")} icon="home" />
        {useSchedule && (
          <HeaderItem
            exact
            to="/schedules"
            name={t("Schedules")}
            icon="calendar alternate outline"
          />
        )}
        <HeaderItem to="/actions" name={t("Yours")} icon="bullhorn" />
        <HeaderItem to="/variable" name={t("Variable")} icon="js" />
        <HeaderItem to="/analytics" name={t("Analytic")} icon="area graph" />
        <HeaderItem to="/bot" name="Bot" icon="paperclip" />
        {inf && (
          <HeaderItem
            exact
            to="/influencer"
            name={t("Influencer")}
            icon="mobile alternate"
          />
        )}
        {comment === true && (
          <HeaderItem
            to={"/reviewspanel"}
            icon="comment"
            name={t("comments")}
          />
        )}

        <HeaderItem to="/subscription" name={t("Subscription")} icon="cart" />
        <div className="mt-auto" />
        {/*<HeaderItem to="/settings/installation" name="Installation" icon="code" />*/}
        <ChangeLanguage />
        <HeaderItem
          to="/settings/installation"
          name={t("Installation")}
          icon="code"
        />
        <HeaderItem to="/help" name={t("Help")} icon="help" />
        <HeaderItem to="/settings" name={t("Settings")} icon="setting" />
        <div className="header-seperator"></div>
        <HeaderItem to="/account" name={t("MyAccount")} icon="user" />
        <HeaderItem
          onClick={props.logoutUser}
          name={t("Logout")}
          icon="log out"
        />
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
});

export default connect(
  (state) => ({ user: state.access.user.loggedUserObj }),
  mapDispatchToProps
)(Header);
