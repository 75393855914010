var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
var eventer = window[eventMethod];
var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
let boot = false;

const hashmap = {};

export const listen = (key, fn) => {
	if (!boot) {
		eventer(
			messageEvent,
			function(e) {
				let source = e.data && e.data.source;
				if (source && hashmap[source]) hashmap[source](e.data);
			},
			false
		);
		boot = true;
	}
	hashmap[key] = fn;
};

export const send = (ifr, data) => {
	if (ifr && ifr.current) ifr.current.contentWindow.postMessage(data, '*');
};
