import React, { Component } from 'react';
import { Container, Segment, Grid } from 'semantic-ui-react';
import Page from '../components/Page';
// eslint-disable-next-line
import _ from 'lodash';

class Contact extends Component {
	render() {
		return (
			<Page title="Contact Us" icon="phone">
				<Container style={{ marginTop: '50px', width: '40%', padding: '10px' }}>
					<Segment>
						<Grid>
							<Grid.Row>
								<Grid.Column width="16" textAlign="center">
									<h2>Contact Us</h2>
									<br />
									<p>You can contact us for get more information about tiers and more.</p>
									<p>
										<br />
										E-mail: <a href="mailto:info@yapaytech.com">info@yapaytech.com</a>
									</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Container>
			</Page>
		);
	}
}

export default Contact;
