import React, { Component } from "react";
import {
  Icon,
  Segment,
  Table,
  Form,
  Tab,
  Button,
  Select,
  Checkbox,
  Divider,
} from "semantic-ui-react";
import Page from "../../components/Page";
import _ from "lodash";
import { post, log } from "../../util/api";
import moment from "moment";
import { withTranslation } from "react-i18next";

const regex1 = /^.*(test|deneme).*$/;
const values = [
  { name: "all", value: "all", filter: null },
  {
    name: "registrationInterruptions",
    value: "register",
    filter: null,
    default_filter: function (soft_data) {
      let date = new Date("2022-06-25T11:20:22.125Z");
      let default_timestamp = date.getTime();
      let filtered_data = _.filter(soft_data, (a) => {
        if (!a.name && new Date(a.created_at).getTime() > default_timestamp) {
          return a;
        }
      });
      return filtered_data;
    },
  },
  {
    name: "lastWeekRegistration",
    value: "last_week",
    filter: null,
    default_filter: function (soft_data) {
      var oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      let default_timestamp = oneWeekAgo.getTime();
      let filtered_data = _.filter(soft_data, (a) => {
        if (new Date(a.created_at).getTime() > default_timestamp) {
          return a;
        }
      });
      return filtered_data;
    },
  },
  {
    name: "subscriber",
    value: "subs",
    default_filter: function (soft_data) {
      let filtered_data = _.filter(soft_data, (a) => {
        if (a.payment_data && a.extra && !a.extra.is_invoice) {
          return a;
        }
      });
      return filtered_data;
    },
    filter: [
      { name: "all", value: "all", default: true },
      {
        name: "activeWTrial",
        value: "active",
        default_filter: function (soft_data) {
          let filtered_data = _.filter(soft_data, (a) => {
            if (a.payment_data && a.payment_data.status === "active") {
              return a;
            }
          });
          return filtered_data;
        },
      },
      {
        name: "activeExpTrial",
        value: "active_deneme",
        default_filter: function (soft_data) {
          let filtered_data = _.filter(soft_data, (a) => {
            if (
              a.payment_data &&
              a.payment_data.status === "active" &&
              a.payment_data.subscription_plan_id !== "trial"
            ) {
              return a;
            }
          });
          return filtered_data;
        },
      },
      {
        name: "deleted",
        value: "deleted",
        default_filter: function (soft_data) {
          let filtered_data = _.filter(soft_data, (a) => {
            if (a.payment_data && a.payment_data.status === "deleted") {
              return a;
            }
          });
          return filtered_data;
        },
      },
      {
        name: "expired",
        value: "timeout",
        default_filter: function (soft_data) {
          let filtered_data = _.filter(soft_data, (a) => {
            if (a.payment_data && a.payment_data.status === "past_due") {
              return a;
            }
          });
          return filtered_data;
        },
      },
    ],
  },
  {
    name: "invoice",
    value: "fatura",
    filter: null,
    default_filter: function (soft_data) {
      let filtered_data = _.filter(soft_data, (a) => {
        if (a && a.extra && a.extra.is_invoice) {
          return a;
        }
      });
      return filtered_data;
    },
  },
  {
    name: "subsPlusInv",
    value: "fatura_subs",
    default_filter: function (soft_data) {
      let filtered_data = _.filter(soft_data, (a) => {
        if (a.payment_data || (a.extra && a.extra.is_invoice)) {
          return a;
        }
      });
      return filtered_data;
    },
    // filter: [
    //   { name: "Hepsi", value: "subsplusinvo", default: true },
    //   {
    //     name: "Deneme Hariç",
    //     value: "deneme_none",
    //     default_filter: function (soft_data) {
    //       console.log("ttt", soft_data);
    //       let filtered_data = _.filter(soft_data, (a) => {
    //         if (a.extra && a.extra.is_invoice) {
    //           return a;
    //         } else if (
    //           (a.payment_data &&
    //             a.payment_data.subscription_plan_id &&
    //             a.payment_data.subscription_plan_id !== "trial") ||
    //           a.payment_data.status !== "past_due"
    //         ) {
    //           return a;
    //         }
    //       });
    //       console.log("ttt", filtered_data);
    //       return filtered_data;
    //     },
    //   },
    // ],
  },
];

class ActionTable extends Component {
  state = {
    loading: true,
    dontShowTestUser: false,
    selected: "register",
    selected_second: null,
    filtered_data: [],
    soft_data: [],
    options: false,
  };

  filtered_new = () => {
    let { soft_data, selected, selected_second } = this.state;
    let return_data;
    let find_method = _.find(values, (e) => {
      return e.value === selected;
    });
    if (find_method) {
      if (!find_method.filter && find_method.default_filter) {
        return_data = find_method.default_filter(soft_data);
      } else if (!find_method.filter && !find_method.default_filter) {
        return_data = soft_data;
      } else if (find_method.filter && find_method.default_filter) {
        soft_data = find_method.default_filter(soft_data);
        _.find(find_method.filter, (a) => {
          if (!selected_second) {
            return_data = soft_data;
          } else {
            if (a.value === selected_second) {
              if (a.default_filter) {
                return_data = a.default_filter(soft_data);
              } else {
                return_data = soft_data;
              }
            }
          }
        });
      }
    }

    this.setState({ filtered_data: return_data });
  };

  getDataDetails = async (pids) => {
    let data_result = log("/collectaction/getSubscriptionAllUsers", { pids })
      .then(function (response) {
        return (response.data && response.data.data) || null;
      })
      .catch(function (error) {
        console.log(error.message);
      });
    return data_result;
  };

  filterDate = async (data) => {
    const { t } = this.props;
    return _.map(data, (obj) => {
      return {
        id: obj && obj.id,
        email: obj && obj.email,
        isim: obj && obj.name,
        Telefon: obj && obj.phone,
        Type: obj && obj.setting && obj.settings.type,
        Url: obj && obj.settings && obj.settings.url,
        invoice:
          obj && obj.extra && obj.extra.is_invoice
            ? t("postPaid")
            : `${
                obj &&
                obj.extra &&
                obj.extra.payment &&
                obj.extra.payment.status === "active"
                  ? t("subscriber")
                  : ""
              } `,
        status:
          (obj && obj.extra && obj.extra.payment && obj.extra.payment.status) ||
          null,
        payment:
          obj &&
          obj.extra &&
          obj.extra.payment &&
          obj.extra.payment.subscription_plan_id === "trial"
            ? t("trial")
            : obj &&
              obj.extra &&
              obj.extra.payment &&
              obj.extra.payment.unit_price,
        // obj && obj.extra && obj.extra.payment && obj.extra.payment.unit_price,
        Kayit_Tarihi: moment(obj.created_at).format("DD-MM-YYYY HH:mm"),
      };
    });
  };

  componentDidMount() {
    post("/admin/dashboard").then(async (data) => {
      this.setState({ data: data.data.result, loading: false });
      let soft_data = _.cloneDeep(data.data.result);
      soft_data = _.filter(soft_data, (e) => {
        if (!e.email.match(regex1)) {
          if (e.extra && !e.extra.is_test) {
            return e;
          } else if (!e.extra) {
            return e;
          }
        } else if (e.email.includes("bellanottestore")) {
          if (e.extra && !e.extra.is_test) {
            return e;
          } else if (!e.extra) {
            return e;
          }
        }
      });

      let date = new Date("2022-06-25T11:20:22.125Z");
      let default_timestamp = date.getTime();
      let filtered_data = _.filter(soft_data, (a) => {
        if (!a.name && new Date(a.created_at).getTime() > default_timestamp) {
          return a;
        }
      });
      //show users
      this.setState({ filtered_data });

      let pids = _.map(data.data.result, (item) => {
        return item.pid;
      });
      let result_subs = await this.getDataDetails(pids);

      _.map(result_subs, (a) => {
        _.find(soft_data, (c, j) => {
          if (c.id === a.uid) {
            soft_data[j].payment_data = a.data;
          }
        });
      });

      this.setState({ soft_data });
    });
  }

  render() {
    let {
      filtered_data: data,
      loading,
      selected,
      selected_second,
      options,
    } = this.state;

    const { t } = this.props;

    if (loading) {
      return <Page.Loader></Page.Loader>;
    }

    return (
      <Segment style={{ overflowY: "auto" }}>
        <div
          id="hello"
          style={{
            display: "inline-flex",
            width: "90%",
          }}
        >
          <div>
            <Button
              style={{ display: "flex" }}
              icon
              size="small"
              positive
              onClick={() => {
                if (options) {
                  this.filtered_new();
                  this.setState({ options: !options });
                } else {
                  this.setState({ options: !options });
                }
              }}
            >
              {options ? t("getResults") : t("options")}
            </Button>
            {options ? (
              <Form>
                {_.map(values, (e, i) => {
                  return (
                    <div key={i + "form-data"}>
                      <Form.Field style={{ marginTop: "1em" }} key={"form" + i}>
                        <Checkbox
                          radio
                          label={t(e.name)}
                          name="checkboxRadioGroup"
                          value={e.value}
                          checked={e.value === selected}
                          onChange={() => {
                            this.setState({ selected: e.value });
                          }}
                        />
                      </Form.Field>
                      {e.value === selected && e.filter ? (
                        <div style={{ display: "grid" }}>
                          {_.map(e.filter, (c, j) => {
                            return (
                              <Checkbox
                                radio
                                style={{ margin: "5px", marginLeft: "15px" }}
                                key={c.name + "i"}
                                checked={
                                  selected_second
                                    ? c.value === selected_second
                                    : j == 0
                                    ? true
                                    : false
                                }
                                label={c.name}
                                value={c.value}
                                onChange={() => {
                                  this.setState({ selected_second: c.value });
                                }}
                              />
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </Form>
            ) : null}
          </div>
        </div>

        <div style={{ display: "inline-flex", width: "10%" }}>
          <Button
            style={{ display: "flex" }}
            icon
            size="small"
            positive
            onClick={() => exportExcel(data)}
          >
            {t("downloadExcel")}
          </Button>
        </div>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>{t("Email")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Phone")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
              <Table.HeaderCell width={2}>URL</Table.HeaderCell>
              <Table.HeaderCell>{t("invoice")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Payment")}</Table.HeaderCell>
              <Table.HeaderCell>{t("registerDate")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(data, (it, i) => {
              let emailDomain = it.email.match(/@(.*)/)[1];
              return (
                <Table.Row key={i}>
                  <Table.Cell>{it.id}</Table.Cell>
                  <Table.Cell>
                    {it.email}
                    {emailDomain ? (
                      <a
                        href={"https://" + emailDomain}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginLeft: "12px" }}
                      >
                        <Icon name="external alternate" />
                      </a>
                    ) : null}
                  </Table.Cell>
                  {it && it.name ? (
                    <Table.Cell>{it && it.name}</Table.Cell>
                  ) : (
                    <Table.Cell></Table.Cell>
                  )}
                  {it && it.phone ? (
                    <Table.Cell>{it && it.phone}</Table.Cell>
                  ) : (
                    <Table.Cell></Table.Cell>
                  )}
                  <Table.Cell>{it.settings && it.settings.type}</Table.Cell>
                  <Table.Cell>{it.settings && it.settings.url}</Table.Cell>
                  <Table.Cell>
                    {it.extra && it.extra.is_invoice
                      ? t("invoice")
                      : it.payment_data
                      ? t("subscriber")
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {it.payment_data && it.payment_data.status}
                  </Table.Cell>
                  <Table.Cell>
                    {it.payment_data && it.payment_data.unit_price ? "$" : ""}
                    {it.payment_data && it.payment_data.new_price ? "$" : ""}
                    {it.payment_data &&
                    it.payment_data.subscription_plan_id === "trial"
                      ? t("trial")
                      : (it.payment_data && it.payment_data.unit_price) ||
                        (it.payment_data && it.payment_data.new_price)}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(it.created_at).format("DD-MM-YYYY HH:mm")}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

const exportExcel = async (data) => {
  let response = await this.filterDate(data);
  let sheets = [
    {
      name: "test",
      datasets: response,
    },
  ];

  let filenameOur = `userTable-${moment().format("DD-MM-YYYY,h:mm:ss")}`;
  await fetch("https://log.collectaction.com/analytic/download", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      filename: filenameOur,
      sheets,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      let dataurl = data.url;
      var a = document.createElement("a");
      a.href = dataurl;
      a.setAttribute("download", dataurl);
      a.click();
      this.setState({ buttonLoading: false });
    });
};

export default withTranslation()(ActionTable);
