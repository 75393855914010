import React, { Component } from "react";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";
import Page from "../../../components/Page";
import { Menu, Icon, Popup, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import Picker from "../../../components/DateRangePicker";
import { withTranslation } from "react-i18next";
import "moment/locale/tr";

import ActionsAnalytic from "./actions";
import BotAnalytic from "./botanalytic";
import BotAnalytic2 from "./botanalytic2";
import WatsonsAnalytic from "./watsonsanalytic";

class Analytic extends Component {
  state = {
    startDate: moment().subtract(7, "day").startOf("day"),
    endDate: moment().subtract(1, "day").endOf("day"),
    
    today: moment(),
  };
  dateChange = ({ selection }) => {
    let startDate = moment(selection.startDate).startOf("day");
    let endDate = moment(selection.endDate);
    if (
      endDate.format("YYYY-MM-DD") !== this.state.today.format("YYYY-MM-DD")
    ) {
      endDate = endDate.endOf("day");
    } else {
      endDate = moment();
    }
    this.setState({ startDate, endDate });
  };
  refresh = () => {
    this.checkUpdate(this.state, ["endDate", "startDate"]);
  };
  checkUpdate(state, list) {
    if (!state) state = this.state;
    let compare = this.lastLoad;
    this.setState({ postEndDate: moment(this.state.endDate).add("1", "day") });
    let checkList = list || ["type", "filter"];

    for (let i = 0; i < checkList.length; i++) {
      const key = checkList[i];
      if (state[key] !== compare[key]) {
        if (key === "filter") return this.loadOffline();
        return this.postGeneral();
      }
    }
  }
  getNewData = () => {
    let { startDate, endDate, newDataToggle } = this.state;
    this.setState({
      newDataToggle: !newDataToggle,
      startDate1: startDate,
      endDate1: endDate,
      openPopup: false,
    });
  };
  render() {
    const { startDate, endDate } = this.state;
    let { t, i18n, product } = this.props;
    let language = i18n.language === "en" ? "en" : "tr";
    let showWatsonsAnalytic =
      product &&
      product.personalize &&
      product.personalize.settings &&
      product.personalize.settings.showWatsonsAnalytic;
    return (
      <Page title={t("Analytic")} icon="area graph">
        <Page.Wrap>
          <div className="myContainer">
            <Menu className="settings" pointing secondary>
              <Menu.Item as={NavLink} exact to="/analytics/actions">
                <Icon name="bullhorn" />
                {t("Actions")}
              </Menu.Item>
              <Menu.Item as={NavLink} exact to="/analytics/botanalytic">
                <Icon name="paperclip" />
                {t("BotAnalytic")}
              </Menu.Item>
              {showWatsonsAnalytic && (
                <Menu.Item as={NavLink} exact to="/analytics/watsonsanalytic">
                  <Icon name="paperclip" />
                  Watsons Analitik
                </Menu.Item>
              )}
              <div style={{ marginLeft: "auto" }}>
                <Popup
                  trigger={
                    <Button
                      primary
                      style={{ marginRight: "10px" }}
                      content={`${moment(startDate)
                        .locale(language)
                        .format("MMM DD, YYYY")} - ${moment(endDate)
                        .locale(language)
                        .format("MMM DD, YYYY")}`}
                      floated="right"
                    />
                  }
                  content={
                    <Picker
                      dateChange={this.dateChange}
                      startDate={startDate.toDate()}
                      endDate={endDate.toDate()}
                      lang={language}
                      getNewData={this.getNewData}
                    />
                  }
                  on="click"
                  position="bottom right"
                  flowing
                />
              </div>
            </Menu>
          </div>
        </Page.Wrap>
        <Switch>
          <Route
            path="/analytics/actions"
            render={() => (
              <ActionsAnalytic
                start_date={this.state.startDate}
                end_date={this.state.endDate}
                newDataToggle={this.state.newDataToggle}
              />
            )}
          />
          {/* <Route path="/analytics/dashboard" render={() => <Dashboard start_date={this.state.startDate} end_date={this.state.endDate} />} /> */}
          <Route
            path="/analytics/botanalytic2"
            render={() => (
              <BotAnalytic2
                start_date={this.state.startDate}
                end_date={this.state.endDate}
                newDataToggle={this.state.newDataToggle}
              />
            )}
          />
          <Route
            path="/analytics/botanalytic"
            render={() => (
              <BotAnalytic
                start_date={this.state.startDate}
                end_date={this.state.endDate}
                newDataToggle={this.state.newDataToggle}
              />
            )}
          />
          {showWatsonsAnalytic && (
            <Route
              path="/analytics/watsonsanalytic"
              render={() => (
                <WatsonsAnalytic
                  start_date={this.state.startDate}
                  end_date={this.state.endDate}
                  newDataToggle={this.state.newDataToggle}
                />
              )}
            />
          )}
          <Redirect to="/analytics/actions" />
        </Switch>
      </Page>
    );
  }
}
const stateToProps = (state) => ({
  access: state.access,
  location: state.router.location,
  product: state.product,
});
export default connect(stateToProps)(withTranslation()(Analytic));
