import React from "react";
import {
  Form,
  Label,
  Checkbox,
  Divider,
  Header,
  Container,
  Popup,
  Icon,
} from "semantic-ui-react";
import { FastField as Field, FieldArray } from "formik";
import _ from "lodash";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { MaterialInput } from "../components/Helpers";
import cs from "classnames";
import { LangFormat } from "./LangText";

export const MiniContainer = styled(Container)`
  width: 780px !important;
`;

export class FormikLabel extends React.PureComponent {
  render() {
    const { name, label, className } = this.props;
    return (
      <Field name={name}>
        {({ field, form }) => {
          let error = form.touched[field.name] && form.errors[field.name];
          return (
            <Form.Field className={className}>
              <label
                css={`
                  height: 25px;
                  line-height: 25px;
                `}
              >
                {label}
                {error && (
                  <Label color="red" size="small" pointing="left">
                    {error}
                  </Label>
                )}
              </label>
            </Form.Field>
          );
        }}
      </Field>
    );
  }
}
export class TrimmedFormikInput extends React.PureComponent {
  render() {
    const { name, label, className, ...rest } = this.props;
    return (
      <Field name={name}>
        {({ field, form }) => {
          let error = form.touched[field.name] && form.errors[field.name];
          return (
            <Form.Field className={className}>
              <label
                css={`
                  height: 25px;
                  line-height: 25px;
                `}
              >
                {label}
                {error && (
                  <Label color="red" size="small" pointing="left">
                    {error}
                  </Label>
                )}
              </label>
              <MaterialInput
                className="black-placeholder"
                {...rest}
                error={!!error}
                {...field}
                onChange={(e, data) =>
                  form.setFieldValue(name, data.value.trim())
                }
              />
            </Form.Field>
          );
        }}
      </Field>
    );
  }
}
export class FormikInput extends React.PureComponent {
  render() {
    const { name, label, className, ...rest } = this.props;
    return (
      <Field name={name}>
        {({ field, form }) => {
          let error = form.touched[field.name] && form.errors[field.name];
          return (
            <Form.Field className={className}>
              <label
                css={`
                  height: 25px;
                  line-height: 25px;
                `}
              >
                {label}
                {error && (
                  <Label color="red" size="small" pointing="left">
                    {error}
                  </Label>
                )}
              </label>
              <MaterialInput
                className="black-placeholder"
                {...rest}
                error={!!error}
                {...field}
              />
            </Form.Field>
          );
        }}
      </Field>
    );
  }
}
export class FormikInputUrl extends React.PureComponent {
  render() {
    const { name, label, className, ...rest } = this.props;
    return (
      <Field name={name}>
        {({ field, form }) => {
          let error = form.touched[field.name] && form.errors[field.name];
          console.log("f", field);
          return (
            <Form.Field className={className}>
              <label
                css={`
                  height: 25px;
                  line-height: 25px;
                `}
              >
                {label}
                {error && (
                  <Label color="red" size="small" pointing="left">
                    {error}
                  </Label>
                )}
              </label>
              <MaterialInput
                className="black-placeholder"
                {...rest}
                error={!!error}
                {...field}
                onChange={(e, { value }) => {
                  if (!/http/.test(value) && value.length > 7) {
                    form.setFieldValue(field.name, "https://" + value);
                  } else {
                    form.setFieldValue(field.name, value);
                  }
                }}
              />
              {!/http/.test(field.value) && (
                <Popup
                  style={{ marginLeft: "10px" }}
                  trigger={
                    <Icon
                      circular
                      name="warning"
                      style={{ marginLeft: "10px" }}
                    />
                  }
                  content="Link https:// içermelidir. "
                  size="mini"
                />
              )}
            </Form.Field>
          );
        }}
      </Field>
    );
  }
}

export const InlineFormikInput = styled(FormikInput)`
  display: flex;
  align-items: center;
  label {
    width: 80px;
    margin-right: 5px;
  }
`;

export const InlineFormikInputUrl = styled(FormikInputUrl)`
  display: flex;
  align-items: center;
  label {
    width: 80px;
    margin-right: 5px;
  }
`;

export class FormikCheckbox extends React.PureComponent {
  render() {
    const { name, ...rest } = this.props;
    return (
      <Field name={name}>
        {({ field, form }) => {
          let error = form.touched[field.name] && form.errors[field.name];
          return (
            <Form.Field>
              <Checkbox
                {...rest}
                error={!!error}
                {...field}
                onChange={(e, data) =>
                  form.setFieldValue(field.name, data.checked)
                }
              />

              {error && (
                <Label color="red" size="small" pointing>
                  {error}
                </Label>
              )}
            </Form.Field>
          );
        }}
      </Field>
    );
  }
}
export class FormikDropdown extends React.PureComponent {
  render() {
    const { name, label } = this.props;
    return (
      <Field name={name}>
        {({ field, form }) => {
          let error = form.touched[field.name] && form.errors[field.name];
          return (
            <Form.Field>
              <label
                css={`
                  height: 25px;
                  line-height: 25px;
                `}
              >
                {label}
                {error && (
                  <Label color="red" size="small" pointing="left">
                    {error}
                  </Label>
                )}
              </label>
              <Form.Dropdown
                floating
                className="yt-line"
                placeholder={this.props.placeholder || "Select"}
                inline
                value={field.value}
                onChange={(e, { value }) => {
                  form.setFieldValue(name, value);
                }}
                compact
                options={this.props.list}
              />

              {error && (
                <Label color="red" size="small" pointing>
                  {error}
                </Label>
              )}
            </Form.Field>
          );
        }}
      </Field>
    );
  }
}
export const MyDivider = (props) => (
  <Divider horizontal className="my-divider">
    <Header as="h4">
      <i aria-hidden="true" className={cs(props.icon, "icon")}></i>
      {LangFormat(props.name)}
    </Header>
  </Divider>
);
export const MyDividerCenter = (props) => (
  <Divider horizontal className="my-divider-center">
    <Header as="h4">{LangFormat(props.name)}</Header>
  </Divider>
);

class ArrayWrap extends React.Component {
  shouldComponentUpdate(next) {
    let nextList = this.getList(next.helper),
      current = this.getList();
    return !_.isEqual(nextList, current);
  }

  getList(obj = this.props.helper) {
    let name = obj.name.replace(".sub", "");
    return _.get(obj.form.values, name);
  }

  render() {
    let list = this.getList();
    let { _render, helper } = this.props;
    if (helper.name.indexOf(".sub") !== -1) list = list.sub;
    return _render({ list, helper });
  }
}

export class FastFieldArray extends React.PureComponent {
  render() {
    return (
      <FieldArray name={this.props.name}>
        {(helper) => (
          <ArrayWrap helper={helper} _render={this.props.children} />
        )}
      </FieldArray>
    );
  }
}
