import React, { Component } from "react";
import {
  MyDivider,
  InlineFormikInput,
  FastFieldArray,
  InlineFormikInputUrl,
  MyDividerCenter,
} from "./FormikInput";
import {
  Dropdown,
  Icon,
  Button,
  Checkbox,
  Tab,
  Accordion,
  Popup,
  TextArea,
} from "semantic-ui-react";
import DraggableDiv from "./DragableDiv";
import { ChromePicker } from "react-color";
import styled from "styled-components/macro";
import RichTextEditor from "react-rte";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { buildProps } from "./PopupPreview";

import { FastField as Field, Field as RawField } from "formik";
import { templateCache, bootSub } from "./CampaingHelpers";
import { MaterialInput } from "./Helpers";
import { connect } from "react-redux";
import cs from "classnames";
import CssModal from "./CssSelector";

import SimpleCodeEditor from "./SimpleCodeEditor";
import { LangFormat, LangText } from "./LangText";
import { formData } from "../util/api";

const MultiDropdown = styled(Dropdown)`
  .menu.transition,
  .dropdown.icon {
    display: none !important;
  }
  &.ui.active.selection.dropdown {
    border-radius: 5px !important;
    box-shadow: none !important;
  }
`;

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    /* 'BLOCK_TYPE_BUTTONS',  */ "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
class MyStatefulEditor extends Component {
  state = {
    value: this.props.value
      ? RichTextEditor.createValueFromString(this.props.value, "html")
      : RichTextEditor.createEmptyValue(),
  };

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  };

  render() {
    return (
      <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}

export function TwoStepButton(props) {
  const [verify, setVerify] = React.useState(false);
  return (
    <Button
      className={props.className}
      as="div"
      size="small"
      color={props.color || "red"}
      onClick={verify ? props.onClick : () => setVerify(true)}
    >
      {verify ? props.second : props.text}
    </Button>
  );
}

export function YtMissing() {
  return <div>Missing</div>;
}

const FormDropdown = styled(Dropdown)`
  min-width: ${(props) => props.size || 200}px;
  //text-align: center !important;
  margin: 9px 10px 9px 0;
  height: 22px;

  .text {
    font-weight: 400 !important;
  }
  .dropdown.icon {
    float: right;
  }
`;

function Wrap(props) {
  console.log("props ", props);
  let divider = props.data.divider || props.divider;
  const title =
    !divider && (props.data.title || (props.parent && props.parent.title));
  let pre_text = props.data.pre_text || (props.parent && props.parent.pre_text);
  let post_text =
    props.data.post_text || (props.parent && props.parent.post_text);
  let subs = props.data && props.data.sub;
  let warning =
    props.data &&
    props.data.isValid === false &&
    props.parent &&
    props.parent.validation &&
    props.parent.validation.warning;
  let wrap = (
    <div
      className={cs("form-gen-wrap", props.className, props.data.className, {
        "has-title": title,
        "has-divider": divider,
        "is-inline": props.data && props.data.inline,
      })}
    >
      {divider && <MyDivider name={divider.name} icon={divider.icon} />}
      {title && <div className="form-gen-title">{LangFormat(title)}</div>}
      {pre_text && <LangText className="pretext">{pre_text}</LangText>}
      {props.children}
      {post_text && <LangText className="posttext">{post_text}</LangText>}
      {warning && <LangText className="warning-text">{warning}</LangText>}
    </div>
  );
  if (subs)
    return (
      <>
        {wrap}
        <SubSection
          {...props}
          className
          deep={(props.deep || 0) + 1}
          key={props.selected}
          list={subs}
        />
      </>
    );
  return wrap;
}

class DateTimeCustomInput extends React.PureComponent {
  render() {
    let { onClick, value } = this.props;
    return (
      <div
        className={cs("yt-datetime yt-line", {
          active:
            this.props.className &&
            /react-datepicker-ignore-onclickoutside/.test(this.props.className),
        })}
        onClick={onClick}
      >
        <Icon name="calendar outline" />
        {value}
      </div>
    );
  }
}
function FragmentWithoutWarning({ key, children }) {
  // to get rid of the warning:
  // "React.Fragment can only have `key` and `children` props."
  return <React.Fragment key={key}>{children}</React.Fragment>;
}
class YtDropdown extends React.PureComponent {
  findObj(key) {
    return _.find(this.props.data.list, { value: key });
  }

  getValue(field) {
    return field.value && field.value.value;
  }

  get list() {
    const { list: _list, validation } = this.props.data || {};
    let list = _.map(_list, (it) => ({ ...it, text: LangFormat(it.text) }));
    if (!validation) return list;
    let ret = [];
    let divider = true;
    _.each(list, (it) => {
      if (!it.isValid && divider) {
        divider = false;
        ret.push({
          key: "divider",
          as: FragmentWithoutWarning,
          content: <Dropdown.Divider />,
        });
      }

      ret.push(_.pick(it, ["key", "text", "value"]));
    });
    return ret;
  }

  render() {
    const { props, list } = this;
    const { divider, vertical } = props.data || {};
    return (
      <Field name={props.path}>
        {({ field, form }) => {
          const { value, _cnfg_no_edit } = field.value || {};
          var obj = this.findObj(value);
          if (!obj) return <YtMissing />;
          return (
            <Wrap
              {...props}
              className={cs({ vertical }, props.data && props.data.className)}
              path={props.path}
              selected={value}
              divider={divider}
              data={obj}
              parent={props.data}
            >
              <FormDropdown
                className="yt-line"
                placeholder={LangFormat(props.data.placeholder) || "Select"}
                size={props.data.size}
                inline
                value={value}
                onChange={(e, { value }) => {
                  let obj = this.findObj(value);
                  if (!obj) return;
                  if (!obj.__boot) bootSub(obj);
                  let _value = _.cloneDeep(obj.__default);
                  if (!props.deep && _value.params)
                    _value.params = [_value.params];
                  form.setFieldValue(props.path, _value);
                }}
                scrolling
                search
                compact
                disabled={!!_cnfg_no_edit}
                options={list}
              />
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

class YtTemplate extends React.PureComponent {
  findObj(key) {
    return _.find(this.props.data.list, { value: key });
  }

  getValue(field) {
    return field.value && field.value.value;
  }

  get list() {
    const { list, validation } = this.props.data || {};
    if (!validation) return list;
    let ret = [];
    let divider = true;
    _.each(list, (it) => {
      if (!it.isValid && divider) {
        divider = false;
        ret.push({
          key: "divider",
          as: FragmentWithoutWarning,
          content: <Dropdown.Divider />,
        });
      }

      ret.push(_.pick(it, ["key", "text", "value"]));
    });
    return ret;
  }

  render() {
    const { props, list } = this;
    const { divider, vertical } = props.data || {};
    return (
      <Field name={props.path}>
        {({ field, form }) => {
          const { value, _cnfg_no_edit } = field.value || {};
          var obj = this.findObj(value);
          if (!obj) return <YtMissing />;
          return (
            <Wrap
              {...props}
              className={cs({ vertical }, props.data && props.data.className)}
              path={props.path}
              selected={value}
              divider={divider}
              data={obj}
              parent={props.data}
            >
              <FormDropdown
                className="yt-line"
                placeholder={props.data.placeholder || "Select"}
                size={props.data.size}
                inline
                value={value}
                onChange={(e, { value }) => {
                  let obj = this.findObj(value);
                  if (!obj) return;
                  if (!obj.__boot) bootSub(obj);
                  let _value = _.cloneDeep(obj.__default);
                  if (!props.deep && _value.params)
                    _value.params = [_value.params];
                  form.setFieldValue(props.path, _value);
                }}
                scrolling
                search
                compact
                disabled={!!_cnfg_no_edit}
                options={list}
              />
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

export const BoxSelect = (props) => (
  <div
    onClick={props.disabled ? null : props.onClick}
    className={cs("box-select", { active: props.active })}
    style={{ marginBottom: "10px" }}
  >
    <div className="icon-container">
      <Icon fitted size="big" name={props.data.icon} />
    </div>
    <div className="name-container">{LangFormat(props.data.text)}</div>
  </div>
);

class YtBoxselect extends React.PureComponent {
  findObj(key) {
    return _.find(this.props.data.list, { value: key });
  }

  getValue(field) {
    return field.value && field.value.value;
  }

  get list() {
    const { list: _list, filter } = this.props.data || {};
    let list = _.map(_list, (it) => ({ ...it, text: LangFormat(it.text) }));
    if (filter) list = _.filter(list, (it) => filter.indexOf(it.value) !== -1);
    return list;
  }

  render() {
    const { props } = this;
    let { divider } = props.data || {};
    return (
      <Field name={props.path}>
        {({ field, form }) => {
          const { value, _cnfg_no_edit, _cnfg_hide_others } = field.value || {};
          var obj = this.findObj(value);
          if (!obj) return <YtMissing />;
          return (
            <Wrap
              {...props}
              className="type-boxselect"
              path={props.path}
              selected={value}
              data={obj}
              divider={divider}
            >
              <div
                className={cs("box-wrap body", {
                  hideothers: _cnfg_hide_others,
                })}
                style={{ flexWrap: "wrap" }}
              >
                {_.map(this.list, (item, i) => {
                  return (
                    <BoxSelect
                      onClick={() => {
                        if (obj.value !== item.value) {
                          let temp = this.findObj(item.value);
                          if (!temp) return;
                          if (!temp.__boot) bootSub(temp);
                          let _value = _.cloneDeep(temp.__default);
                          if (!props.deep && _value.params)
                            _value.params = [_value.params];
                          form.setFieldValue(props.path, _value);
                        }
                      }}
                      active={obj.value === item.value}
                      key={i}
                      disabled={!!_cnfg_no_edit}
                      data={item}
                    />
                  );
                })}
              </div>
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

const __poperoptions = {
  preventOverflow: {
    enabled: true,
    escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
    boundariesElement: "viewport",
  },
};
class YtDateTime extends React.PureComponent {
  render() {
    const { props } = this;
    const { d_time = true, d_date = true } = props.data;
    const viewFormat = `${d_date ? "MMMM d, yyyy" : ""} ${
      d_time ? "HH:mm" : ""
    }`.trim();
    return (
      <Field name={props.path + ".value"}>
        {({ field, form }) => (
          <Wrap {...props} selected={field.value || new Date()}>
            <DatePicker
              timeFormat="HH:mm"
              dateFormat={viewFormat}
              customInput={<DateTimeCustomInput />}
              selected={(field.value && new Date(field.value)) || new Date()}
              onChange={(value) => form.setFieldValue(field.name, value)}
              showTimeSelect={d_time}
              showTimeSelectOnly={!d_date}
              popperModifiers={__poperoptions}
            />
          </Wrap>
        )}
      </Field>
    );
  }
}
class YtInput extends React.PureComponent {
  static inputTypeDefinitions = {
    string: "text",
    number: "number",
  };

  render() {
    const { props } = this;
    const inputtype =
      props.data.inputtype ||
      YtInput.inputTypeDefinitions[props.data.type] ||
      "text";
    return (
      <Field name={props.path}>
        {({ field }) => {
          const { value, _cnfg_no_edit } = field.value || {};
          return (
            <Wrap {...props} className={"type-string"} selected={field.value}>
              <div
                size={props.data.size}
                css={`
                  max-width: ${(props) =>
                    props.size + "px" || "100%"} !important;
                  margin-right: 10px;
                `}
              >
                <MaterialInput
                  css={`
                    margin: 9px 0;
                    max-width: 100%;
                    height: 22px;
                  `}
                  type={inputtype}
                  name={field.name + ".value"}
                  placeholder={props.data.placeholder || "..."}
                  value={value}
                  onChange={field.onChange}
                  disabled={!!_cnfg_no_edit}
                />
              </div>
            </Wrap>
          );
        }}
      </Field>
    );
  }
}
class YtTextArea extends React.PureComponent {
  static inputTypeDefinitions = {
    string: "text",
    number: "number",
  };

  render() {
    const { props } = this;
    const inputtype =
      props.data.inputtype ||
      YtTextArea.inputTypeDefinitions[props.data.type] ||
      "text";
    return (
      <Field name={props.path}>
        {({ field }) => {
          const { value, _cnfg_no_edit } = field.value || {};
          return (
            <Wrap {...props} className={"type-string"} selected={field.value}>
              <div
                size={props.data.size}
                css={`
                  max-width: ${(props) =>
                    props.size + "px" || "100%"} !important;
                  margin-right: 10px;
                `}
              >
                <TextArea
                  css={`
                    margin: 9px 0;
                    max-width: 100%;
                    height: 100px;
                    width: 430px;
                    outline: none;
                  `}
                  type={inputtype}
                  name={field.name + ".value"}
                  placeholder={props.data.placeholder || "..."}
                  value={value}
                  onChange={field.onChange}
                  disabled={!!_cnfg_no_edit}
                />
              </div>
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

class YtFileInput extends React.PureComponent {
  static inputTypeDefinitions = {
    string: "text",
    number: "number",
  };
  render() {
    const { props } = this;
    function fileUpload(file, form, name) {
      const url = "https://uploads.yapaytech.com/flow/uploadPermImage";
      const formData = new FormData();
      formData.append("files[]", file);
      let image_url = "";
      fetch(url, {
        method: "post",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then(async (data) => {
          image_url = data && data.files && data.files[0] && data.files[0].url;
          handleChange(form, name, image_url);
        });
    }
    const handleFileInput = (e, form, name) => {
      let file = e.target.files[0];
      fileUpload(file, form, name);
    };
    const handleChange = (form, name, value) => {
      form.setFieldValue(name + ".value", value);
    };
    return (
      <Field name={props.path}>
        {({ field, form }) => {
          const { value } = field.value || {};
          return (
            <Wrap {...props} className={"type-string"} selected={field.value}>
              <div
                size={props.data.size}
                css={`
                  max-width: ${(props) =>
                    props.size + "px" || "100%"} !important;
                  margin-right: 10px;
                  display: flex;
                `}
              >
                <>
                  <MaterialInput
                    css={`
                      max-width: 95%;
                      height: 22px;
                    `}
                    type={props.type || "text"}
                    name={field.name}
                    placeholder={props.placeholder || "..."}
                    value={value || ""}
                    onChange={(e, { value }) => {
                      handleChange(form, field.name, value);
                    }}
                  />
                  <div
                    className="image-upload"
                    style={{
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <label htmlFor={`${field.name}_file-input`}>
                      <Icon
                        name="upload"
                        color="blue"
                        size="large"
                        style={{ cursor: "pointer" }}
                      ></Icon>
                    </label>
                    <input
                      id={`${field.name}_file-input`}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileInput(e, form, field.name);
                      }}
                    />
                  </div>
                </>
              </div>
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

class YtExcelInput extends React.PureComponent {
  static inputTypeDefinitions = {
    string: "text",
    number: "number",
  };

  render() {
    const { props } = this;

    async function fileUpload(file, form, name) {
      // eslint-disable-next-line no-redeclare
      var form = new FormData();
      let pid = props.product.id;
      form.append("file", file);
      form.append("pid", pid);
      await formData("/api/insertCoupons", form);
    }
    const handleFileInput = (e, form, name) => {
      let file = e.target.files[0];
      fileUpload(file, form, name);
    };

    return (
      <Field name={props.path}>
        {({ field, form }) => {
          const { value } = field.value || {};
          return (
            <Wrap {...props} className={"type-string"} selected={field.value}>
              <div
                size={props.data.size}
                css={`
                  max-width: ${(props) =>
                    props.size + "px" || "100%"} !important;
                  margin-right: 10px;
                  display: flex;
                  margin-top: 7px;
                `}
              >
                <>
                  <div
                    className="image-upload"
                    style={{
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <label htmlFor={`${field.name}_file-input`}>
                      <Icon
                        name="upload"
                        color="blue"
                        size="large"
                        style={{ cursor: "pointer" }}
                      ></Icon>
                    </label>
                    <input
                      id={`${field.name}_file-input`}
                      type="file"
                      accept=".xlsx, .xls"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileInput(e, form, field.name);
                      }}
                    />
                  </div>
                </>
              </div>
            </Wrap>
          );
        }}
      </Field>
    );
  }
}
class YtYoutubeEmbed extends React.PureComponent {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  render() {
    const { data } = this.props;
    let { text, doc, youtube } = data;
    console.log("propsi ", this.props);

    return (
      <>
        <div style={{ height: "40px", width: "100%", marginTop: "15px" }}>
          <Button.Group>
            <Button
              disabled={!doc ? true : false}
              color="blue"
              target={"_blank"}
              href={doc || ""}
            >
              <Icon name="book" />
              Doküman
            </Button>
            <Button.Or text="&" />
            <Button
              disabled={!youtube ? true : false}
              color="red"
              onClick={this.handleClick}
            >
              <Icon name="youtube" />
              İzle
            </Button>
          </Button.Group>
        </div>
        {this.state.open && (
          <div
            style={{
              right: "0",
              top: "18%",
              position: "fixed",
              border: "1px solid #d6d6d6",
              background: "#f2f1f1",
              borderRadius: "5px",
              padding: "5px",
              zIndex: "999",
              boxShadow: "7px 6px 19px 5px #b7b7b7",
              paddingTop: "14px",
            }}
          >
            <div className="yt-p-4" style={{ maxWidth: 500 }}>
              <div className="yt-font-color-dark yt-font-size-15 yt-pr-4 yt-word-break">
                <Icon
                  name="close"
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    position: "absolute",
                    zIndex: "9999",
                    top: "4px",
                    color: "red",
                  }}
                  onClick={this.handleClick}
                ></Icon>
                <MyDividerCenter
                  name={text}
                  icon={"video play"}
                  style={{ paddingTop: "25px" }}
                />
                <div
                  className="video-responsive"
                  style={{
                    overflow: "hidden",
                    position: "relative",
                    width: "100%",
                    marginTop: "15px",
                  }}
                >
                  <iframe
                    width="480"
                    height="480"
                    style={{
                      height: "100%",
                      width: "100%",
                      paddingBottom: "20px",
                    }}
                    src={youtube || ""}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
class YtColorPicker extends React.PureComponent {
  static inputTypeDefinitions = {
    string: "text",
    number: "number",
  };

  render() {
    const { props } = this;
    const inputtype =
      props.data.inputtype ||
      YtInput.inputTypeDefinitions[props.data.type] ||
      "text";
    return (
      <Field name={props.path}>
        {({ field, form }) => {
          const { value = "#fff", _cnfg_no_edit } = field.value || {};
          return (
            <Wrap {...props} selected={field.value}>
              <div
                size={props.data.size}
                css={`
                  display: flex;
                  align-items: center;
                  .color-wrap {
                    width: 50px;
                    height: 30px;
                    padding: 2px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 3px;
                    margin-left: 5px;
                  }
                  .color-display {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                  }
                  .button {
                    margin-left: 5px;
                  }
                  max-width: ${(props) =>
                    props.size + "px" || "100%"} !important;
                  margin-right: 10px;
                `}
              >
                <MaterialInput
                  css={`
                    margin: 9px 0;
                    max-width: 100%;
                    height: 22px;
                  `}
                  type={inputtype}
                  name={field.name + ".value"}
                  placeholder={props.data.placeholder || "..."}
                  value={value}
                  onChange={field.onChange}
                  disabled={!!_cnfg_no_edit}
                />
                <Popup
                  trigger={
                    <div className="color-wrap">
                      <div
                        className="color-display"
                        style={{ backgroundColor: value }}
                      />
                    </div>
                  }
                  on="click"
                  position="bottom right"
                  content={
                    <ChromePicker
                      style={{ zIndex: "14" }}
                      color={value || ""}
                      onChange={({ hex }) => {
                        form.setFieldValue(field.name + ".value", hex);
                      }}
                    />
                  }
                  style={{ borderRadius: 0, padding: 0 }}
                />
              </div>
            </Wrap>
          );
        }}
      </Field>
    );
  }
}
class YtNumber extends React.PureComponent {
  static inputTypeDefinitions = {
    string: "text",
    number: "number",
  };

  render() {
    const { props } = this;
    const inputtype =
      props.data.inputtype ||
      YtInput.inputTypeDefinitions[props.data.type] ||
      "text";
    return (
      <Field name={props.path}>
        {({ field }) => {
          const { value, _cnfg_no_edit } = field.value || {};
          return (
            <Wrap {...props} selected={field.value}>
              <div
                size={props.data.size}
                css={`
                  margin-right: 10px;
                  max-width: ${(props.data &&
                    props.data.size &&
                    props.data.size + "px !important") ||
                  "100%"};
                `}
              >
                <MaterialInput
                  css={`
                    margin: 9px 0;
                    max-width: 100%;
                    height: 22px;
                  `}
                  type={inputtype}
                  name={field.name + ".value"}
                  placeholder={props.data.placeholder || "..."}
                  min={props.data.min}
                  max={props.data.max}
                  value={value}
                  onChange={field.onChange}
                  disabled={!!_cnfg_no_edit}
                />
              </div>
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

class YtRichText extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <Field name={props.path + ".value"}>
        {({ field, form }) => {
          return (
            <Wrap {...props} selected={field.value}>
              <MyStatefulEditor
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
              />
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

class YtBoolean extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <Field name={props.path + ".value"}>
        {({ field, form }) => (
          <Wrap {...props} selected={field.value} className="type-boolean">
            <div
              size={props.data.size}
              css={`
                max-width: ${(props) => props.size || 200}px;
                margin-right: 10px;
                display: flex;
                align-items: center;
              `}
            >
              <Checkbox
                toggle
                onChange={(e, { checked }) => {
                  form.setFieldValue(field.name, checked);
                }}
                checked={field.value}
              />
            </div>
          </Wrap>
        )}
      </Field>
    );
  }
}

class YtTabs extends React.PureComponent {
  render() {
    const { tabs } = this.props.data;
    const panes = _.map(tabs, (tab, i) => {
      return {
        menuItem: { key: "tab-" + i, content: "Tab " + (i + 1), ...tab.__tab },
        render: () => (
          <Tab.Pane key={"tab-" + i} attached={false}>
            <Section
              path={`${this.props.path ? `${this.props.path}.` : ""}${
                tab.__name || i
              }`}
              data={tab}
            />
          </Tab.Pane>
        ),
      };
    });

    return (
      <Tab
        className="yt-tab"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    );
  }
}

class YtCondition extends React.PureComponent {
  render() {
    const { from, definition } = this.props.data;
    definition.className = "yt-condition-wrap";
    //const { props } = this;
    return <Section path={from} data={definition} />;
  }
}

class YtAccordion extends React.PureComponent {
  state = { active: false };

  handleClick = () => {
    this.setState((state) => ({ active: !state.active }));
  };

  render() {
    const { tabs, title } = this.props.data;
    return (
      <Accordion className="yt-accordion">
        <Accordion.Title active={this.state.active} onClick={this.handleClick}>
          <Icon name="dropdown" />
          {title || "Gelişmiş"}
        </Accordion.Title>
        <Accordion.Content active={this.state.active}>
          {_.map(tabs, (tab, i) => {
            return (
              <Section
                key={i}
                path={`${this.props.path ? `${this.props.path}.` : ""}${
                  tab.__name || i
                }`}
                data={tab}
              />
            );
          })}
        </Accordion.Content>
      </Accordion>
    );
  }
}

class YtCode extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <Field name={props.path + ".value"}>
        {({ field, form }) => {
          return (
            <Wrap {...props} className="type-code">
              <SimpleCodeEditor
                value={field.value || ""}
                lang={props.data.lang}
                onValueChange={(code) => form.setFieldValue(field.name, code)}
              />
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

class YtDivider extends React.PureComponent {
  render() {
    const { props } = this;
    return <MyDivider name={props.data.name} icon={props.data.icon} />;
  }
}

class YtCssSelector extends React.PureComponent {
  getPreview(__form) {
    const state = __form.values;
    let { data, form } = this.props;
    if (data.selector === "full") {
      if (data.preview) return _.get(state.parameters, data.preview);
    } else if (data.preview && form) {
      let value = _.get(state, data.preview);
      let list = _.get(form, `formData.${data.preview}.data.list`);
      let schema = _.find(list, { value: value.type });
      let props = buildProps(schema, value.parameters);
      props.position = "inline";
      return { component: value.component, props };
    }
  }

  render() {
    const { props } = this;
    return (
      <RawField name={props.path}>
        {({ field, form }) => (
          <Wrap {...props}>
            <div>
              <CssModal
                onSelect={(data) => form.setFieldValue(props.path, data)}
                _css={field.value.value}
                url={field.value._url}
                preview={() => this.getPreview(form)}
                data={this.props.data}
              >
                <Button
                  type="button"
                  icon
                  basic
                  color="orange"
                  style={{ width: 200 }}
                >
                  <Icon name="edit" /> Open CSS Selector
                </Button>
              </CssModal>
              <div
                size={props.data.size}
                css={`
                  display: flex;
                  align-items: center;
                  flex: 1;
                `}
              >
                <MaterialInput
                  css={`
                    margin: 9px 0;
                    max-width: 100%;
                    flex: 1;
                    height: 22px;
                  `}
                  type="text"
                  name={field.name + ".value"}
                  placeholder={props.data.placeholder || ".classname"}
                  value={field.value.value}
                  onChange={field.onChange}
                />
              </div>
            </div>
          </Wrap>
        )}
      </RawField>
    );
  }
}

class YtButton extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <Wrap {...props}>
        <ul className="tree-view">
          <li>
            <InlineFormikInput name={`${props.path}.title`} label="Title" />
          </li>

          <Field name={props.path + ".onclick"}>
            {({ field, form }) => {
              const __type = field.value && field.value.__type;
              const isUrl = __type === "url";
              return (
                <>
                  {props.product &&
                    props.product.settings &&
                    props.product.settings.dahi && (
                      <li>
                        <div className="type-cont">
                          <span className="type-span">Type</span>

                          <Button.Group>
                            <Button
                              as="div"
                              type="button"
                              size="small"
                              primary={isUrl}
                              onClick={() => {
                                if (!isUrl)
                                  form.setFieldValue(`${props.path}.onclick`, {
                                    __type: "url",
                                    payload: "",
                                  });
                              }}
                              content="Url"
                              icon="linkify"
                            />
                            <Button
                              as="div"
                              type="button"
                              size="small"
                              primary={!isUrl}
                              onClick={() => {
                                if (isUrl)
                                  form.setFieldValue(`${props.path}.onclick`, {
                                    __type: "dahiChatConnect",
                                    payload: "",
                                  });
                              }}
                              content="Dahi"
                              icon="chat"
                            />
                          </Button.Group>
                        </div>
                      </li>
                    )}
                  <li>
                    <InlineFormikInputUrl
                      key={__type}
                      label={isUrl ? "Url" : "Payload"}
                      placeholder={isUrl ? "https://website.com" : "Payload"}
                      name={`${props.path}.onclick.payload`}
                    />
                  </li>
                </>
              );
            }}
          </Field>
        </ul>
      </Wrap>
    );
  }
}

class YtScreen extends React.PureComponent {
  render() {
    const { props } = this;
    let list = props.data.list;
    let only = props.data.only;
    return (
      <Field name={props.path + ".value"}>
        {({ field, form }) => (
          <Wrap
            {...props}
            selected={field.value}
            divider={{ name: "Ekran", icon: "tv" }}
          >
            <div className="screen-wrap">
              <div className="screen">
                {list.map((it) => {
                  let disabled = only && only.indexOf(it.value) === -1;
                  return (
                    <div
                      className={cs("screen-item", { disabled })}
                      key={it.value}
                      onClick={() =>
                        !disabled &&
                        form.setFieldValue(props.path + ".value", it.value)
                      }
                    >
                      <div
                        className={
                          "item-wrap " +
                          (it.value === field.value ? "selected" : "")
                        }
                      >
                        {it.text}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Wrap>
        )}
      </Field>
    );
  }
}

const YtMiniButton = (props) => {
  return (
    <Button
      icon
      className="yt-button"
      basic
      compact
      size="mini"
      type="button"
      onClick={props.onClick}
    >
      <Icon size="small" name={props.icon} /> {props.text || ""}
    </Button>
  );
};
class YtArray extends React.PureComponent {
  onDragStart = (ev, i) => {
    ev.dataTransfer.setData("index", i);
  };

  onDragOver = (ev) => {
    ev.preventDefault();
  };

  onDrop = (ev, a) => {
    let b = ev.dataTransfer.getData("index");
    console.log(parseInt(a, 10));
    this.props.action(parseInt(a, 10), parseInt(b, 10));
  };

  render() {
    const { props } = this;
    let divider = props.data.divider || props.divider;
    const title =
      !divider && (props.data.title || (props.parent && props.parent.title));
    let max = (props.data && props.data.max) || 15;
    return (
      <FastFieldArray name={props.path}>
        {({ helper, list, drag }) => {
          function swap(a, b) {
            helper.move(b, a);
          }
          return (
            <div
              className={cs("form-gen-wrap", {
                "has-title": title,
                "has-divider": divider,
                "is-inline": props.data && props.data.inline,
              })}
              css={`
                display: inline-block;
                flex: 1;
                flex-wrap: wrap;
                .ui.horizontal.divider.my-divider {
                  max-width: 100% !important;
                  margin-left: 0px;
                  margin-right: 0px;
                }
              `}
            >
              {divider && <MyDivider name={divider.name} icon={divider.icon} />}
              {title && (
                <div className="form-gen-title">{LangFormat(title)}</div>
              )}
              {list.map((data, i) => {
                const path = `${helper.name}.${i}`;
                console.log("props.data", props.data);
                return (
                  <DraggableDiv key={i} i={i} action={swap.bind(this)}>
                    <div className="yt-array-wrap draggable" key={i}>
                      <SubSection
                        data={props.data}
                        path={path}
                        deep={2}
                        list={props.data.sub}
                      />
                      <YtMiniButton
                        right
                        icon="remove"
                        onClick={() => helper.remove(i)}
                      />
                    </div>
                  </DraggableDiv>
                );
              })}
              {max > list.length && (
                <YtMiniButton
                  text="Add"
                  icon="add"
                  onClick={() => {
                    bootSub(props.data);
                    helper.push(props.data.__default[0]);
                  }}
                />
              )}
            </div>
          );
        }}
      </FastFieldArray>
    );
  }
}

class YtObject extends React.PureComponent {
  render() {
    const { props } = this;
    return <Wrap data={props.data} deep={1} path={props.path} />;
  }
}

class YtMultiString extends React.PureComponent {
  state = { options: [] };

  onchange = (e, { value }) => {
    this.form.setFieldValue(this.props.path + ".value", value);
  };

  handleAddition = () => {};

  render() {
    const { props } = this;
    return (
      <Field name={props.path + ".value"}>
        {({ field, form }) => {
          this.form = form;
          return (
            <Wrap {...props} selected={field.value}>
              <MultiDropdown
                value={field.value}
                name={field.name}
                search
                selection
                options={_.map(field.value, (value) => ({
                  value,
                  text: value,
                }))}
                allowAdditions
                multiple
                onChange={this.onchange}
                onAddItem={this.handleAddition}
                fluid
              />
            </Wrap>
          );
        }}
      </Field>
    );
  }
}

export const SubSection = React.memo((props) => {
  if (!props.list || !props.list.length) return null;
  return (
    <div className={cs("sub-section", props.className)}>
      {_.map(props.list, (data, i) => {
        if (
          props.condition === undefined ||
          (data.type === "condition") === props.condition
        )
          return (
            <Section
              {...props}
              path={
                data.type === "tabs" || data.type === "accordion"
                  ? props.path
                  : `${props.path}${props.deep === 2 ? "" : ".params"}.${
                      data.__name || i
                    }`
              }
              index={i}
              deep={props.deep}
              key={i}
              data={data}
            />
          );
      })}
    </div>
  );
});

export const viewDefinitions = {
  dropdown: YtDropdown,
  string: YtInput,
  color: YtColorPicker,
  number: YtNumber,
  datetime: YtDateTime,
  boxselect: YtBoxselect,
  screen: YtScreen,
  button: connect((state) => ({ product: state.product.personalize }))(
    YtButton
  ),
  multistring: YtMultiString,
  cssinput: YtCssSelector,
  boolean: YtBoolean,
  richtext: YtRichText,
  code: YtCode,
  tabs: YtTabs,
  accordion: YtAccordion,
  condition: YtCondition,
  divider: YtDivider,
  array: YtArray,
  object: YtObject,
  template: YtTemplate,
  image_file: YtFileInput,
  doc_file: connect((state) => ({ product: state.product.personalize }))(
    YtExcelInput
  ),
  youtube_embed: YtYoutubeEmbed,
  textArea: YtTextArea,
};

export class Section extends React.PureComponent {
  boot() {
    console.log("this.props ",this.props)
    let data = this.props.data;
    let { type } = data;
    if (type === this.__type) return;
    this.__type = type;
    this.data = data;
    let Component = viewDefinitions[type];
    if (!Component) {
      let { type_definition } = templateCache();
      let dataType = type_definition[type];
      if (dataType) {
        Component = viewDefinitions[dataType.type] || YtMissing;
        this.data = _.assignIn({}, dataType, data);
        this.data.type = dataType.type;
      } else Component = YtMissing;
    }
    this.Component = Component;
  }

  render() {
    this.boot();
    const { props, Component, data } = this;
    return <Component {...props} data={data} />;
  }
}

export default withTranslation()(Section);
