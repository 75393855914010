import React from "react";
import {
  Segment,
  Table,
  Button,
  Header,
  Tab,
  Modal,
  Loader,
  Form,
  Icon,
  Divider,
} from "semantic-ui-react";
import Page from "../../components/Page";
//import { Link } from "react-router-dom";
// eslint-disable-next-line
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { post, log } from "../../util/api";
//import { da } from "date-fns/locale";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import "moment/locale/en-in";
import "moment/locale/tr";
import Countries from "./countries.json";

var moment = require("moment");
// import Header from '../../components/Header';

var panes = [
  {
    menuItem: "Subscription",
  },
  {
    menuItem: "createdSubs",
  },
];

class Subs extends React.Component {
  // state = {
  // 	pid: '',
  // 	data: [],
  // 	camp_data: [],
  // 	subs_data: [],
  // };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      pid: props.product.id,
      data: [],
      camp_data: [],
      subs_data: [],
      history: [],
      stepsEnabled: false,
      initialStep: 0,
      loading: false,
      loadingActiveSubs: false,
      loadingActiveAction: false,
      exitOnEsc: true,
      exitOnOverlayClick: true,
      hintsEnabled: false,
      hints: [{ hint: "Hint enabled", hintPosition: "middle-right" }],
      companyName:
        (this.props.user.extra &&
          this.props.user.extra.company &&
          this.props.user.extra.company.name) ||
        "",
      vatNumber:
        (this.props.user.extra &&
          this.props.user.extra.company &&
          this.props.user.extra.company.vatNumber) ||
        "",
      companyCountry:
        (this.props.user.extra &&
          this.props.user.extra.company &&
          this.props.user.extra.company.country) ||
        "",
      companyCity:
        (this.props.user.extra &&
          this.props.user.extra.company &&
          this.props.user.extra.company.city) ||
        "",
      companyState:
        (this.props.user.extra &&
          this.props.user.extra.company &&
          this.props.user.extra.company.state) ||
        "",
      companyPostCode:
        (this.props.user.extra &&
          this.props.user.extra.company &&
          this.props.user.extra.company.postcode) ||
        "",
      companyStreet:
        (this.props.user.extra &&
          this.props.user.extra.company &&
          this.props.user.extra.company.street) ||
        "",
    };
  }

  componentDidMount() {
    this.subsDetail();
    this.getCampaings();
    this.getSubs2();
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  toggleSteps = () => {
    this.setState((prevState) => ({
      stepsEnabled: !prevState.stepsEnabled,
    }));
  };
  handleFormChange = (e, { name, value }) => this.setState({ [name]: value });
  postData(values) {
    let extra = _.merge(this.props.user.extra, {
      company: {
        name: values.companyName,
        vatNumber: values.vatNumber,
        country: values.country,
        state: values.state,
        city: values.city,
        street: values.street,
        postcode: values.postcode,
      },
    });
    post("/auth/updateInfo", {
      id: this.props.user.id,
      company: values.companyName,
      extra,
    });
  }
  refresh() {
    setTimeout(() => {
      this.subsDetail();
      this.getCampaings();
      this.getSubs2();
    }, 1000);
  }
  checkoutHandler = async (quantity, custom_price, isVat) => {
    this.setState({ btnloading: true });
    this.postData({
      companyName: this.state.companyName,
      vatNumber: this.state.vatNumber,
      country: this.state.companyCountry,
      city: this.state.companyCity,
      state: this.state.companyState,
      street: this.state.companyStreet,
      postcode: this.state.companyPostCode,
    });
    //return;
    let custom_discount =
      this.props.product &&
      this.props.product.settings &&
      this.props.product.settings.custom_discount;
    if (custom_discount) {
      custom_price = custom_price * custom_discount;
    }
    let plan_id = 768866;
    if (this.props.product.settings.custom_sub_id) {
      plan_id = this.props.product.settings.custom_sub_id;
    }
    let link = await post("/payment/custompaddlelink", {
      plan_id,
      email: this.props.user.email,
      pid: this.props.product.id,
      custom_price, //change
      isVat,
      vat_number: this.state.vatNumber,
      vat_company_name: this.state.companyName,
      vat_street: this.state.companyStreet,
      vat_city: this.state.companyCity,
      vat_country: this.state.companyCountry,
      country: this.state.companyCountry || "BR",
      postcode: this.state.companyPostCode,
      vat_state: this.state.companyState,
      vat_postcode: this.state.companyPostCode,
    });
    //this.setState({ btnloading: false });
    try {
      let { product = {} } = this.props;
      let email = this.props.user.email;
      //let country = 'BR';
      if (product && product.id) {
        window.Paddle.Checkout.open({
          override: link.data.response.url,
          email,
          country: this.state.companyCountry || "BR",
          quantity,
          postcode: this.state.companyPostCode,
          passthrough: {
            pid: product.id,
          },
          successCallback: async (data) => {
            post("/payment/checkSubscription", {
              checkout_id: data.checkout.id,
              plan_id: data.product.id,
              user_id: data.user.id,
              pid: this.props.product.id,
              quantity: data.product.quantity,
              email: data.user.email,
              extra: data,
            })
              .then(async (resp) => {
                console.log(resp);
              })
              .catch((err) => {
                console.error(err);
              });
          },
          closeCallback: () => {
            this.setState({
              messageOnScreen:
                "Your process has been cancelled, see you again soon!",
              messageColor: "red",
              btnloading: false,
            });
          },
        });
      }
      this.refresh();
    } catch (err) {
      console.error(err);
    }
    this.setState({ purchaseModal: false, customPayment: false });
    this.refresh();
  };

  async getSubs() {
    let { pid, subs_data } = this.state;
    let result = await log("/collectaction/getSubs", { pid })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error.message);
      });

    let now = moment();
    now = now.format("YYYY-MM-DD");
    let data = [],
      datacreate,
      trial;
    if (result && result.data && result.data.data) {
      data = _.uniqBy(result.data.data, "category");
      datacreate = _.filter(
        data,
        (v) => v.label === "create" && subs_data[0].data.next_bill_date > now
      );
      trial = _.filter(
        data,
        (v) => v.label === "trial" && v.extra.trial_end > now
      );
      data = datacreate.concat(trial);
    }
    this.setState({ data, loadingActiveAction: true });
  }
  async getSubs2() {
    let { pid, loading } = this.state;
    let data = await post("/payment/getSubsLogHistory", { pid });
    const propertyNames = data.data.history;

    //data = _.uniqBy(da.data.data, 'category');
    if (data && data.success) loading = true;
    this.setState({ history: propertyNames, loading });

    return propertyNames;
  }

  async getCampaings() {
    let data = await log("/collectaction/getCampaignV2")
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error.message);
      });
    if (data && data.data && data.data.data) {
      this.setState({ camp_data: data.data.data });
    }
  }

  async subsDetail() {
    let { pid } = this.state;
    let data = await log("/collectaction/getSubscriptionUser", { pid })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error.message);
      });
    if (data && data.data && data.data.data) {
      this.setState({ subs_data: data.data.data, loadingActiveSubs: true });

      this.getSubs();
    } else {
      this.setState({ loadingActiveSubs: false });
    }
  }

  closeModal = () => {
    this.setState({
      historyModal: false,
      purchaseModal: false,
    });
  };

  getPaymentHistory = (value) => async () => {
    let { product } = this.props;
    await post("/payment/getPaymentHistory", {
      subscription_id: value.subscription_id,
      pid: product.id,
    }).then((data) => {
      this.setState({
        paymentHistory: _.orderBy(data.data.response, "id", "desc"),
        historyModal: true,
      });
    });
  };

  updatePayment = (value) => async () => {
    let { product } = this.props;
    let data = await post("/payment/getsubsdetails", {
      pid: product.id,
      subscription_id: value.subscription_id,
    });
    let update_url =
      data.data && data.data.response && data.data.response[0].update_url;
    window.Paddle.Checkout.open({
      override: update_url,
      passthrough: {
        pid: this.props.product.id,
      },
    });
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {
    let { t, product } = this.props;
    let {
      data,
      camp_data,
      subs_data,
      activeIndex,
      history,
      loading,
      loadingActiveSubs,
      loadingActiveAction,
      vatNumber,
      companyCity,
      companyCountry,
      companyName,
      companyPostCode,
      companyState,
      companyStreet,
    } = this.state;
    let account_custom_price = product.settings.account_custom_price || 300;
    let language = t("lang");
    var steps = [
      {
        intro: t("subscription1"),
        tooltipClass: ".layout-wrap .ui.pointing.secondary.menu",
        position: "right",
        element: ".layout-wrap .ui.pointing.secondary.menu",
      },
      {
        intro: t("subscription2"),
        tooltipClass: ".ui.center.aligned.segment:nth-child(1)",
        position: "right",
        element: ".ui.center.aligned.segment:nth-child(1)",
      },
      {
        intro: t("subscription3"),
        tooltipClass: ".ui.center.aligned.segment:nth-child(2)",
        position: "right",
        element: ".ui.center.aligned.segment:nth-child(2)",
      },
      {
        intro: t("subscription4"),
        tooltipClass: ".ui.center.aligned.segment:nth-child(3)",
        position: "right",
        element: ".ui.center.aligned.segment:nth-child(3)",
      },
    ];
    var options = {
      nextLabel: t("Next"),
      prevLabel: t("back"),
      skipLabel: t("finish"),
      doneLabel: t("Close"),
      buttonClass: "guiding",
      showProgress: true,
    };

    panes.forEach((e, i) => {
      panes[i].menuItem = t(e.menuItem);
    });
    return (
      <>
        {loading ? (
          <Page>
            <Loader active inline="centered" />
          </Page>
        ) : (
          <Page title={t("Subscription")} icon="cart">
            {activeIndex === 0 && (
              <Button
                size="small"
                onClick={this.toggleSteps}
                color={"instagram"}
                style={{ top: "1%", right: "2%", position: "fixed" }}
              >
                {t("tutorial")}
              </Button>
            )}
            <Page.Wrap>
              <Tab
                menu={{ secondary: true, pointing: true }}
                activeIndex={activeIndex}
                onTabChange={this.handleTabChange}
                panes={panes}
              />
            </Page.Wrap>
            {activeIndex === 0 && (
              <Page.Wrap>
                <Segment textAlign="center">
                  <Header>{t("Subscription")}</Header>
                  {!loadingActiveSubs ? (
                    <Loader active inline="centered" />
                  ) : (
                    <>
                      {subs_data && subs_data[0] ? (
                        <Table celled padded style={{ textAlign: "center" }}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>
                                {t("createdDate")}
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                {t("nextBillDate")}
                              </Table.HeaderCell>
                              <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                              <Table.HeaderCell>
                                {t("totalPrice")}
                              </Table.HeaderCell>
                              <Table.HeaderCell width="4" textAlign="center">
                                {t("Settings")}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          {_.map(subs_data, (dat, i) => (
                            <Table.Body key={dat.category}>
                              <Table.Row>
                                {subs_data && subs_data[0] ? (
                                  <>
                                    <Table.Cell>
                                      {moment(subs_data[0].created_at).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {subs_data[0].data.next_bill_date || "-"}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {t(subs_data[0].data.status)}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {"$"}
                                      {subs_data[0].data.unit_price ||
                                        subs_data[0].data.new_price ||
                                        "0"}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        disabled={
                                          !subs_data[0].data.subscription_id
                                        }
                                        color="blue"
                                        onClick={this.getPaymentHistory({
                                          subscription_id:
                                            subs_data[0].data.subscription_id,
                                        })}
                                      >
                                        {t("invoice")}
                                      </Button>
                                      <Button
                                        disabled={
                                          !subs_data[0].data.subscription_id
                                        }
                                        color="green"
                                        onClick={this.updatePayment({
                                          subscription_id:
                                            subs_data[0].data.subscription_id,
                                        })}
                                      >
                                        {t("changePayment")}
                                      </Button>
                                    </Table.Cell>
                                  </>
                                ) : (
                                  <div></div>
                                )}
                              </Table.Row>
                            </Table.Body>
                          ))}
                        </Table>
                      ) : (
                        <div>
                          <div>{t("activeSubs")}</div>
                          <div style={{ paddingTop: "10px" }}>
                            <Button
                              primary
                              onClick={() =>
                                this.setState({
                                  purchaseModal: true,
                                })
                              }
                              loading={this.state.btnloading}
                            >
                              {t("subscribe")}
                            </Button>
                          </div>
                          <div>{t("startFreeSubs")}</div>
                        </div>
                      )}
                    </>
                  )}
                </Segment>
                <Segment textAlign="center">
                  {loadingActiveAction ? (
                    <>
                      {data && _.find(data, { label: "create" }) ? (
                        <>
                          <Header>{t("activeActions")}</Header>
                          <Table celled padded style={{ textAlign: "center" }}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell width="3">
                                  {t("actionName")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1">
                                  {t("price")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  {t("subsDate")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  {t("nextBillDate")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  {t("dailyLimit")}
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  {t("Limit")}
                                </Table.HeaderCell>

                                <Table.HeaderCell width="1" textAlign="center">
                                  {t("Detail")}
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            {_.filter(data, { label: "create" }).map((dat) => (
                              <Table.Body key={dat.category}>
                                <Table.Row>
                                  <Table.Cell>
                                    {_.filter(
                                      camp_data,
                                      (it) => it.id === parseInt(dat.category)
                                    ).map(
                                      (v) =>
                                        v.settings &&
                                        v.settings.title &&
                                        v.settings.title[language]
                                    )}
                                  </Table.Cell>

                                  <Table.Cell>
                                    {"$"}
                                    {dat.price}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {moment(dat.date).format("YYYY-MM-DD")}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {subs_data[0].data.next_bill_date}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {dat.extra.daily_limit === "-1"
                                      ? t("unlimited")
                                      : dat.extra.daily_limit
                                      ? dat.extra.daily_limit
                                      : dat.extra.limit / 20}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {dat.extra.limit === "-1"
                                      ? t("unlimited")
                                      : dat.extra.limit}
                                  </Table.Cell>

                                  <Table.Cell>
                                    <Button
                                      color="blue"
                                      target="_blank"
                                      href={`/action?id=${dat.category}`}
                                    >
                                      {t("Detail")}
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            ))}
                          </Table>
                        </>
                      ) : (
                        <Segment textAlign="center">
                          <Header>{t("activeActions")}</Header>
                          <div>{t("dontHaveActiveCampaign")}</div>
                        </Segment>
                      )}
                    </>
                  ) : (
                    <Loader active inline="centered" />
                  )}
                </Segment>
                <Segment textAlign="center">
                  {data && _.find(data, { label: "trial" }) ? (
                    <>
                      <Header>{t("trialActions")}</Header>
                      <Table celled padded style={{ textAlign: "center" }}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width="3">
                              {t("actionName")}
                            </Table.HeaderCell>
                            <Table.HeaderCell width="2">
                              {t("finishedTrial")}
                            </Table.HeaderCell>
                            <Table.HeaderCell width="1">
                              {t("dailyLimit")}
                            </Table.HeaderCell>
                            <Table.HeaderCell width="1">
                              {t("Limit")}
                            </Table.HeaderCell>
                            <Table.HeaderCell width="1" textAlign="center">
                              {t("Detail")}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        {_.filter(data, { label: "trial" }).map((dat) => (
                          <Table.Body key={dat.category}>
                            <Table.Row>
                              <Table.Cell>
                                {_.filter(
                                  camp_data,
                                  (it) => it.id === parseInt(dat.category)
                                ).map(
                                  (v) =>
                                    v.settings &&
                                    v.settings.title &&
                                    v.settings.title[language]
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {moment(dat.extra.trial_end).format(
                                  "YYYY-MM-DD"
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {dat.extra.daily_limit === -1
                                  ? t("unlimited")
                                  : dat.extra.daily_limit}
                              </Table.Cell>
                              <Table.Cell>
                                {dat.extra.limit === -1
                                  ? t("unlimited")
                                  : dat.extra.limit}
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  color="blue"
                                  target="_blank"
                                  href={`/action?id=${dat.category}`}
                                >
                                  {t("Detail")}
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        ))}
                      </Table>
                    </>
                  ) : (
                    <Segment textAlign="center">
                      <Header>{t("trialActions")}</Header>
                      <div>{t("dontHaveActiveCampaign")}</div>
                    </Segment>
                  )}
                </Segment>
                <Segment textAlign="center">
                  <Header>{t("CustomPayment")}</Header>
                  <div style={{ paddingTop: "10px" }}>
                    <Button
                      primary
                      onClick={() =>
                        this.setState({
                          purchaseModal: true,
                          customPayment: true,
                        })
                      }
                      loading={this.state.btnloading}
                    >
                      {t("subscribe")}
                    </Button>
                  </div>
                </Segment>
              </Page.Wrap>
            )}
            {activeIndex === 1 && (
              <Page.Wrap>
                {history ? (
                  <>
                    {_.map(history, (dat, i) => (
                      <Segment
                        textAlign="center"
                        style={{ marginBottom: "40px" }}
                      >
                        <>
                          <Segment
                            textAlign="center"
                            style={{ background: "#f9fafb" }}
                          >
                            <Header>
                              {new Date(i).getFullYear()}{" "}
                              {
                                moment.localeData(t("lang"))._months[
                                  new Date(i).getMonth(i)
                                ]
                              }{" "}
                              -{" "}
                              {
                                moment.localeData(t("lang"))._months[
                                  new Date(i).getMonth(i) + 1 > 12
                                    ? 1
                                    : new Date(i).getMonth(i) + 1
                                ]
                              }
                            </Header>
                          </Segment>
                          <Table celled padded style={{ textAlign: "center" }}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>
                                  {t("actionName")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                                <Table.HeaderCell>
                                  {t("label")}
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            {_.map(dat, (da, j) => (
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell style={{ width: "33%" }}>
                                    {_.filter(
                                      camp_data,
                                      (it) => it.id === parseInt(j)
                                    ).map(
                                      (v) =>
                                        v.settings &&
                                        v.settings.title &&
                                        v.settings.title[language]
                                    )}
                                  </Table.Cell>
                                  <Table.Cell style={{ width: "33%" }}>
                                    {da.logs[0].date}
                                  </Table.Cell>
                                  <Table.Cell style={{ width: "33%" }}>
                                    {t(da.logs[0].label)}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            ))}
                          </Table>
                        </>
                      </Segment>
                    ))}
                  </>
                ) : (
                  <Segment textAlign="center">
                    <Header>{t("createdSubs")}</Header>
                    <div>{t("noneSubs")}</div>
                  </Segment>
                )}
              </Page.Wrap>
            )}

            <Steps
              enabled={this.state.stepsEnabled}
              steps={steps}
              initialStep={this.state.initialStep}
              exitOnEsc={this.state.exitOnEsc}
              exitOnOverlayClick={this.state.exitOnOverlayClick}
              onExit={this.onExit}
              options={options}
            />
            {/*Payment History*/}
            <Modal
              open={this.state.historyModal}
              onClose={this.closeModal}
              style={{ maxWidth: "500px" }}
            >
              <Header icon="dollar" content={t("createdSubs")} />
              <Modal.Content>
                <h4>{t("nextPayment")}</h4>
                {_.map(
                  _.filter(this.state.paymentHistory, { is_paid: 0 }),
                  function (it, i) {
                    return (
                      <a
                        href={it.is_paid ? it.receipt_url : null}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={it.id}
                        style={{
                          display: "flex",
                          color: "#646464",
                          padding: "14px",
                          flex: " 0 0 auto",
                          textDecoration: "none",
                          border: "1px solid rgb(221, 221, 221)",
                          fontSize: "600",
                        }}
                      >
                        <div style={{ width: "70%" }}>
                          <div style={{ fontWeight: "600" }}>
                            {t("orderCode") + ": " + it.id}
                          </div>
                          <div style={{ fontWeight: "600" }}>
                            {t("nextBillDate") +
                              ": " +
                              moment.localeData(t("lang"))._months[
                                new Date(it.payout_date).getMonth(
                                  it.payout_date
                                )
                              ] +
                              " " +
                              moment(it.payout_date, "YYYY-MM-DD").format(
                                "DD, YYYY"
                              )}
                          </div>
                        </div>
                        <div>
                          <div style={{ fontWeight: "600" }}>
                            {"$" + it.amount}
                          </div>
                        </div>
                      </a>
                    );
                  }
                )}
              </Modal.Content>
              <Modal.Content style={{ paddingTop: "0", paddingBottom: "0" }}>
                <h4>{t("pastPayments")}</h4>
              </Modal.Content>
              <Modal.Content scrolling>
                <div
                  style={{
                    borderTop: "1px solid rgb(221, 221, 221)",
                    borderRadius: "4px",
                    display: "flex",
                  }}
                ></div>
                {_.map(
                  _.filter(this.state.paymentHistory, { is_paid: 1 }),
                  function (it, i) {
                    return (
                      <a
                        href={it.is_paid ? it.receipt_url : null}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={it.id}
                        style={{
                          display: "flex",
                          color: "#646464",
                          padding: "14px",
                          flex: " 0 0 auto",
                          textDecoration: "none",
                          border: "1px solid rgb(221, 221, 221)",
                          borderTop: "none",
                          fontSize: "600",
                        }}
                      >
                        <div style={{ width: "70%" }}>
                          <div style={{ fontWeight: "600" }}>
                            {t("orderCode") + ": " + it.id}
                          </div>
                          <div style={{ fontWeight: "600" }}>
                            {t("date") +
                              ": " +
                              moment.localeData(t("lang"))._months[
                                new Date(it.payout_date).getMonth(
                                  it.payout_date
                                )
                              ] +
                              " " +
                              moment(it.payout_date, "YYYY-MM-DD").format(
                                "DD, YYYY"
                              )}
                          </div>
                        </div>
                        <div>
                          <div style={{ fontWeight: "600" }}>
                            {"$" + it.amount}
                          </div>
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "black",
                              fontWeight: "400",
                            }}
                          >
                            {t("showReceipt")}
                          </div>
                        </div>
                      </a>
                    );
                  }
                )}
              </Modal.Content>
            </Modal>
            {/*purchase Modal*/}
            <Modal
              open={this.state.purchaseModal}
              onClose={() =>
                this.setState({ purchaseModal: false, customPayment: false })
              }
              style={{ maxWidth: "500px" }}
              history
            >
              <Header icon="exchange" content="Satın Al" />
              <Modal.Content scrolling>
                <div>
                  <div style={{ margin: "auto", marginBottom: "20px" }}>
                    <p>{t("taxSettings")}</p>
                    <Button
                      primary
                      onClick={() =>
                        this.checkoutHandler(
                          1,
                          this.state.customPayment ? account_custom_price : 0,
                          false
                        )
                      }
                    >
                      {t("noCompany")}
                    </Button>
                  </div>
                  <Divider />
                  <Form
                    onSubmit={() =>
                      this.checkoutHandler(
                        1,
                        this.state.customPayment ? account_custom_price : 0,
                        true
                      )
                    }
                  >
                    <Form.Input
                      label={t("taxNumber")}
                      placeholder={t("enterTaxNumber")}
                      required
                      name="vatNumber"
                      value={vatNumber}
                      onChange={this.handleFormChange}
                    ></Form.Input>
                    <Form.Input
                      label={t("CompanyName")}
                      required
                      name="companyName"
                      placeholder={t("enterCompanyName")}
                      value={companyName}
                      onChange={this.handleFormChange}
                    ></Form.Input>
                    <Form.Input
                      label={t("street")}
                      required
                      name="companyStreet"
                      value={companyStreet}
                      placeholder={t("enterStreet")}
                      onChange={this.handleFormChange}
                    ></Form.Input>
                    <Form.Input
                      label={t("city")}
                      required
                      value={companyCity}
                      name="companyCity"
                      placeholder={t("enterCity")}
                      onChange={this.handleFormChange}
                    ></Form.Input>
                    <Form.Input
                      label={t("state")}
                      value={companyState}
                      name="companyState"
                      placeholder={t("enterState")}
                      onChange={this.handleFormChange}
                    ></Form.Input>
                    <Form.Dropdown
                      label={t("country")}
                      placeholder={t("enterCountry")}
                      search
                      required
                      autoComplete="off"
                      fluid
                      name="companyCountry"
                      value={companyCountry}
                      options={Countries}
                      selection
                      onChange={this.handleFormChange}
                    ></Form.Dropdown>
                    <Form.Input
                      label={t("postCode") + " (**)"}
                      placeholder={t("enterPostCode")}
                      value={companyPostCode}
                      required={_.includes(
                        [
                          "AU",
                          "CA",
                          "DE",
                          "ES",
                          "FR",
                          "GB",
                          "IN",
                          "IT",
                          "NL",
                          "US",
                        ],
                        this.state.country
                      )}
                      name="companyPostCode"
                      onChange={this.handleFormChange}
                    ></Form.Input>
                    <Form.Group>
                      <Form.Button
                        style={{ marginLeft: "auto" }}
                        positive
                        type="submit"
                      >
                        <Icon name="checkmark" /> {t("Next")}
                      </Form.Button>
                      <Form.Button color="red" onClick={this.closeModal}>
                        <Icon name="x" /> {t("close")}
                      </Form.Button>
                    </Form.Group>
                  </Form>
                  <span>{t("mustPostCode")}</span>
                </div>
              </Modal.Content>
            </Modal>
          </Page>
        )}
      </>
    );
  }
}

const stateToProps = (state) => ({
  access: state.access,
  product: state.product.personalize,
  location: state.router.location,
  user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Subs));
