import { Link } from 'react-router-dom';
// eslint-disable-next-line
import styled from 'styled-components/macro';

export const Goto = styled(Link)`
	color: #666;
	&:hover {
		color: #333;
	}
`;
