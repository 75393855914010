import React, { Component } from 'react';
import { Container, Segment, Icon } from 'semantic-ui-react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Contact extends Component {
	render() {
		let { t } = this.props;
		return (
			<Page title={t('Help')} icon="help">
				<Page.Wrap>
					<Container>
						<Segment textAlign="center">
							<div>
								<h1>{t('ContactUs')}</h1>
								<br />
								<p>{t('ContactUsText')}</p>
								<p>
									<br />
									E-mail: <a href="mailto:info@yapaytech.com">info@yapaytech.com</a>
								</p>
								<p>
									<br />
									{t('Goto') + ' '} <a href="https://yapaytech.com/products/personalize/faq">{t('FAQ')}</a>
								</p>
								<p>
									<a href="https://yapaytech.com/legal/terms">{t('TermsofService')}</a> {t('and')}{' '}
									<a href="https://yapaytech.com/legal/privacy">{t('PrivacyPolicy')}.</a>
								</p>
							</div>
						</Segment>

						<Segment textAlign="center">
							<div>
								<h1>{t('Tutorials')}</h1>
								<p>{t('WatchTutorials')}</p>
								<Link to="/help/videos">
									<Icon name="video" /> {t('GotoTutorials')}
								</Link>
							</div>
						</Segment>
					</Container>
				</Page.Wrap>
			</Page>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, product: state.product, location: state.router.location });

export default connect(stateToProps)(withTranslation()(Contact));
