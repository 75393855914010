import React from 'react';
import { Dropdown, Icon, Button, Segment, Label, Responsive, Tab, Form } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { MyDivider, FastFieldArray } from './FormikInput';
import _ from 'lodash';
import { Field as RawField } from 'formik';
import { bootSub, getFilterOptions, getPageOptions } from './CampaingHelpers';
import cs from 'classnames';
import { SubSection, BoxSelect } from './FormParts';
import { ActionFromOld } from './ActionForm';

const FormDropdown = styled(Dropdown)`
	min-width: ${(props) => props.size || 200}px;
	//text-align: center !important;
	margin: 9px 10px 9px 0;
	height: 22px;

	.text {
		font-weight: 400 !important;
	}
	.dropdown.icon {
		float: right;
	}
`;

const YtButton = (props) => {
	return (
		<Button icon className="yt-button" basic compact size="mini" type="button" onClick={props.onClick}>
			<Icon size="small" name={props.icon} /> {props.text || ''}
		</Button>
	);
};

export class FilterItem extends React.PureComponent {
	render() {
		const { props } = this;
		const list = props.options.list,
			defaultValue = props.options.__default || { value: 'all' };
		return (
			<Segment className="filter-wrap" attached={props.attached || true}>
				<Label
					size="large"
					attached="top"
					css={`
						background: #d2dbe8 !important;
					`}
				>
					<Icon name={props.icon || 'angle right'} />
					{props.question}
				</Label>
				<FastFieldArray name={props.save}>
					{({ list: andList, helper: andHelpers }) => {
						//const andList = andHelpers.form.values[andHelpers.name];
						let remove = (index) => () => {
							andList.length > 1 ? andHelpers.remove(index) : andHelpers.replace(index, _.cloneDeep(defaultValue));
						};
						return (
							<div>
								{andList.map((data, index) => (
									<RawField key={index} name={`${andHelpers.name}.${index}`}>
										{({ field, form }) => {
											const { value, _cnfg_no_delete } = field.value;
											let selected = _.find(list, { value });
											return (
												<div className="line-wrap sectionwrap" key={index}>
													<FormDropdown
														className="yt-line"
														value={value}
														onChange={(e, { value }) => {
															let obj = _.find(list, { value });
															if (!obj) return;
															if (!obj.__boot) bootSub(obj);
															let _value = _.cloneDeep(obj.__default);
															if (_value.params) _value.params = [_value.params];
															form.setFieldValue(field.name, _value);
														}}
														options={list}
														disabled={!!_cnfg_no_delete}
														inline
														scrolling
														search
													/>

													{selected && selected.sub ? (
														<FastFieldArray name={`${field.name}.params`}>
															{({ list, helper: orHelpers }) => {
																if (!list) {
																	bootSub(selected);
																	setTimeout(() => {
																		orHelpers.form.setFieldValue(`${field.name}.params`, [selected.__default]);
																	}, 0);
																	console.warn('TODO this is wrong...');
																	return null;
																}
																return (
																	<div
																		css={`
																			display: inline-block;
																			flex: 1;
																			flex-wrap: wrap;
																		`}
																	>
																		{list.map((data, i) => {
																			const path = `${orHelpers.name}.${i}`;
																			return (
																				<div
																					css={`
																						display: flex;
																						flex-wrap: wrap;
																						@media screen and (max-width: ${Responsive.onlyMobile.maxWidth - 1}px) {
																							border: 1px solid #d2dbe8;
																							border-radius: 3px;
																							padding: 0 5px;
																							margin-bottom: 5px;
																						}
																					`}
																					key={value + i}
																				>
																					<SubSection path={path} deep={2} data={selected} list={selected.sub} />
																					{!_cnfg_no_delete && (
																						<YtButton
																							right
																							icon="remove"
																							onClick={() =>
																								list.length > 1 ? orHelpers.remove(i) : remove(index)()
																							}
																						/>
																					)}
																				</div>
																			);
																		})}

																		{!_cnfg_no_delete && (
																			<YtButton
																				text="or"
																				icon="add"
																				onClick={() => {
																					bootSub(selected);
																					orHelpers.push(selected.__default.params);
																				}}
																			/>
																		)}
																	</div>
																);
															}}
														</FastFieldArray>
													) : defaultValue.value !== value || index !== 0 ? (
														<YtButton right icon="remove" onClick={remove(index)} />
													) : null}
												</div>
											);
										}}
									</RawField>
								))}
								{!props.disable_add_button && andList && (andList.length !== 1 || !_.isEqual(andList[0], props.options.__default)) && (
									<div className="line-wrap">
										<YtButton text={props.andText || 'and'} icon="add" onClick={() => andHelpers.push(props.options.__default)} />
									</div>
								)}
							</div>
						);
					}}
				</FastFieldArray>
			</Segment>
		);
	}
}

export class DelayItem extends React.PureComponent {
	render() {
		const { props } = this;
		const list = props.options.list,
			defaultValue = props.options.__default || { value: 'all' };
		return (
			<Segment className="filter-wrap" attached={props.attached || true}>
				<Label
					size="large"
					attached="top"
					css={`
						background: #d2dbe8 !important;
					`}
				>
					<Icon name={props.icon || 'angle right'} />
					{props.question}
				</Label>
				<FastFieldArray name={props.save}>
					{({ list: andList, helper: andHelpers }) => {
						console.log('andList', list, andList);
						return (
							<div>
								{andList.map((data, index) => (
									<RawField key={index} name={`${andHelpers.name}.${index}`}>
										{({ field, form }) => {
											return _.map(list, (o, oi) => {
												const { value } = field.value;
												//let selected = _.find(list, { value });
												return (
													<div className="line-wrap sectionwrap" key={index}>
														<Form.Field style={{ margin: 'auto' }}>
															<label style={{ marginRight: '10px' }}>{o.text}</label>
														</Form.Field>

														{o && o.sub ? (
															<FastFieldArray name={`${field.name}.params`}>
																{({ list, helper: orHelpers }) => {
																	if (!list) {
																		bootSub(o);
																		setTimeout(() => {
																			orHelpers.form.setFieldValue(`${field.name}.params`, [o.__default]);
																		}, 0);
																		console.warn('TODO this is wrong...');
																		return null;
																	}
																	return (
																		<div
																			css={`
																				display: inline-block;
																				flex: 1;
																				flex-wrap: wrap;
																			`}
																		>
																			{list.map((data, i) => {
																				const path = `${orHelpers.name}.${i}`;
																				return (
																					<div
																						css={`
																							display: flex;
																							flex-wrap: wrap;
																							@media screen and (max-width: ${Responsive.onlyMobile.maxWidth -
																								1}px) {
																								border: 1px solid #d2dbe8;
																								border-radius: 3px;
																								padding: 0 5px;
																								margin-bottom: 5px;
																							}
																						`}
																						key={value + i}
																					>
																						<SubSection path={path} deep={2} data={o} list={o.sub} />
																					</div>
																				);
																			})}
																		</div>
																	);
																}}
															</FastFieldArray>
														) : defaultValue.value !== value || index !== 0 ? (
															{
																/* 	<YtButton right icon="remove" onClick={remove(index)} /> */
															}
														) : null}
													</div>
												);
											});
										}}
									</RawField>
								))}
							</div>
						);
					}}
				</FastFieldArray>
			</Segment>
		);
	}
}

export class TriggerSelector extends React.PureComponent {
	render() {
		return (
			<RawField name={this.props.data.save + '.0'}>
				{({ field, form }) => {
					let selected = field.value;
					const { _cnfg_hide_others } = field.value || {};
					let _data = _.find(this.props.data.options.list, { value: selected.value });
					return (
						<div className={cs('trigger-wrap', { 'mini-box': this.props.data.mini })}>
							<div className={cs('body', { hideothers: _cnfg_hide_others })} style={{ flexWrap: 'wrap' }}>
								{_.map(this.props.data.options.list, (item, i) => {
									return (
										<BoxSelect
											onClick={() => {
												if (selected.value !== item.value) form.setFieldValue(field.name, item.__default);
											}}
											active={selected.value === item.value}
											key={i}
											data={item}
											disabled={!!selected._cnfg_no_edit}
										/>
									);
								})}
							</div>
							{_data && _data.sub && (
								<div className="subs">
									{this.props.data.showicon && (
										<span>
											<Icon fitted name={_data.icon} />
										</span>
									)}
									<SubSection
										key={selected.value}
										selected={selected.value}
										path={field.name + '.params'}
										deep={2}
										data={_data}
										list={_data.sub}
									/>
								</div>
							)}
						</div>
					);
				}}
			</RawField>
		);
	}
}

export class RecFilterForm extends React.PureComponent {
	render() {
		const { pagetype } = getPageOptions();
		return (
			<div
				css={`
					margin: 10px 20px;
					@media screen and (max-width: ${Responsive.onlyMobile.maxWidth - 1}px) {
						margin: 10px;
					}
				`}
			>
				<MyDivider name="Page Type" icon="file alternate outline" />
				<TriggerSelector data={pagetype} />
			</div>
		);
	}
}

export class FilterForm extends React.PureComponent {
	render() {
		const { options, trigger } = getFilterOptions();
		return (
			<div
				css={`
					margin: 10px 20px;
					@media screen and (max-width: ${Responsive.onlyMobile.maxWidth - 1}px) {
						margin: 10px;
					}
				`}
			>
				<MyDivider name="Trigger" icon="lightning" />
				<TriggerSelector data={trigger} />

				<MyDivider name="Conditions" icon="setting" />
				{options.map((data, i) => (
					<FilterItem key={i} {...data} />
				))}
			</div>
		);
	}
}
/* 
export class TriggerItem extends React.PureComponent {
	render() {
		const { props } = this;
		let { list, mainData, current, options } = props;
		let { name, text } = mainData;
		let defaultValue = list[0].value;
		return (
			<Segment className="filter-wrap" attached={props.attached || true}>
				<Label
					size="large"
					attached="top"
					css={`
						background: #d2dbe8 !important;
					`}
				>
					<Icon name={props.icon || 'angle right'} />
					{text}
				</Label>
				<FastFieldArray name={mainData.name}>
					{({ list: andList, helper: andHelpers }) => {
						//const andList = andHelpers.form.values[andHelpers.name];
						let remove = (index) => () => {
							andList.length > 1 ? andHelpers.remove(index) : andHelpers.replace(index, _.cloneDeep(defaultValue));
						};
						return (
							<div>
								{andList.map((data, index) => (
									<RawField key={index} name={`${andHelpers.name}.${index}`}>
										{({ field, form }) => {
											const { value, _cnfg_no_delete } = field.value;
											let selected = _.find(list, { value });
											return (
												<div className="line-wrap sectionwrap" key={index}>
													<FormDropdown
														className="yt-line"
														value={value}
														onChange={(e, { value }) => {
															let obj = _.find(list, { value });
															if (!obj) return;
															if (!obj.__boot) bootSub(obj);
															let _value = _.cloneDeep(obj.__default);
															if (_value.params) _value.params = [_value.params];
															form.setFieldValue(field.name, _value);
														}}
														options={list}
														disabled={!!_cnfg_no_delete}
														inline
														scrolling
														search
													/>
													{selected && selected.sub ? (
														<FastFieldArray name={`${field.name}.params`}>
															{({ list, helper: orHelpers }) => {
																if (!list) {
																	bootSub(selected);
																	setTimeout(() => {
																		orHelpers.form.setFieldValue(`${field.name}.params`, [selected.__default]);
																	}, 0);
																	console.warn('TODO this is wrong...');
																	return null;
																}
																return (
																	<div
																		css={`
																			display: inline-block;
																			flex: 1;
																			flex-wrap: wrap;
																		`}
																	>
																		{list.map((data, i) => {
																			const path = `${orHelpers.name}.${i}`;
																			return (
																				<div
																					css={`
																						display: flex;
																						flex-wrap: wrap;
																						@media screen and (max-width: ${Responsive.onlyMobile.maxWidth - 1}px) {
																							border: 1px solid #d2dbe8;
																							border-radius: 3px;
																							padding: 0 5px;
																							margin-bottom: 5px;
																						}
																					`}
																					key={value + i}
																				>
																					<SubSection path={path} deep={2} data={selected} list={selected.sub} />
																					{!_cnfg_no_delete && (
																						<YtButton
																							right
																							icon="remove"
																							onClick={() =>
																								list.length > 1 ? orHelpers.remove(i) : remove(index)()
																							}
																						/>
																					)}
																				</div>
																			);
																		})}

																		{!_cnfg_no_delete && (
																			<YtButton
																				text="or"
																				icon="add"
																				onClick={() => {
																					bootSub(selected);
																					orHelpers.push(selected.__default.params);
																				}}
																			/>
																		)}
																	</div>
																);
															}}
														</FastFieldArray>
													) : defaultValue.value !== value || index !== 0 ? (
														<YtButton right icon="remove" onClick={remove(index)} />
													) : null}
												</div>
											);
										}}
									</RawField>
								))}
								{props.options && andList && (andList.length !== 1 || !_.isEqual(andList[0], props.options.__default)) && (
									<div className="line-wrap">
										<YtButton text={props.andText || 'and'} icon="add" onClick={() => andHelpers.push(props.options.__default)} />
									</div>
								)}
							</div>
						);
					}}
				</FastFieldArray>
			</Segment>
		);
	}
}
 */

function TabTrigger(props) {
	const { Forms } = getFilterOptions();
	console.log('Forms', Forms);
	return (
		<Tab.Pane attached={false}>
			<FilterItem {...Forms.trigger} />
			<FilterItem {...Forms.cancel} />
			<DelayItem {...Forms.schedule_delay} />
		</Tab.Pane>
	);
}

function TabCondition(props) {
	const { Forms } = getFilterOptions();
	return (
		<Tab.Pane attached={false}>
			<FilterItem {...Forms.who} />
			<FilterItem {...Forms.where} />
			<FilterItem {...Forms.when} />
		</Tab.Pane>
	);
}

function TabAction(props) {
	return (
		<Tab.Pane attached={false} className="action-tab">
			<FastFieldArray name="tags">
				{({ helper, list }) => {
					return <ActionFromOld helper={helper} ref={props._editor} actions={list || []} t={props.t} />;
				}}
			</FastFieldArray>
		</Tab.Pane>
	);
}

export class TriggerForm extends React.PureComponent {
	render() {
		const panes = [
			{
				menuItem: 'Trigger',
				render: () => <TabTrigger {...this.props} />,
			},
			{
				menuItem: 'Condition',
				render: () => <TabCondition {...this.props} />,
			},
			{
				menuItem: 'Action',
				render: () => <TabAction {...this.props} />,
			},
		];
		return <Tab className="action-page" menu={{ secondary: true, pointing: true }} panes={panes} />;
	}
}
