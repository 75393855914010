import React from "react";
import Page from "../../components/Page";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { Button, Responsive, Breadcrumb } from "semantic-ui-react";
//import _ from 'lodash';
import { Link } from "react-router-dom";
import { get, post } from "../../util/api";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { templateCache, SetUp } from "../../components/CampaingHelpers";
import { MaterialInput } from "../../components/Helpers";
import { FormBuilder } from "../../components/FormBuilder";
import Ansi from "ansi-to-react";
import _ from "lodash";
/* 
const FilterOptions = [
	{ save: 'trigger', question: 'Trigger', options: TriggerOptions, default: 'windowLoadedTrigger', andText: 'or' },
	{ save: 'who', question: 'Who', attached: 'top', color: 'grey', options: WhoOptions },
	{ save: 'where', question: 'Where', color: 'grey', options: WhereOptions },
	{ save: 'when', question: 'When', attached: 'bottom', color: 'grey', options: WhenOptions },
]; */

const ButtonsLine = (props) => {
  const current = props._editor.current;
  const { t } = props;
  if (!current) return null;
  return (
    <>
      <Button as="div" size="small" onClick={current.cancel}>
        {t("Cancel")}
      </Button>
      <Button
        as="div"
        size="small"
        color="teal"
        onClick={() => current.save(props.name)}
      >
        {t("Save")}
      </Button>
    </>
  );
};

class Variable extends React.Component {
  state = {
    load_temp: !!templateCache(),
    loading: this.props.match.params.id !== "create",
    id: this.props.match.params.id || null,
    blocking: true,
    saving: false,
    buttons: [],
    editAction: false,
    current: null,
    actions: [],
    message: null,
    name: "",
  };
  _editor = React.createRef();

  save = () => {
    setTimeout(async () => {
      if (this._editor && this._editor.current) {
        this.setState({ saving: true });
        let data = this._editor.current.values;
        let key = data && data.key && data.key.value;
        let obj = {
          name: this.state.name || key,
          disabled: !!(this.state.current && this.state.current.disabled),
          data,
        };
        if (this.state.id && this.state.id !== "create") obj.id = this.state.id;
        else delete obj.id;
        let {
          success,
          data: response,
          message,
        } = await post("/personalize/variable/save", { data: obj }).then(
          (resp) => resp.data
        );
        if (success && response) {
          if (!this.state.id || this.state.id === "create") {
            this.setState({ id: response.id, blocking: false });
            this.props.history.replace(`/variable/${response.id}`);
          }
        }
        this.setState({
          saving: false,
          current: obj,
          name: key || "",
          blocking: false,
          message,
        });
      }
    }, 0);
  };

  componentDidMount() {
    if (this.props.id !== "create") this.getList();
    if (!this.state.temp_load) this.getTemp();
  }

  getTemp() {
    SetUp().then(() => {
      this.setState({ temp_load: true });
    });
  }

  async getList() {
    let data = await get("/personalize/variable/get/" + this.state.id);
    if (data && data.success) {
      let { id, name = "", data: others, disabled } = data.data;
      let current = others._raw;
      if (!current) {
        current = {
          key: { value: others.name || "" },
          auto: { value: others.auto || false },
          unique: { value: _.has(others, "unique") ? others.unique : true },
          code: { value: (others.parameters && others.parameters.code) || "" },
        };
      }
      if (current) current.disabled = disabled;
      let key = others && others.name;
      this.setState({
        loading: false,
        name: name || key || `Değişken #${id}`,
        current,
      });
    } else this.setState({ loading: false });
  }

  bindSave = (getForm) => {
    this.getForm = getForm;
  };

  setPage = (save, cancel) => {
    this._saveAction = save;
    this._cancelAction = cancel;
    this.setState({ edit_on: !!save });
  };

  onChange = (e, { value, name }) => {
    this.setState({ [name]: value });
  };

  header() {
    const { t } = this.props;
    if (this.state.loading || !this.state.temp_load)
      return <Breadcrumb.Divider icon={{ name: "asterisk", loading: true }} />;
    return (
      <>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section>
          <MaterialInput
            size="mini"
            placeholder={t("variableName")}
            name="name"
            readOnly
            value={this.state.name}
          />
        </Breadcrumb.Section>
      </>
    );
  }

  render() {
    const { t } = this.props;
    if (this.state.loading || !this.state.temp_load)
      return (
        <Page title={t("Variable")} icon="js" breads={this.header()}>
          <Page.Loader />;
        </Page>
      );
    return (
      <Page
        //header={this.header()}
        title={t("Variable")}
        icon="js"
        hidemobile
        breads={this.header()}
        extra={
          <div
            css={`
              margin-left: auto;
              display: flex;
              flex-direction: row;
              align-items: center;
              .button {
                white-space: nowrap;
              }
              @media screen and (max-width: ${Responsive.onlyMobile.maxWidth -
                1}px) {
                .button {
                  padding: 11px !important;
                }
                padding-left: 5px;
                .hide-xs {
                  display: none !important;
                }
              }
            `}
          >
            {this.state.edit_on ? (
              <ButtonsLine
                name={this.state.actionname}
                _editor={this._editor}
              />
            ) : (
              <>
                <Button as={Link} size="small" to="/variable">
                  {t("Exit")}
                </Button>
                <Button
                  primary
                  size="small"
                  loading={this.state.saving}
                  onClick={this.save}
                >
                  {t("Save")}
                </Button>
              </>
            )}
          </div>
        }
      >
        <Page.Wrap>
          <FormBuilder
            type="variable"
            current={this.state.current}
            _form={this._editor}
            _page={this}
          />
          {this.state.message && (
            <Ansi className="error-pre">{this.state.message}</Ansi>
          )}
        </Page.Wrap>
      </Page>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(Variable));

//{process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(values, null, 2)}</pre>}
