import React, { Component } from 'react';
import Page from '../components/Page';
import { Message, Container, Image, Segment} from 'semantic-ui-react';

var getParams = function(url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};
class Error extends Component {
	render() {
		var query = getParams(window.location.href);
		return (
			<Page title="Error" icon="x">
				<Container style={{marginTop:'25px'}}>
					<Segment>
						<Image src="/error-triangle.png" size="small" centered />
						<Message error>
							<Message.Header>{query.title}</Message.Header>
							<p>{query.desc}</p>
							Go to <a href={query.url}> {query.pageName}.</a>
						</Message>
					</Segment>
				</Container>
			</Page>
		);
	}
}

export default Error;
