import React, { Component, useState, useEffect } from "react";
import {
  Modal,
  Form,
  Confirm,
  Message,
  Tab,
  Icon,
  Segment,
  Button,
  Divider,
  Label,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { logout } from "../actions/access.actions";
import { post } from "../util/api";
import { withTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _, { replace } from "lodash";
import MultiStep from "react-multistep";
import $ from "jquery";
import SyntaxHighlighter from "react-syntax-highlighter";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  dark,
  nord,
  atomDark,
  atelierCaveDark,
  anOldHope,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Stepper, Step } from "react-mui-stepper";
import ReactPlayer from "react-player";

const ecommerceType = [
  {
    id: 0,
    text: "T-Soft",
    value: "tsoft",
    key: "tsoft",
    videourl: "https://youtu.be/baVQ_6tmIPE",
  },
  {
    id: 1,
    text: "Ticimax",
    value: "ticimax",
    key: "ticimax",
    videourl: "https://youtu.be/7oxnrF82DQQ",
  },
  {
    id: 2,
    text: "Inveon",
    value: "inveon",
    key: "inveon",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
  {
    id: 3,
    text: "Akinon",
    value: "akinon",
    key: "akinon",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
  {
    id: 4,
    text: "IdeaSoft",
    value: "ideasoft",
    key: "ideasoft",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
  {
    id: 5,
    text: "WooCommerce",
    value: "woocommerce",
    key: "woocommerce",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
  {
    id: 6,
    text: "Faprika",
    value: "faprika",
    key: "faprika",
    videourl: "https://youtu.be/AmNJyeBDC74",
  },
  {
    id: 7,
    text: "Projesoft",
    value: "projesoft",
    key: "projesoft",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
  {
    id: 8,
    text: "PlatinMarket",
    value: "Platinmarket",
    key: "Platinmarket",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
  {
    id: 9,
    text: "Ikas",
    value: "ikas",
    key: "ikas",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
  {
    id: 10,
    text: "Diğer",
    value: "base",
    key: "base",
    videourl: "",
    desc: "Bu Eticaret altyapısının örnek videosu elimizde bulunmamaktadır. Kendi eticaret altyapınızın panelinden script'inizi ekleyebilirsiniz.",
  },
];
function contactUs() {
  var messages = {
    tr: "Script'i nasıl eklerim",
    en: "How do I add the script",
    es: "¿Cómo agrego el guión?",
    pt: "Como adiciono o script",
    ru: "Как добавить скрипт",
    de: "Wie füge ich das Skript hinzu",
    fr: "Comment ajouter le script",
  };
  var lang = navigator.language || navigator.userLanguage;
  var title = messages[lang];
  let contactUshref = document.querySelector("#contactUshref");
  contactUshref.href = `https://api.whatsapp.com/send?phone=908503071679&text=Siteme ${title}`;
}
let c;

class StartModal extends Component {
  state = {
    company: "",
    url: "https://",
    phone: "",
    userName: "",
    activeIndex: 9,
    ecommerce: "base",
    activeEcommerce: "base",
    stepChange: 0,
  };

  changeStepsNext = () => {
    let step = this.state.stepChange + 1;
    let value = this.state.phone;
    const indiaRegex = /^(?:[0-9] ?){11,14}[0-9]$/;
    if (
      this.state.company.length > 0 &&
      this.state.url.length > 8 &&
      this.state.userName
    ) {
      if (step >= c) {
        let check = step;
        check = check - 1;
      } else {
        if (!value.match(indiaRegex)) {
          this.setState({
            error_message: "Lütfen telefon numaranızı doğru giriniz",
          });
        } else {
          this.setState({ phone: replace(`+${this.state.phone}`) });
          this.setState({ stepChange: step, error_message: "" });
        }
      }
      // }
    } else {
      this.setState({
        error_message:
          "Sonraki Adıma Geçmek için Lütfen Gerekli bilgileri doldurunuz",
      });
    }
  };

  changeStepsBack = () => {
    let step = this.state.stepChange - 1;
    if (step <= 0) {
      this.setState({ stepChange: 0 });
    } else {
      this.setState({ stepChange: step });
    }
  };
  handleChange = (e, { name, value }) => {
    if (name === "url") {
      const urlPattern = new RegExp(/^(?:http(s)?:\/\/)/);
      if (!urlPattern.test(value)) {
        value = value.replace(/.*/, "");
        //add https to value
        value = `https://${value}`;
      }
    }
    if (name === "userName") {
      const indiaRegex = /^([A-Z-İĞÜŞÖÇ]|[a-z-_ığüşöç])*/;
      if (!value.match(indiaRegex)) {
        this.setState({
          error_message: "Lütfen adınızı giriniz",
        });
      } else {
        this.setState({
          error_message: "",
        });
      }
    }
    this.setState({ [name]: value });
  };

  handleTabsChange = (e, { value }) => {
    this.setState({ activeEcommerce: value });
  };
  handleSubmit = async () => {
    let { company, url, activeEcommerce, phone, userName } = this.state;

    let checkurl =
      /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
        url
      );
    if (checkurl) {
      await post("/personalize/postStartModal", {
        company,
        url,
        type: activeEcommerce,
        phone,
      }).then((data) => {
        if (data.data.success) {
          post("/auth/updateInfo", {
            phone: this.state.phone,
            name: this.state.userName,
            company: this.state.company,
            name: this.state.userName,
          });
          window.location.reload();
        }
      });
    } else {
      this.setState({ error_message: "Lütfen geçerli bir Url girin." });
    }
  };
  openLogout() {
    this.setState({ logoutopen: true });
  }
  closeLogout() {
    this.setState({ logoutopen: false });
  }
  checkURL = (e, { name, value }) => {
    var string = value;
    if (!~string.indexOf("https")) {
      string = "https://" + string;
    }
    value = string;
    return value;
  };
  onCopy = () => {
    if (!this.state.copied) this.setState({ copied: true });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  };
  handleItemClick = (id) => {
    this.setState({ activeIndex: id });
  };

  render() {
    let { company, url, error_message, activeEcommerce, phone, userName } =
      this.state;
    let { user, product, t } = this.props;
    const id = product.id || user.id;

    const code = `<!--ytag-->\n<script>\n(function (d,s,i) {\n var j = d.createElement('script');\nj.async = true;\nj.id = 'ytag';\nj.src = 'https://cdn.yapaytech.com/ytag/script.js';\nj.setAttribute('pid',i);\nd.head.appendChild(j);\n})( document, 'script', '${id}');\n</script>\<!-- End ytag -->`;

    const videPanes = ecommerceType.map((d) => ({
      menuItem: d.text,
      render: () => (
        <Tab.Pane attached={false}>
          <div>
            {d.videourl.length > 0 && <h3>{t("checkOutTheVideo")}</h3>}

            {d.videourl.length > 0 && (
              <ReactPlayer
                id="ecommerceVideos"
                controls
                url={d.videourl}
                style={{ position: "relative", margin: "auto", width: "auto" }}
              />
            )}
            {<h4 style={{ lineHeight: "20px" }}>{d.desc}</h4>}
          </div>
        </Tab.Pane>
      ),
    }));

    const stepsLer = [
      {
        name: "StepOne",
        component: (
          <div>
            <Modal.Header
              className="modalHeader"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: "800",
                fontSize: "20px",
              }}
            >
              {t("welcomeCollectact")}
            </Modal.Header>
            <Modal.Content>
              {error_message && <Message error content={error_message} />}
              <Form onSubmit={this.handleSubmit}>
                <Form.Input
                  id="userName"
                  label={t("nameandsirname")}
                  placeholder={t("nameandsirname")}
                  name="userName"
                  value={userName}
                  onChange={this.handleChange}
                  type="text"
                  required
                ></Form.Input>
                <div className="countrCodeAndPhone">
                  <Label style={{ backgroundColor: "white" }}>
                    {t("Phone")}
                  </Label>
                  <PhoneInput
                    country={"tr"}
                    value={this.state.phone}
                    id="phone"
                    onChange={(phone) => this.setState({ phone })}
                  />
                </div>
                <Form.Input
                  id="companyInput"
                  label={t("Company")}
                  placeholder={t("Company")}
                  name="company"
                  value={company}
                  onChange={this.handleChange}
                  type="text"
                  required
                ></Form.Input>
                <Form.Input
                  label={t("websiteName")}
                  name="url"
                  value={url}
                  onChange={this.handleChange}
                  type="url"
                  required
                ></Form.Input>

                <Form.Dropdown
                  id="selectEcommerce"
                  label={t("EcommerceTypeNew")}
                  placeholder={t("Select Ecommerce")}
                  selection
                  options={ecommerceType}
                  name="ecommerce"
                  value={activeEcommerce}
                  onChange={this.handleTabsChange}
                />

                <Segment
                  style={{
                    display: "none",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                ></Segment>
              </Form>
            </Modal.Content>
          </div>
        ),
      },
      {
        name: "StepTwo",
        component: (
          <div>
            <h1>{t("howtoAddScript")}</h1>
            <Segment id="codeInformation">
              <SyntaxHighlighter
                language="javascript"
                style={anOldHope}
                showLineNumbers
                useInlineStyles
              >
                {code}
              </SyntaxHighlighter>
              <CopyToClipboard text={code} onCopy={this.onCopy}>
                <Button
                  className="helpBtn"
                  id="helpbutton"
                  fluid
                  color={this.state.copied ? "orange" : "blue"}
                >
                  <Icon name={this.state.copied ? "check" : "copy"} />
                  {this.state.copied ? t("Copied") : t("Copy")}
                </Button>
              </CopyToClipboard>
            </Segment>

            <div style={{ width: "auto" }}>
              <Tab
                id="ecommmerceTabs"
                menu={{ secondary: true, pointing: true }}
                panes={videPanes}
                activeIndex={
                  _.find(ecommerceType, { value: activeEcommerce }).id
                }
              />
            </div>

            <Segment id="afraidWrondScriptSegment" style={{ display: "flex" }}>
              <div
                className="infoHighlight"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3>{t("afridtoAddWrond")}</h3>
                <span style={{ fontWeight: "600" }}>
                  {t("HowToaddScriptHelp")}
                </span>
                <Button
                  id="contactBtn"
                  color="orange"
                  style={{ marginTop: "10px", width: "171px" }}
                >
                  <Icon name="call" />
                  <a
                    id="contactUshref"
                    onClick={contactUs}
                    target={"_blank"}
                    style={{ color: "white" }}
                  >
                    {t("ContactUs")}
                  </a>
                </Button>
              </div>
            </Segment>
            <Segment id="documentationSegment" style={{ display: "flex" }}>
              <div className="infoHighlight">
                <h3>{t("Checkoutthedocumentation")}</h3>
                <Button id="helpBtn" color="orange">
                  <Icon name="book" />
                  <a
                    className="documantationBtn"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href="https://yapaytech.gitbook.io/collectaction-uygulama-marketi/"
                    style={{ color: "white" }}
                  >
                    {t("documentation")}
                  </a>
                </Button>
              </div>
            </Segment>
            <Segment style={{ height: "auto" }}>
              <div
                className="information"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="infoHighlight"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{t("yourInformation")}</h3>
                  <span style={{ fontSize: "15px", marginBottom: "10px" }}>
                    {t("nameandsirname")} :{" "}
                    <span style={{ fontWeight: "600" }}>
                      {this.state.userName}
                    </span>
                  </span>
                  <span style={{ fontSize: "15px", marginBottom: "10px" }}>
                    {t("Phone")} :{" "}
                    <span style={{ fontWeight: "600" }}>
                      {this.state.phone}
                    </span>
                  </span>
                  <span style={{ fontSize: "15px", marginBottom: "10px" }}>
                    {t("Company")} :{" "}
                    <span style={{ fontWeight: "600" }}>
                      {this.state.company}
                    </span>
                  </span>
                  <span style={{ fontSize: "15px", marginBottom: "10px" }}>
                    {t("websiteName")} :{" "}
                    <span style={{ fontWeight: "600" }}>{this.state.url}</span>
                  </span>

                  <span style={{ fontSize: "15px", marginBottom: "10px" }}>
                    {t("EcommerceTypeNew")} :{" "}
                    <span style={{ fontWeight: "600" }}>
                      {this.state.activeEcommerce.charAt(0).toUpperCase() +
                        this.state.activeEcommerce.slice(1)}
                    </span>
                  </span>
                </div>
              </div>
            </Segment>
          </div>
        ),
      },
    ];
    {
      c = stepsLer.length;
    }
    setTimeout(() => {
      let scrollIndex = _.find(ecommerceType, {
        value: this.state.activeEcommerce,
      }).id;
      console.log("scrollIndex", scrollIndex);
      if (window.innerWidth < 1024) {
        if (scrollIndex > 6) {
          if (document.querySelector(".ui.secondary.pointing.menu") !== null) {
            document
              .querySelector(".ui.secondary.pointing.menu")
              .scroll(195, 0);
          }
        }
      }
    }, 200);
    return (
      <div>
        <Modal
          id="startModal"
          open
          style={{
            height: "auto",
            position: "relative",
            padding: "20px",
          }}
          onClose={() => this.openLogout()}
        >
          <Stepper
            withNumbers
            alternativeLabel
            activeStep={this.state.stepChange}
          >
            <Step>{t("Userinformation")}</Step>
            <Step>{t("AddYourScriptYourself")}</Step>
          </Stepper>
          <Segment id="multiStepSegment" style={{ border: "none" }}>
            <MultiStep
              id="buttonlar"
              activeStep={this.state.stepChange}
              showNavigation={false}
              steps={stepsLer}
            />
            <div
              id="bottomDivNextBack"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {this.state.stepChange !== c - 1 && (
                <Button
                  id="goNextBtn"
                  onClick={this.changeStepsNext}
                  color="green"
                >
                  {" "}
                  {t("Next")}
                  <Icon name="angle right" />
                </Button>
              )}
            </div>
            {this.state.stepChange !== 0 && (
              <div
                id="bottomDivBack"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  id="goBackBtn"
                  onClick={this.changeStepsBack}
                  color="green"
                  style={{ marginTop: "10px" }}
                >
                  <Icon name="angle left" />
                  {t("back")}
                </Button>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Button id="submitBtn" positive type="submit">
                    <Icon name="save" />
                    {t("complete")}
                  </Form.Button>
                </Form>
              </div>
            )}
          </Segment>

          <Confirm
            open={this.state.logoutopen}
            content={t("DoYouLogout")}
            cancelButton={t("no")}
            confirmButton={t("yes")}
            onCancel={() => this.closeLogout()}
            style={{ width: "500px" }}
            onConfirm={() => this.props.dispatch(logout())}
          />
        </Modal>
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(StartModal));
