import React, { Component } from "react";
import { Segment, Table } from "semantic-ui-react";
import Page from "../../components/Page";
import _ from "lodash";
import { get } from "../../util/api";
import moment from "moment";
import { withTranslation } from "react-i18next";

class ActionTable extends Component {
  state = {
    loading: true,
    mockData: [
      {
        email: "Beyt",
        label: "update-campaign",
        extra: {
          id: 795,
          name: "Hikaye Bannerı",
          type: "campaign",
          status: "active",
          deleted: false,
          created_at: "2021-03-18T11:13:08.117Z",
          updated_at: "2021-03-18T11:14:20.077Z",
        },
        type: "tsoft",
        date: "2021-03-18 14:14:20",
      },
      {
        email: "Beyt",
        label: "update-campaign",
        extra: {
          id: 795,
          name: "Hikaye Bannerı",
          type: "campaign",
          status: "active",
          deleted: false,
          created_at: "2021-03-18T11:13:08.117Z",
          updated_at: "2021-03-18T11:13:51.710Z",
        },
        type: "tsoft",
        date: "2021-03-18 14:14:20",
      },
    ],
  };
  componentDidMount() {
    get("/footprint/list").then((data) =>
      this.setState({ data: data.result, loading: false })
    );
  }
  render() {
    let { data, loading } = this.state;
    const { t } = this.props;
    if (loading) {
      return <Page.Loader></Page.Loader>;
    }
    return (
      <Segment style={{ overflowY: "auto" }}>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Email")}</Table.HeaderCell>
              <Table.HeaderCell>Pid</Table.HeaderCell>
              <Table.HeaderCell>{t("Action")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
              <Table.HeaderCell>{t('event')}</Table.HeaderCell>
              <Table.HeaderCell>{t("date")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {/* <Table.Body>
						{_.map(mockData, function (it, i) {
							return (
								<Table.Row>
									<Table.Cell>{it.email}</Table.Cell>
									<Table.Cell>{it.type}</Table.Cell>
									<Table.Cell className="footprint-action">
										{_.map(it.action, function (o) {
											return (
												<Table.Row>
													<Table.Cell className="first">{o.name}</Table.Cell>
													<Table.Cell className="first" width="12">
														{_.map(o.event, function (a) {
															return (
																<Table.Row>
																	<Table.Cell className="noBorder">{a.type + ' - ' + a.date}</Table.Cell>
																</Table.Row>
															);
														})}
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				 */}
          <Table.Body>
            {_.map(data, (it) => (
              <Table.Row>
                <Table.Cell>{it.email}</Table.Cell>
                <Table.Cell>{it.pid}</Table.Cell>
                <Table.Cell>{it.extra.name}</Table.Cell>
                <Table.Cell>
                  {it.extra.deleted ? "Silinmiş" : it.extra.status}
                </Table.Cell>
                <Table.Cell>{it.label}</Table.Cell>
                <Table.Cell>
                  {moment(it.date).format("DD-MM-YYYY HH:mm")}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
export default withTranslation()(ActionTable);
