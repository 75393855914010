import React, { PureComponent } from 'react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { Button } from 'semantic-ui-react';
import { get } from '../../util/api';
import { withTranslation } from 'react-i18next';
class BotTest extends PureComponent {
	state = { loading: false };

	componentDidCatch(error, errorInfo) {
		console.warn(error, errorInfo);
	}

	refresh = async () => {
		if (this.state.loading) return;
		try {
			this.setState({ loading: true });
			await get('/personalize/bot/publish/test');
			this.setState({ loading: false });
		} catch (error) {
			this.setState({ loading: false });
		}
	};

	render() {
		let { t } = this.props;
		let test_id = this.props.config && this.props.config.test_id;
		return (
			<>
				<div className="header">
					<Button fluid loading={this.state.loading} onClick={this.refresh}>
						{t('UpdateBot')}
					</Button>
				</div>
				<iframe
					title="bot_test"
					css={`
						flex: 1;
						border: none !important;
						width: 100%;
						height: 100%;
						//box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15);
						//border-radius: 5px;
					`}
					src={'/chat.html?bot=' + test_id}
				/>
			</>
		);
	}
}
/**
 * <Frame
				css={`
					flex: 1;
					border: none !important;
					width: 100%;
					height: 100%;
					//box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15);
					//border-radius: 5px;
				`}
				initialContent={runCode(this.props.bot || '58dd0731732a58822ac82483')}
			></Frame>
 */
export default withTranslation()(BotTest);
