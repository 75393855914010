import React from "react";
import Page from "../../components/Page";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { FilterForm, TriggerSelector } from "../../components/Selector";
import { Section } from "../../components/FormParts";
import { ActionFrom } from "../../components/ActionForm";
import {
  Icon,
  Button,
  Responsive,
  Image,
  Breadcrumb,
  Accordion,
  List,
  Modal,
  Segment,
  Container,
  Header,
  Label,
  Dropdown,
  Popup,
  Grid,
} from "semantic-ui-react";
//import _ from 'lodash';
import { Link, Prompt, Redirect } from "react-router-dom";
import { get, post } from "../../util/api";
import {
  getFilterOptions,
  templateCache,
  templateCacheControl,
  SetUp,
  bootSub,
} from "../../components/CampaingHelpers";
import { MyDivider } from "../../components/FormikInput";
import { Formik, Form } from "formik";
import { MaterialInput } from "../../components/Helpers";
import { FormBuilder } from "../../components/FormBuilder";
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

const DEFINITION_TYPE = "action";

function merger(current, schema, isForm) {
  let def = schema && schema.__default;
  let params = def && def.params;

  if (
    !isForm &&
    current &&
    current.parameters &&
    current.parameters.parameters &&
    params
  ) {
    let ret = {};
    _.each(params, (val, key) => (ret[key] = val));
    _.each(current.parameters.parameters, (val, key) => (ret[key] = val));
    current.parameters.parameters = ret;
  }
  if (isForm && current && current.parameters && params) {
    let ret = {};
    _.each(params, (val, key) => (ret[key] = val));
    _.each(current.parameters, (val, key) => (ret[key] = val));
    current.parameters = ret;
  }
  return current || params;
}

const statusList = {
  active: { text: "Active", color: "green" },
  hidden: { text: "Hidden", color: "grey" },
  disabled: { text: "Disabled", color: "grey" },
  test: { text: "Test", color: "yellow" },
};

const DateLabel = styled(Label)`
  margin: 0 !important;
  width: 100%;
  text-align: right;
  .detail {
    text-align: right;
    width: 40px;
  }
`;

function getTemplateVariation(template, var_id, language) {
  if (!template) return;
  if (!var_id && template.default) var_id = template.default;
  console.log("found1 ", template, language);
  let found =
    template.settings &&
    template.settings.data &&
    _.find(template.settings.data[language], { id: var_id });
  console.log("found ", found, language);
  if (!found) found = _.find(template.data, { id: var_id });
  return found;
}

const ActionCard = (props) => {
  let { data } = props;
  return (
    <List.Item active={props.active} onClick={() => props.onClick(data)}>
      <Image src={data.image || "/images/white-image.png"} />
      <List.Content style={{ textAlign: "center", padding: "15px 0 15px 0" }}>
        <List.Header style={{ fontSize: "17px" }}>{data.name}</List.Header>
      </List.Content>
    </List.Item>
  );
};

class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temp_load: !!templateCacheControl(DEFINITION_TYPE),
      loading:
        (this.props.match.params.id &&
          this.props.match.params.id !== "create") ||
        this.props.match.params.t_id,
      id: this.props.match.params.id || null,
      saving: false,
      modalOpen: false,
      current: null,
      actions: [],
      name: "",
      actionname: "",
      status: "disabled",
      activeIndex: -1,
      openListModal: false,
      redirect_template: null,
      openTestModal: false,
      stepsEnabled: false,
      initialStep: 0,
      exitOnEsc: true,
      exitOnOverlayClick: true,
      hintsEnabled: false,
      hideforTrendyol: false,
    };
  }
  // state = {
  // 	temp_load: !!templateCacheControl(DEFINITION_TYPE),
  // 	loading: (this.props.match.params.id && this.props.match.params.id !== 'create') || this.props.match.params.t_id,
  // 	id: this.props.match.params.id || null,
  // 	saving: false,
  // 	modalOpen: false,
  // 	current: null,
  // 	actions: [],
  // 	name: '',
  // 	actionname: '',
  // 	status: 'disabled',
  // 	activeIndex: -1,
  // 	openListModal: false,
  // 	redirect_template: null,
  // 	openTestModal: false,
  // };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  toggleSteps = () => {
    this.setState((prevState) => ({
      stepsEnabled: !prevState.stepsEnabled,
    }));
  };
  toggleHints = () => {
    this.setState((prevState) => ({
      hintsEnabled: !prevState.hintsEnabled,
    }));
  };

  _form = React.createRef();

  save = () => {
    this._form.current.submitForm();
  };

  action_to_save(data, name) {
    let { value, schema, widgetSchema } = data;
    if (schema.type === "popupActionTag")
      return [
        {
          ...value,
          name: name,
          parameters: {
            parameters: value.parameters.parameters,
            type: value.parameters.type || widgetSchema.value,
            component: value.parameters.component || widgetSchema.component,
            props: widgetSchema.props,
          },
          type: schema.value,
        },
      ];
    else
      return [
        {
          ...value,
          name: name,
          type: schema.value,
        },
      ];
  }

  handleSubmit = async (data, actions) => {
    actions.setSubmitting(true);
    data.status = this.state.status;
    this.setState({ saving: true });
    data._key = this.state._key;
    data._var = this.state._var;
    if (this.state.type === "recommendation") {
      let obj = { name: this.state.name, status: "disabled", ...data };
      if (this.state.id && this.state.id !== "create") obj.id = this.state.id;
      else delete obj.id;
      let { success, data: response } = await post("/campaign/save", {
        data: obj,
        type: "recommendation",
      }).then((resp) => resp.data);
      if (success && response) {
        await post("/personalize/recommendationSave", { force: true }).then(
          (data) => {}
        );
        if (!this.state.id || this.state.id === "create") {
          this.createTrial({ actions, data, response });
        }
      }
      this.setState({
        saving: false,
        current: obj,
        name: this.state.name || "",
      });
    } else {
      let obj = { name: this.state.name, status: "disabled", ...data };
      if (this.state.id && this.state.id !== "create") obj.id = this.state.id;
      else delete obj.id;
      obj.tags = this.action_to_save(data.action, obj.name);
      if (this.state.actions && this.state.actions.length > 1)
        obj.tags = _.concat(obj.tags, _.slice(this.state.actions, 1));
      delete obj.action;
      let { success, data: response } = await post("/campaign/save", {
        data: obj,
        type: "campaign",
      }).then((resp) => resp.data);
      if (success && response) {
        if (!this.state.id || this.state.id === "create") {
          this.createTrial({ actions, data, response });
        }
      }
      this.setState({
        saving: false,
        current: obj,
        name:
          this.state.name || (response.id && `Action #${response.id}`) || "",
        action: this.setScheme(obj.tags),
      });
    }
    actions.setSubmitting(false);
    actions.resetForm(data);
  };

  componentDidMount() {
    this.prepare();
    if (!this.state.id) {
      this.getData();
      this.getSubs();
    }
  }

  async getSubs() {
    let pid = this.props.product.id;
    let [subscriptionsData, subLog] = await Promise.all([
      post("/personalize/getsubscription", { pid }),
      post("/personalize/getLogs", {
        pid,
        category: parseInt(this.state.loading),
      }),
    ]);
    let last_sub = _.maxBy(subLog.data.data, "id");
    let subscriptions = subscriptionsData.data.subscriptions;

    let isActiveSub = _.find(
      subscriptions,
      (it) => it.data.status === "active" || it.data.status === "trial"
    );

    this.setState({
      subscriptions,
      subLog,
      last_sub,
      isActiveSub,
    });
  }

  async getData() {
    if (this.state.loading) {
      await get(
        `/campaign/templateV2/${this.state.loading}?type=campaign&developer=` +
          this.state.developer
      ).then((it) => {
        if (it && it.data && it.data.pricing && it.data.pricing[0]) {
          this.setState({
            price: it.data.pricing[0],
          });
        }
      });
    }
  }
  async createTrial({ actions, data, response }) {
    let { product, user } = this.props;
    let { price } = this.state;
    let pid = product.id;

    actions.resetForm(data);
    this.setState({ id: response.id });
    this.props.history.replace(`/actions/${response.id}`);

    // await post("/payment/createFreeTrial", {
    //   pid: pid,
    //   category: this.state._key,
    //   limit: (price && price.limit) || 10000,
    //   daily_limit: ((price && price.limit) || 10000) / 20,
    //   trial_day: 7,
    // }).then((data) => {
    //   if (data.data.success) {
    //     post("/footprint/add", {
    //       category: "collectaction",
    //       label: "checkout-start",
    //       extra: {
    //         pid,
    //         uid: user.id,
    //         plan: "trial",
    //       },
    //     }).then(() => {
    //       actions.resetForm(data);
    //       this.setState({ id: response.id });
    //       this.props.history.replace(`/actions/${response.id}`);
    //     });
    //   } else {
    //     this.refresh();
    //   }
    // });
  }
  async prepare() {
    if (!this.state.temp_load) await SetUp(DEFINITION_TYPE);
    this.setState({ temp_load: true });
    if (this.state.id && this.state.id !== "create") await this.loadAction();
    else if (this.props.match.params.t_id) await this.loadTemplate();
  }

  async loadTemplate() {
    let data = await get(
      "/campaign/templateV2/" + this.props.match.params.t_id
    );
    if (data && data.success) {
      this.setStateForTemplate(data.data);
    } else this.setState({ loading: false });
  }

  setStateForTemplate(template, var_id) {
    let { config, limit, installed } = template;
    let { title } = template.settings;
    const { t } = this.props;
    let language = t("lang");
    console.log("template ", template);
    if (limit && installed && installed.length && installed.length >= limit)
      return this.setState({ redirect_template: template.id, language });
    let found_template = getTemplateVariation(template, var_id, language);
    console.log("found_template ", found_template);

    let { who, where, when, trigger, tags, frequency, ratio } =
      found_template.data;
    let actions = tags || [];
    let type =
      found_template.data.type || (config && config._type) || "campaign";
    this.setState({
      loading: false,
      name: title[language],
      current:
        type === "recommendation"
          ? found_template.data
          : { who, where, when, trigger, frequency, ratio },
      actions,
      _key: template.id,
      _var: found_template.id,
      _template: template,
      action: this.setScheme(actions),
      config,
      opt: found_template._opt || (config && config._opt) || {},
      type,
    });
  }

  listOnclick = (variable) => {
    let id = variable && variable.id;
    if (id !== this.state._var)
      this.setStateForTemplate(this.state._template, variable && variable.id);
    this.setState({ openListModal: false });
  };

  setScheme(actions) {
    let currentValue = actions && actions[0];
    if (!currentValue) return null;
    let { actionList, widgetList } = templateCache();
    switch (currentValue.type) {
      case "popupActionTag": {
        const widgetSchema =
          currentValue.parameters.type &&
          _.find(widgetList, { value: currentValue.parameters.type });
        let schema = _.find(actionList, { value: currentValue.type });
        bootSub(widgetSchema);
        let value = merger(currentValue, widgetSchema);
        //if (!schema) console.warn('Can not find schema for ', currentValue.type);
        return { value, schema, widgetSchema: widgetSchema };
      }
      default: {
        let schema = _.find(actionList, { value: currentValue.type });
        bootSub(schema);
        let value = merger(currentValue, schema, true);
        return { value, schema };
      }
    }
  }

  async loadAction() {
    try {
      let data = await get("/campaign/getV2/" + this.state.id);
      let { name = "", status, data: others, _template, type } = data.data;
      let { who, where, when, trigger, tags, frequency, ratio } = others;
      let actions = tags || [];
      let found_template = getTemplateVariation(
        _template,
        others._var,
        this.props.t("lang") || "tr"
      );

      let _opt =
        (found_template && found_template._var) ||
        (_template && _template.config && _template.config._opt);
      others.status = status;
      let _type = found_template.data.type || type;
      let buildData;

      if (data.data.id) {
        let camp_id = data.data.id;
        buildData = await post("/personalize/getBuildCampaign", {
          camp_ids: [camp_id],
        });
        if (buildData) {
          buildData =
            buildData.data && buildData.data.data && buildData.data.data[0];
        }
      }
      if (_template && _template.id && _template.id === 82) {
        this.setState({ hideforTrendyol: true });
      }
      if (window.location.search === "?debug=true") {
        this.setState({ hideforTrendyol: false });
      }

      this.setState({
        loading: false,
        aid: data.data.id,
        name: name || `Action #${data.data.id}`,
        current:
          _type === "recommendation"
            ? others
            : { who, where, when, trigger, frequency, ratio, status },
        actions,
        _key: others._key,
        _var: found_template && found_template.id,
        _template,
        action: this.setScheme(actions),
        type: _type,
        status: data && data.data && data.data.status,
        opt: _opt || {},
        buildData,
      });
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  }

  onChange = (e, { value, name }) => {
    this.setState({ [name]: value });
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  onChangeStatus = (e, { value, name }) => {
    if (value === "test") {
      this.setState({ openTestModal: true });
    }
    this.setState({ statusLoading: true });

    post("/campaign/save", { data: { id: this.state.id, status: value } }).then(
      () => {
        this.setState({ status: value, statusLoading: false });
      }
    );
  };
  _refreshUpdate() {
    window.location.href = "/subscription";
  }

  header(t) {
    let options_ = {
      nextLabel: t("Next"),
      prevLabel: t("back"),
      skipLabel: t("finish"),
      doneLabel: t("Close"),
      buttonClass: "guiding",
      showProgress: true,
    };

    let steps = [
      {
        intro: t("actionEditTutorial1"),
        tooltipClass: ".action-edit-wrap .scroll-body",
        position: "right",
        element: ".action-edit-wrap .scroll-body",
      },
      {
        intro: t("actionEditTutorial2"),
        tooltipClass: ".ui.compact.icon.left.labeled",
        position: "right",
        element: ".ui.compact.icon.left.labeled",
      },
      {
        intro: t("actionEditTutorial3"),
        tooltipClass: ".inner-wrap",
        position: "right",
        element: ".inner-wrap",
      },
      {
        intro: t("actionEditTutorial4"),
        tooltipClass: ".ui.selection.dropdown",
        position: "right",
        element: ".ui.selection.dropdown",
      },
      {
        intro: t("actionEditTutorial5"),
        tooltipClass: ".ui.basic.icon.button:nth-child(2)",
        position: "right",
        element: ".ui.basic.icon.button:nth-child(2)",
      },
      {
        intro: t("actionEditTutorial6"),
        tooltipClass: ".ui.basic.icon.button:nth-child(3)",
        position: "right",
        element: ".ui.basic.icon.button:nth-child(3)",
      },
      // {
      //   intro: `Filtrelemeler,aksiyonlar ile ilgili örnekleri veya nasıl kullanıldığını inceleyebilirsiniz.`,
      //   tooltipClass: ".ui.left.labeled.button:nth-child(1)",
      //   position: "right",
      //   element: ".ui.left.labeled.button:nth-child(1)",
      // },
    ];

    /*let hints = [
      {
        hint: "Kullanıcı sayfanın hangi aşamasında bu aksiyonun tetiklenmesini istediğiniz kısım. Sayfa yüklendiği zaman mı? yoksa sayfa yüklendikten 10 saniye sonra mı? gibi kurguların yapıldığı kısımdır.",
        hintPosition: ".ui.page.modals.dimmer .ui.header:nth-child(1)",
        element: ".ui.page.modals.dimmer .ui.header:nth-child(1)",
      },
      {
        hint: "Aksiyonun conditions ayarlarının bulunduğu kısımdır. Hangi sayfada, kime ve hangi zaman aralığında gösterileceğinin belirlendiği kısımdır.",
        hintPosition: ".setting.icon:nth-last-child(1)",
        element: ".setting.icon:nth-last-child(1)",
      },
      {
        hint: "Aksiyonun gelişmiş ayarları ve control grubun bulunduğu kısımdır.",
        hintPosition: ".ui.modal .accordion.ui .title",
        element: ".ui.modal .accordion.ui .title",
      },
    ];*/
    let { status, statusLoading, error, action, buildData } = this.state;

    //let toggleStatus = status === 'active' ? true : false;
    const options = [
      { key: "active", icon: "check", text: t("Active"), value: "active" },
      { key: "test", icon: "eye", text: "Test", value: "test" },
      {
        key: "disabled",
        icon: "close",
        text: t("Disabled"),
        value: "disabled",
      },
    ];
    if (error) {
      return <Redirect to="/actions" />;
    }
    if (this.state.loading || !this.state.temp_load)
      return (
        <Breadcrumb size="big">
          <Breadcrumb.Section as={Link} to={`/actions`}>
            <Icon name="bullhorn" />
            {t("Actions")}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon={{ name: "asterisk", loading: true }} />
        </Breadcrumb>
      );
    let language = t("lang");
    let tempList = _.filter(
      this.state._template.settings.data[language],
      (it) => !it.advance
    );
    if (localStorage.developer === "true")
      tempList = this.state._template.settings.data[language];

    return (
      <>
        <Steps
          enabled={this.state.stepsEnabled}
          steps={steps}
          initialStep={this.state.initialStep}
          exitOnEsc={this.state.exitOnEsc}
          exitOnOverlayClick={this.state.exitOnOverlayClick}
          options={options_}
          onExit={this.onExit}
        />

        {/* <Hints style={{ zIndex: '-1' }} enabled={this.state.hintsEnabled} hints={this.state.hints} /> */}
        <Breadcrumb size="big">
          <Breadcrumb.Section className="mobile-none" as={Link} to={`/actions`}>
            <Icon name="bullhorn" />
            {t("Actions")}
          </Breadcrumb.Section>
          <Breadcrumb.Divider className="mobile-none" icon="right chevron" />
          <Breadcrumb.Section>
            <MaterialInput
              className="actionName"
              size="mini"
              placeholder="Action Name"
              name="name"
              onChange={this.onChange}
              value={this.state.name}
            />
          </Breadcrumb.Section>
        </Breadcrumb>
        <div
          css={`
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            .button {
              white-space: nowrap;
            }
            @media screen and (max-width: ${Responsive.onlyMobile.maxWidth -
              1}px) {
              .button {
                padding: 11px !important;
              }
              padding-left: 5px;
              .hide-xs {
                display: none !important;
              }
            }
          `}
        >
          {status === "test" && (
            <Popup
              id="popup-alert"
              position="left center"
              wide
              trigger={
                <Icon
                  onClick={() => this.setState({ openTestModal: true })}
                  id="icon-anim"
                  circular
                  style={{
                    marginRight: "28px",
                    cursor: "pointer",
                    background: "#fbbd08",
                    color: "white",
                  }}
                  name="question"
                >
                  {"  "}
                </Icon>
              }
            >
              <Grid divided>
                <Grid.Column textAlign="center">
                  <p>
                    http://yourwebsite.com/<b>?ytm_test=true</b>
                  </p>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "-5px",
                      marginBottom: "5px",
                    }}
                  ></div>
                  <p>{t("testDetail")}</p>
                </Grid.Column>
              </Grid>{" "}
            </Popup>
          )}
          {action &&
            action.schema &&
            action.schema.value === "popupActionTag" && (
              <Button
                onClick={this.toggleSteps}
                color={"instagram"}
                size="small"
              >
                {t("tutorial")}
              </Button>
            )}
          <Modal
            open={this.state.openTestModal}
            onClose={() => this.setState({ openTestModal: false })}
          >
            <Modal.Header>
              {t("test")}
              <Button
                href={
                  "https://yapaytech.gitbook.io/collectaction-uygulama-marketi/aksiyon/mod-degisimi"
                }
                target="_blank"
                color={"instagram"}
                style={{ float: "right" }}
              >
                {t("modeDetails")}
              </Button>
            </Modal.Header>
            <Modal.Content style={{ textAlign: "center", paddingLeft: "0px" }}>
              <p>{t("testSite")}</p>
            </Modal.Content>
            <Modal.Content>
              <img
                width="100%"
                src={
                  "https://cdnydm.com/permedia/WzcvNDPJGZkCAfcyZyGxGg.png?size=1310x931"
                }
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                size="small"
                onClick={() => this.setState({ openTestModal: false })}
              >
                {t("Close")}
              </Button>
            </Modal.Actions>
          </Modal>
          {tempList && tempList.length > 1 ? (
            <Modal
              className="edit-conditions"
              closeIcon
              size="large"
              open={this.state.openListModal}
              onClose={() => this.setState({ openListModal: false })}
              onOpen={() => this.setState({ openListModal: true })}
              trigger={<Button size="small">{t("modifyStyle")}</Button>}
            >
              <Modal.Header>{t("modifyStyle")}</Modal.Header>
              <Modal.Content
                scrolling
                css={`
                  padding: 0px !important;
                `}
              >
                <List
                  id={"action-card"}
                  divided
                  selection
                  relaxed="very"
                  horizontal
                  style={{ display: "inline-flex" }}
                >
                  {_.map(
                    this.state._template.settings.data[language],
                    (it, i) => (
                      <ActionCard
                        key={i}
                        data={it}
                        active={it.id === this.state._var}
                        onClick={this.listOnclick}
                      />
                    )
                  )}
                </List>
              </Modal.Content>
            </Modal>
          ) : null}
          {buildData && buildData.block && (
            <Popup
              trigger={
                <Icon
                  size={"large"}
                  style={{
                    paddingRight: "28px",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                  name="exclamation triangle"
                  color="red"
                  onClick={this._refreshUpdate}
                />
              }
              content={t("dailyLimitUp")}
              position="top right"
            />
          )}
          <div style={{ display: "flex", margin: "3px" }}>
            <span style={{ margin: "auto 5px auto", display: "flex" }}>
              {t("Status")}:
            </span>
            <Dropdown
              onChange={this.onChangeStatus}
              size="tiny"
              name="toggleStatus"
              direction="left"
              options={options}
              icon={false}
              trigger={
                <DateLabel
                  color={statusList[status || "disabled"].color}
                  size="large"
                >
                  {statusLoading && <Icon name="asterisk" loading />}
                  {t("" + statusList[status || "disabled"].text)}
                </DateLabel>
              }
            />
            {/* <Checkbox toggle name="toggleStatus" defaultChecked={toggleStatus} value={toggleStatus} onChange={this.onChangeStatus}></Checkbox> */}
          </div>
          <Button size="small" onClick={() => this.props.history.goBack()}>
            {t("Cancel")}
          </Button>
          <Button
            primary
            size="small"
            loading={this.state.saving}
            onClick={this.save}
          >
            {t("SaveText", { text: t("ActionTo") })}
            {/* 	<span className="hide-xs"> {t('Action')}</span> */}
          </Button>
        </div>
      </>
    );
  }

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    let { product, t } = this.props;
    let { config } = this.state;
    let ecommerceType =
      (product &&
        (product.type || (product.settings && product.settings.type))) ||
      "base";
    if (this.state.redirect_template) {
      return (
        <Redirect
          to={{
            pathname: "/action",
            search: "?id=" + this.state.redirect_template,
          }}
        />
      );
    }
    if (this.state.loading || !this.state.temp_load)
      return (
        <Page header={this.header(t)}>
          <Page.Loader />
        </Page>
      );
    let currentState = this.state.current;
    const { frequency, ratio, preData } = getFilterOptions();
    if (currentState && !currentState.frequency)
      currentState.frequency = preData.frequency;
    const { activeIndex } = this.state;
    let canInstall =
      ecommerceType && config && (config[ecommerceType] || config.base);
    if (!this.state._var) {
      return (
        <Page header={this.header(t)} flex>
          <Page.Wrap>{t("contactUs")}</Page.Wrap>
        </Page>
      );
    }
    if (canInstall || !config) {
      if (this.state.type === "recommendation")
        return (
          <Page header={this.header(t)} flex key={this.state._var}>
            <Page.Wrap>
              <FormBuilder
                _form={this._form}
                handleSubmit={this.handleSubmit}
                type="recommend"
                current={this.state.current}
                _page={this}
                _key={this.state._key}
              />
            </Page.Wrap>
          </Page>
        );
      return (
        <Page header={this.header(t)} flex>
          <Formik
            initialValues={{
              ...(currentState || preData),
              _key: this.state._key,
              action: this.state.action,
            }}
            innerRef={this._form}
            onSubmit={this.handleSubmit}
            key={this.state._var}
          >
            {({ values, dirty }) => {
              return (
                <Form
                  autoComplete="off"
                  css={`
                    background: inherit;
                    max-height: 100%;
                    min-height: 100%;
                    overflow: auto;
                    overflow-x: hidden;
                  `}
                >
                  <input
                    autoComplete="false"
                    name="hidden"
                    type="text"
                    className={"d-none"}
                  />
                  <Prompt
                    when={dirty}
                    message={(location) =>
                      `${t("AreYouSureWithoutSaving", {
                        pathname: location.pathname,
                      })}`
                    }
                  />

                  <Modal
                    className="edit-conditions"
                    closeIcon
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                  >
                    <Modal.Header>
                      {t("EditConditions")}
                      <Button
                        color={"instagram"}
                        style={{ float: "right" }}
                        target={"_blank"}
                        href={
                          "https://yapaytech.gitbook.io/collectaction-uygulama-marketi/filtre-ayarlari/genel"
                        }
                      >
                        {t("filterDocs")}
                      </Button>
                    </Modal.Header>
                    <Modal.Content scrolling>
                      <FilterForm t={t} />
                      <Accordion
                        css={`
                          margin: 20px;
                        `}
                      >
                        <Accordion.Title
                          active={activeIndex === 0}
                          index={0}
                          onClick={this.handleClick}
                        >
                          <Icon name="dropdown" />
                          {t("Advanced")}
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                          <MyDivider name="Frequency" icon="lightning" />
                          <TriggerSelector data={frequency} />
                          <MyDivider name={"Config"} icon="cog" />
                          <Section path={"ratio"} data={ratio} />
                        </Accordion.Content>
                      </Accordion>
                    </Modal.Content>
                  </Modal>
                  {this.state.hideforTrendyol === false && (
                    <ActionFrom
                      {...values.action}
                      handleOpen={this.handleOpen}
                      opt={this.state.opt}
                      t={t}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </Page>
      );
    }
    return (
      <Page
        header={
          <Breadcrumb size="big">
            <Breadcrumb.Section
              className="mobile-none"
              as={Link}
              to={`/actions`}
            >
              <Icon name="bullhorn" />
              {t("Actions")}
            </Breadcrumb.Section>
            <Breadcrumb.Divider className="mobile-none" icon="right chevron" />
          </Breadcrumb>
        }
      >
        <Page.Wrap>
          <Container>
            <Segment>
              <Header>{t("dontInstall")}</Header>
              <p>{t("dontInstallText")}</p>
            </Segment>
          </Container>
        </Page.Wrap>
      </Page>
    );
  }
}
export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(Actions));
//{process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(values, null, 2)}</pre>}
