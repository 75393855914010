import React from "react";
import _ from "lodash";
// eslint-disable-next-line
import styled, {
  StyleSheetManager,
  createGlobalStyle,
} from "styled-components/macro";
import Frame, { FrameContextConsumer } from "react-frame-component";
import { Header, Icon, Button, Popup, Dropdown } from "semantic-ui-react";
import cs from "classnames";
import { SizeMe } from "react-sizeme";
import Mockup, { trList, enList } from "./Mockup";
import { Field } from "formik";
import useFetch from "use-http";

function loadScript(document, src, done) {
  var js = document.createElement("script");
  js.crossorigin = "anonymous";
  js.src = src;
  js.onload = () => {
    done();
  };
  js.onerror = () => {
    done(new Error("Failed to load script " + src));
  };
  document.head.appendChild(js);
}

const simple = (obj) => {
  if (_.isArray(obj)) {
    return _.map(obj, simple);
  }
  if (_.isObject(obj)) {
    if (obj.__type === "onclick") return genOnclick(obj);
    if (obj && obj.value !== undefined && !obj.params) return simple(obj.value);
    let _obj = {};
    _.each(obj, (val, key) => {
      _obj[key] = simple(val);
    });

    return _obj;
  }
  return obj;
};

function openInNewTab(href) {
  let a = document.createElement("a");
  a.target = "_blank";
  a.href = href;
  a.click();
}

const genOnclick = (obj) => {
  const { title, onclick } = obj;
  const { __type, payload } = onclick;
  let fn;
  if (__type === "url") fn = () => openInNewTab(payload);
  else fn = () => {};
  return { title, onclick: fn };
};

export class PopupPreview extends React.PureComponent {
  componentDidMount() {
    if (!this.__ytm) this.loadYtm();
  }

  get __ytm() {
    return this.props.ctx.window.__ytm__;
  }

  loadYtm() {
    let { document } = this.props.ctx;
    if (this.loading) return;
    this.loading = true;
    loadScript(document, "/widget/widget.js", () => {
      if (!this.loading) return;
      this.loading = false;
      this.__load();
    });
  }

  __load() {
    let { schema, params } = this.props;
    if (!schema) return;
    let { __ytm } = this;
    if (__ytm)
      __ytm.update({ key: "X", type: schema.component, props: params });
  }

  componentWillUnmount() {
    let { __ytm } = this;
    this.loading = false;
    if (__ytm) __ytm.update(null);
  }

  componentDidUpdate() {
    //this.__ytm.update(null);
    this.__load();
  }

  render() {
    return <div />;
  }
}

const GlobalStyle = createGlobalStyle`
	html,
	body{
		padding: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		font-family: arial;
		background:#eff1f5;
	}
	body::before{
		content:'ÖN İZLEME';
		position:absolute;
		display:block;
		top:50%;
		left:50%;
		text-align:center;
		color: #888;
		line-height: 40px;
		height:40px;
		width:100px;
		margin: -20px 0 0 -50px;
	}

	.frame-root,
	.frame-content {
		//display:none;
		width:100%;
		height:100%;
		overflow: auto;
	}
	.yt-kit.yt-spacer-center-center{
		transform: none!important;
	}
`;

const runPreview = (__preview, props, copy) => {
  let isArray = _.isArray(__preview);
  if (!isArray && !_.isObject(__preview)) return _.clone(__preview);
  if (!copy) copy = isArray ? [] : {};
  _.each(__preview, (val, key) => {
    let temp;
    if (_.isString(val))
      temp = val.replace(/\$\{(.*?)\}/g, (e, text) => props[text] || "").trim();
    else temp = runPreview(val, props);
    if (isArray) copy.push(temp);
    else copy[key] = temp;
  });
  return copy;
};

export const buildProps = (schema, parameters = {}, base = {}) => {
  let props = simple(parameters);
  if (schema && schema.__preview) {
    let copy = runPreview(schema.__preview, props);
    _.assignIn(props, copy);
  }
  return _.assignIn(base, (schema && schema.props) || {}, props);
};

const ratio_height = 736;
const ratio_width = 414;
//const ratio = ratio_width / ratio_height;

export class Preview extends React.PureComponent {
  constructor(props) {
    super(props);
    let opt = props.opt || {};
    let List = trList;
    this.state = {
      mobile: !!opt.mobile,
      mockup: opt.value || this.props._default || List[0].value,
    };
    this.preview = React.createRef();
  }

  getComponent(ctx) {
    const { schema, parameters = {}, t } = this.props;
    let props = buildProps(schema, parameters, {
      __isMobile: this.state.mobile,
    });

    return (
      <PopupPreview
        ref={this.preview}
        ctx={ctx}
        params={props}
        schema={schema}
        t={t}
      />
    );
  }

  get _ytm() {
    return this.preview.current && this.preview.current.__ytm;
  }

  restart = () => {
    if (this._ytm && this._ytm.restart) this._ytm.restart();
  };

  render() {
    let { t, inputText = {}, isCartOpen, noPreview } = this.props;
    let List = enList;
    return (
      <div className={cs("phone-preview-wrap", { mobile: this.state.mobile })}>
        <div className="inner-wrap">
          <div className="browser-head">
            <Header>
              <Icon name="eye" style={{ opacity: 0.4, fontSize: 22 }} />
              <Header.Content>Preview</Header.Content>
            </Header>
            <div style={{ marginLeft: "auto", marginRight: 3 }}>
              {this.props._disabledlist ? null : (
                <Dropdown
                  selection
                  value={this.state.mockup}
                  onChange={(e, { value }) => this.setState({ mockup: value })}
                  options={List}
                />
              )}
              <Popup
                position="top right"
                content={"Toggle Mobile"}
                trigger={
                  <Button
                    as="div"
                    basic
                    icon="mobile"
                    onClick={() =>
                      this.setState({ mobile: !this.state.mobile })
                    }
                  />
                }
              />
              <Popup
                position="top right"
                content={"Re-Animate"}
                trigger={
                  <Button basic as="div" icon="redo" onClick={this.restart} />
                }
              />
            </div>
          </div>
          <SizeMe monitorHeight={true} refreshRate={1000}>
            {({ size }) => {
              const currentRatio = this.state.mobile
                ? size.height / ratio_height
                : 1;
              return (
                <div className="flex-wrap">
                  <Frame
                    css={`
                      -ms-zoom: ${currentRatio};
                      -moz-transform: scale(${currentRatio});
                      -o-transform: scale(${currentRatio});
                      -webkit-transform: scale(${currentRatio});
                      transform: scale(${currentRatio});

                      -ms-transform-origin: 50% 0;
                      -moz-transform-origin: 50% 0;
                      -o-transform-origin: 50% 0;
                      -webkit-transform-origin: 50% 0;
                      transform-origin: 50% 0;
                    `}
                    className="full-size no-border"
                    style={
                      this.state.mobile
                        ? {
                            height: ratio_height || size.height,
                            width:
                              ratio_width ||
                              Math.floor(ratio_width * currentRatio) + 1,
                          }
                        : {}
                    }
                  >
                    <FrameContextConsumer>
                      {(frameContext) => (
                        <StyleSheetManager target={frameContext.document.head}>
                          <React.Fragment>
                            <GlobalStyle t={t} />
                            <Mockup
                              type={this.state.mockup}
                              inputText={inputText}
                              isCartOpen={isCartOpen}
                              noPreview={noPreview}
                            />
                            {this.getComponent(frameContext)}
                          </React.Fragment>
                        </StyleSheetManager>
                      )}
                    </FrameContextConsumer>
                  </Frame>
                </div>
              );
            }}
          </SizeMe>
        </div>
      </div>
    );
  }
}
const fetch_config = { responseType: "json", cachePolicy: "no-cache" };

function MailDisplay(props) {
  let { value, data } = props;
  let pure = simple(value[data.from] || {});
  const { loading, response } = useFetch(
    "/api/mailer/template/" + pure.value,
    fetch_config,
    [pure.value]
  );
  let json = response.data;
  let html = json && json.template;
  let real = React.useMemo(() => {
    return (
      html &&
      html.replace(/\{\{(.*?)\}\}/g, (x, name) => {
        return pure.params[name] || name;
      })
    );
  }, [html, pure.params]);
  let ref = React.useRef();
  React.useLayoutEffect(() => {
    if (ref.current) {
      var doc = ref.current.contentWindow.document;
      doc.open();
      doc.write(real);
      doc.close();
    }
  }, [ref, real]);
  if (loading) return <div className="loading-full">Loading...</div>;

  return (
    <iframe
      css={`
        -ms-zoom: ${1};
        -moz-transform: scale(${1});
        -o-transform: scale(${1});
        -webkit-transform: scale(${1});
        transform: scale(${1});

        -ms-transform-origin: 50% 0;
        -moz-transform-origin: 50% 0;
        -o-transform-origin: 50% 0;
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
      `}
      className="full-size no-border"
      src="about:blank"
      title="email-preview"
      ref={ref}
    />
  );
}

class MailPreview extends React.PureComponent {
  render() {
    let { schema } = this.props;
    let { preview_data } = schema;
    return (
      <Field name={"parameters"}>
        {({ field }) => {
          return (
            <div className="mail-preview">
              <div className="preview-header">Title Section</div>
              <MailDisplay data={preview_data} value={field.value} />
            </div>
          );
        }}
      </Field>
    );
  }
}

function TemplateDisplay(props) {
  let { schema, value } = props;

  let coll = React.useMemo(() => {
    let pure = simple(value.params);
    let temp = _.find(schema.sub[0].list, { value: pure.template.value });
    let template = temp.data;
    let preview_data = {};
    let params = pure.template.params;
    function fill(it, text) {
      return (
        text &&
        text.replace(/{{\d+}}/g, (code) => {
          let id = `${it.type}-${code}`;
          if (id && params[id]) return params[id];
          return code;
        })
      );
    }
    _.each(template.components, (it) => {
      switch (it.type) {
        case "HEADER": {
          let text = fill(it, it.text);

          preview_data.header = text;
          break;
        }
        case "BODY":
          let text = fill(it, it.text);

          preview_data.body = text;
          break;
        case "BUTTONS":
          preview_data.buttons = [];
          _.each(it.buttons, (btn) => {
            if (btn.type === "URL") {
              let text = fill(it, btn.text),
                url = fill(it, btn.url);
              preview_data.buttons.push({ text, url });
            } else console.warn("UNKNOWN", btn);
          });

          break;
        default:
          console.warn("unknown", it);
          break;
      }
    });
    return { pure, temp, preview_data };
  }, [schema, value.params]);
  //console.log('TD', props, coll);
  let { preview_data } = coll;
  return (
    <div className="preview-body">
      {preview_data.header && (
        <div className="header">{preview_data.header}</div>
      )}
      {preview_data.body && <div className="body">{preview_data.body}</div>}
      {_.map(preview_data.buttons, (btn, i) => (
        <div className="preview-button" key={i}>
          <div className="text">{btn.text}</div>
          <div className="url">
            <Icon name="external" />
            {btn.url}
          </div>
        </div>
      ))}
    </div>
  );
}

class TemplatePreview extends React.PureComponent {
  render() {
    let { schema } = this.props;
    let { preview_data, sub } = schema;
    let current = _.find(sub, { __name: preview_data.from });
    return (
      <Field name={"parameters.data"}>
        {({ field }) => {
          let template_data = _.find(current.list, {
            value: field.value.value,
          });
          return (
            <div className="template-preview">
              <div className="preview-header">Template Message</div>
              <TemplateDisplay
                data={preview_data}
                schema={template_data}
                value={field.value}
              />
            </div>
          );
        }}
      </Field>
    );
  }
}

function WhatsappMessagePreview(props) {
  let { value, params } = props.value;
  if (value === "text")
    return (
      <div className="message">
        <div className="text" style={{ whiteSpace: "pre-wrap" }}>
          <div>{params.message}</div>
        </div>
      </div>
    );
  if (value === "image")
    return (
      <div className="message type-image">
        <div className="image-wrap">
          <img src={params.message} alt="" />
        </div>
        <div className="text" style={{ whiteSpace: "pre-wrap" }}>
          {params.text}
        </div>
      </div>
    );
  return null;
}

class WhatsAppPreview extends React.PureComponent {
  render() {
    let { schema } = this.props;
    let { preview_data } = schema;
    return (
      <Field name={"parameters." + preview_data.from}>
        {({ field }) => {
          let value = field.value;
          let pure = simple(value);
          return (
            <div className="whatsapp-message-preview">
              <div className="preview-header">WhatsApp Message</div>
              <div className="message-wrap">
                {_.map(pure, (it, i) => (
                  <WhatsappMessagePreview key={i} value={it.message} />
                ))}
              </div>
            </div>
          );
        }}
      </Field>
    );
  }
}

const PREVIEW_CLASSES = {
  mailpreview: MailPreview,
  templatepreview: TemplatePreview,
  whatsapppreview: WhatsAppPreview,
};

function UnknownPreview() {
  return <div>UnknownPreview</div>;
}

export class GenericPreview extends React.PureComponent {
  render() {
    let { schema } = this.props;
    let { preview_data } = schema;
    let Cls = PREVIEW_CLASSES[preview_data.type] || UnknownPreview;
    return (
      <div className="gen-preview">
        <Cls {...this.props} />
      </div>
    );
  }
}
