import React, { useRef, useState } from 'react';
//import Editor from 'react-simple-code-editor';
//import { highlight, languages } from 'prismjs/components/prism-core';
/* import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css'; */
import '../scss/code.scss';
//import { post } from '../util/api';
//import Ansi from 'ansi-to-react';
import Monaco, { monaco } from '@monaco-editor/react';

/* const merged_language = languages.extend('html', languages.javascript);
const langs = {
	...languages,
	html: merged_language,
}; */

const conf = {
	minimap: { enabled: false },
	scrollBeyondLastLine: false,
};

export function useMonacoEditor() {
	const [monacoEditor, setMonacoEditor] = useState();
	if (!monacoEditor) {
		monaco.init().then(setMonacoEditor);
	}
	return monacoEditor;
}

export default function V2Editor(props) {
	const monaco = useMonacoEditor();
	const valueGetter = useRef();

	function onChange(code) {
		//setCode(code);
		if (code !== props.value) props.onValueChange(code);
	}

	function listenEditorChanges() {
		let editor = valueGetter.current;
		if (!editor) return;
		/* editor.onDidChangeModelContent(ev => {
			onChange(editor.getValue());
		}); */
		//editor.onDidBlurEditorWidget(() => onChange(editor.getValue()));
		editor.onDidBlurEditorText(() => onChange(editor.getValue()));
		if (props.save) editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => props.save());
	}

	function handleEditorDidMount(_, editor) {
		valueGetter.current = editor;
		listenEditorChanges();
	}

	return <Monaco language={props.lang || 'javascript'} options={conf} value={props.value} editorDidMount={handleEditorDidMount} />;
}
/* 
export function SimpleCodeEditor(props) {
	const [error, setError] = React.useState(null);
	const [code, setCode] = React.useState(props.value);
	React.useEffect(() => {
		if (!props.control || !code) return;
		let timeout = setTimeout(() => {
			post(props.control, { code }).then(response => {
				let { data } = response;
				if (data.success) {
					setCode(error);
					setError(null);
				} else if (data.message) {
					setError(data.message);
				}
			});
		}, 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, [props.control, code]);

	function onChange(code) {
		setCode(code);
		props.onValueChange(code);
	}

	return (
		<div className="fill">
			<Editor
				{...props}
				onValueChange={onChange}
				highlight={code => highlight(code, props.lang ? langs[props.lang] || langs.html : langs.html)}
				padding={10}
			/>
			{error && <Ansi className="error-pre">{error}</Ansi>}
		</div>
	);
} */
