import React, { Component } from "react";
import Page from "../../components/Page";
import {
  Icon,
  Table,
  Segment,
  Header,
  Input,
  Grid,
  Image,
  List,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
//import { postlocal, post } from "../../util/api";
import _ from "lodash";

var dev;

class Trendyol extends Component {
  state = {
    load: true,
    data: {},
    search: "",
    alert: "",
    check: "",
    contact_us: "",
    productCount: 0,
  };

  onChangeInput = (e) => {
    let { value, name } = e;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    let { access } = this.props;
    if (
      access &&
      access.user &&
      access.user.loggedUserObj &&
      access.user.loggedUserObj.extra &&
      access.user.loggedUserObj.extra.admin
    ) {
      dev = true;
    }

    if (!dev) this.getData();
    else this.setState({ load: false, data: null });
  }

  getAnalyze() {
    let product = 0;
    let { data } = this.state;
    let counter = 0;
    if (data && data.result && data.result.star_data) {
      _.map(data.result.star_data, (e) => {
        counter++;
        if (counter > 4) {
          product += e;
        }
      });

      this.setState({ productCount: product });
    }
  }

  async findTrendyolInfo() {
    let { search, check } = this.state;
    search = search && search.trim();
    if (search) {
      this.setState({ load: true });
      let { product } = this.props;
      let { personalize } = product;

      let body = { dev, firm_id: search, pid: personalize.id };
      let settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
      let res = await fetch(
        "https://micro.yapaytech.com/api/trendyol_comment/getTrendyolInfo",
        settings
      ).then((response) => response.json());

      if (res && res.success && res.result != false) {
        let check =
          res.result &&
          res.result.comment &&
          res.result.comment.contentSummary &&
          res.result.comment.contentSummary.averageRating;
        this.setState({
          data: res.result,
          load: false,
          check,
        });
        if (!check) {
          this.setState({ alert: "Hata" });
          setTimeout(() => {
            this.setState({ alert: "" });
          }, 2500);
        }

        this.setState({ data: res.result, load: false });
      }
      if (res && res.data && res.result == false) {
        this.setState({
          load: false,
          contact_us:
            "Çok fazla veri bulunmaktadır. Bizimle iletişime geçebilirsiniz.",
        });
      }
    } else {
      this.setState({ alert: "Hata" });
      setTimeout(() => {
        this.setState({ alert: "" });
      }, 2500);
    }

    this.getAnalyze();
  }

  async getData() {
    let { product } = this.props;
    let { personalize } = product;
    let settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pid: personalize.id, dev: false, first: true }),
    };

    let res = await fetch(
      "https://micro.yapaytech.com/api/trendyol_comment/getTrendyolInfo",
      settings
    ).then((response) => response.json());
    if (res && res.success) {
      let check =
        res.result &&
        res.result.comment &&
        res.result.comment.contentSummary &&
        res.result.comment.contentSummary.averageRating;
      this.setState({
        data: res.result,
        load: false,
        check,
      });
    } else if (res && !res.success) {
      this.setState({
        load: false,
      });
    }

    this.getAnalyze();
  }

  render() {
    let { data, load, search, check, alert, productCount, contact_us } =
      this.state;

    if (load) {
      return (
        <Page
          icon="chart line"
          title={"Trendyol Analiz"}
          extra={
            <div
              css={`
                margin-left: auto;
              `}
            ></div>
          }
        >
          <Page.Loader key="loader" />
        </Page>
      );
    } else {
      if (dev && !check) {
        return (
          <Page
            icon="chart line"
            title={"Trendyol Analiz"}
            extra={
              <div
                css={`
                  margin-left: auto;
                `}
              ></div>
            }
          >
            <div style={{ margin: 20 }}>
              <Segment>
                <div style={{ marginBottom: "15px" }}>
                  Geliştirici panelidir. Burada aranan firmalar database
                  tarafına kaydedilmez.
                </div>
                {alert && (
                  <div style={{ color: "red" }}>
                    Bu id ye ait trendyol firması bulunamadı...
                  </div>
                )}
                {contact_us && <div style={{ color: "red" }}>{contact_us}</div>}
                <Input
                  placeholder="Trendyol Id"
                  name="search"
                  type="number"
                  action={{
                    content: "Ara",
                    onClick: () => {
                      this.findTrendyolInfo();
                    },
                  }}
                  value={search}
                  onChange={(e, { value, name }) => {
                    this.onChangeInput({ value, name });
                  }}
                />
              </Segment>
            </div>
          </Page>
        );
      }
      if (!dev && !check) {
        return (
          <Page
            icon="chart line"
            title={"Trendyol Analiz"}
            extra={
              <div
                css={`
                  margin-left: auto;
                `}
              ></div>
            }
          >
            <div style={{ margin: 20 }}>
              <Segment>
                <div style={{ marginBottom: "15px" }}>
                  Trendyol Analitik sayesinde trendyol yorumlarının ne kadar
                  etkili olabileceğinin analizini yapın!
                </div>
                {alert && (
                  <div style={{ color: "red" }}>
                    Bu id ye ait trendyol firması bulunamadı...
                  </div>
                )}
                {contact_us && <div style={{ color: "red" }}>{contact_us}</div>}
                <Input
                  placeholder="Trendyol Id"
                  name="search"
                  type="number"
                  action={{
                    content: "Ara",
                    onClick: () => {
                      this.findTrendyolInfo();
                    },
                  }}
                  value={search}
                  onChange={(e, { value, name }) => {
                    this.onChangeInput({ value, name });
                  }}
                />
              </Segment>
            </div>
          </Page>
        );
      }
      if (check) {
        return (
          <Page
            icon="chart line"
            title={"Trendyol Analiz"}
            extra={
              <div
                css={`
                  margin-left: auto;
                `}
              >
                {dev && (
                  <Input
                    style={{ marginLeft: "20px" }}
                    placeholder="Trendyol Id"
                    name="search"
                    type="number"
                    action={{
                      content: "Ara",
                      onClick: () => {
                        this.findTrendyolInfo();
                      },
                    }}
                    value={search}
                    onChange={(e, { value, name }) => {
                      this.onChangeInput({ value, name });
                    }}
                  />
                )}
              </div>
            }
          >
            <div style={{ margin: 20 }}>
              <Grid columns={3} divided>
                <Grid.Row stretched>
                  <Grid.Column>
                    <Segment color="purple">
                      <Header as="h3" style={{ textAlign: "center" }}>
                        Puan(Yıldız) Analitiği
                      </Header>
                      <div style={{ textAlign: "left" }}>
                        <Table
                          basic="very"
                          celled
                          collapsing
                          style={{ width: "100%" }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>
                                <Header as="h4">Yıldız Değeri</Header>
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <Header as="h4">Ürün Sayısı</Header>
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>0</Header.Content>
                                  <Header.Subheader>Yıldız</Header.Subheader>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.star_data.star_0}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    1 ile 1,99
                                    <Header.Subheader>
                                      Yıldız Arası
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.star_data.star_1_2}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    2 ile 2,99
                                    <Header.Subheader>
                                      Yıldız Arası
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.star_data.star_2_3}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    3 ile 3,99
                                    <Header.Subheader>
                                      Yıldız Arası
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.star_data.star_3_4}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    4 ile 4,99
                                    <Header.Subheader>
                                      Yıldız Arası
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.star_data.star_4_5}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    5<Header.Subheader>Yıldız</Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.star_data.star_least_5}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    </Segment>
                    <Segment color="pink">
                      <Header as="h3" style={{ textAlign: "center" }}>
                        Değerlendirme Analitiği
                      </Header>
                      <div style={{ textAlign: "left" }}>
                        <Table
                          basic="very"
                          celled
                          collapsing
                          style={{ width: "100%" }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>
                                <Header as="h4">Değerlendirme Sayıları</Header>
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <Header as="h4">Ürün Sayısı</Header>
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>0</Header.Content>
                                  <Header.Subheader>
                                    Değerlendirme Sayısı
                                  </Header.Subheader>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.comment_data.comment_0}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    0 ile 99
                                    <Header.Subheader>
                                      Değerlendirme Sayısı
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.comment_data.comment_0_100}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    100 ile 199
                                    <Header.Subheader>
                                      Değerlendirme Sayısı
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.comment_data.comment_100_200}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    200 ile 499
                                    <Header.Subheader>
                                      Değerlendirme Sayısı
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.comment_data.comment_200_500}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    500 ile 1000
                                    <Header.Subheader>
                                      Değerlendirme Sayısı
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.comment_data.comment_500_1000}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    1000 ile 2000
                                    <Header.Subheader>
                                      Değerlendirme Sayısı
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.comment_data.comment_1000_2000}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                <Header as="h4">
                                  <Header.Content>
                                    2000+
                                    <Header.Subheader>
                                      Değerlendirme Sayısı
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                              <Table.Cell>
                                {data.result.comment_data.comment_more_2000}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment color="green" style={{ maxHeight: "120px" }}>
                      <Header as="h3" style={{ textAlign: "center" }}>
                        {data.result.data.name}
                        <div style={{ textAlign: "center" }}>
                          <Header.Subheader style={{ marginTop: "10px" }}>
                            <strong> {data.result.data.productCounts}</strong>{" "}
                            Ürün
                          </Header.Subheader>
                          <Header.Subheader>
                            <strong>
                              {data.result.data.totalCommentCount}
                            </strong>{" "}
                            Toplam Yorum
                          </Header.Subheader>
                          <Header.Subheader>
                            <strong>
                              {(data.result.data.averageRating &&
                                data.result.data.averageRating.toFixed(2)) ||
                                "-"}
                            </strong>{" "}
                            Ortalama Yorum Puanı
                          </Header.Subheader>
                        </div>
                      </Header>
                    </Segment>

                    <Segment
                      color="red"
                      style={{ textAlign: "center", maxHeight: "560px" }}
                    >
                      <Header as="h3">En İyi Ürün</Header>

                      <Image
                        style={{
                          width: "100%",
                          paddingRight: "8%",
                          paddingLeft: "8%",
                          maxWidth: "300px",
                          margin: 0,
                          textAlign: "center",
                          display: "inline",
                        }}
                        src={data.result.theMostCommentProduct.images[0]}
                        size="small"
                      />

                      <Header as="h4">
                        {data.result.theMostCommentProduct.name}
                        <Header.Subheader style={{ marginTop: "10px" }}>
                          <strong>
                            {data.result.theMostCommentProduct.averageRating.toFixed(
                              2
                            )}
                          </strong>{" "}
                          Puan
                        </Header.Subheader>
                        <Header.Subheader>
                          <strong>
                            {data.result.theMostCommentProduct.totalCount}
                          </strong>{" "}
                          Değerlendirme
                        </Header.Subheader>
                        <Header.Subheader>
                          <strong>
                            {data.comment.contentSummary.totalCommentCount}
                          </strong>{" "}
                          Yorum
                        </Header.Subheader>
                      </Header>
                    </Segment>
                    {data.question && (
                      <Segment color="blue">
                        <Header as="h3" style={{ textAlign: "center" }}>
                          {"Soru & Cevap"}
                          <div style={{ textAlign: "left" }}>
                            <a>
                              <b>Soru</b>
                            </a>
                            <br />
                            <Header.Subheader style={{ marginTop: "10px" }}>
                              {data.question.text}
                            </Header.Subheader>
                            <Header.Subheader>
                              <strong>{data.question.userName}</strong>
                              {" - "} {data.question.creationDate}
                            </Header.Subheader>
                            <br />
                            <a>
                              <b>Yanıt</b>
                            </a>
                            <div>
                              <Header.Subheader>
                                {data.question.answer.text}{" "}
                                <div>
                                  {" "}
                                  <strong>
                                    {data.question.answer.creationDate}
                                  </strong>
                                  {" - "}
                                  {data.question.answeredDateMessage}
                                </div>
                              </Header.Subheader>
                            </div>
                          </div>
                        </Header>
                      </Segment>
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <Segment
                      color="yellow"
                      style={{
                        maxHeight: "960px",
                        overflowX: "auto",
                      }}
                    >
                      <Header as="h3" style={{ textAlign: "center" }}>
                        En İyi Yorumlar
                      </Header>
                      <List divided relaxed>
                        <div
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {_.map(
                            _.filter(
                              data.comment.productReviews.content,
                              (a) => {
                                return a.rate === 5;
                              }
                            ),
                            (e, i, x) => {
                              return (
                                <div key={i}>
                                  <List.Item>
                                    <List.Content>
                                      <List.Header>{e.comment}</List.Header>
                                      <List.Description>
                                        <strong
                                          style={{
                                            color: "#5e5e5e",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {e.userFullName}
                                        </strong>
                                      </List.Description>
                                    </List.Content>
                                  </List.Item>
                                  {x.length - 1 !== i && <hr />}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </List>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Segment color="orange">
                <List relaxed>
                  {productCount >= 5 && (
                    <List.Item>
                      <Icon name="thumbs up outline" size="large" />
                      <List.Content>
                        <List.Header as="a">Müşteri Memmuniyeti</List.Header>
                        <List.Description>
                          Filtreleme özelliği ile 3 puan ve üzeri{" "}
                          <a>
                            <b>{productCount}</b>
                          </a>{" "}
                          farklı üründe{" "}
                          <a>
                            <b>{data.result.data.least_3}</b>
                          </a>{" "}
                          tane yorumu sitenizde gösterin ve satışlarınızı{" "}
                          <a>
                            <b>%70 oranında arttırın.</b>
                          </a>{" "}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                  {data.question && (
                    <List.Item>
                      <Icon name="thumbs up outline" size="large" />
                      <List.Content>
                        <List.Header as="a">Soru & Cevap</List.Header>
                        <List.Description>
                          Ürünlerde bulunan{" "}
                          <a>
                            <b>soru & cevaplar'ı</b>
                          </a>{" "}
                          sitenize entegre edin ve müşterilerinizin kafasındaki
                          soru işaretlerini kaldırın ve{" "}
                          <a>
                            <b>satışlarınızı arttırın.</b>
                          </a>{" "}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )}

                  <List.Item>
                    <Icon name="thumbs up outline" size="large" />
                    <List.Content>
                      <List.Header as="a">Resimli Ürünler</List.Header>
                      <List.Description>
                        Yorumlarda bulunan{" "}
                        <a>
                          <b>resimleri, popup ile açılabilir şekilde</b>
                        </a>{" "}
                        sayfanıza entegre edin ve müşterilerinizin ürünleri
                        farklı yerlerden görmesini sağlayın ve{" "}
                        <a>
                          <b>kararsızlıklarının önüne geçin!</b>
                        </a>{" "}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  {data.result.star_data.star_least_5 > 10 && (
                    <List.Item>
                      <Icon name="thumbs up outline" size="large" />
                      <List.Content>
                        <List.Header as="a">İyi Satıcı</List.Header>
                        <List.Description>
                          {data.result.star_data.star_least_5} farklı
                          ürünleriniz de bulunan{" "}
                          <a>
                            <b>5 tam puan</b>
                          </a>
                          ınızı toplam{" "}
                          <a>
                            <b>{data.result.star_data.star_least_5}</b>
                          </a>{" "}
                          üründe göstererek müşterilerinizin size olan{" "}
                          <a>
                            <b>güven duygusu arttırın!</b>
                          </a>{" "}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                </List>
              </Segment>
            </div>
          </Page>
        );
      }
    }
  }
}

const stateToProps = (state) => ({
  access: state.access,
  product: state.product,
  location: state.router.location,
});

export default connect(stateToProps)(withTranslation()(Trendyol));
