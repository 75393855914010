import React from "react";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-GB";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button } from 'semantic-ui-react';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from "date-fns";
const dateLocales = { tr, en };
const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};
let defaultStaticRangesEn = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Yesterday",
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: "This Week",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Last 7 Days",
    range: () => ({
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
    }),
  },
  {
    label: "Last 30 Days",
    range: () => ({
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
    }),
  },
]);
let defaultStaticRangesTr = createStaticRanges([
  {
    label: "Bugün",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Dün",
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: "Bu Hafta",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Geçen Hafta",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "Bu Ay",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Geçen Ay",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Son 7 Gün",
    range: () => ({
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
    }),
  },
  {
    label: "Son 30 Gün",
    range: () => ({
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
    }),
  },
]);
const Picker = (props) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <>
    <DateRangePicker
      locale={dateLocales[props.lang || "tr"]}
      ranges={[
        {
          startDate: props.startDate,
          endDate: props.endDate,
          key: "selection",
        },
      ]}
      maxDate={new Date()}
      onChange={props.dateChange}
      staticRanges={
        props.lang === "en" ? defaultStaticRangesEn : defaultStaticRangesTr
      }
      inputRanges={[]}
      months={2}
    />
    <Button
      className={
        props.isTimeSelectPage && props.timeSelect
          ? "analytic-button with-time-select"
          : "analytic-button"
      }
      basic
      color="blue"
      onClick={props.getNewData}
    >
      Getir
    </Button>
  </>
);

export default Picker;
