import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import useFetch from 'use-http';
import { Menu, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Editor from './edit';
import Publish from './publish';
import Settings from './settings';
import ScriptPage from './script';
import EcommerceConfig from './ecommerce_config';

const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };
export default () => {
	let { t } = useTranslation();
	const { loading, response } = useFetch('/api/personalize/bot/boot', fetch_config, []);

	if (loading)
		return (
			<Page disabled={true}>
				<Page.Loader />
			</Page>
		);
	let data = response.data && response.data.data;
	let ecommerceconfig = data.ecommerceconfig;
	return (
		<Page disabled={true} flex>
			<Page.Wrap className="wrap-16 d-flex flex-column">
				<Menu className="settings" pointing secondary>
					<Menu.Item as={NavLink} to="/bot/edit">
						<Icon name="code" />
						{t('Editor')}
					</Menu.Item>
					<Menu.Item as={NavLink} to="/bot/publish">
						<Icon name="asterisk" />
						{t('Publish')}
					</Menu.Item>
					<Menu.Item as={NavLink} to="/bot/settings">
						<Icon name="settings" />
						{t('Settings')}
					</Menu.Item>
					{ecommerceconfig && (
						<Menu.Item as={NavLink} to="/bot/ecommerce_config">
							<Icon name="settings" />
							{t('EcommerceConfig')}
						</Menu.Item>
					)}
					<Menu.Item as={NavLink} to="/bot/script">
						<Icon name="code" />
						{t('Script')}
					</Menu.Item>
				</Menu>
				<Switch>
					<Route path="/bot/edit" render={(props) => <Editor {...props} config={response && response.data && response.data.data} />} />
					<Route path="/bot/publish" component={Publish} />
					<Route path="/bot/settings" component={Settings} />
					{ecommerceconfig && <Route path="/bot/ecommerce_config" component={EcommerceConfig} />}
					<Route path="/bot/script" render={(props) => <ScriptPage {...props} config={response && response.data && response.data.data} />} />
					<Redirect to="/bot/edit" />
				</Switch>
			</Page.Wrap>
		</Page>
	);
};
