import React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { withTranslation } from 'react-i18next';

const Styles = createGlobalStyle`
	body {
		&::before {
			display: none !important;
		}
		background: white !important;
	}
	.cart-popup{	
		&.hide{
			display:none;
		}
		position: absolute;
		width: 370px;
		padding: 30px 30px 20px;
		top: 62px;
		right: -30px;
		background-color: #fff;
		transition: .4s;
		z-index: 101;
		box-shadow: -2px 2px 15px 0 #ccc;
		.cart-popup-header{
			display:flex;
			.cart-popup-img{
				img{
					width:80px;
					margin-right:20px;
				}
			}
			.cart-popup-text{
				display:flex;
				width:100%;
				h4{
					margin-top:2px
				}
				.left{
					width:100%;
				}
				.right{
					p{
						margin-top:2px;
					}
				}
			}
		}.cart-popup-body{
			padding-top: 16px;
			border-top: 1px solid #ddd;
			.total-price{
				display:flex;
				margin:auto;
				p {
					font-weight:bold;
				}
				.price{
					margin-left:auto
				}
			
			}
		}
	}
	.back-to-top {
    z-index: 99999;
    position: fixed;
    display: block;
    width: 50px;
    height: 50px;
	bottom: 25px;
	background-position: center center;
    background-size: 25px;
    background-repeat: no-repeat;
	&.bottom-left{
		left: 25px;
	}
	&.bottom-right{
		right: 25px;
	}								
	}
	.image-discount {
    position: absolute;
    display: flex;
    padding-left: 5px!important;
    color: white;
    padding-right: 5px!important;
    font-size: 16px;
	&.bottom-left{
		bottom:0;
		left:0
	}&.bottom-right{
		bottom:0;
		right:0
	}&.top-left{
		top:0;
		left:0
	}&.top-right{
		top:0;
		right:0
	}
    }
	.search-bar{
		width: 1200px;
 		background-color: #eee;
		height: 20px;
		border-radius: 10px;
		line-height: 20px;
		padding: 5px;
		.bar{
			display:flex
		}
		.icons {
			display: flex;
			margin-left:auto;
			margin-right:5px;
			.icon {
				margin-left:auto;
				width: 20px;
			}
		}
	}
	.cross {
		&.close{
			display:none;
		}
		background-image:url('/images/diogonal.jpg');
		height:40px;
		display:flex;
		justify-content:center;
		align-items:center;
		p {
			margin:0
		}
	}
	.container {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		span { 
			margin-right:20px!important
		}
		@media (min-width: 576px) {
			max-width: 540px;
		}
		@media (min-width: 768px) {
			max-width: 720px;
		}
		@media (min-width: 992px) {
			max-width: 960px;
		}
		@media (min-width: 1200px) {
			max-width: 1140px;
		}

	}
	.mainmenu{
		display:contents;
		@media (max-width: 576px) {
			display:none
		}
	}
	.mobile-tabs{
		@media (min-width: 576px) {
			display:none
		}
	}
	.yt-pos-top-right{
		margin-top:60px;
	}.yt-spacer-top-right{
		margin-top:60px;
	}
	.cart-item-count {
		&.noShow{
			display:none;
		}
		font-size: 13px;
    	line-height: 17px;
		width: 17px;
		height: 17px;
    	left: 41%;
    	position: absolute;
		top: -14%;
		padding: 2px;
		margin: auto;
		background-color:red;
		color:white;
		border-radius: 50%;
		font-weight:700;
		text-align: center;
		&.small{
			font-size: 10px;
    		line-height: 13px;
			width: 13px!important;
			height: 13px!important;
			&.down{
				left: 60%;
				position: absolute;
				top: 25%;
			}
			&.up{
				left: 60%;
				position: absolute;
				top: -60%;
			}
		}
	}
	.position-relative{
		position:relative;
	}
	.free-cargo {
	width: 230px;
    height: 40px;
	margin-top: 10px;
	margin-bottom:20px;
    font-size: 16px;
    color: #fff;
    line-height: 40px;
    text-align: center;
	}
	.tabs {
		@media (max-width: 576px) {
			display:none
		}
		display: flex;
		.tab {
			display: flex;
			border: 1px solid #eee;
			width: 280px;
			background-color: #eee;
			height:32px;
			align-items:center;
			> .favicon {
				margin-left: 7px;
				margin-right: 7px;
				width: 20px;
				height: 20px;
				img {
					width: 20px;
					height: 20px;
				}
			}
			&.active {
				background-color: white;
				border-bottom: none;
			}		
		
		}
	}
	.center{
		margin:auto;
		align-items: center;
		justify-content: center;
		display: flex;	
		height:100%;position: absolute;
		font-size: 32px;
		z-index: 1111111;
		background: white;
		height: 100px;
		top:calc( 50% - 50px);
		width: 350px;
		left: calc( 50% - 175px);
		border:1px solid lightgray;
		border-radius: 5px;
		box-shadow: 0px 0px 12px 8px rgb(0 0 0 / 15%);
	}
	.shoppingCart {
		@media (min-width: 320px) and (max-width: 480px)  {
			width:100%
		}
		@media (min-width: 481px) {
			width:65%
		}
	}
	.summaryCart {
		@media (min-width: 320px) and (max-width: 480px)  {
			width:100%
		}
		@media (min-width: 481px) {
			width:30%
		}
	}
	.featuredProducts {
		div {
			border: 1px solid #eee;
			margin: auto;
			margin-bottom:10px
		}
	}
	.similarProduct {
		>div{
			display: flex;
			flex: 1;
			align-items: center;
			border: 1px solid #eee;
			justify-content: center;
			margin-bottom:15px;
		}
	}
	.input-bar {
		@media (max-width: 576px) {
			display:none
		}
		display: flex;
		height: 40px;
		padding:0 5px;
		align-items:center;
		border-bottom: 1px solid #eee;
		.icons {
			display: flex;
			.icon {
				margin:0 7px;
				width: 20px;
			}
		}
		.bar {
			width: 100%;
			border: 1px solid #eee;
			border-radius: 10px;
			height: 28px;
			background-color: #eee;
			display: flex;
			align-items: center;
			.text {
				margin-left: 10px;
			}
		}
	}.ecommercetab{
		p{
			text-align:center
		}
	}
	.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
	/* *,
	::after,
	::before {
		box-sizing: border-box;
	} */
`;

const Line = styled.div`
	background: #606060;
	border-radius: 3px;
	height: 24px;
	margin-bottom: 5px;
	opacity: 0.7;
	width: ${(props) => props.width || 'auto'};
`;

const Product = (props) => (
	<div
		css={`
			display: flex;
			flex: 1;
			align-items: center;
			border: 1px solid #eee;
			margin: 0 5px 5px 5px;
			padding: 12px;
			justify-content: center;
		`}
	>
		<img src="http://www.pickplugins.com/demo/wc-product-filter/wp-content/uploads/sites/35/2018/10/hoodie-2-200x200.jpg" alt="" height="60px" />
		<div
			css={`
				padding: 10px;
				> p {
					margin: auto;
				}
			`}
		>
			<p>{props.t('ProductTitle')}</p>
			<img src="/images/starRate.svg" height="10px" alt=""></img>
			<p
				css={`
					font-weight: 100;
				`}
			>
				₺120
			</p>
		</div>
	</div>
);

function BrowserLine() {
	return (
		<div className="input-bar">
			<div className="icons">
				<img className="icon" src="/icons/arrow-left.svg" alt="prev" />
				<img className="icon" src="/icons/arrow-right.svg" alt="next" />
				<img className="icon" src="/icons/refresh_material.svg" alt="refresh" />
				<img className="icon" src="/icons/home_material.svg" alt="home" />
			</div>
			<div className="bar">
				<div className="text">https://ecommerce.com</div>
			</div>
		</div>
	);
}

class Mockup extends React.PureComponent {
	render() {
		let { t, inputText, isCartOpen, noPreview } = this.props;
		let { actionType } = inputText;
		let isEcommerceFooter = inputText.actionType === 'clearFooterTag' ? 'open' : 'close';
		let cargoBanner = actionType === 'cargoBannerTag';
		//let isleft = /left/.test(inputText.position) ? 'left' : 'right';
		//let topValue = /top/.test(inputText.position) ? '3%' : '95%';
		switch (this.props.type) {
			case 'empty':
				return null;
			case 'product':
				return (
					<>
						<Styles />
						{noPreview ? <div className="center">Ön İzleme Yoktur</div> : null}
						{actionType === 'upButtonTag' ? (
							<div
								id="back-to-top"
								className={'btn back-to-top ' + inputText.position}
								style={{
									cursor: 'pointer',
									backgroundColor: inputText.bannercolor,
									backgroundImage: `url('${inputText.back_to_up_url || 'https://cdnydm.com/permedia/Pmi2kQU1vT3zTXpOs71EBA.png?size=24x24'}') `,
								}}
							/>
						) : (
							''
						)}
						<div
							css={`
								border-bottom: 1px solid #eee;
								padding-top: 5px;
							`}
						>
							<div className="tabs">
								<div className="tab active">
									<div className="favicon position-relative">
										<img src="/favicon.ico" alt="favicon"></img>
									</div>
									<div className="title">
										{/* {actionType === 'pageTitleTagWithFavicon' && inputText.show_on_title ? '(1) ' : ''} */}
										{inputText.title_fore || t('frontendTitle')}
									</div>
								</div>
								<div className="tab">
									<div className="favicon">
										<span className="position-relative">
											{actionType === 'pageTitleTagWithFavicon' && inputText.show_favicon ? (
												<div
													className={'cart-item-count small fade-in ' + inputText.position + ' ' + inputText.isFaviconShow}
													style={{
														backgroundColor: inputText.background_color,
														color: inputText.text_color,
													}}
												>
													1
												</div>
											) : (
												''
											)}
											<img src="/favicon.ico" alt="favicon"></img>
										</span>
									</div>
									<div className="title">
										{actionType === 'pageTitleTagWithFavicon' && inputText.show_on_title ? '(1) ' : ''}
										{inputText.title_back_basket || t('backgroundTitle')}
									</div>
								</div>
							</div>
						</div>
						<BrowserLine />
						<div
							css={`
								height: 50px;
								border-bottom: 1px solid #eee;
								text-align: left;
								display: flex;
								align-items: center;
								span {
									display: inline-block;
									margin: 0 5px;
								}
							`}
						>
							<div className="container mainmenu">
								<span>{t('Home')}</span>
								<span>{t('About')}</span>
								<span>{t('Shop')}</span>
								<span>{t('Help')}</span>
							</div>
							{/* <div className="search-bar">
								<div className="bar">
									<div
										className="typewrite text"
										data-period="2000"
										data-type='[ "Hi, Im Si.", "I am Creative.", "I Love Design.", "I Love to Develop." ]'
									>
										Ürün Ara
									</div>
									<div className="icons">
										<img className="icon" src="/icons/search.svg" alt="search" />
									</div>
								</div>
							</div> */}
							<div className="container mobile-tabs">
								<img src="/icons/list.svg" alt="list" width="30"></img>
							</div>
							<div className="container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<img src="/icons/user.svg" alt="user" style={{ width: '40px' }} />
								<span className="position-relative">
									<div className="cart-item-count" style={{left: '70%'}}>1</div>
									<img src="/icons/shopping-cart-2.svg" className="header-icons" alt="cart" style={{ width: '30px', marginLeft: '10px' }} />
									<div className={`cart-popup ${isCartOpen ? 'open' : 'hide'}`}>
										<div className="cart-popup-header">
											<div className="cart-popup-img">
												<img src="/images/imageplaceholder.svg" alt="" />
											</div>
											<div className="cart-popup-text">
												<div className="left">
													<h4>Ürün Başlığı</h4>
													<p>Ürün Detay</p>
												</div>
												<div className="right">
													<p>39.99₺</p>
												</div>
											</div>
										</div>

										<div className="cart-popup-body">
											<div className="total-price">
												<p className="total">TOPLAM</p>
												<p className="price">39.99₺</p>
											</div>
											<button
												css={`
													background: #7a7a7a;
													border-radius: 0px;
													color: #ffffff;
													font-size: 20px;
													width: 100%;
													padding: 10px 20px 10px 20px;
													text-decoration: none;
													border-width: 0;
												`}
											>
												ALIŞVERİŞİ TAMAMLA
											</button>
										</div>
									</div>
								</span>
							</div>
						</div>
						<div
							css={`
								@media (min-width: 500px) {
									display: flex;
									flex-wrap: wrap;
									.left {
										min-width: 240px;
										width: 240px;
									}
								}
								> div {
									flex: 1;
									border-bottom: 1px solid #eee;
								}
								.left {
									background: #f8f8f8;
									border-right: 1px solid #eee;
									display: flex;
									height: 450px;
									align-item: center;
									justify-content: center;
								}
							`}
						>
							<div className="left" style={{ position: 'relative' }}>
								<img src="https://cdnydm.com/permedia/bS9V0wPrUi5CZtOTsJni3g.jpg?size=381x300" alt="" width="300" height="300" style={{ marginTop: '50px'}}/>
								{actionType === 'imageLabelTag' ? (
									<div
										className={'image-discount ' + inputText.position}
										style={{
											backgroundColor: inputText.bannercolor,
											height: inputText.height + 'px',
											lineHeight: inputText.height + 'px',
										}}
									>
										<span>{inputText.text}</span>
									</div>
								) : (
									''
								)}
							</div>
							<div
								css={`
									padding: 16px;
								`}
							>
								<h1
									css={`
										font-weight: 100;
									`}
								>
									{t('ProductTitle')}
								</h1>
								{cargoBanner ? (
									<div
										className="free-cargo"
										style={{
											backgroundColor: inputText.bannercolor || '#E3A840',
										}}
									>
										{inputText.freecargotext}
									</div>
								) : null}
								<img src="/images/starRate.svg" height="15px" alt=""></img>
								<h2
									css={`
										font-weight: 100;
									`}
								>
									₺120
								</h2>
								<Line width="66%" />
								<Line width="33%" />
								<Line width="33%" />
								<Line width="33%" />
								<br></br>
								<br></br>
								<button
									css={`
										background: #7a7a7a;
										border-radius: 0px;
										color: #ffffff;
										font-size: 20px;
										padding: 10px 20px 10px 20px;
										text-decoration: none;
										border-width: 0;
									`}
								>
									{t('AddCart')}
								</button>
							</div>
						</div>
						<div
							css={`
								flex: 1;
								flex-wrap: wrap;
							`}
						>
							<h1
								css={`
									font-weight: 100;
									text-align: center;
								`}
							>
								{t('SimilarProduct')}
							</h1>
							<div
								className="similarProduct"
								css={`
									display: flex;
									flex: 1;
									flex-wrap: wrap;
								`}
							>
								<div>
									<img src="https://cdnydm.com/permedia/aUm3VylgU0hSvPZvdLja3A.jpg?size=150x150" alt="" height="60px" />
									<div
										css={`
											padding: 10px;
											> p {
												margin: auto;
											}
										`}
									>
										<p>{t('ProductTitle')}</p>
										<img src="/images/starrate_4.svg" height="10px" alt=""></img>
										<p
											css={`
												font-weight: 100;
											`}
										>
											₺110
										</p>
									</div>
								</div>
								<div>
									<img src="https://cdnydm.com/permedia/YQWJ9Fp6VMIbzzckajp2nA.jpg?size=200x200" alt="" height="60px" />
									<div
										css={`
											padding: 10px;
											> p {
												margin: auto;
											}
										`}
									>
										<p>{t('ProductTitle')}</p>
										<img src="/images/starRate.svg" height="10px" alt=""></img>
										<p
											css={`
												font-weight: 100;
											`}
										>
											₺150
										</p>
									</div>
								</div>
								<div>
									<img src="https://cdnydm.com/permedia/bS9V0wPrUi5CZtOTsJni3g.jpg?size=381x300" alt="" height="60px" />
									<div
										css={`
											padding: 10px;
											> p {
												margin: auto;
											}
										`}
									>
										<p>{t('ProductTitle')}</p>
										<img src="/images/starrate_4.svg" height="10px" alt=""></img>
										<p
											css={`
												font-weight: 100;
											`}
										>
											₺80
										</p>
									</div>
								</div>
								<div>
									<img src="https://cdnydm.com/permedia/s6gnY_2PpkRt2fMsi-QM6g.jpg?size=150x150" alt="" height="60px" />
									<div
										css={`
											padding: 10px;
											> p {
												margin: auto;
											}
										`}
									>
										<p>{t('ProductTitle')}</p>
										<img src="/images/starRate.svg" height="10px" alt=""></img>
										<p
											css={`
												font-weight: 100;
											`}
										>
											₺40
										</p>
									</div>
								</div>
							</div>
						</div>
						<div
							className="footer"
							css={`
								display: flex;
								justify-content: center;
								padding-top: 30px;
								border-bottom: 1px solid #eee;
								border-top: 1px solid #eee;
								.tab {
									margin-left: 50px;
									margin: auto;
									margin-top: 0;
								}
								.address {
									width: 40%;
									justify-content: center;
									text-align: center;
									flex-wrap: wrap;
									padding-top: 50px;
								}
							`}
						>
							<div className="address">
								<img src="https://cdnydm.com/permedia/iJlWM8zpL0NC5FrKAF_eSA.png?size=800x400" alt="" height="70px" />
								<p>{t('Address')}</p>
							</div>
							{/* <div className="container"></div> */}
							<div className="tab">
								<h3>{t('MainMenu')}</h3>
								<p>{t('Home')}</p>
								<p>{t('About')}</p>
								<p>{t('Shop')}</p>
								<p>{t('Help')}</p>
							</div>
							<div className="tab">
								<h3>{t('FindUs')}</h3>
								<p>Facebook</p>
								<p>Twitter</p>
								<p>Instagram</p>
							</div>
						</div>
						<div className={'ecommercetab cross ' + isEcommerceFooter}>
							<p>
								Bu site <strong>EcommerceTab</strong> altyapısı ile hazırlanmıştır.
							</p>
						</div>
					</>
				);
			case 'home':
				return (
					<>
						<Styles /> {noPreview ? <div className="center">Ön İzleme Yoktur</div> : null}
						{actionType === 'upButtonTag' ? (
							<div
								id="back-to-top"
								className={'btn back-to-top ' + inputText.position}
								style={{
									cursor: 'pointer',
									backgroundColor: inputText.bannercolor,
									backgroundImage: `url('${inputText.back_to_up_url || 'https://cdnydm.com/permedia/Pmi2kQU1vT3zTXpOs71EBA.png?size=24x24'}') `,
								}}
							/>
						) : (
							''
						)}
						<div
							css={`
								border-bottom: 1px solid #eee;
								padding-top: 5px;
							`}
						>
							<div className="tabs">
								<div className="tab active">
									<div className="favicon">
										<span className="position-relative">
											<img src="/favicon.ico" alt="favicon"></img>
										</span>
									</div>
									<div className="title">
										{/* {actionType === 'pageTitleTagWithFavicon' && inputText.show_on_title ? '(1) ' : ''} */}
										{inputText.title_fore || t('frontendTitle')}
									</div>
								</div>
								<div className="tab">
									<div className="favicon">
										<span className="position-relative">
											{actionType === 'pageTitleTagWithFavicon' && inputText.show_favicon ? (
												<div
													className={'cart-item-count small fade-in ' + inputText.position + ' ' + inputText.isFaviconShow}
													style={{
														backgroundColor: inputText.background_color,
														color: inputText.text_color,
													}}
												>
													1
												</div>
											) : (
												''
											)}
											<img src="/favicon.ico" alt="favicon"></img>
										</span>
									</div>
									<div className="title">
										{actionType === 'pageTitleTagWithFavicon' && inputText.show_on_title ? '(1) ' : ''}
										{inputText.title_back_basket || t('backgroundTitle')}
									</div>
								</div>
							</div>
						</div>
						<BrowserLine />
						<div
							css={`
								height: 50px;
								border-bottom: 1px solid #eee;
								text-align: left;
								display: flex;
								align-items: center;
								span {
									display: inline-block;
									margin: 0 5px;
									width: 80px;
								}
							`}
						>
							<div className="container mainmenu">
								<span>{t('Home')}</span>
								<span>{t('About')}</span>
								<span>{t('Shop')}</span>
								<span>{t('Help')}</span>
							</div>
							<div className="container mobile-tabs">
								<img src="/icons/list.svg" alt="list" width="30"></img>
							</div>
							{/* <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ width: '150px', height: '30px' }}></div>
							</div> */}
							<div className="container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<img src="/icons/user.svg" alt="" style={{ width: '40px' }} />
								<span className="position-relative">
									<div className="cart-item-count">1</div>
									<img src="/icons/shopping-cart-2.svg" className="header-icons" alt="cart" style={{ width: '30px', marginLeft: '10px' }} />
								</span>
							</div>
						</div>
						<div>
							<div
								css={`
									height: 70px;
									border-bottom: 1px solid #eee;
									text-align: center;
									align-items: center;
								`}
							>
								<h1>{t('YourWebsite')}</h1>
							</div>
							<div
								css={`
									height: 300px;
									border-bottom: 1px solid #eee;
									text-align: center;
									align-items: center;
									display: flex;
									.campaigns {
										width: 95%;
										height: 300px;
										background: #f8f8f8;
										border-right: 1px solid #eee;
										display: flex;
										align-item: center;
										justify-content: center;
									}
									.reverse {
										transform: rotate(180deg);
									}
								`}
							>
								{/* <img className="reverse" src="/images/arrow.svg" alt="" />
								 */}
								 <div className="campaigns">
									<img src="https://cdnydm.com/permedia/bS9V0wPrUi5CZtOTsJni3g.jpg?size=381x300" width="300" height="300" alt="" />
								</div>
								{/* <img src="/images/arrow.svg" alt="" /> */}
							</div>
							<h3
								css={`
									text-align: center;
								`}
							>
								{t('FeaturedProducts')}
							</h3>
							<div
								className="featuredProducts"
								css={`
									display: flex;
									flex-wrap: wrap;
									align-item: center;
									border-bottom: 1px solid #eee;
									padding-bottom: 50px;
									justify-content: center;
									p {
										text-align: center;
									}
								`}
							>
								<div>
									<img src="https://cdnydm.com/permedia/s6gnY_2PpkRt2fMsi-QM6g.jpg?size=150x150" alt="" width="150" />
									<p>{t('ProductTitle')}</p>
									<p>₺40</p>
								</div>
								<div>
									<img src="https://cdnydm.com/permedia/aUm3VylgU0hSvPZvdLja3A.jpg?size=150x150" alt="" width="150" />
									<p>{t('ProductTitle')}</p>
									<p>₺150</p>
								</div>
								<div>
									<img src="https://cdnydm.com/permedia/X9J8y-3qqidWWcaDiLe5gA.jpg?size=150x150" alt="" width="150" />
									<p>{t('ProductTitle')}</p>
									<p>₺100</p>
								</div>
							</div>

							<div
								className="footer"
								css={`
									display: flex;
									justify-content: center;
									padding-top: 30px;
									border-bottom: 1px solid #eee;
									.tab {
										margin-left: 50px;
										margin: auto;
										margin-top : inherit;
									}
									.address {
										width: 40%;
										justify-content: center;
										text-align: center;
										flex-wrap: wrap;
										padding-top: 50px;
									}
								`}
							>
								<div className="address">
									<img src="https://cdnydm.com/permedia/iJlWM8zpL0NC5FrKAF_eSA.png?size=800x400" alt="" height="70px" />
									<p>{t('Address')}</p>
								</div>
								{/* <div className="container"></div> */}
								<div className="tab">
									<h3>{t('MainMenu')}</h3>
									<p>{t('Home')}</p>
									<p>{t('About')}</p>
									<p>{t('Shop')}</p>
									<p>{t('Help')}</p>
								</div>
								<div className="tab">
									<h3>{t('FindUs')}</h3>
									<p>Facebook</p>
									<p>Twitter</p>
									<p>Instagram</p>
								</div>
							</div>
							<div className={'ecommercetab cross ' + isEcommerceFooter}>
								<p>
									Bu site <strong>EcommerceTab</strong> altyapısı ile hazırlanmıştır.
								</p>
							</div>
						</div>
					</>
				);
			case 'cart':
				return (
					<>
						<Styles /> {noPreview ? <div className="center">Ön İzleme Yoktur</div> : null}
						{actionType === 'upButtonTag' ? (
							<div
								id="back-to-top"
								className={'btn back-to-top ' + inputText.position}
								style={{
									cursor: 'pointer',
									backgroundColor: inputText.bannercolor,
									backgroundImage: `url('${inputText.back_to_up_url || 'https://cdnydm.com/permedia/Pmi2kQU1vT3zTXpOs71EBA.png?size=24x24'}') `,
								}}
							/>
						) : (
							''
						)}
						<div
							css={`
								border-bottom: 1px solid #eee;
								padding-top: 5px;
							`}
						>
							<div className="tabs">
								<div className="tab active">
									<div className="favicon">
										<span className="position-relative">
											<img src="/favicon.ico" alt="favicon"></img>
										</span>
									</div>
									<div className="title">
										{/* {actionType === 'pageTitleTagWithFavicon' && inputText.show_on_title ? '(1) ' : ''} */}
										{inputText.title_fore || t('frontendTitle')}
									</div>
								</div>
								<div className="tab">
									<div className="favicon">
										<span className="position-relative">
											{actionType === 'pageTitleTagWithFavicon' && inputText.show_favicon ? (
												<div
													className={'cart-item-count small fade-in ' + inputText.position + ' ' + inputText.isFaviconShow}
													style={{
														backgroundColor: inputText.background_color,
														color: inputText.text_color,
													}}
												>
													1
												</div>
											) : (
												''
											)}
											<img src="/favicon.ico" alt="favicon"></img>
										</span>
									</div>
									<div className="title">
										{actionType === 'pageTitleTagWithFavicon' && inputText.show_on_title ? '(1) ' : ''}
										{inputText.title_back_basket || t('backgroundTitle')}
									</div>
								</div>
							</div>
						</div>
						<BrowserLine />
						<div
							css={`
								height: 50px;
								border-bottom: 1px solid #eee;
								text-align: left;
								display: flex;
								align-items: center;
								span {
									display: inline-block;
									margin: 0 5px;
									width: 80px;
								}
							`}
						>
							<div className="container mainmenu">
								<span>{t('Home')}</span>
								<span>{t('About')}</span>
								<span>{t('Shop')}</span>
								<span>{t('Help')}</span>
							</div>
							<div className="container mobile-tabs">
								<img src="/icons/list.svg" alt="list" width="30"></img>
							</div>
							<div className="container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<img src="/icons/user.svg" alt="" style={{ width: '40px' }} />
								<span className="position-relative">
									<div className="cart-item-count">2</div>
									<img src="/icons/shopping-cart-2.svg" className="header-icons" alt="cart" style={{ width: '30px', marginLeft: '10px' }} />
								</span>
							</div>
						</div>
						<div
							css={`
								display: flex;
								background: #f8f8f8;
								padding-top: 40px;
								flex-wrap: wrap;
								padding-bottom: 40px;
								text-align: 'left';
								p {
									color: grey;
									font-size: 1.7rem;
									@media (min-width: 577px) {
										margin: auto;
									}
									@media (max-width: 576px) {
										margin-top: auto;
										margin-bottom: auto;
										margin-left: 20px;
										text-align: left;
									}
								}
								p:nth-child(1) {
									color: black !important;
								}
							`}
						>
							<p>1. {t('ShoppingCart')}</p>
							<p>2. {t('ShippingDetails')}</p>
							<p>3. {t('PaymentOptions')}</p>
						</div>
						<div
							css={`
								display: flex;
								margin: 20px;
								flex-wrap: wrap;
								h1 {
									font-weight: 100;
								}
								.shoppingProduct {
									display: flex;
									margin: auto;
									align-items: center;
									border: 1px solid #eee;
								}
							`}
						>
							<div
								className="shoppingCart"
								css={`
									img {
										margin: 30px;
									}
								`}
							>
								<h1>{t('ShoppingCart')}</h1>
								<div>
									<div className="shoppingProduct">
										<img src="https://cdnydm.com/permedia/3MiwRDOA7kFQYT86h3hEvw.jpg?size=200x200" alt="" height="80px" />
										<div>
											<p>{t('ProductName')}</p>
											<p>₺100</p>
										</div>
									</div>
									<div className="shoppingProduct">
										<img src="https://cdnydm.com/permedia/s6gnY_2PpkRt2fMsi-QM6g.jpg?size=150x150" alt="" height="80px" />
										<div>
											<p>{t('ProductName')}</p>
											<p>₺50</p>
										</div>
									</div>
								</div>
								<button
									css={`
										background: #7a7a7a;
										border-radius: 0px;
										color: #ffffff;
										font-size: 20px;
										padding: 10px 20px 10px 20px;
										text-decoration: none;
										border-width: 0;
										margin: 25px;
									`}
								>
									{t('Next')}
								</button>
								<button
									css={`
										background: #d2cbcb;
										border-radius: 0px;
										color: #ffffff;
										font-size: 20px;
										padding: 10px 20px 10px 20px;
										text-decoration: none;
										border-width: 0;
									`}
								>
									{t('Cancel')}
								</button>
							</div>
							<div
								className="summaryCart"
								css={`
									margin-left: 10px;
									img {
										margin: 30px;
									}
								`}
							>
								<h1>{t('Summary')}</h1>
								<hr></hr>
								<div
									css={`
										display: flex;
										p {
											font-size: 1.3rem;
											margin-bottom: 36px;
										}
									`}
								>
									<div>
										<p>{t('SUBTOTAL')}</p>
										<p>{t('SHIPPING')}</p>
										<p>{t('TAXES')}</p>
									</div>
									<div
										css={`
											margin-left: auto;
										`}
									>
										<p>₺220</p>
										<p>{t('FREE')}</p>
										<p>₺20</p>
									</div>
								</div>
								<hr></hr>
								<div
									css={`
										display: flex;
										p {
											font-size: 1.7rem;
										}
									`}
								>
									<div>
										<p>{t('TOTAL')}</p>
									</div>
									<div
										css={`
											margin-left: auto;
										`}
									>
										<p>₺240</p>
									</div>
								</div>
							</div>
						</div>
						<div
							className="footer"
							css={`
								display: flex;
								justify-content: center;
								padding-top: 30px;
								border-bottom: 1px solid #eee;
								border-top: 1px solid #eee;
								.tab {
									margin-left: 50px;
									margin: auto;
									margin-top: 0;
								}
								.address {
									width: 40%;
									justify-content: center;
									text-align: center;
									flex-wrap: wrap;
									padding-top: 50px;
								}
							`}
						>
							<div className="address">
								<img src="https://cdnydm.com/permedia/iJlWM8zpL0NC5FrKAF_eSA.png?size=800x400" alt="" height="70px" />
								<p>{t('Address')}</p>
							</div>
							{/* <div className="container"></div> */}
							<div className="tab">
								<h3>{t('MainMenu')}</h3>
								<p>{t('Home')}</p>
								<p>{t('About')}</p>
								<p>{t('Shop')}</p>
								<p>{t('Help')}</p>
							</div>
							<div className="tab">
								<h3>{t('FindUs')}</h3>
								<p>Facebook</p>
								<p>Twitter</p>
								<p>Instagram</p>
							</div>
						</div>
						<div className={'ecommercetab cross ' + isEcommerceFooter}>
							<p>
								Bu site <strong>EcommerceTab</strong> altyapısı ile hazırlanmıştır.
							</p>
						</div>
					</>
				);
			default:
				return (
					<>
						<Styles /> {noPreview ? <div className="center">Ön İzleme Yoktur</div> : null}
						{actionType === 'upButtonTag' ? (
							<div
								id="back-to-top"
								className={'btn back-to-top ' + inputText.position}
								style={{
									cursor: 'pointer',
									backgroundColor: inputText.bannercolor,
									backgroundImage: `url('${inputText.back_to_up_url || 'https://cdnydm.com/permedia/Pmi2kQU1vT3zTXpOs71EBA.png?size=24x24'}') `,
								}}
							/>
						) : (
							''
						)}
						<div
							css={`
								border-bottom: 1px solid #eee;
								padding-top: 5px;
							`}
						>
							<div className="tabs">
								<div className="tab active">
									<div className="favicon">
										<span className="position-relative">
											<img src="/favicon.ico" alt="favicon"></img>
										</span>
									</div>
									<div className="title">
										{/* {actionType === 'pageTitleCartCountTag' ? '(1) ' : ''} */}
										{inputText.title_fore || t('frontendTitle')}
									</div>
								</div>
								<div className="tab">
									<div className="favicon">
										<span className="position-relative">
											{actionType === 'pageTitleTagWithFavicon' && inputText.show_favicon ? (
												<div
													className={'cart-item-count small fade-in ' + inputText.position + ' ' + inputText.isFaviconShow}
													style={{
														backgroundColor: inputText.background_color,
														color: inputText.text_color,
													}}
												>
													1
												</div>
											) : (
												''
											)}
											<img src="/favicon.ico" alt="favicon"></img>
										</span>
									</div>
									<div className="title">
										{actionType === 'pageTitleTagWithFavicon' && inputText.show_on_title ? '(1) ' : ''}
										{inputText.title_back_basket || t('backgroundTitle')}
									</div>
								</div>
							</div>
						</div>
						<BrowserLine />
						<div
							css={`
								height: 50px;
								border-bottom: 1px solid #eee;
								text-align: left;
								display: flex;
								align-items: center;
								span {
									display: inline-block;
									margin: 0 5px;
									width: 80px;
								}
							`}
						>
							<div className="container mainmenu">
								<span>{t('Home')}</span>
								<span>{t('About')}</span>
								<span>{t('Shop')}</span>
								<span>{t('Help')}</span>
							</div>
							<div className="container mobile-tabs">
								<img src="/icons/list.svg" alt="list" width="30"></img>
							</div>
							<div className="container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<img src="/icons/user.svg" alt="" style={{ width: '40px' }} />
								<span className="position-relative">
									<div className="cart-item-count">2</div>
									<img src="/icons/shopping-cart-2.svg" className="header-icons" alt="cart" style={{ width: '30px', marginLeft: '10px' }} />
								</span>
							</div>
						</div>
						<h2
							css={`
								margin-left: 20px;
							`}
						>
							{t('CurrentCategory')}
						</h2>
						<div
							css={`
								display: flex;
								margin: 10px;
								.categories {
									width: 65%;
									margin: 0 10px 10px 10px;
									div {
										border: 1px solid #eee;
										padding: 5px;
										font-size: 14px;
										opacity: 0.7;
									}
									> div:nth-child(3) {
										font-weight: bold;
									}
								}
								.category-products {
									width: 100%;
								}
							`}
						>
							<div className="categories">
								<div>{t('T-Shirts')}</div>
								<div>{t('Sweaters')}</div>
								<div>{t('Sweatshirts')}</div>
								<div>{t('Button Down Shirts')}</div>
								<div>{t('Jackets & Coats')}</div>
								<div>{t('Pants & Shorts')}</div>
								<div>{t('Tracksuits')}</div>
								<div>{t('Swimwear')}</div>
								<div>{t('Underwear')}</div>
								<div>{t('Sneakers')}</div>
								<div>{t('Sandals & Slip-Ons')}</div>
								<div>{t('Watches')}</div>
								<div>{t('Sunglasses')}</div>
								<div>{t('Fragrance')}</div>
								<div>{t('Caps & Hats')}</div>
								<div>{t('Scarves & Gloves')}</div>
								<div>{t('Socks')}</div>
								<div>{t('Bags')}</div>
								<div>{t('Small Leather Goods')}</div>
								<div>{t('Belts')}</div>
							</div>
							<div className="category-products">
								<div
									css={`
										display: flex;
										flex: 1;
										flex-wrap: wrap;
									`}
								>
									<Product t={t} />
									<Product t={t} />
									<Product t={t} />
									<Product t={t} />
								</div>
								<div
									css={`
										display: flex;
										flex: 1;
										flex-wrap: wrap;
									`}
								>
									<Product t={t} />
									<Product t={t} />
									<Product t={t} />
									<Product t={t} />
								</div>
								<div
									css={`
										display: flex;
										flex: 1;
										flex-wrap: wrap;
									`}
								>
									<Product t={t} />
									<Product t={t} />
									<Product t={t} />
									<Product t={t} />
								</div>
							</div>
						</div>{' '}
						<div
							className="footer"
							css={`
								display: flex;
								justify-content: center;
								padding-top: 30px;
								border-bottom: 1px solid #eee;
								border-top: 1px solid #eee;
								.tab {
									margin-left: 50px;
									margin: auto;
									margin-top: 0;
								}
								.address {
									width: 40%;
									justify-content: center;
									text-align: center;
									flex-wrap: wrap;
									padding-top: 50px;
								}
							`}
						>
							<div className="address">
								<img src="https://cdnydm.com/permedia/iJlWM8zpL0NC5FrKAF_eSA.png?size=800x400" alt="" height="70px" />
								<p>{t('Address')}</p>
							</div>
							{/* <div className="container"></div> */}
							<div className="tab">
								<h3>{t('MainMenu')}</h3>
								<p>{t('Home')}</p>
								<p>{t('About')}</p>
								<p>{t('Shop')}</p>
								<p>{t('Help')}</p>
							</div>
							<div className="tab">
								<h3>{t('FindUs')}</h3>
								<p>Facebook</p>
								<p>Twitter</p>
								<p>Instagram</p>
							</div>
						</div>{' '}
						<div className={'ecommercetab cross ' + isEcommerceFooter}>
							<p>
								Bu site <strong>EcommerceTab</strong> altyapısı ile hazırlanmıştır.
							</p>
						</div>
					</>
				);
		}
	}
}

export const enList = [
	{ text: 'Product Page', value: 'product' },
	{ text: 'Category Page', value: 'category' },
	{ text: 'Home Page', value: 'home' },
	{ text: 'Cart Page', value: 'cart' },
	{ text: 'Empty Page', value: 'empty' },
];
export const trList = [
	{ text: 'Ürün Sayfası', value: 'product' },
	{ text: 'Kategori Sayfası', value: 'category' },
	{ text: 'Anasayfa', value: 'home' },
	{ text: 'Sepet Sayfası', value: 'cart' },
	{ text: 'Boş Sayfa', value: 'empty' },
];

export default withTranslation()(Mockup);
