import React from 'react';
import { Icon, Loader as RawLoader, Breadcrumb } from 'semantic-ui-react';
import cs from 'classnames';

const Page = (props) => (
	<div className="page-wrap">
		{props.disabled ? null : (
			<div className="layout-header">
				{props.header || (
					<Breadcrumb size="big">
						<Breadcrumb.Section>
							{props.icon && <Icon name={props.icon} />}
							<span>{props.title}</span>
							{props.subtitle && <span className="description">{props.subtitle}</span>}
						</Breadcrumb.Section>
						{props.breads}
					</Breadcrumb>
				)}
				{props.extra}
			</div>
		)}
		<div className={cs('layout-body', { 'd-flex': props.hasOwnProperty('flex') })}>{props.children}</div>
	</div>
);

Page.Loader = React.memo(() => (
	<div className="layout-center" style={{maxHeight:'75vh'}}>
		<RawLoader active inline="centered" />
	</div>
));

Page.Wrap = React.memo((props) => <div className={cs('layout-wrap', props.className)}>{props.children}</div>);

export default Page;
