import React, { Component } from 'react';

import i18n from '../i18n';

export function LangFormat(text) {
	return text && (typeof text === 'string' ? text : text[i18n.language] || text.en);
}

export function LangText(props) {
	let text = LangFormat(props.children);
	return <span className={props.className}>{text}</span>;
}
