import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  LoginPage,
  RegisterPage,
  ForgetPasswordPage,
  ResetPasswordPage,
} from "./pages/auth";
import Page from "./components/Page";
import Contact from "./pages/contact";
import CheckMail from "./pages/checkMail";
import Header from "./components/Header";
import AccountRouter from "./pages/account/router";
import { Page404 } from "./pages/404";
import { load_product } from "./actions/product.actions";
import Help from "./pages/personalize/help";
import HelpVideos from "./pages/personalize/helpvideos";
import Influencer from "./pages/personalize/influencer";
import HelpInfluencer from "./pages/personalize/help_influencer";
import Trendyol from "./pages/personalize/trendyol";

import PageSettings from "./pages/personalize/settings";
import BotRouter from "./pages/bot";
import WhatsappBotRouter from "./pages/whatsappbot";
import ActionEdit from "./pages/personalize/actionEdit";
import ActionList from "./pages/personalize/actionList";
import Analytics from "./pages/personalize/analytics";
import Subscription from "./pages/personalize/subscription";
import Templates from "./pages/personalize/templates";
import Schedules from "./pages/personalize/schedule";
import Template from "./pages/personalize/templateslider";
import StartModal from "./components/StartModal";
import CampaignsEdit from "./pages/personalize/campaignsEdit";
import PaymentPage from "./pages/personalize/payment";
import Admin from "./pages/admin";
import VariableEdit from "./pages/personalize/variable_edit";
import VariableList from "./pages/personalize/variable_list";
import ReviewHelper from './pages/personalize/reviewHelper'

import ErrorPage from "./pages/error";

const LayoutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <>
        <Header />
        <Component {...matchProps} />{" "}
      </>
    )}
  />
);

function LoadProduct({ dispatch }) {
  React.useEffect(() => {
    dispatch(load_product());
  }, [dispatch]);
  return <Page.Loader key="loader" />;
}

class App extends React.PureComponent {
  render() {
    const { access, product } = this.props;
    let trueProduct = product.personalize;
    let passModal =
      trueProduct && trueProduct.settings && trueProduct.settings.__passModal;
    const user = access.user;
    const isLogin = user && user.isAuthenticated;

    const _loader = <Page.Loader key="loader" />;
    let temp_id =
      (window.location.pathname &&
        window.location.pathname.match(/template-(.*)/) &&
        window.location.pathname.match(/template-(.*)/)[1]) ||
      "";
    if (access.loading === "profile" || product.loading) return _loader;
    const admin =
      user &&
      user.loggedUserObj &&
      user.loggedUserObj.extra &&
      user.loggedUserObj.extra.admin;
    if (
      !passModal &&
      access &&
      access.user &&
      access.user.loggedUserObj &&
      access.user.loggedUserObj.host !== "influencer.collectaction.com"
    ) {
      return (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route exact path="/forgot" component={ForgetPasswordPage} />
          <Route path="/forgot/checkyourmail" component={CheckMail} />
          <Route
            path="/verify/resetPassword/:token"
            component={ResetPasswordPage}
          />
          {!isLogin && (
            <Redirect
              to={{ pathname: "/login", state: { from: this.props.location } }}
            />
          )}
          {!this.props.product.personalize && (
            <Route component={() => <LoadProduct {...this.props} />} />
          )}
          <LayoutRoute path="/" component={StartModal} />
        </Switch>
      );
    }
    //todo access.user.loggedUserObj.host === "inf.collectaction.com"
    if (
      access &&
      access.user &&
      access.user.loggedUserObj &&
      access.user.loggedUserObj.host === "influencer.collectaction.com"
    ) {
      return (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />

          <Route exact path="/forgot" component={ForgetPasswordPage} />
          <Route path="/forgot/checkyourmail" component={CheckMail} />
          <Route
            path="/verify/resetPassword/:token"
            component={ResetPasswordPage}
          />

          {!isLogin && (
            <Redirect
              to={{ pathname: "/login", state: { from: this.props.location } }}
            />
          )}
          {!this.props.product.personalize && (
            <Route component={() => <LoadProduct {...this.props} />} />
          )}
          <LayoutRoute path="/" exact component={Influencer} />
          <LayoutRoute path="/account" component={AccountRouter} />
          <LayoutRoute path="/help-influencer" component={HelpInfluencer} />
          <LayoutRoute path="/contact" component={Contact} />
          <LayoutRoute path="/help/videos" component={HelpVideos} />
          <LayoutRoute path="/help" exact component={Help} />
          <LayoutRoute path="/settings" component={PageSettings} />
          <LayoutRoute component={Page404} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route exact path="/forgot" component={ForgetPasswordPage} />
          <Route path="/forgot/checkyourmail" component={CheckMail} />
          <Route
            path="/verify/resetPassword/:token"
            component={ResetPasswordPage}
          />

          {!isLogin && (
            <Redirect
              to={{ pathname: "/login", state: { from: this.props.location } }}
            />
          )}
          {!this.props.product.personalize && (
            <Route component={() => <LoadProduct {...this.props} />} />
          )}
          <LayoutRoute path="/trendyol" exact component={Trendyol} />
          <LayoutRoute path="/influencer" exact component={Influencer} />
          <LayoutRoute path="/reviewspanel" component={ReviewHelper}/>
          <LayoutRoute path="/account" component={AccountRouter} />
          <LayoutRoute path="/contact" component={Contact} />
          <LayoutRoute path="/" exact component={Templates} />
          <LayoutRoute path="/schedules" exact component={Schedules} />
          <LayoutRoute path="/help/videos" component={HelpVideos} />
          <LayoutRoute path="/help" exact component={Help} />
          <LayoutRoute path="/analytics" component={Analytics} />
          <LayoutRoute path="/subscription" component={Subscription} />
          {temp_id === "46" ? (
            <LayoutRoute
              path="/actions/create/template-:t_id"
              component={BotRouter}
            />
          ) : (
            <LayoutRoute
              path="/actions/create/template-:t_id"
              component={ActionEdit}
            />
          )}
          <LayoutRoute
            path="/campaigns/create/template-:t_id"
            component={CampaignsEdit}
          />
          <LayoutRoute path="/campaigns/:id" component={CampaignsEdit} />
          <LayoutRoute path="/actions/:id" component={ActionEdit} />
          <LayoutRoute path="/actions" exact component={ActionList} />
          <LayoutRoute path="/variable/:id" component={VariableEdit} />
          <LayoutRoute path="/variable" exact component={VariableList} />
          <LayoutRoute path="/settings" component={PageSettings} />
          <LayoutRoute path="/bot" component={BotRouter} />
          <LayoutRoute path="/whatsappbot" component={WhatsappBotRouter} />
          {/* <LayoutRoute path="/analytic" exact component={Analytic} /> */}
          <LayoutRoute path="/action" exact component={Template} />
          <LayoutRoute path="/schedule" exact component={Template} />
          <LayoutRoute path="/actions" exact component={Templates} />
          <LayoutRoute path="/payment" exact component={PaymentPage} />
          <LayoutRoute path="/error" exact component={ErrorPage} />
          {admin && (
            <LayoutRoute
              path="/yta"
              component={() => <Admin {...this.props}></Admin>}
            />
          )}
          <LayoutRoute component={Page404} />
        </Switch>
      );
    }
  }
}

const stateToProps = (state) => ({
  access: state.access,
  product: state.product,
  location: state.router.location,
});

export default connect(stateToProps)(App);
