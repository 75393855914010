import React, { Component } from "react";
import Page from "../../components/Page";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Button,
  Table,
  Checkbox,
  Breadcrumb,
  Segment,
  Popup,
  Icon,
  Modal,
  ModalActions,
  ModalContent,
  Image,
} from "semantic-ui-react";
import _ from "lodash";
import { get, post } from "../../util/api";
import moment from "moment";
class reviewHelper extends Component {
  state = {
    loading: true,
    data: [],
    saveButton: false,
    changedData: [],
    modal: false,
    created_at: true,
  };

  componentDidMount() {
    let { id } = this.props.product.personalize;
    let pid = id;
    post("/personalize/getCommentWithAdmin/list", { pid }).then((res) => {
      let sortedResult = _.sortBy(
        res.data.result,
        (it) => it.created_at
      ).reverse();
      this.setState({ loading: false, data: sortedResult });
    });
  }

  sortData = (value = "created_at", asc = "desc") => {
    let { data } = this.state;
    let created = true;
    let sorted_data = _.orderBy(data, [value], [asc]);
    if (value == "updated_at") created = false;
    this.setState({ data: sorted_data, created_at: created });
  };
  approve = (index, comingData) => {
    let { data } = this.state;
    let { changedData } = this.state;
    let tempChangingData;
    if (changedData.length === 0) {
      tempChangingData = _.filter(data, (it, i) => {
        if (it.sid === comingData.sid && it.id === comingData.id) {
          if (it.verify === true) {
            it.verify = false;
            return it;
          } else {
            it.verify = true;
            return it;
          }
        }
      });
    } else {
      let holdData = _.filter(data, (it, i) => {
        if (it.sid === comingData.sid && it.id === comingData.id) {
          if (it.verify === true) {
            it.verify = false;
            return it;
          } else {
            it.verify = true;
            return it;
          }
        }
      });
      tempChangingData = _.concat(changedData, holdData);
      tempChangingData = _.unionBy(tempChangingData);
    }
    this.setState({ changedData: tempChangingData, saveButton: true });
  };
  saveCommentChanges = () => {
    let { changedData } = this.state;
    let { id } = this.props.product.personalize;
    post("/personalize/getCommentWithAdmin/change", { changedData }).then(
      (res) => {
        console.log("res>", res);
      }
    );
    this.setState({ saveButton: false });
  };
  render() {
    let { loading, data, saveButton, created_at } = this.state;
    //data = _.sortBy(data, (it) => it.created_at).reverse();
    let { t } = this.props;
    if (loading) {
      return (
        <Page
          icon="chart line"
          title={t("akinonCommentModule")}
          extra={
            <div
              css={`
                margin-left: auto;
              `}
            ></div>
          }
        >
          <Page.Loader key="loader" />
        </Page>
      );
    } else {
      return (
        <Page
          icon="chart line"
          title={t("akinonCommentModule")}
          header={
            <Breadcrumb size="big">
              <Breadcrumb.Section>
                {t("akinonCommentModule")}
              </Breadcrumb.Section>
            </Breadcrumb>
          }
        >
          {/* <Page.Header>
                <Button content={'save'} icon={"save"}/>
            </Page.Header> */}
          <Page.Wrap>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {saveButton === true ? (
                <Button
                  color={"blue"}
                  content={t("Save")}
                  icon={"save"}
                  onClick={() => this.saveCommentChanges()}
                />
              ) : (
                <Button
                  color={"blue"}
                  content={t("Save")}
                  icon={"save"}
                  disabled
                />
              )}
            </div>
            <Segment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t("productId")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("productLink")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("customerMail")}</Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={() => {
                        this.sortData("created_at", "desc");
                      }}
                      style={{
                        background: created_at ? "gray" : "",
                        cursor: "pointer",
                      }}
                    >
                      {t("createdDate")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={() => {
                        this.sortData("updated_at", "desc");
                      }}
                      style={{
                        background: !created_at ? "gray" : "",
                        cursor: "pointer",
                      }}
                    >
                      {t("updated_at")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t("userName")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("commentContent")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("imageComment")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("review")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("approval")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(data, (it, i) => (
                    <Table.Row>
                      <Table.Cell>{it.product_id}</Table.Cell>
                      <Table.Cell>
                        {it && it.extra && it.extra.absolute_url ? (
                          <a
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                            href={`https://${
                              it && it.extra && it.extra.absolute_url
                            }`}
                          >
                            {it && it.extra && it.extra.absolute_url}
                          </a>
                        ) : (
                          <></>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {it && it.extra && it.extra.email}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(it.created_at)
                          .locale(t("lang"))
                          .format("LL - HH:mm")}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(it.updated_at)
                          .locale(t("lang"))
                          .format("LL - HH:mm")}
                      </Table.Cell>

                      <Table.Cell>{it.extra.userFullName}</Table.Cell>
                      <Table.Cell id="akinonPanelTableCell">
                        {it.extra.comment}
                      </Table.Cell>
                      <Table.Cell
                        id="comment-images"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {_.map(
                          it && it.extra && it.extra.mediaFiles,
                          (it, i) => (
                            <div
                              onClick={() => {
                                this.setState({ modal: it.url });
                              }}
                            >
                              {/* <Popup
                                pinned
                                on="click"
                                trigger={<Icon name="image" size="big" />}
                                content={
                                  <img
                                    style={{ maxWidth: "200px" }}
                                    src={it.url}
                                  />
                                }
                              /> */}
                              <Icon name="image" size="big" />
                            </div>
                          )
                        )}
                      </Table.Cell>
                      <Table.Cell>{it.extra.rate}</Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          toggle
                          onChange={() => this.approve(i, it)}
                          checked={it.verify !== false}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </Page.Wrap>
          {this.state.modal && (
            <Modal onClose={() => this.setState({ modal: null })} open={true}>
              <ModalContent image>
                <Image src={this.state.modal} wrapped />
              </ModalContent>
              <ModalActions>
                <Button
                  color="black"
                  onClick={() => this.setState({ modal: null })}
                >
                  {t("close")}
                </Button>
              </ModalActions>
            </Modal>
          )}
        </Page>
      );
    }
  }
}
const stateToProps = (state) => ({
  access: state.access,
  product: state.product,
  location: state.router.location,
});
export default connect(stateToProps)(withTranslation()(reviewHelper));
