import { templateCache, bootSub } from './CampaingHelpers';
import _ from 'lodash';

const recommend_preview = {
	position: 'top-center',
	wrapStyle: 'padding:20px 0 0 0!important;background: white;',
	items: _.map(
		[
			// {
			// 	price: '129,00',
			// 	image: 'http://www.pickplugins.com/demo/wc-product-filter/wp-content/uploads/sites/35/2018/10/hoodie-2-200x200.jpg',
			// 	title: 'Kırmızı Hoodie',
			// 	description: '¨129,00¨ *asdasd*',
			// 	currency: 'TL',
			// 	pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
			// 	url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			// },
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/bS9V0wPrUi5CZtOTsJni3g.jpg?size=200x200',
				title: 'Polo',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/s6gnY_2PpkRt2fMsi-QM6g.jpg?size=200x200',
				title: 'Şapka',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/3MiwRDOA7kFQYT86h3hEvw.jpg?size=200x200',
				title: 'Hoodie',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/VHQPvDwm4xFL4TvGzgWarg.jpg?size=200x200',
				title: 'Hoodie',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/CQq815P_Y62VVBPeMloFOA.jpg?size=200x200',
				title: 'Sweatwer',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/HlmVYmina3gW5Vof3Mb1dg.jpg?size=200x200',
				title: 'Bere',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/YQWJ9Fp6VMIbzzckajp2nA.jpg?size=200x200',
				title: 'Kemer',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/aUm3VylgU0hSvPZvdLja3A.jpg?size=200x200',
				title: 'Güneş Gözlüğü',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
			{
				price: '129,00',
				image: 'https://cdnydm.com/permedia/yd5fzD0xpXEU99JtQf9JQg.jpg?size=200x200',
				title: 'Bere',
				description: '  129,00',
				currency: 'TL',
				pid: '4473f664-b9ac-49d5-940b-c5c4ec05509d',
				url: 'https://www.atasunoptik.com.tr/inesta-ao9027-c205-4723150-unisex-gunes-gozlukleri_80187',
			},
		],
		(it) => {
			return {
				title: it.title,
				price: '' + it.price + it.currency,
				imageUrl: it.image,
				button: { onclick: { __type: 'url', payload: it.url }, __type: 'onclick' },
			};
		}
	),
};

const Sys = {
	campaign: () => [
		{
			label: {
				name: 'Trigger',
				icon: 'lightning',
			},
			type: 'boxselect',
			path: 'trigger.0',
			data: {
				type: 'dropdown',
			},
		},
		{
			label: {
				name: 'Conditions',
				icon: 'setting',
			},
			type: 'condition',
			data: {},
		},
		{
			label: {
				name: 'Actions',
				icon: 'bullhorn',
			},
			type: 'actions',
			data: {},
		},
	],
	recommend: (cache) => [
		{
			label: {
				name: 'Page Type',
				icon: 'file alternate outline',
			},
			type: 'editor',
			data: {
				type: 'boxselect',
				path: 'pagetype',
				list: cache.recommendation,
			},
		},
		{
			label: {
				name: 'View',
				icon: 'block layout',
			},
			type: 'pop-editor',
			card: () => cache.actionKeyMap,
			data: {
				path: 'tags',
				type: 'popup',
				list: [_.assignIn({ __preview: recommend_preview }, cache.recommend_widget[0])],
			},
		},
		{
			label: {
				name: 'CSS Placement',
				icon: 'map marker alternate',
			},
			type: 'editor',
			data: { type: 'cssinput', preview: 'tags', path: 'css', default: '' },
		},
		{
			label: {
				name: 'Status',
				icon: 'toggle on',
			},
			type: 'editor',
			desc: 'Ürün önerisini açmadan önce belli bir süre boyunca kullanıcı datası toplanması gerekebilir.',
			data: {
				type: 'boxselect',
				path: 'state',
				list: cache.recommendationstate,
			},
			//pre_text: ' Statüs : ',
			//post_text: 'Öneri kartlarının düzgün bir şekilde çalışması için bir süre sadece data toplanmalıdır.',
		},
	],
	variable: (cache) => [
		{
			label: {
				name: 'Options',
				icon: 'file alternate outline',
			},
			type: 'editor',
			data: {
				type: 'string',
				path: 'key',
				title: 'Key',
				placeholder: 'variable_name',
				className: 'one-line',
			},
		},
		{
			type: 'editor',
			data: {
				type: 'boolean',
				path: 'unique',
				default: true,
				title: 'Unique',
				className: 'one-line',
			},
		},
		{
			type: 'editor',
			data: {
				type: 'boolean',
				path: 'auto',
				default: false,
				title: 'Auto Run',
				className: 'one-line',
			},
		},
		{
			label: {
				name: 'Code',
				icon: 'js',
			},
			type: 'editor',
			data: {
				type: 'code',
				path: 'code',
			},
		},
	],
};

function initializeForm(form) {
	let preData = {};
	let formData = {};
	_.each(form, (editor) => {
		bootSub(editor.data);
		if (editor.multi && editor.data.__default && editor.data.__default.params) editor.data.__default.params = [editor.data.__default.params];
		let name = editor.data.path || editor.data.__name;
		if (name) {
			preData[name] = form.multi ? [editor.data.__default] : editor.data.__default;
			formData[name] = editor;
		} else console.warn('name is missing', editor);
		//preData.__form[editor.data.path || editor.data.__name] = { multi: !!form.multi, type: form.data.type };
	});
	return { __default: preData, editors: form, formData };
}

export function CreateForm(type) {
	if (!type || !Sys[type]) return null;
	const cache = templateCache();
	const form = Sys[type](cache);
	return initializeForm(form);
}
