import React, { Component } from "react";
import { Bar, Line } from "react-chartjs-2";
import Page from "../../../components/Page";
import {
  Grid,
  Header,
  Icon,
  Segment,
  Table,
  Dropdown,
  Form,
  Popup,
} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import { get, log } from "../../../util/api";
import { connect } from "react-redux";
import { colors2 } from "../../../constants/colors";
import { withTranslation } from "react-i18next";

const ChartWrap = (props) => (
  <Grid.Column
    width={props.width}
    style={{ margin: "auto", maxWidth: "1000px" }}
  >
    <Header
      as="h4"
      attached="top"
      style={{ display: "flex", alignItems: "center" }}
    >
      <Icon name={props.icon || "chart bar"} />
      <Header.Content>{props.title}</Header.Content>
      <div style={{ marginLeft: "auto" }}> {props.content}</div>
    </Header>
    <Segment attached>{props.children}</Segment>
  </Grid.Column>
);
const barOptions = {
  scales: {
    xAxes: [{ stacked: false }],
    yAxes: [{ stacked: false, ticks: { min: 0 } }],
  },
  tooltips: {
    mode: "index",
    intersect: false,
    filter: (a) => a.yLabel,
  },
  hover: {
    mode: "nearest",
    intersect: true,
  },
  legend: {
    display: true,
    labels: { fill: false },
  },
};

class Analytic extends Component {
  state = {
    loading: true,
    filter: "",
    type: "day",
    startDate: this.props.start_date,
    endDate: this.props.end_date,
    open: false,
    column: null,
    direction: null,
    selectedDate: "daily",
    showActive: true,
    showPasif: false,
    showDeleted: false,
    showapproved_session: false,
    showcount: true,
    showcount_session: true,
    showcount_user: true,
    showsession_click_count: false,
    showsession_close_click_count: false,
    showtotal_click_count: true,
    showtotal_close_click_count: false,
    show_session: true,
    show_click: true,
    show_total_selling: true,
    show_click_selling: true,
    show_click_the_selling: true,
    show_close: false,
  };
  componentDidMount() {
    this.postGeneral();
  }
  componentDidUpdate(oldProps, oldState) {
    const check_list = ["start_date", "end_date"];
    for (let i = 0; i < check_list.length; i++) {
      const key = check_list[i];
      if (key === "start_date" || key === "end_date") {
        if (this.props[key] !== oldProps[key]) {
          this.postGeneral();
          break;
        }
      }
      if (!_.isArray(this.state[key])) {
        if (this.state[key] !== oldState[key]) {
          this.postGeneral();
          break;
        }
      } else if (!_.isEqual(this.state[key], oldState[key])) {
        this.postGeneral();
        break;
      }
    }
  }
  async postGeneral() {
    let { start_date, end_date } = this.props;
    let pid = this.props.product.id || "6f7db77b-83e6-4a5a-82e7-b323a6176223";
    const [campaignList, newData, newAnalytic, newAnalyticDate] =
      await Promise.all([
        get("/campaign/template/list?type=campaign"),
        get("/campaign"),
        log("/collectaction/getInfo", {
          start_date,
          end_date,
          filter: {
            pid,
          },
          select: [],
          group: ["type", "camp_id"],
          pairs: {},
          options: {
            total_count: "sum(count1)",
            count_with_order: "sum(count2)",
            count_with_the_order: "sum(count3)",
            unique_user: "count(distinct select1)",
            total_session: "count(*)",
          },
        }),
        log("/collectaction/getInfo?date", {
          start_date,
          end_date,
          filter: {
            pid,
          },
          select: [],
          group: ["type", "camp_id", "date"],
          pairs: {},
          options: {
            total_count: "sum(count1)",
            count_with_order: "sum(count2)",
            count_with_the_order: "sum(count3)",
            unique_user: "count(distinct select1)",
            total_session: "count(*)",
          },
        }),
      ]);
    this.setState({
      loading: false,
      newAnalytic: _.groupBy(newAnalytic.data.result, "camp_id"),
      campaignList: campaignList.data,
      camplist: newData.data,
      newAnalyticDate: _.groupBy(newAnalyticDate.data.result, "camp_id"),
      camp_group: _.groupBy(newAnalytic.data.result, "camp_id"),
    });
  }
  handleSort = (selected) => () => {
    const { column, apiCostList, direction } = this.state;
    if (selected !== column) {
      this.setState({
        column: selected,
        apiCostList: _.sortBy(apiCostList, [selected]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      apiCostList: apiCostList.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };
  handleFilterChange = (e, { text, value }) =>
    this.setState({ selectedCamp: value });
  toggle = () =>
    this.setState((prevState) => ({
      selectedDate: prevState.selectedDate === "daily" ? "hourly" : "daily",
    }));

  dateChange = ({ selection }) => {
    this.setState({
      startDate: selection.startDate,
      endDate: selection.endDate,
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  refresh = () => {
    this.checkUpdate(this.state, ["endDate", "startDate"]);
  };
  sumbystring = (data, value) => {
    return _.sumBy(data, (item) => Number(item[value]));
  };

  TableLine = (
    data,
    showapproved_session,
    showcount,
    showcount_session,
    showcount_user,
    showsession_click_count,
    showsession_close_click_count,
    showtotal_click_count,
    showtotal_close_click_count
  ) => (
    <Table.Body>
      {_.map(data, (it, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>
              {it.deleted
                ? "Silinmiş"
                : it.status === "active"
                ? this.props.t("Active")
                : this.props.t("Disabled")}
            </Table.Cell>
            <Table.Cell>{it.camp_name}</Table.Cell>
            {showapproved_session && (
              <Table.Cell>{it.approved_session}</Table.Cell>
            )}
            {showcount && <Table.Cell>{it.count}</Table.Cell>}
            {showcount_session && <Table.Cell>{it.count_session}</Table.Cell>}
            {showcount_user && <Table.Cell>{it.count_user}</Table.Cell>}
            {showsession_click_count && (
              <Table.Cell>{it.session_click_count}</Table.Cell>
            )}
            {showsession_close_click_count && (
              <Table.Cell>{it.session_close_click_count}</Table.Cell>
            )}
            {showtotal_click_count && (
              <Table.Cell>{it.total_click_count}</Table.Cell>
            )}
            {showtotal_close_click_count && (
              <Table.Cell>{it.total_close_click_count}</Table.Cell>
            )}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
  toggle = (e, { name }) =>
    this.setState((prevState) => ({ [name]: !prevState[name] }));
  render() {
    let { t } = this.props;
    const {
      camp_group,
      selectedCamp,
      selectedDate,
      camplist,
      showActive,
      showDeleted,
      showPasif,
      showcount,
      newAnalytic,
      newAnalyticDate,
      show_session,
      show_click,
      show_total_selling,
      show_click_selling,
      show_click_the_selling,
      show_close,
    } = this.state;
    const { start_date, end_date } = this.props;
    if (this.state.loading) return <Page.Loader />;
    _.map(newAnalytic, function (it) {
      return _.map(it, function (d, i) {
        var e = _.findIndex(camplist, function (o) {
          return o.id === _.parseInt(d.camp_id);
        });
        let gd = {
          [d.type + "_count_with_order"]: _.parseInt(d.count_with_order),
          [d.type + "_count_with_the_order"]: _.parseInt(
            d.count_with_the_order
          ),
          [d.type + "_total_count"]: _.parseInt(d.total_count),
          [d.type + "_total_session"]: _.parseInt(d.total_session),
          [d.type + "_unique_user"]: _.parseInt(d.unique_user),
        };
        _.assign(camplist[e], gd);
      });
    });
    let tablList = camplist;
    if (!newAnalytic) {
      return (
        <div className="myContainer">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("Durum")}</Table.HeaderCell>
                <Table.HeaderCell>{t("İsim")}</Table.HeaderCell>
                {show_session && (
                  <Table.HeaderCell>{t("Oturum Sayısı")}</Table.HeaderCell>
                )}
                {showcount && (
                  <Table.HeaderCell>{t("Tetiklenme Sayısı")}</Table.HeaderCell>
                )}
                {show_click && (
                  <Table.HeaderCell>{t("Tıklama Sayısı")}</Table.HeaderCell>
                )}
                {show_total_selling && (
                  <Table.HeaderCell>
                    {t("Tetiklenme-Satış Oranı")}
                  </Table.HeaderCell>
                )}
                {show_click_selling && (
                  <Table.HeaderCell>
                    {t("Tıklama-Satış Oranı")}
                  </Table.HeaderCell>
                )}
                {show_click_the_selling && (
                  <Table.HeaderCell>
                    {t("Tıklama-Seçili Ürün Satış Oranı")}
                  </Table.HeaderCell>
                )}
                {show_close && (
                  <Table.HeaderCell>{t("Kapatılan")}</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ textAlign: "center" }} colSpan="13">
                  {t("NoDataShowing")}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      );
    }
    tablList = _.filter(tablList, function (it) {
      return (
        (showActive && it.status === "active") ||
        (showPasif && it.status === "disabled") ||
        (showDeleted && it.deleted)
      );
    });
    let dropdowndata = _.map(camp_group, (it) => {
      let selectAction = _.find(camplist, function (o) {
        return "" + o.id === it[0].camp_id;
      });
      return {
        text: (selectAction && selectAction.name) || it[0].camp_id,
        key: it[0].camp_id,
        value: it[0].camp_id,
        status: (selectAction && selectAction.status) || "deleted",
        deleted: selectAction && selectAction.deleted === false ? false : true,
      };
    });
    dropdowndata = _.filter(dropdowndata, function (it) {
      return (
        (showActive && it.status === "active") ||
        (showPasif && it.status === "disabled") ||
        (showDeleted && it.deleted)
      );
    });
    /* if (!dropdowndata.length) {
			dropdowndata.push({ text: '', key: '', value: '' });
		} */
    if (_.findIndex(dropdowndata, { value: selectedCamp }) < 0) {
      if (dropdowndata && dropdowndata.length) {
        this.setState({ selectedCamp: dropdowndata[0].value });
      }
    }
    /* 	if (!selectedCamp) {
			this.setState({ selectedCamp: dropdowndata[0].value });
			return <Page.Loader></Page.Loader>;
		} */

    let graphdata = chartData(
      start_date,
      end_date,
      selectedDate,
      selectedCamp,
      t,
      showcount,
      show_session,
      show_click,
      show_total_selling,
      show_close,
      newAnalyticDate,
      "bar"
    );
    let graphdataLine = chartData(
      start_date,
      end_date,
      selectedDate,
      selectedCamp,
      t,
      showcount,
      show_session,
      show_click,
      show_total_selling,
      show_close,
      newAnalyticDate,
      "line"
    );
    return (
      <div className="myContainer scroll">
        <Segment>
          <Form>
            <Form.Group>
              <Form.Field>{t("Action Durumu")}:</Form.Field>
              <Form.Checkbox
                label={t("Active")}
                name="showActive"
                checked={showActive}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                label={t("Disabled")}
                name="showPasif"
                checked={showPasif}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                label={t("Deleted")}
                name="showDeleted"
                checked={showDeleted}
                onChange={this.toggle}
              ></Form.Checkbox>
            </Form.Group>
            <Form.Group>
              <Form.Field>{t("Variables")}:</Form.Field>
              <Form.Checkbox
                name="show_session"
                checked={show_session}
                label={t("Oturum Sayısı")}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                name="showcount"
                checked={showcount}
                label={t("Tetiklenme Sayısı")}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                name="show_click"
                checked={show_click}
                label={t("Tıklama Sayısı")}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                name="show_total_selling"
                checked={show_total_selling}
                label={t("Tetiklenme-Satış Oranı")}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                name="show_click_selling"
                checked={show_click_selling}
                label={t("Tıklama-Satış Oranı")}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                name="show_click_the_selling"
                checked={show_click_the_selling}
                label={t("Tıklama-Seçili Ürün Satış Oranı")}
                onChange={this.toggle}
              ></Form.Checkbox>
              <Form.Checkbox
                name="show_close"
                checked={show_close}
                label={t("Kapatılan")}
                onChange={this.toggle}
              ></Form.Checkbox>
            </Form.Group>
          </Form>
        </Segment>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Durum")}</Table.HeaderCell>
              <Table.HeaderCell>{t("İsim")}</Table.HeaderCell>
              {show_session && (
                <Table.HeaderCell>{t("Oturum Sayısı")}</Table.HeaderCell>
              )}
              {showcount && (
                <Table.HeaderCell>{t("Tetiklenme Sayısı")}</Table.HeaderCell>
              )}
              {show_total_selling && (
                <Table.HeaderCell width={2}>
                  <Popup
                    content={t("PopupSellingRate")}
                    trigger={<Icon name="question circle outline"></Icon>}
                  ></Popup>
                  {t("Toplam-Satış Oranı")}
                </Table.HeaderCell>
              )}
              {show_click && (
                <Table.HeaderCell>{t("Tıklama Sayısı")}</Table.HeaderCell>
              )}
              {show_click_selling && (
                <Table.HeaderCell width={2}>
                  <Popup
                    content={t("PopupClickRate")}
                    trigger={<Icon name="question circle outline"></Icon>}
                  ></Popup>
                  {t("Tıklama-Satış Oranı")}
                </Table.HeaderCell>
              )}
              {show_click_the_selling && (
                <Table.HeaderCell width={2}>
                  <Popup
                    content={t("PopupTheClickRate")}
                    trigger={<Icon name="question circle outline"></Icon>}
                  ></Popup>
                  {t("Tıklama-Seçili Ürün Satış Oranı")}
                </Table.HeaderCell>
              )}
              {show_close && (
                <Table.HeaderCell>{t("Kapatma Sayısı")}</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(tablList, function (it) {
              return (
                <Table.Row>
                  <Table.Cell>
                    {it.deleted
                      ? "Silinmiş"
                      : it.status === "active"
                      ? t("Active")
                      : t("Disabled")}
                  </Table.Cell>
                  <Table.Cell width={4} style={{ padding: 0 }}>
                    <Table className="inTable" attached={true} celled>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell className="border">{it.name}</Table.Cell>
                        </Table.Row>
                        {it._key === 82 && (
                          <Table.Row>
                            <Table.Cell className="border">
                              {"‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‏‏‎ ‎‎- Yüklenme"}
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {/* <Table.Row>
													<Table.Cell className="border">{'‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‏‏‎ ‎‎- Gösterim'}</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell className="border">{'‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎- Kontrol Grubu'}</Table.Cell>
												</Table.Row> */}
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                  {show_session && (
                    <Table.Cell style={{ padding: 0 }}>
                      <Table className="inTable" attached={true} celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className="border">
                              {(it.campaignShown_total_session || 0) +
                                (it.campaignControl_total_session || 0)}
                            </Table.Cell>
                          </Table.Row>
                          {it._key === 82 && (
                            <Table.Row>
                              <Table.Cell className="border">
                                {it.campaignShown_total_session || 0}
                              </Table.Cell>
                            </Table.Row>
                          )}

                          {/* <Table.Row>
														<Table.Cell className="border">
															{(it._key === 82 ? it.campaignVisible_total_session : it.campaignShown_total_session) || 0}
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="border">{it.campaignControl_total_session || 0}</Table.Cell>
													</Table.Row> */}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  )}
                  {showcount && (
                    <Table.Cell style={{ padding: 0 }}>
                      <Table className="inTable" attached={true} celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className="border">
                              {(it.campaignShown_total_count || 0) +
                                (it.campaignControl_total_count || 0)}
                            </Table.Cell>
                          </Table.Row>
                          {it._key === 82 && (
                            <Table.Row>
                              <Table.Cell className="border">
                                {it.campaignShown_total_count || 0}
                              </Table.Cell>
                            </Table.Row>
                          )}
                          {/* <Table.Row>
														<Table.Cell className="border">
															{(it._key === 82 ? it.campaignVisible_total_count : it.campaignShown_total_count) || 0}
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="border">{it.campaignControl_total_count || 0}</Table.Cell>
													</Table.Row> */}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  )}

                  {show_total_selling && (
                    <Table.Cell style={{ padding: 0 }}>
                      <Table className="inTable" attached={true} celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className="border"
                              style={{ display: "flex" }}
                            >
                              <p>
                                {(it.campaignShown_count_with_order || 0) +
                                  (it.campaignControl_count_with_order || 0)}
                              </p>
                              <p
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                              >
                                {((it.campaignShown_total_count ||
                                  it.campaignControl_total_count) &&
                                  (
                                    (((it.campaignShown_count_with_order || 0) +
                                      (it.campaignControl_count_with_order ||
                                        0)) /
                                      ((it.campaignShown_total_count || 0) +
                                        (it.campaignControl_total_count ||
                                          0))) *
                                    100
                                  ).toFixed(2) + "%") ||
                                  "0%"}
                              </p>
                            </Table.Cell>
                          </Table.Row>
                          {it._key === 82 && (
                            <Table.Row>
                              <Table.Cell
                                className="border"
                                style={{ display: "flex" }}
                              >
                                <p>{it.campaignShown_count_with_order || 0}</p>
                                <p
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "10px",
                                  }}
                                >
                                  {(it.campaignShown_total_count &&
                                    (
                                      (it.campaignShown_count_with_order /
                                        it.campaignShown_total_count) *
                                      100
                                    ).toFixed(2) + "%") ||
                                    "0%"}
                                </p>
                              </Table.Cell>
                            </Table.Row>
                          )}
                          {/* <Table.Row>
														<Table.Cell className="border" style={{ display: 'flex' }}>
															<p>
																{(it._key === 82
																	? _.max([it.campaignVisible_count_with_order, it.tagClick_count_with_order])
																	: it.campaignShown_count_with_order) || 0}
															</p>
															{it._key === 82 ? (
																<p style={{ marginLeft: 'auto', marginRight: '10px' }}>
																	{(it.campaignVisible_total_count &&
																		(
																			(_.max([it.campaignVisible_count_with_order, it.tagClick_count_with_order]) /
																				it.campaignVisible_total_count) *
																			100
																		).toFixed(2) + '%') ||
																		'0%'}
																</p>
															) : (
																<p style={{ marginLeft: 'auto', marginRight: '10px' }}>
																	{(it.campaignShown_total_count &&
																		((it.campaignShown_count_with_order / it.campaignShown_total_count) * 100).toFixed(2) +
																			'%') ||
																		'0%'}
																</p>
															)}
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="border" style={{ display: 'flex' }}>
															<p>{it.campaignControl_count_with_order || 0}</p>
															<p style={{ marginLeft: 'auto', marginRight: '10px' }}>
																{(it.campaignControl_total_count &&
																	(
																		((it.campaignControl_count_with_order || 0) / it.campaignControl_total_count) *
																		100
																	).toFixed(2) + '%') ||
																	'0%'}
															</p>
														</Table.Cell>
													</Table.Row> */}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  )}
                  {show_click && (
                    <Table.Cell style={{ padding: 0 }}>
                      <Table className="inTable" attached={true} celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className="border">
                              {it.tagClick_total_count || 0}
                            </Table.Cell>
                          </Table.Row>
                          {it._key === 82 && (
                            <Table.Row>
                              <Table.Cell className="border">
                                {it.tagClick_total_count || 0}
                              </Table.Cell>
                            </Table.Row>
                          )}
                          {/* <Table.Row>
														<Table.Cell className="border">{it.tagClick_total_count || 0}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="border">-</Table.Cell>
													</Table.Row> */}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  )}
                  {show_click_selling && (
                    <Table.Cell style={{ padding: 0 }}>
                      <Table className="inTable" attached={true} celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className="border"
                              style={{ display: "flex" }}
                            >
                              <p>{it.tagClick_count_with_order || 0}</p>
                              <p
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                              >
                                {(it.tagClick_total_count &&
                                  (
                                    ((it.tagClick_count_with_order || 0) /
                                      it.tagClick_total_count) *
                                    100
                                  ).toFixed(2) + "%") ||
                                  "0%"}
                              </p>
                            </Table.Cell>
                          </Table.Row>
                          {it._key === 82 && (
                            <Table.Row>
                              <Table.Cell
                                className="border"
                                style={{ display: "flex" }}
                              >
                                <p>{it.tagClick_count_with_order || 0}</p>
                                <p
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "10px",
                                  }}
                                >
                                  {(it.tagClick_total_count &&
                                    (
                                      ((it.tagClick_count_with_order || 0) /
                                        it.tagClick_total_count) *
                                      100
                                    ).toFixed(2) + "%") ||
                                    "0%"}
                                </p>
                              </Table.Cell>
                            </Table.Row>
                          )}
                          {/* <Table.Row>
														<Table.Cell className="border" style={{ display: 'flex' }}>
															<p>{it.tagClick_count_with_order || 0}</p>
															<p style={{ marginLeft: 'auto', marginRight: '10px' }}>
																{(it.tagClick_total_count &&
																	(((it.tagClick_count_with_order || 0) / it.tagClick_total_count) * 100).toFixed(2) + '%') ||
																	'0%'}
															</p>
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="border">-</Table.Cell>
													</Table.Row> */}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  )}
                  {show_click_the_selling && (
                    <Table.Cell style={{ padding: 0 }}>
                      <Table className="inTable" attached={true} celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className="border"
                              style={{ display: "flex" }}
                            >
                              <p>
                                {(it.campaignShown_count_the_with_order || 0) +
                                  (it.campaignControl_count_the_with_order ||
                                    0)}
                              </p>
                              <p
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                              >
                                {(it.tagClick_total_count &&
                                  (
                                    (((it.campaignShown_count_the_with_order ||
                                      0) +
                                      (it.campaignControl_count_the_with_order ||
                                        0)) /
                                      it.tagClick_total_count) *
                                    100
                                  ).toFixed(2) + "%") ||
                                  "0%"}
                              </p>
                            </Table.Cell>
                          </Table.Row>
                          {it._key === 82 && (
                            <Table.Row>
                              <Table.Cell
                                className="border"
                                style={{ display: "flex" }}
                              >
                                <p>
                                  {it.campaignShown_count_the_with_order || 0}
                                </p>
                                <p
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "10px",
                                  }}
                                >
                                  {(it.tagClick_total_count &&
                                    (
                                      ((it.campaignShown_count_the_with_order ||
                                        0) /
                                        it.tagClick_total_count) *
                                      100
                                    ).toFixed(2) + "%") ||
                                    "0%"}
                                </p>
                              </Table.Cell>
                            </Table.Row>
                          )}
                          {/* <Table.Row>
														{it._key === 82 ? (
															<Table.Cell className="border" style={{ display: 'flex' }}>
																<p>{it.campaignVisible_count_the_with_order || 0}</p>
																<p style={{ marginLeft: 'auto', marginRight: '10px' }}>
																	{(it.tagClick_total_count &&
																		(
																			((it.campaignVisible_count_the_with_order || 0) / it.tagClick_total_count) *
																			100
																		).toFixed(2) + '%') ||
																		'0%'}
																</p>
															</Table.Cell>
														) : (
															<Table.Cell className="border" style={{ display: 'flex' }}>
																<p>{it.campaignShown_count_the_with_order || 0}</p>
																<p style={{ marginLeft: 'auto', marginRight: '10px' }}>
																	{(it.tagClick_total_count &&
																		(
																			((it.campaignShown_count_the_with_order || 0) / it.tagClick_total_count) *
																			100
																		).toFixed(2) + '%') ||
																		'0%'}
																</p>
															</Table.Cell>
														)}
													</Table.Row>
													<Table.Row>
														<Table.Cell className="border">-</Table.Cell>
													</Table.Row> */}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  )}
                  {show_close && (
                    <Table.Cell style={{ padding: 0 }}>
                      <Table className="inTable" attached={true} celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className="border">
                              {it.tagCloseClick_total_count || 0}
                            </Table.Cell>
                          </Table.Row>
                          {it._key === 82 && (
                            <Table.Row>
                              <Table.Cell className="border">
                                {it.tagCloseClick_total_count || 0}
                              </Table.Cell>
                            </Table.Row>
                          )}
                          {/* <Table.Row>
														<Table.Cell className="border">{it.tagCloseClick_total_count || 0}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="border">-</Table.Cell>
													</Table.Row> */}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        {dropdowndata && dropdowndata.length ? (
          <ChartWrap
            className="aaa"
            width={6}
            style={{ width: "80%" }}
            title={t("Graph")}
            content={
              <Dropdown
                options={dropdowndata}
                selection
                defaultValue={dropdowndata[0].value}
                icon="filter"
                labeled
                button
                value={selectedCamp}
                className="icon"
                onChange={this.handleFilterChange}
                style={{ alignItems: "center", display: "flex" }}
              />
            }
          >
            <Bar data={graphdata} options={barOptions} />
            <Line data={graphdataLine} options={barOptions} />
          </ChartWrap>
        ) : (
          ""
        )}
      </div>
    );
  }
}
var getDateArray = function (start, end) {
  var arr = [],
    dt = new Date(start);

  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }

  return arr;
};
var getHourArray = function (start, end) {
  var arr = [],
    dt = 0;

  while (dt < 24) {
    arr.push(dt);
    dt++;
  }
  return arr;
};
function chartData(
  start_date,
  end_date,
  selectedDate,
  selectedCamp,
  t,
  showcount,
  show_session,
  show_click,
  show_total_selling,
  show_close,
  newAnalyticDate,
  type
) {
  let startDate = new Date(start_date);
  let endDate = new Date(end_date);
  let labels = [];
  var dateArr = getDateArray(startDate, endDate);
  var hourArr = getHourArray();
  let gdata = newAnalyticDate["" + selectedCamp];
  /* _.map(gdata, function (it) {
		return (it.day = moment(it.date));
	}); */
  let ngdata = gdata;
  let graphdata = [];
  _.map(ngdata, function (it) {
    let e = _.findIndex(graphdata, function (o) {
      return o.day === it.day;
    });
    let gd = {
      day: it.day,
      [it.type + "_count_with_order"]: _.parseInt(it.count_with_order || 0),
      [it.type + "_count_with_the_order"]: _.parseInt(
        it.count_with_the_order || 0
      ),
      [it.type + "_total_count"]: _.parseInt(it.total_count || 0),
      [it.type + "_total_session"]: _.parseInt(it.total_session || 0),
      [it.type + "_unique_user"]: _.parseInt(it.unique_user || 0),
    };
    if (e >= 0) {
      _.assign(graphdata[e], gd);
    } else {
      graphdata.push(gd);
    }
  });
  let shownSessionLine = [],
    controlSessionLine = [],
    totalSessionLine = [],
    shownTotalLine = [],
    controlTotalLine = [],
    totalTotalLine = [],
    shownClickLine = [],
    shownSellingLine = [],
    controlSellingLine = [],
    totalSellingLine = [],
    closedLine = [],
    sellingTotalRate = [],
    sellingTotalRateShown = [],
    sellingTotalRateControl = [],
    sellingClickRate = [],
    theSellingClickRate = [],
    theSellingClickRateShown = [],
    sellingClickRateShown = [];
  ///{(it.campaignShown_total_count &&((it.campaignShown_count_with_order / it.campaignShown_total_count) * 100).toFixed(2) + '%') ||'0%'}
  _.map(graphdata, function (it) {
    sellingTotalRateShown.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        (it.campaignShown_total_count &&
          (
            (it.campaignShown_count_with_order / it.campaignShown_total_count) *
            100
          ).toFixed(2)) ||
        0,
      type: t("Gösterim-Satış/Toplam"),
    });
    sellingTotalRateControl.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        (it.campaignControl_total_count &&
          (
            (it.campaignControl_count_with_order /
              it.campaignControl_total_count) *
            100
          ).toFixed(2)) ||
        0,
      type: t("Kontrol-Satış/Toplam"),
    });
    sellingTotalRate.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        ((it.campaignControl_total_count || it.campaignShown_total_count) &&
          (
            (((it.campaignControl_count_with_order || 0) +
              (it.campaignShown_count_with_order || 0)) /
              ((it.campaignShown_total_count || 0) +
                (it.campaignControl_total_count || 0))) *
            100
          ).toFixed(2)) ||
        0,
      type: t("Toplam-Satış/Toplam"),
    });
    sellingClickRateShown.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        (it.tagClick_total_count &&
          (
            (it.tagClick_count_with_order / it.tagClick_total_count) *
            100
          ).toFixed(2)) ||
        0,
      type: t("Gösterim-Satış/Tıklama"),
    });
    sellingClickRate.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        (it.tagClick_total_count &&
          (
            ((it.tagClick_count_with_order || 0) / it.tagClick_total_count) *
            100
          ).toFixed(2)) ||
        0,
      type: t("Toplam-Satış/Tıklama"),
    });
    theSellingClickRateShown.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        (it.tagClick_total_count &&
          (
            (it.campaignShown_count_the_with_order / it.tagClick_total_count) *
            100
          ).toFixed(2)) ||
        0,
      type: t("Gösterim-Seçili Satış/Tıklama"),
    });
    theSellingClickRate.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        (it.tagClick_total_count &&
          (
            (((it.campaignShown_count_the_with_order || 0) +
              (it.campaignControl_count_the_with_order || 0)) /
              it.tagClick_total_count) *
            100
          ).toFixed(2)) ||
        0,
      type: t("Toplam-Seçili Satış/Tıklama"),
    });
    shownSessionLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.campaignShown_total_session),
      type: t("Gösterim-Oturum"),
    });
    controlSessionLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.campaignControl_total_session),
      type: t("Kontrol-Oturum"),
    });
    totalSessionLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        parseInt(it.campaignControl_total_session) +
        parseInt(it.campaignShown_total_session),
      type: t("Toplam-Oturum"),
    });
    shownTotalLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.campaignShown_total_count),
      type: t("Gösterim-Tetiklenme Sayı"),
    });
    controlTotalLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.campaignControl_total_count),
      type: t("Kontrol-Tetiklenme Sayı"),
    });
    totalTotalLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        parseInt(it.campaignControl_total_count) +
        parseInt(it.campaignShown_total_count),
      type: t("Toplam-Tetiklenme Sayı"),
    });
    shownClickLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.tagClick_total_count),
      type: t("Toplam-Tıklama"),
    });
    shownSellingLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.campaignShown_count_with_order),
      type: t("Gösterim-Satış"),
    });
    controlSellingLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.campaignControl_count_with_order),
      type: t("Kontrol-Satış"),
    });
    totalSellingLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count:
        parseInt(it.campaignControl_count_with_order) +
        parseInt(it.campaignShown_count_with_order),
      type: t("Toplam-Satış"),
    });
    closedLine.push({
      day: it.day,
      dayNumber: moment(it.day).dayOfYear(),
      count: parseInt(it.tagCloseClick_total_count),
      type: t("Kapatılan"),
    });
  });
  let newdata = [];
  if (type === "bar") {
    newdata = [
      shownSessionLine,
      controlSessionLine,
      totalSessionLine,
      shownTotalLine,
      controlTotalLine,
      totalTotalLine,
      shownClickLine,
      shownSellingLine,
      controlSellingLine,
      totalSellingLine,
      closedLine,
    ];
  } else {
    newdata = [
      sellingTotalRateShown,
      sellingTotalRateControl,
      sellingTotalRate,
      sellingClickRateShown,
      sellingClickRate,
      theSellingClickRateShown,
      theSellingClickRate,
    ];
  }
  if (selectedDate === "daily") {
    _.map(newdata, function (o) {
      if (o && o.length)
        return _.each(dateArr, function (d, i) {
          if (_.findIndex(o, { dayNumber: moment(d).dayOfYear() }) < 0) {
            o.push({
              day: moment(d).format(),
              dayNumber: moment(d).dayOfYear(),
              count: 0,
              type: o[0].type,
            });
          }
        });
    });
  } else {
    _.map(newdata, function (o) {
      _.map(o, function (it, index) {
        return _.each(hourArr, function (d, i) {
          if (_.findIndex(it, { hour: d }) === -1) {
            o.push({
              day: o[0].day,
              hour: d,
              dayNumber: o[0].dayNumber,
              count: 0,
              type: o[0].type,
            });
          }
        });
      });
    });
  }

  if (selectedDate === "daily") {
    labels = _.map(dateArr, (it) => moment(it).format("DD-MMM"));
  } else {
    labels = hourArr;
  }
  let i = 0;
  let datasets = [];
  if (newdata[0] && newdata[0].length) {
    datasets = _.map(newdata, function (it) {
      if (selectedDate === "daily") {
        it = _.sortBy(it, ["dayNumber", "asc"]);
      } else {
        it = _.sortBy(it, ["hour", "asc"]);
      }
      let isHidden = "";
      switch (it[0].type) {
        case "Gösterim-Oturum":
          isHidden = show_session;
          break;
        case "Kontrol-Oturum":
          isHidden = show_session;
          break;
        case "Toplam-Oturum":
          isHidden = show_session;
          break;
        case "Gösterim-Tetiklenme Sayı":
          isHidden = showcount;
          break;
        case "Kontrol-Tetiklenme Sayı":
          isHidden = showcount;
          break;
        case "Toplam-Tetiklenme Sayı":
          isHidden = showcount;
          break;
        case "Toplam Tıklama":
          isHidden = show_click;
          break;
        case "Gösterim-Satış":
          isHidden = show_total_selling;
          break;
        case "Kontrol-Satış":
          isHidden = show_total_selling;
          break;
        case "Toplam-Satış":
          isHidden = show_total_selling;
          break;
        case "Kapatılan":
          isHidden = show_close;
          break;
        default:
          isHidden = true;
      }
      let color = colors2[i++ % colors2.length];
      return {
        label: it[0].type,
        day: _.map(it, (item) => moment(item.day, "DD-MM").format()),
        data: _.map(it, (item) => item.count),
        borderColor: color,
        backgroundColor: color,
        fill: false,
        hidden: !isHidden,
      };
    });
  }
  return {
    datasets,
    labels,
  };
}
export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(Analytic));
