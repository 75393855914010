import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "tr",
    supportedLngs: ["tr", "en"],
    nonExplicitSupportedLngs: true,
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: true },
  });

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get("lang");

// Dil ayarını kontrol et
if (lang) {
  i18n.changeLanguage(lang).then(() => console.log(`Language set to ${lang}`));
}

export default i18n;
