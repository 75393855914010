import React, { Component } from "react";
import {
  Container,
  Segment,
  Icon,
  Image,
  Step,
  Input,
  Button,
  Divider,
  Header,
  Label,
  Loader,
} from "semantic-ui-react";
import Page from "../../components/Page";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { post } from "../../util/api";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
      camp_id: "",
      copied: "Kopyala",
      link: "",
      checkUrl: "",
      edit: false,
      shareLink: "",
      fullDomain: "",
      fullDomains: "",
      loading: false,
      isInf:
        this.props &&
        this.props.access &&
        this.props.access.user &&
        this.props.access.user.loggedUserObj &&
        this.props.access.user.loggedUserObj.extra &&
        this.props.access.user.loggedUserObj.extra.inf_id,
    };
  }

  async componentDidMount() {
    let { isInf } = this.state;
    let { id } = this.props.product.personalize;
    let body = { id: id };
    fetch("api/personalize/checkInflucer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        let response = data;
        let url = response && response.result;
        if (!isInf) {
          this.setState({ active: "Influencer Id" });
        }
        if (isInf && url && url.length > 0) {
          this.setState({ active: "Link Oluştur" });
          let pureHttps = url.match(
            /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/gim
          );
          let yc = url.split("yc")[1];
          let finalShareLink = pureHttps + "/influencer?" + "yc" + yc;
          let ci = yc.split("&ci=")[1];
          this.setState({
            shareLink: finalShareLink,
            camp_id: ci,
            checkUrl: url,
            fullDomains: url.split("&yc")[0],
          });
        } else {
          this.setState({ active: "Kampanya Id" });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  handleClick = async (e, { title }) => {
    let { isInf, camp_id } = this.state;
    if (isInf && title !== "Influencer Id" && title !== "Link Oluştur")
      this.setState({ active: title });
    else if (
      isInf &&
      title !== "Influencer Id" &&
      title === "Link Oluştur" &&
      camp_id
    )
      this.setState({ active: title });
  };

  onChange = (e, { name, value }) => {
    let { isInf } = this.state;
    if (isInf && name != "fullDomains") this.setState({ [name]: value });
    else if (name === "fullDomains") {
      this.setState({ [name]: value });
    }
  };

  createLink = async () => {
    let { link, isInf, camp_id } = this.state;
    link = link.split("?")[0];
    let created_link = link.trim();
    let after = created_link + `?yc=${isInf}&ci=${camp_id}`;
    this.setState({ created_link: after });
  };

  createProductLink = async () => {
    let { isInf, camp_id, fullDomain } = this.state;
    // console.clear();
    this.setState({ loading: true });
    let mainLink = fullDomain.trim();
    if (mainLink.indexOf("?") != -1) {
      mainLink = `${mainLink}&yc=${isInf}&ci=${camp_id}`;
    } else {
      mainLink = `${mainLink}?yc=${isInf}&ci=${camp_id}`;
    }
    if (mainLink) {
      let { id } = this.props.product.personalize;
      let after = mainLink;
      await post("/personalize/createinflucer", { id, after });
      let mainDomain = mainLink.match(
        /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/gim
      );
      let sLink = `${mainDomain}/influencer?yc=${isInf}&ci=${camp_id}`;
      let url = sLink;
      this.setState({
        createLink: sLink,
        shareLink: sLink,
        checkUrl: url,
        fullDomains: after.split("&yc")[0],
        loading: false,
      });
    }
  };
  changeProductLink = async () => {
    // console.clear();
    let { fullDomains, isInf, camp_id } = this.state;
    this.setState({ loading: true });
    if (fullDomains.length > 0) {
      let copyFullDomains = fullDomains;
      let firstPie = copyFullDomains.split(".com")[0] + ".com";
      let secondPie = copyFullDomains.split(".com")[1];
      if (secondPie.indexOf("?") != -1) {
        secondPie = `${secondPie}&yc=${isInf}&ci=${camp_id}`;
      } else {
        secondPie = `${secondPie}?yc=${isInf}&ci=${camp_id}`;
      }

      let { id } = this.props.product.personalize;
      let after = firstPie + secondPie;
      let response = await post("/personalize/createinflucer", { id, after });
      let mainDomain = after.match(
        /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/gim
      );
      let sLink = `${mainDomain}/influencer?yc=${isInf}&ci=${camp_id}`;
      let url = sLink;
      this.setState({
        createLink: sLink,
        shareLink: sLink,
        checkUrl: url,
        edit: false,
        loading: false,
      });
    }
  };
  copiedText = () => {
    this.setState({ copied: "Kopyalandı" });
    let { created_link, shareLink } = this.state;
    shareLink && shareLink.length > 0
      ? navigator.clipboard.writeText(shareLink)
      : navigator.clipboard.writeText(created_link);
    setTimeout(() => {
      this.setState({ copied: "Kopyala" });
    }, 1500);
  };
  deleteCampaign = async (url) => {
    this.setState({ loading: true });
    let { id } = this.props.product.personalize;
    let response = await post("/personalize/deleceteCampaignInfluencer", {
      id,
    });
    this.setState({
      checkUrl: "",
      shareLink: "",
      createLink: "",
      camp_id: "",
      active: "Kampanya Id",
      loading: false,
    });
  };

  watchEdit = async () => {
    this.setState({ edit: true });
  };
  render() {
    let { t } = this.props;
    let {
      active,
      isInf,
      camp_id,
      link,
      created_link,
      copied,
      checkUrl,
      edit,
      shareLink,
      fullDomain,
      fullDomains,
      loading,
    } = this.state;
    return (
      <Page title={t("help-inf")} icon="pencil alternate">
        <Page.Wrap>
          <Container>
            <Segment textAlign="center">
              <Step.Group>
                <Step
                  active={active === "Influencer Id"}
                  icon={isInf ? "thumbs up outline" : "x"}
                  link
                  style={{
                    background: isInf ? "#4BB543" : "#D0342C",
                    color: "white",
                  }}
                  onClick={this.handleClick}
                  title="Influencer Id"
                  description={isInf ? "" : ""}
                />
                <Step
                  active={active === "Kampanya Id"}
                  icon="clipboard outline"
                  link
                  onClick={this.handleClick}
                  style={{
                    background: camp_id ? "#4BB543" : "#D0342C",
                    color: "white",
                  }}
                  title="Kampanya Id"
                  description="Kampanya idnizi oluşturun!"
                />
                <Step
                  active={active === "Link Oluştur"}
                  icon="linkify"
                  link
                  onClick={this.handleClick}
                  title="Link Oluştur"
                  style={{
                    background:
                      checkUrl && checkUrl.length > 0 ? "#4BB543" : "#f3f4f5",
                  }}
                  description="Link oluşturun!"
                />
              </Step.Group>
              {active == "Influencer Id" && isInf && (
                <div>
                  Influencer idniz: <b>{isInf}</b>
                </div>
              )}
              {active == "Influencer Id" && !isInf && (
                <div>
                  Influencer idniz bulunamamaktadır. Lütfen bizimle iletişime
                  geçiniz!
                </div>
              )}
              {active == "Kampanya Id" && (
                <>
                  <div>
                    Daha önceden çalıştığınız firmalarda kullanmadığınız
                    kampanya idsi oluşturmalısınız!
                  </div>
                  <div>
                    <Input
                      placeholder="A12CV2"
                      name="camp_id"
                      value={camp_id}
                      onChange={this.onChange}
                    />
                  </div>
                </>
              )}
              {active == "Link Oluştur" &&
                ((isInf && camp_id) || (checkUrl && checkUrl.length > 0)) && (
                  <>
                    <div style={{ width: "100%" }}>
                      {checkUrl && checkUrl.length > 0 ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "20px",
                            }}
                          >
                            {edit ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Label
                                  content={"full Domain"}
                                  style={{
                                    marginTop: "10px",
                                    textAlign: "left",
                                  }}
                                />
                                {loading === false ? (
                                  <Input
                                    placeholder={
                                      "https://www.loccomoda.com/Arama?1&kelime=28814,28813,28812,28811,28810,28809"
                                    }
                                    name="fullDomains"
                                    value={fullDomains}
                                    onChange={this.onChange}
                                  />
                                ) : (
                                  <Segment
                                    style={{
                                      paddingTop: "20px",
                                      paddingBottom: "20px",
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Loader active />
                                  </Segment>
                                )}
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Segment>
                                    <Header
                                      as="h3"
                                      style={{ textAlign: "left" }}
                                    >{`Influencer ID: ${isInf}`}</Header>
                                  </Segment>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Label
                                    content={"full Domain"}
                                    style={{
                                      marginTop: "10px",
                                      textAlign: "left",
                                    }}
                                  />
                                  <Input
                                    placeholder={
                                      "https://www.loccomoda.com/Arama?1&kelime=28814,28813,28812,28811,28810,28809"
                                    }
                                    name="fullDomain"
                                    value={checkUrl.split("&yc")[0]}
                                    disabled
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            )}

                            {loading === false ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "10px",
                                }}
                              >
                                {edit === true && fullDomains !== checkUrl ? (
                                  <Button
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                    icon={"save"}
                                    color={"blue"}
                                    content={"Kaydet"}
                                    onClick={() => this.changeProductLink()}
                                  />
                                ) : (
                                  <Button
                                    onClick={() => this.watchEdit()}
                                    icon={"edit"}
                                    color={"green"}
                                    content={"düzenle"}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                                {edit === true ? (
                                  <Button
                                    icon={"cancel"}
                                    content={"iptal"}
                                    color={"red"}
                                    onClick={() =>
                                      this.setState({ edit: false })
                                    }
                                  />
                                ) : (
                                  <Button
                                    onClick={() =>
                                      this.deleteCampaign(checkUrl)
                                    }
                                    icon={"trash"}
                                    color={"red"}
                                    content={"sil"}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <Segment
                                style={{
                                  paddingTop: "20px",
                                  paddingBottom: "20px",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                }}
                              >
                                {" "}
                                <Loader active />
                              </Segment>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            Paylaşacağınız linki buraya yapıştırmalısınız.
                          </div>
                          {loading == false ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <Label
                                content={"full Domain"}
                                style={{ marginTop: "10px", textAlign: "left" }}
                              />
                              <Input
                                placeholder={
                                  "https://www.loccomoda.com/Arama?1&kelime=28814,28813,28812,28811,28810,28809"
                                }
                                name="fullDomain"
                                value={fullDomain}
                                onChange={this.onChange}
                              />
                              {fullDomain && fullDomain.length > 0 ? (
                                <Button
                                  style={{ marginTop: "10px" }}
                                  onClick={() => this.createProductLink()}
                                >
                                  Ürün liste linki oluştur
                                </Button>
                              ) : (
                                <Button style={{ marginTop: "10px" }} disabled>
                                  Ürün liste linki oluştur
                                </Button>
                              )}
                            </div>
                          ) : (
                            <Segment
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "10px",
                              }}
                            >
                              {" "}
                              <Loader active />
                            </Segment>
                          )}
                        </div>
                      )}
                    </div>
                    {shareLink && loading == false && (
                      <>
                        <div style={{ margin: 10 }}>{shareLink}</div>
                        <Button
                          content={copied}
                          color="blue"
                          onClick={() => {
                            this.copiedText();
                          }}
                        />
                        <div style={{ margin: 10 }}>
                          Kopyalamış olduğunuz linki paylaşmalısınız. Bu şekilde
                          satış takibi yapılabilmektedir
                        </div>
                      </>
                    )}
                  </>
                )}
            </Segment>
          </Container>
        </Page.Wrap>
      </Page>
    );
  }
}

const stateToProps = (state) => ({
  access: state.access,
  product: state.product,
  location: state.router.location,
});

export default connect(stateToProps)(withTranslation()(Contact));
