import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Breadcrumb,
  Label,
  Button,
  Icon,
  Modal,
  Header,
  Popup,
  Dropdown,
  List,
  Divider,
  Form,
} from "semantic-ui-react";
import Page from "../../components/Page";
import { get, post } from "../../util/api";
import { Link, Redirect } from "react-router-dom";
import _, { parseInt } from "lodash";
import Slider from "react-slick";
import TagList from "./taglist.json";
//import moment from "moment";
//import { Calendar } from "react-date-range";
import Countries from "./countries.json";

//import Lightbox from 'react-image-lightbox';
import ImageGallery from "react-image-gallery";
import { withTranslation } from "react-i18next";
import "moment/locale/en-in";
import "moment/locale/tr";
import Parser from "html-react-parser";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";

const ActionCard = (props) => {
  let { data } = props;
  return (
    <List.Item
      active={props.active}
      as={Link}
      to={"/campaigns/" + data.id}
      style={{ fontSize: "18px", display: "flex", alignItems: "center" }}
    >
      <Label
        color={data.status === "active" ? "green" : "gray"}
        key={data.status}
        style={{ marginRight: "10px" }}
      >
        {data.status === "active" ? "Aktif" : "Pasif"}
      </Label>
      <List.Content>
        <List.Header>{data.name}</List.Header>
      </List.Content>
    </List.Item>
  );
};
class Templates extends Component {
  state = {
    loading: true,
    tid: new URLSearchParams(window.location.search).get("id"),
    imageModal: false,
    open: false,
    request_open: false,
    developer: (localStorage && localStorage.developer) || false,
    open_go_action: false,
    install_more_open: false,
    companyName:
      (this.props.user.extra &&
        this.props.user.extra.company &&
        this.props.user.extra.company.name) ||
      "",
    vatNumber:
      (this.props.user.extra &&
        this.props.user.extra.company &&
        this.props.user.extra.company.vatNumber) ||
      "",
    companyCountry:
      (this.props.user.extra &&
        this.props.user.extra.company &&
        this.props.user.extra.company.country) ||
      "",
    companyCity:
      (this.props.user.extra &&
        this.props.user.extra.company &&
        this.props.user.extra.company.city) ||
      "",
    companyState:
      (this.props.user.extra &&
        this.props.user.extra.company &&
        this.props.user.extra.company.state) ||
      "",
    companyPostCode:
      (this.props.user.extra &&
        this.props.user.extra.company &&
        this.props.user.extra.company.postcode) ||
      "",
    companyStreet:
      (this.props.user.extra &&
        this.props.user.extra.company &&
        this.props.user.extra.company.street) ||
      "",
  };
  componentDidMount() {
    this.getSubs();
    this.getSurvey();
    this.getData();
  }
  handleFormChange = (e, { name, value }) => this.setState({ [name]: value });
  async getData() {
    if (this.state.tid) {
      await get(
        `/campaign/templateV2/${this.state.tid}?type=campaign&developer=` +
          this.state.developer
      ).then((it) => {
        console.log("it ", it.data);
        this.setState({
          list: it.data,
          loading: false,
          selectedAction: it.data.installed.length && it.data.installed[0].id,
        });
      });
    }
  }
  postRequest = (action) => {
    this.setState({ btnloading: true });
    let email = this.props.user.email;
    let type = this.props.product.type;
    post("/survey/add", {
      data: {
        email,
        type,
        action,
      },
    }).then((data) => {
      if (data.data.success) {
        this.setState({
          btnloading: false,
          message: "Katılımınız için teşekkür ederiz, talebiniz alınmıştır.",
          btndisabled: true,
        });
      } else {
        this.setState({ btnloading: false, message: "Error" });
      }
    });
  };

  checkoutHandler = async (
    quantity,
    custom_price,
    category,
    limit,
    daily_limit,
    tier,
    isVat
  ) => {
    this.setState({ btnloading: true });
    this.postData({
      companyName: this.state.companyName,
      vatNumber: this.state.vatNumber,
      country: this.state.companyCountry,
      city: this.state.companyCity,
      state: this.state.companyState,
      street: this.state.companyStreet,
      postcode: this.state.companyPostCode,
    });
    //return;
    let custom_discount =
      this.props.product &&
      this.props.product.settings &&
      this.props.product.settings.custom_discount;
    if (custom_discount) {
      custom_price = custom_price * custom_discount;
    }
    let link = await post("/payment/custompaddlelink", {
      plan_id: 768866,
      email: this.props.user.email,
      pid: this.props.product.id,
      category,
      custom_price, //change
      isVat,
      vat_number: this.state.vatNumber,
      vat_company_name: this.state.companyName,
      vat_street: this.state.companyStreet,
      vat_city: this.state.companyCity,
      vat_country: this.state.companyCountry,
      country: this.state.companyCountry || "BR",
      postcode: this.state.companyPostCode,
      vat_state: this.state.companyState,
      vat_postcode: this.state.companyPostCode,
    });
    //this.setState({ btnloading: false });
    try {
      let { product = {} } = this.props;
      let email = this.props.user.email;
      //let country = 'BR';
      if (product && product.id) {
        window.Paddle.Checkout.open({
          override: link.data.response.url,
          email,
          country: this.state.companyCountry || "BR",
          quantity,
          postcode: this.state.companyPostCode,
          passthrough: {
            pid: product.id,
            categoryId: category,
            limit,
            daily_limit,
            tier: "tier_" + tier,
          },
          successCallback: async (data) => {
            post("/payment/checkSubscription", {
              checkout_id: data.checkout.id,
              plan_id: data.product.id,
              user_id: data.user.id,
              pid: this.props.product.id,
              quantity: data.product.quantity,
              email: data.user.email,
              extra: data,
            })
              .then(async (resp) => {
                console.log(resp);
              })
              .catch((err) => {
                console.error(err);
              });
          },
          closeCallback: () => {
            this.setState({
              messageOnScreen:
                "Your process has been cancelled, see you again soon!",
              messageColor: "red",
              btnloading: false,
            });
          },
        });
      }
      this.refresh();
    } catch (err) {
      console.error(err);
    }
    this.setState({ purchaseModal: false });
    this.refresh();
  };

  updateAfterAmuntEntered = (value) => async () => {
    //let planId = parseInt(value.plan_id, 10);
    let {
      payment_status,
      selected_price,
      selected_id,
      //ownApp,
      selected_tier,
      selected_limit,
      selected_daily_limit,
    } = this.state;
    post("/payment/updatePrice", {
      pid: this.props.product.id,
      subscription_id: value.subscription_id,
      user_id: value.user_id,
      price: value.price,
      categoryId: selected_id,
      payment_status,
      selected_price,
      tier: selected_tier,
      limit: selected_limit,
      daily_limit: selected_daily_limit,
    }).then(async (data) => {
      if (data.data.success) {
        this.getSubs();
        this.setState({
          messageOnScreen: "Update process is successful!",
          messageColor: "green",
        });
      } else {
        this.setState({
          messageOnScreen: "Update process cannot be applied!",
          messageColor: "red",
        });
      }
    });
    this.close();
    this.refresh();
  };
  openChangePlanModalPreview = async (
    subscription_id,
    price,
    selected_id,
    tier,
    payment_status,
    ownApp,
    limit,
    daily_limit
  ) => {
    let { subscriptions } = this.state;
    let activeSub = _.find(
      subscriptions,
      (it) =>
        it.data.subscription_plan_id === "768866" ||
        it.data.subscription_plan_id === "custom"
    );
    let currency_price =
      (activeSub &&
        activeSub.data &&
        parseFloat(
          activeSub.data.next_payment_amount ||
            activeSub.data.new_price ||
            activeSub.data.unit_price
        )) ||
      0;
    let custom_discount =
      this.props.product &&
      this.props.product.settings &&
      this.props.product.settings.custom_discount;
    let custom_price_with_discount = price;
    if (custom_discount) {
      custom_price_with_discount = parseFloat(price) * custom_discount;
    }

    let total_price = Math.max(currency_price + custom_price_with_discount, 0);
    this.setState({
      selected_id,
      selected_price: custom_price_with_discount,
      selected_tier: tier,
      selected_limit: limit,
      selected_daily_limit: daily_limit,
    });

    await post("/payment/checkpreview?asd", {
      subscription_id,
      price: total_price, //change
      //plan_id: 768866,
    }).then((data) => {
      if (data.data.success) {
        this.setState({
          previewdata: data.data.response,
          previewModal: true,
          selectedSubcriptionId: subscription_id,
          payment_status,
          ownApp,
          total_price, //change
        });
      } else {
        this.setState({
          messageOnScreen: data.data.message.message,
          messageColor: "red",
        });
      }
    });
  };
  async getSurvey() {
    get("/survey/user").then((data) => {
      this.setState({ survey: data.result });
    });
  }
  createPremiumSub(
    price,
    category,
    tier,
    status,
    last_sub,
    limit,
    daily_limit
  ) {
    post("/payment/upsertpremium", {
      pid: this.props.product.id,
      price,
      last_price: price,
      category,
      tier,
      status,
      last_sub,
      limit,
      daily_limit,
    }).then(() => this.refresh());
  }

  updatePremiumSub(
    price,
    category,
    tier,
    status,
    last_sub,
    limit,
    daily_limit
  ) {
    let last_price = last_sub.price - price;
    post("/payment/upsertpremium", {
      pid: this.props.product.id,
      price,
      last_price,
      category,
      tier,
      status,
      last_sub,
      limit,
      daily_limit,
    }).then(() => this.refresh());
  }
  close = () =>
    this.setState({
      open: false,
      request_open: false,
      open_go_action: false,
      install_more_open: false,
      previewModal: false,
    });
  handleRemove = (cid) => {
    this.setState({ remove: true });
    post("/campaign/delete", {
      id: cid,
    }).then(() => {
      this.close();
      this.getData();
    });
  };
  closeModal = () => {
    this.setState({ purchaseModal: false });
  };
  onChange = (e, { value, name }) => {
    this.setState({ [name]: value });
  };
  refresh() {
    setTimeout(() => {
      this.getSubs();
      this.getSurvey();
      this.getData();
    }, 1000);
  }
  createTrial =
    ({ category, limit = 20000, daily_limit, trial_day = 7 }) =>
    async () => {
      this.setState({ trialButtonLoadingApi: true });
      let { product, user } = this.props;
      let pid = product.id;
      await post("/payment/createFreeTrial", {
        pid: pid,
        category,
        limit,
        daily_limit,
        trial_day,
      }).then((data) => {
        if (data.data.success) {
          post("/footprint/add", {
            category: "collectaction",
            label: "checkout-start",
            extra: {
              pid,
              uid: user.id,
              plan: "trial",
            },
          }).then(() => this.refresh());
        } else {
          this.refresh();
        }
      });
    };

  async getSubs() {
    let pid = this.props.product.id;
    let { tid } = this.state;
    let [subscriptionsData, subLog] = await Promise.all([
      post("/personalize/getsubscription", { pid }),
      post("/personalize/getLogs", { pid, category: this.state.tid }),
    ]);
    let last_sub = _.maxBy(subLog.data.data, "id");
    let subscriptions = subscriptionsData.data.subscriptions;

    let checkAction = subLog && subLog.data && subLog.data.data;
    let res = _.find(checkAction, (o) => {
      return o.category === tid;
    });

    this.setState({
      subscriptions,
      subLog,
      last_sub,
      isBought: res ? true : false,
    });
  }

  postData(values) {
    let extra = _.merge(this.props.user.extra, {
      company: {
        name: values.companyName,
        vatNumber: values.vatNumber,
        country: values.country,
        state: values.state,
        city: values.city,
        street: values.street,
        postcode: values.postcode,
      },
    });
    post("/auth/updateInfo", {
      id: this.props.user.id,
      company: values.companyName,
      extra,
    });
  }
  render() {
    let {
      loading,
      list,
      tid,
      imageModal,
      photoIndex,
      open,
      request_open,
      selectedAction,
      open_go_action,
      install_more_open,
      survey,
      subscriptions,
      vatNumber,
      companyCity,
      companyCountry,
      companyName,
      companyPostCode,
      companyState,
      companyStreet,
      selectedPackage,
      isBought,
    } = this.state;
    if (!tid) {
      return <Redirect to="/" />;
    }
    let { product, t } = this.props;
    let language = t("lang");
    let isActiveSub = _.find(
      subscriptions,
      (it) => it.data.status === "active" || it.data.status === "trial"
    );
    let ecommerceType = product && product.type;
    let open_payment = true;
    if (loading) {
      return (
        <Page
          header={
            <Breadcrumb size="big">
              <Breadcrumb.Section as={Link} to={`/`}>
                <Icon name="arrow left"></Icon>
                {t("Actions")}
              </Breadcrumb.Section>
            </Breadcrumb>
          }
        >
          <Page.Loader></Page.Loader>
        </Page>
      );
    }
    let isAccess = _.findIndex(survey, function (it) {
      return it && it.id === list.id;
    });

    /* let mocPrc = [
			{
				name: 'start',
				price: '9',
				limit: 1000,
				extra: ['support', 'extra1'],
			},
			{
				name: 'basic',
				price: '19',
				limit: 10000,
				extra: ['support', 'extra1', 'extra2'],
			},
			{
				name: 'pro',
				price: '29',
				limit: 100000,
				extra: ['support', 'extra1', 'extra3', 'extra3'],
			},
		]; */
    let temp = list;
    let canInstall =
      temp && temp.config && (temp.config[ecommerceType] || temp.config.base);
    let images =
      temp.settings &&
      temp.settings.images[language] &&
      temp.settings.images[language].length
        ? temp.settings.images[language]
        : [];
    temp.rate = temp.vote_count ? temp.vote_amount / temp.vote_count : 0;
    let imagelist =
      images && images.length
        ? _.map(images, function (it) {
            if (/cdnydm/.test(it)) {
              return {
                original: it,
                thumbnail: it,
              };
            }
            return {
              thumbnail: `https://img.youtube.com/vi/${it}/0.jpg`,
              original: `https://img.youtube.com/vi/${it}/0.jpg`,
              embedUrl: `https://www.youtube.com/embed/${it}`,
              renderItem: _renderVideo.bind(this),
            };
          })
        : [];
    let pricing =
      (temp &&
        temp.settings &&
        temp.settings.pricing &&
        temp.settings.pricing[language]) ||
      [];

    /* pricing.push({
      name: "100.000 GÖSTERIM",
      extra: ["Tüm Yorumlar", "Page Load Özelliği"],
      limit: "",
      price: "19",
      hidden: true,
    }); */
    if (!product.settings.show_hidden_price) {
      pricing = _.filter(pricing, (it) => !it.hidden);
    }
    if (temp.settings.data.length <= 0) {
      canInstall = false;
    }

    let limit = list.type === "schedule" ? 99 : list.limit || 1;
    const settings = {
      speed: 500,
      slidesToShow: _.minBy([2, parseInt(imagelist.length)]),
      slidesToScroll: 1,
      initialSlide: 0,

      responsive: [
        {
          breakpoint: 1700,
          settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 0 },
        },
        {
          breakpoint: 1300,
          settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 0 },
        },
        {
          breakpoint: 480,
          settings: { slidesToShow: 1, slidesToScroll: 1 },
        },
      ],
    };
    let isContact = false;
    let custom_discount =
      this.props.product &&
      this.props.product.settings &&
      this.props.product.settings.custom_discount;
    let filterList = TagList;
    if (ecommerceType === "flo") {
      filterList = [
        {
          text: "Flo",
          key: "flo",
          value: "flo",
          color: "blue",
        },
      ];
    }
    function _toggleShowVideo(url) {
      let obj = this.state.showVideo;
      obj[url] = !this.state.showVideo[url];
      this.setState({
        showVideo: obj,
      });

      if (this.state.showVideo[url]) {
        if (this.state.showPlayButton) {
          this.setState({ showGalleryPlayButton: false });
        }

        if (this.state.showFullscreenButton) {
          this.setState({ showGalleryFullscreenButton: false });
        }
      }
    }
    function _renderVideo(item) {
      return (
        <div>
          <div className="video-wrapper">
            <div
              className="close-video"
              onClick={_toggleShowVideo.bind(this, item.embedUrl)}
            />
            <iframe
              id="video"
              title="video"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      );
    }
    let temptype = temp.type === "schedule" ? "campaigns" : "actions";
    return (
      <Page
        header={
          <Breadcrumb size="big">
            <Breadcrumb.Section as={Link} to={`/`}>
              <Icon name="arrow left"></Icon>
              {t("Actions")}
            </Breadcrumb.Section>
          </Breadcrumb>
        }
      >
        <Segment className="templateSlider">
          <div
            className="header-box"
            style={{ marginBottom: "10px", marginTop: "20px" }}
          >
            <div className="image-side">
              <img
                src={temp.settings.image[language]}
                alt={temp.settings.image[language]}
                lazy="true"
              />
            </div>
            <div className="text-side" style={{ marginTop: "2px" }}>
              <h1 style={{ marginTop: "2px" }}>
                {temp.settings.title[language]}
              </h1>
              <div>
                <img
                  src="/images/star.svg"
                  alt="star"
                  width={14}
                  style={{ marginRight: "5px" }}
                ></img>
                <span>{temp.rate || 3.2}</span>
              </div>
              <p className="only-web">{temp.settings.description[language]}</p>
              <br />
              {console.log("temp ", temp, limit)}
              <div className="only-web">
                {isContact ? (
                  <div>
                    <Button
                      color={"teal"}
                      style={{ marginTop: "10px", minWidth: "120px" }}
                      as={Link}
                      to={"/help"}
                    >
                      {t("ContactUs")}
                    </Button>
                    <p style={{ marginTop: "3px" }}>{t("takeInfo")}</p>
                  </div>
                ) : temp.installed && temp.installed.length ? (
                  <div>
                    {temp.installed.length > 1 ? (
                      <Button
                        color={"teal"}
                        style={{ marginTop: "10px", minWidth: "120px" }}
                        onClick={() => this.setState({ open_go_action: true })}
                      >
                        {t("GotoAction")}
                      </Button>
                    ) : (
                      <Button
                        color={"teal"}
                        style={{ marginTop: "10px", minWidth: "120px" }}
                        as={Link}
                        to={"/" + temptype + "/" + temp.installed[0].id}
                      >
                        {t("GotoAction")}
                      </Button>
                    )}
                    {temp.installed.length < limit && (
                      <Button
                        color={"green"}
                        style={{ marginTop: "10px", minWidth: "120px" }}
                        onClick={() =>
                          this.setState({ install_more_open: true })
                        }
                      >
                        {t("InstallMore")}
                      </Button>
                    )}
                    <Button
                      color={"red"}
                      style={{ marginTop: "10px", minWidth: "120px" }}
                      onClick={() => this.setState({ open: true })}
                    >
                      {t("Uninstall")}
                    </Button>
                  </div>
                ) : canInstall ? (
                  <Button
                    color={"teal"}
                    style={{ marginTop: "10px", minWidth: "120px" }}
                    as={Link}
                    to={
                      temp.data && temp.data.asistant
                        ? "/bot"
                        : "/" + temptype + "/create/template-" + temp.id
                    }
                  >
                    {t("Install")}
                  </Button>
                ) : (
                  <Popup
                    content={
                      isAccess >= 0
                        ? t("dontInstallActionTrue")
                        : t("dontInstallAction")
                    }
                    position="bottom left"
                    trigger={
                      isAccess >= 0 ? (
                        <Button
                          color={"green"}
                          style={{ marginTop: "10px", minWidth: "120px" }}
                        >
                          {t("requestAccessTrue")}
                        </Button>
                      ) : (
                        <Button
                          color={"blue"}
                          style={{ marginTop: "10px", minWidth: "120px" }}
                          onClick={() => this.setState({ request_open: true })}
                        >
                          {t("requestAccess")}
                        </Button>
                      )
                    }
                  />
                )}
                <br />
                {/* {temp.pricing && temp.pricing[0] ? (
									temp.pricing[0].price && temp.pricing[0].price > 0 ? (
										<p className="price-text" style={{ marginTop: '10px' }}>
											{t('14Free')} {t('startWithPrice', { price: temp.pricing[0].price })} <a href="#pricing">{t('prices')}</a>
										</p>
									) : (
										<p className="price-text" style={{ marginTop: '10px' }}>
											{t('Free')}.
										</p>
									)
								) : (
									''
								)} */}
              </div>
            </div>
          </div>
          <div className="only-mobile">
            <p>{temp.settings.description[language]}</p>
            {isContact ? (
              <div>
                <Button
                  color={"teal"}
                  style={{ marginTop: "10px" }}
                  as={Link}
                  to={"/help"}
                >
                  {t("ContactUs")}
                </Button>
                <p style={{ marginTop: "3px" }}>{t("takeInfo")}</p>
              </div>
            ) : temp.installed && temp.installed.length ? (
              <div>
                <Button
                  fluid
                  color={"teal"}
                  style={{ marginTop: "10px" }}
                  as={Link}
                  to={"/campaigns/" + temp.installed[0].id}
                >
                  {t("GotoAction")}
                </Button>
                {temp.installed.length < limit && (
                  <Button
                    fluid
                    color={"green"}
                    style={{ marginTop: "10px" }}
                    onClick={() => this.setState({ install_more_open: true })}
                  >
                    {t("InstallMore")}
                  </Button>
                )}
                <Button
                  fluid
                  color={"red"}
                  style={{ marginTop: "10px" }}
                  onClick={() => this.setState({ open: true })}
                >
                  {t("Uninstall")}
                </Button>
              </div>
            ) : canInstall ? (
              <Button
                fluid
                color={"teal"}
                style={{ marginTop: "10px" }}
                as={Link}
                to={
                  temp.data && temp.data.asistant
                    ? "/bot"
                    : "/" + temptype + "/create/template-" + temp.id
                }
              >
                {t("Install")}
              </Button>
            ) : (
              <div>
                <Button
                  color={"blue"}
                  style={{ marginTop: "10px" }}
                  onClick={() => this.setState({ request_open: true })}
                >
                  {t("requestAccess")}
                </Button>
                <p>{t("dontInstallAction")}</p>
              </div>
            )}
            <br />
            {/* {temp.pricing && temp.pricing[0] ? (
							temp.pricing[0].price && temp.pricing[0].price > 0 ? (
								<p className="price-text" style={{ marginTop: '10px' }}>
									{t('14Free')} {t('startWithPrice', { price: temp.pricing[0].price })} <a href="#pricing">{t('prices')}.</a>
								</p>
							) : (
								<p className="price-text" style={{ marginTop: '10px' }}>
									{t('Free')}.
								</p>
							)
						) : (
							''
						)} */}
          </div>
          <hr className="slider-hr"></hr>
          {images && images.length ? (
            <div className="slider_div">
              <Slider {...settings}>
                {_.map(images, (it, i) => (
                  <div
                    key={i}
                    onClick={() =>
                      this.setState({ imageModal: true, photoIndex: i })
                    }
                  >
                    <img
                      className={"template-images"}
                      src={
                        /cdnydm/.test(it)
                          ? it
                          : `https://img.youtube.com/vi/${it}/0.jpg`
                      }
                      alt={it}
                    />
                    <div
                      className={
                        "play-youtube-button" +
                        (/cdnydm/.test(it) ? " hide" : " show")
                      }
                    >
                      <img src="/icons/youtube.svg" alt="" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            ""
          )}
          <div className="long-description">
            {temp.settings.long_description &&
              temp.settings.long_description[language] &&
              Parser(temp.settings.long_description[language])}

            {/* <p>{temp.description}</p>
							<p>{temp.description}</p> */}
          </div>
          <div className="tags">
            {_.map(filterList, function (it, i) {
              if (temp.config[it.key]) {
                return (
                  <Label color={it.color} key={i}>
                    {it.text}
                  </Label>
                );
              }
            })}
          </div>
          {pricing && pricing.length > 0 && <hr className="slider-hr"></hr>}
          {pricing && pricing.length > 0 && (
            <div id="pricing" className="pricing">
              <h1 className="p-title">
                {t("Fiyatlandırma")}{" "}
                <div style={{ color: "#637381", fontSize: "16px" }}>
                  {t("7trial")}
                </div>
              </h1>
              <div className="price-card-part">
                {_.map(pricing, (it, i) => (
                  <div className="price-card" key={i}>
                    {/* <h2>{_.upperCase(it.name)}</h2> */}
                    <h2>{it && it.name && it.name.toUpperCase()}</h2>
                    <h1>
                      {parseInt(it.price) < 0
                        ? t("Free")
                        : "$" +
                          (custom_discount
                            ? (parseInt(it.price) * custom_discount).toFixed(2)
                            : it.price)}
                      {parseInt(it.price) > 0 && <span>/{t("month")}</span>}
                    </h1>
                    <ul className="extra">
                      <li>
                        {parseInt(it.limit.length) === 0 ? (
                          ""
                        ) : (
                          <img
                            className="check"
                            src="/icons/check.svg"
                            alt="check.svg"
                          ></img>
                        )}
                        {temp.id === 96 ? (
                          <p>
                            {it.limit
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                            {t("product")}
                          </p>
                        ) : (
                          <p>
                            {/* {parseInt(it.limit) < 0 ? t("Limitsiz") : it.limit}{" "}
                          {parseInt(it.limit.length)===0 ? ("eşit") : ("depil") } */}
                            {parseInt(it.limit.length) === 0
                              ? ""
                              : parseInt(it.limit) < 0
                              ? t("Limitsiz")
                              : it.limit
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                            {parseInt(it.limit.length) === 0
                              ? ""
                              : temp.id === 46
                              ? t("Konuşma")
                              : t("Gösterim")}
                          </p>
                        )}
                      </li>
                      {_.map(it.extra, (o, oi) => (
                        <li key={oi}>
                          <img
                            className="check"
                            src="/icons/check.svg"
                            alt="check.svg"
                          ></img>
                          <p>{o}</p>
                        </li>
                      ))}
                    </ul>
                    {open_payment ||
                    (localStorage.test_payment &&
                      localStorage.test_payment === "true") ? (
                      <div>
                        {this.state.last_sub &&
                        this.state.last_sub.label !== "deleted" ? (
                          i ===
                          parseInt(
                            this.state.last_sub.extra.tier.replace("tier_", "")
                          ) ? (
                            product.settings.isPremium ? (
                              <Button
                                negative
                                onClick={() =>
                                  this.createPremiumSub(
                                    -_.max([parseInt(it.price), 0]),
                                    tid,
                                    "tier_" + i,
                                    "deleted",
                                    this.state.last_sub,
                                    it.limit,
                                    it.daily_limit
                                  )
                                }
                                loading={this.state.btnloading}
                                fluid
                              >
                                {t("unsubscribe")}
                              </Button>
                            ) : isActiveSub ? (
                              <Button
                                negative
                                onClick={() =>
                                  this.openChangePlanModalPreview(
                                    isActiveSub.charge_id,
                                    -_.max([parseInt(it.price), 0]),
                                    tid,
                                    "tier_" + i,
                                    "deleted",
                                    this.state.last_sub,
                                    it.limit,
                                    it.daily_limit
                                  )
                                }
                                loading={this.state.btnloading}
                                fluid
                              >
                                {t("unsubscribe")}
                              </Button>
                            ) : (
                              <Button
                                primary
                                onClick={() =>
                                  this.setState({
                                    purchaseModal: true,
                                    selectedPackage: {
                                      price: _.max([parseInt(it.price), 0]),
                                      temp_id: temp.id,
                                      limit: it.limit,
                                      daily_limit: it.daily_limit,
                                      order: i,
                                    },
                                  })
                                }
                                loading={this.state.btnloading}
                                fluid
                              >
                                {t("subscribe")}
                              </Button>
                            )
                          ) : this.state.last_sub.label === "trial" ? (
                            isActiveSub && isActiveSub.trial !== true ? (
                              <Button
                                primary
                                onClick={() =>
                                  this.openChangePlanModalPreview(
                                    isActiveSub.charge_id,
                                    _.max([parseInt(it.price), 0]),
                                    tid,
                                    "tier_" + i,
                                    "create",
                                    this.state.last_sub,
                                    it.limit,
                                    it.daily_limit
                                  )
                                }
                                loading={this.state.btnloading}
                                fluid
                              >
                                {t("subscribeasd")}
                              </Button>
                            ) : (
                              <Button
                                primary
                                onClick={() =>
                                  this.setState({
                                    purchaseModal: true,
                                    selectedPackage: {
                                      price: _.max([parseInt(it.price), 0]),
                                      temp_id: temp.id,
                                      limit: it.limit,
                                      daily_limit: it.daily_limit,
                                      order: i,
                                    },
                                  })
                                }
                                loading={this.state.btnloading}
                                fluid
                              >
                                {t("subscribe")}
                              </Button>
                            )
                          ) : product.settings.isPremium ? (
                            <Button
                              primary
                              onClick={() =>
                                this.updatePremiumSub(
                                  _.max([parseInt(it.price), 0]),
                                  tid,
                                  "tier_" + i,
                                  "create",
                                  this.state.last_sub,
                                  it.limit,
                                  it.daily_limit
                                )
                              }
                              loading={this.state.btnloading}
                              fluid
                            >
                              {t("subscribe")}
                            </Button>
                          ) : isActiveSub ? (
                            <Button
                              primary
                              onClick={() =>
                                this.openChangePlanModalPreview(
                                  isActiveSub.charge_id,
                                  _.max([
                                    parseInt(it.price) -
                                      this.state.last_sub.price,
                                    0,
                                  ]),
                                  tid,
                                  "tier_" + i,
                                  "create",
                                  this.state.last_sub,
                                  it.limit,
                                  it.daily_limit
                                )
                              }
                              loading={this.state.btnloading}
                              fluid
                            >
                              {t("subscribe")}
                            </Button>
                          ) : (
                            <Button
                              primary
                              onClick={() =>
                                this.setState({
                                  purchaseModal: true,
                                  selectedPackage: {
                                    price: _.max([parseInt(it.price), 0]),
                                    temp_id: temp.id,
                                    limit: it.limit,
                                    daily_limit: it.daily_limit,
                                    order: i,
                                  },
                                })
                              }
                              loading={this.state.btnloading}
                              fluid
                            >
                              {t("subscribe")}
                            </Button>
                          )
                        ) : product.settings.isPremium ? (
                          <Button
                            primary
                            onClick={() =>
                              this.createPremiumSub(
                                _.max([parseInt(it.price), 0]),
                                tid,
                                "tier_" + i,
                                "create",
                                this.state.last_sub,
                                it.limit,
                                it.daily_limit
                              )
                            }
                            loading={this.state.btnloading}
                            fluid
                          >
                            {t("subscribe")}
                          </Button>
                        ) : (
                          <div>
                            <Button
                              positive
                              onClick={this.createTrial({
                                category: temp.id,
                                limit:
                                  pricing && pricing[0] && pricing[0].limit,
                                daily_limit:
                                  (pricing &&
                                    pricing[0] &&
                                    pricing[0].daily_limit) ||
                                  parseInt(pricing[0].limit) / 20,
                                trial_day: it.trial_day,
                              })}
                              fluid
                              loading={this.state.trialButtonLoadingApi}
                              style={{
                                paddingRight: "12px !important",
                                paddingLeft: "12px !important",
                              }}
                            >
                              {t("XTrialDays", { day: 7 })}
                            </Button>
                            {isActiveSub &&
                            isActiveSub.data.subscription_plan_id !==
                              "trial" ? (
                              <div style={{ textAlign: "right" }}>
                                <Button
                                  //onClick={() => this.checkoutHandler(1, it.price, temp.id, it.limit, it.daily_limit, i)}
                                  onClick={() =>
                                    this.openChangePlanModalPreview(
                                      isActiveSub.charge_id,
                                      _.max([parseInt(it.price), 0]),
                                      tid,
                                      "tier_" + i,
                                      "create",
                                      this.state.last_sub,
                                      it.limit,
                                      it.daily_limit
                                    )
                                  }
                                  style={{
                                    fontSize: "11px",
                                    color: "black",
                                    textDecorationLine: "underline",
                                    background: "white",
                                    padding: "0",
                                  }}
                                >
                                  {t("skipTrial")}
                                </Button>
                              </div>
                            ) : (
                              <div style={{ textAlign: "right" }}>
                                <Button
                                  //onClick={() => this.checkoutHandler(1, it.price, temp.id, it.limit, it.daily_limit, i)}
                                  onClick={() =>
                                    this.setState({
                                      purchaseModal: true,
                                      selectedPackage: {
                                        price: _.max([parseInt(it.price), 0]),
                                        temp_id: temp.id,
                                        limit: it.limit,
                                        daily_limit: it.daily_limit,
                                        order: i,
                                      },
                                    })
                                  }
                                  style={{
                                    fontSize: "11px",
                                    color: "black",
                                    textDecorationLine: "underline",
                                    background: "white",
                                    padding: "0",
                                  }}
                                >
                                  {t("skipTrial")}
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {!this.state.last_sub ? (
                          <Button
                            positive
                            onClick={this.createTrial({
                              category: temp.id,
                              limit: pricing && pricing[0] && pricing[0].limit,
                              daily_limit:
                                (pricing &&
                                  pricing[0] &&
                                  pricing[0].daily_limit) ||
                                parseInt(pricing[0].limit) / 20,
                              trial_day: it.trial_day || 7,
                            })}
                            fluid
                            loading={this.state.trialButtonLoadingApi}
                            style={{
                              paddingRight: "12px !important",
                              paddingLeft: "12px !important",
                            }}
                          >
                            {t("XTrialDays", { day: 14 })}
                          </Button>
                        ) : (
                          <Button
                            primary
                            as="a"
                            href="/help"
                            fluid
                            style={{
                              paddingRight: "12px !important",
                              paddingLeft: "12px !important",
                            }}
                          >
                            {t("ContactUs")}
                          </Button>
                        )}
                      </div>
                    )}
                    {/* TODO PAYMENT */}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Segment>
        {isActiveSub && (
          <Modal
            open={this.state.previewModal}
            onClose={this.close}
            style={{ maxWidth: "700px" }}
          >
            <Header icon="exchange" content={t("updateSubscription")} />
            <Modal.Content>
              <div style={{ display: "flex" }}>
                <div style={{ margin: "auto" }}>
                  <h3>{t("immediatePayment")}:</h3>
                  <p>
                    {t("amount")}:{" "}
                    {this.state.previewdata &&
                      this.state.previewdata.immediate_payment.amount + " $"}
                  </p>
                  <p>
                    {t("date")}:{" "}
                    {this.state.previewdata &&
                      this.state.previewdata.immediate_payment.date}
                  </p>
                </div>
                <div style={{ margin: "auto" }}>
                  <h3>{t("nextPayment")}:</h3>
                  <p>
                    {t("amount")}:{" "}
                    {this.state.previewdata &&
                      this.state.previewdata.next_payment.amount + " $"}
                  </p>
                  <p>
                    {t("date")}:{" "}
                    {this.state.previewdata &&
                      this.state.previewdata.next_payment.date}
                  </p>
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions style={{ textAlign: "left" }}>
              <div style={{ display: "flex" }}>
                <h3 style={{ marginBottom: "auto", marginTop: "auto" }}>
                  {t("ruSureChange")}
                </h3>
                <Button
                  color="green"
                  style={{ marginLeft: "auto" }}
                  onClick={this.updateAfterAmuntEntered({
                    subscription_id: isActiveSub.data.subscription_id,
                    user_id: isActiveSub.data.user_id,
                    price: this.state.total_price,
                  })}
                >
                  <Icon name="checkmark" /> {t("yes")}
                </Button>
                <Button color="red" onClick={this.close}>
                  <Icon name="x" /> {t("close")}
                </Button>
              </div>
            </Modal.Actions>
          </Modal>
        )}
        <Modal
          content={
            <ImageGallery
              items={imagelist}
              showThumbnails={false}
              showPlayButton={false}
              startIndex={photoIndex}
              showFullscreenButton={false}
            />
          }
          size="large"
          open={imageModal}
          style={{ width: "95%" }}
          onClose={() => this.setState({ imageModal: false })}
          closeIcon
        ></Modal>
        <Modal open={open} onClose={this.close}>
          <Header icon="trash" content={`${t("Delete")}`} />
          <Modal.Content>
            <p>{t("deleteSelectAction")}</p>
            <Dropdown
              options={_.map(temp.installed, function (it) {
                return {
                  key: it.id,
                  text: it.name,
                  value: it.id,
                  icon: it.status === "active" ? "check" : "close",
                };
              })}
              className="long-dropdown"
              selection
              name="selectedAction"
              value={selectedAction}
              onChange={this.onChange}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="grey" onClick={this.close}>
              <Icon name="close" /> {t("Cancel")}
            </Button>
            <Button
              loading={this.state.remove}
              color="red"
              onClick={() => this.handleRemove(selectedAction)}
            >
              <Icon name="trash" /> {t("Delete")}
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={request_open} onClose={this.close}>
          <Header icon="check" content={`${t("requestAccess")}`} />
          <Modal.Content>
            <p>{t("requestAccessText")}</p>
            <p>{this.state.message}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={this.close}
              disabled={this.state.btndisabled}
            >
              <Icon name="close" /> {t("no")}
            </Button>
            <Button
              loading={this.state.btnloading}
              color="green"
              onClick={() => this.postRequest(temp.title)}
              disabled={this.state.btndisabled}
            >
              <Icon name="checkmark" /> {t("yes")}
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={open_go_action} onClose={this.close}>
          <Header icon="arrow right" content={`${t("GotoAction")}`} />
          <Modal.Content
            scrolling
            css={`
              padding: 0px !important;
            `}
          >
            <List divided selection verticalAlign="middle">
              {_.map(temp.installed, (it, i) => (
                <ActionCard
                  key={i}
                  data={it}
                  active={it.id === this.state._var}
                />
              ))}
            </List>
          </Modal.Content>
        </Modal>
        <Modal open={install_more_open} onClose={this.close}>
          <Header content={`${t("InstallMore")}`} />
          <Modal.Content>
            <p>{t("InstallMoreCurrent", { current: temp.installed.length })}</p>
            <p>{t("InstallMoreLimit", { limit: limit })}</p>
            <p>{t("InstallMoreText")}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.close}>
              <Icon name="close" /> {t("no")}
            </Button>
            <Button
              color={"green"}
              style={{ marginTop: "10px" }}
              as={Link}
              to={
                temp.data && temp.data.asistant
                  ? "/bot"
                  : "/" + temptype + "/create/template-" + temp.id
              }
            >
              <Icon name="checkmark" /> {t("yes")}
            </Button>
            {/* <Button
								loading={this.state.btnloading}
								color="green"
								onClick={() => this.postRequest(temp.title)}
								disabled={this.state.btndisabled}
							>
								<Icon name="checkmark" /> {t('yes')}
							</Button> */}
          </Modal.Actions>
        </Modal>
        {/*purchase Modal*/}
        <Modal
          open={this.state.purchaseModal}
          onClose={() => this.setState({ purchaseModal: false })}
          style={{ maxWidth: "500px" }}
          history
        >
          <Header icon="exchange" content={t("buy")} />
          <Modal.Content scrolling>
            <div>
              <div style={{ margin: "auto", marginBottom: "20px" }}>
                <p>{t("taxSettings")}</p>
                <Button
                  primary
                  onClick={() =>
                    this.checkoutHandler(
                      1,
                      selectedPackage.price,
                      selectedPackage.temp_id,
                      selectedPackage.limit,
                      selectedPackage.daily_limit,
                      selectedPackage.order,
                      false
                    )
                  }
                >
                  {t("noCompany")}
                </Button>
              </div>
              <Divider />
              <Form
                onSubmit={() =>
                  this.checkoutHandler(
                    1,
                    selectedPackage.price,
                    selectedPackage.temp_id,
                    selectedPackage.limit,
                    selectedPackage.daily_limit,
                    selectedPackage.order,
                    true
                  )
                }
              >
                <Form.Input
                  label={t("taxNumber")}
                  placeholder={t("enterTaxNumber")}
                  required
                  name="vatNumber"
                  value={vatNumber}
                  onChange={this.handleFormChange}
                ></Form.Input>
                <Form.Input
                  label={t("CompanyName")}
                  required
                  name="companyName"
                  placeholder={t("enterCompanyName")}
                  value={companyName}
                  onChange={this.handleFormChange}
                ></Form.Input>
                <Form.Input
                  label={t("street")}
                  required
                  name="companyStreet"
                  value={companyStreet}
                  placeholder={t("enterStreet")}
                  onChange={this.handleFormChange}
                ></Form.Input>
                <Form.Input
                  label={t("city")}
                  required
                  value={companyCity}
                  name="companyCity"
                  placeholder={t("enterCity")}
                  onChange={this.handleFormChange}
                ></Form.Input>
                <Form.Input
                  label={t("state")}
                  value={companyState}
                  name="companyState"
                  placeholder={t("enterState")}
                  onChange={this.handleFormChange}
                ></Form.Input>
                <Form.Dropdown
                  label={t("country")}
                  placeholder="Ülke Seçin"
                  search
                  required
                  autoComplete="off"
                  fluid
                  name={t("enterCountry")}
                  value={companyCountry}
                  options={Countries}
                  selection
                  onChange={this.handleFormChange}
                ></Form.Dropdown>
                <Form.Input
                  label={t("postCode") + " (**)"}
                  placeholder={t("enterPostCode")}
                  value={companyPostCode}
                  required={_.includes(
                    [
                      "AU",
                      "CA",
                      "DE",
                      "ES",
                      "FR",
                      "GB",
                      "IN",
                      "IT",
                      "NL",
                      "US",
                    ],
                    this.state.country
                  )}
                  name="companyPostCode"
                  onChange={this.handleFormChange}
                ></Form.Input>
                <Form.Group>
                  <Form.Button
                    style={{ marginLeft: "auto" }}
                    positive
                    type="submit"
                  >
                    <Icon name="checkmark" /> {t("Next")}
                  </Form.Button>
                  <Form.Button color="red" onClick={this.closeModal}>
                    <Icon name="x" /> {t("Close")}
                  </Form.Button>
                </Form.Group>
              </Form>
              <span>{t("mustPostCode")}</span>
            </div>
          </Modal.Content>
        </Modal>
      </Page>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(Templates));
