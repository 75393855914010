import React, { Component } from "react";
import Page from "../../components/Page";
import {
  Message,
  Icon,
  Button,
  Table,
  Modal,
  Segment,
  Header,
  Input,
  Statistic,
  Container,
} from "semantic-ui-react";
/* eslint-disable no-unused-expressions */
import { log } from "../../util/api";

import _ from "lodash";
import "moment/locale/en-in";
import "moment/locale/tr";
import moment from "moment";
//import cs from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
//import admin from "../admin";

const ResultTable = ({ list, getThis }) => {
  return (
    <Table style={{ textAlign: "center" }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="1">{"ID"}</Table.HeaderCell>
          <Table.HeaderCell width="1">{"Influencer ID"}</Table.HeaderCell>
          <Table.HeaderCell width="1">{"Kampanya ID"}</Table.HeaderCell>
          <Table.HeaderCell width="2">{"Sipariş Kodu"}</Table.HeaderCell>
          <Table.HeaderCell width="3">
            {"Sipariş Oluşturulma Zamanı"}
          </Table.HeaderCell>
          <Table.HeaderCell width="2">
            {"Sipariş Ürün Fiyat Toplamı"}
          </Table.HeaderCell>
          <Table.HeaderCell width="2">{"Sipariş Detayı"}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(list, (dat, i) => {
          return (
            <Table.Row key={i} style={{ textAlign: "center" }}>
              <Table.Cell>{dat.id}</Table.Cell>
              <Table.Cell>{dat.inf_id}</Table.Cell>
              <Table.Cell>{dat.camp_id || " - "}</Table.Cell>

              <Table.Cell>{dat.order_code}</Table.Cell>
              <Table.Cell>
                {moment(dat.created_at).format("YYYY-MM-DD")}
              </Table.Cell>
              <Table.Cell>
                {dat.productsPriceTotal}
                {"₺"}
              </Table.Cell>
              <Table.Cell>
                <Button color="blue" onClick={getThis.OpenModal(dat)}>
                  Detay
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const GetInfluencerTotalData = (state) => {
  return (
    <Segment>
      <Header as="h2" icon style={{ textAlign: "center", display: "block" }}>
        <Icon name="tag"></Icon>
        <Header.Subheader>Toplam İstatistikler</Header.Subheader>
      </Header>
      <Statistic.Group widths="3" style={{ marginTop: "15px" }}>
        <Statistic>
          <Statistic.Value>{state.state.order_code}</Statistic.Value>
          <Statistic.Label>
            Sipariş <br />
            Miktarı
          </Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>
            {" "}
            {/*eslint-disable-next-line no-irregular-whitespace*/}
            {state.state.total || "0"}
            {" ₺"}
          </Statistic.Value>
          <Statistic.Label>
            Toplam Satış
            <br />
            Miktarı
          </Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value style={{ paddingBottom: "15px" }}>
            {"   "}
            {state.state.brand_count}
          </Statistic.Value>
          <Statistic.Label>Markalar</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    </Segment>
  );
};

const GetAdminTotalData = (state) => {
  return (
    <Segment style={{ margin: "15px" }}>
      <Header as="h2" icon style={{ textAlign: "center", display: "block" }}>
        <Icon name="tag"></Icon>
        <Header.Subheader>Toplam İstatistikler</Header.Subheader>
      </Header>
      <Statistic.Group
        horizontal={false}
        widths="3"
        style={{ marginTop: "15px" }}
      >
        <Statistic>
          <Statistic.Value>{state.state.order_code}</Statistic.Value>
          <Statistic.Label>
            Sipariş <br />
            Miktarı
          </Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>
            {/*eslint-disable-next-line no-irregular-whitespace*/}
            {state.state.total || "0"}
            {" ₺"}
          </Statistic.Value>
          <Statistic.Label>
            Toplam Satış
            <br />
            Miktarı
          </Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value style={{ paddingBottom: "15px" }}>
            <Icon name="mobile alternate" />
            {"   "}
            {state.state.inf_id}
          </Statistic.Value>
          <Statistic.Label>Influencer</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    </Segment>
  );
};

const GetInfluencerId = (id) => {
  return (
    <Segment style={{ margin: "15px" }}>
      Influencer ID:<b> {id.id || "Bizimle iletişime geçin!"}</b>
    </Segment>
  );
};

class Influencer extends Component {
  state = {
    loading: false,
    search: "",
    searching: false,
    data: "",
    open: false,
    developer: (localStorage && localStorage.developer) || false,
    influencerTotalPrice: 0,
    influencerData: {},
    adminData: {},
    type: "",
  };

  componentDidMount() {
    let check = this.props.access.user.loggedUserObj.host;
    let check_id =
      this.props.access.user.loggedUserObj &&
      this.props.access.user.loggedUserObj.extra &&
      this.props.access.user.loggedUserObj.extra.inf_id;

    if (check === "influencer.collectaction.com" && check_id) {
      //todo  check === "inf.collectaction.com
      this.setState({ type: "normal", inf_id: check_id });
      this.loadValue("normal");
      this.updateInfluencerData();
    } else {
      this.setState({ type: "admin" });
      this.loadValue("admin");
      this.updateAdminData();
    }
  }

  updateInfluencerData = () => {
    let { access } = this.props;
    let { influencerData, inf_id } = this.state;

    let pid =
      (this.props.access.user.loggedUserObj &&
        this.props.access.user.loggedUserObj.extra &&
        this.props.access.user.loggedUserObj.extra.inf_id) ||
      null;
    log("/api/getInfluencerAnalyticAdmin", {
      search: pid,
      type: "user",
    }).then((it) => {
      if (it && it.data && it.data.success) {
        let arr = [];
        arr = it.data.data[0];
        _.merge(influencerData, arr);
        this.setState({ influencerData });
      }
    });
  };
  updateAdminData = () => {
    let { product } = this.props;
    let { adminData } = this.state;
    let pid = product.personalize.id;
    //pid pid = "3702a98a-8c65-457b-940d-9f92a302a370";
    log("/api/getInfluencerAnalyticAdmin", {
      search: pid,
      type: "admin",
    }).then((it) => {
      if (it && it.data && it.data.success) {
        let arr = [];
        arr = it.data.data[0];
        _.merge(adminData, arr);
        this.setState({ adminData });
      }
    });
  };

  loadValue = (types) => {
    let { search, adminData, influencerData, type, inf_id } = this.state;

    let total = 0,
      pid;
    if (types === "admin" || types === "normal") {
      type = types;
    }
    if (search) {
      search = search.trim();
    }
    if (type === "admin") {
      let { product } = this.props;
      pid = product.personalize.id;
      //pid pid = "3702a98a-8c65-457b-940d-9f92a302a370";
    } else {
      let check_id =
        this.props.access.user.loggedUserObj &&
        this.props.access.user.loggedUserObj.extra &&
        this.props.access.user.loggedUserObj.extra.inf_id;
      //todo influencer key
      pid = check_id;
      // pid = "009";
    }

    if (pid) {
      this.setState({ searching: true, loading: true });
      let body;
      if (!search) {
        body = {
          pid,
          type,
        };
      } else {
        body = {
          search,
          pid,
          type,
        };
      }

      log("/api/getInfluencerAnalytic", body).then((it) => {
        let list = it && it.data && it.data.data;
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let productsAll = [];
            let productTotalPrices = 0;
            const element = JSON.parse(list[i].data.products);
            for (let j = 0; j < element.length; j++) {
              const value = element[j];
              let { name, price, oldPrice, quantity, id } = value;
              let products = {
                name,
                price: price.toFixed(2),
                oldPrice,
                quantity,
                id,
              };
              productTotalPrices += price;
              productsAll.push(products);
            }
            list[i].data.total = productTotalPrices;
            list[i].data.products = productsAll;
            list[i].productsPriceTotal =
              list[i].productsPriceTotal || 0 + productTotalPrices;
            total += list[i].data.total;
          }
          adminData.total = total.toFixed(2);
          influencerData.total = total.toFixed(2);
          let orderCount = list.length;
          this.setState({
            list,
            loading: false,
            searching: false,
            adminData,
            influencerData,
            orderCount,
            type,
          });
        } else {
          this.setState({
            list: [],
            loading: false,
            searching: false,
            influencerTotalPrice: 0,
            orderCount: 0,
            type,
          });
        }
      });
    }
  };

  onChange = (e, { name, value }) => {
    this.setState({ search: value });
  };

  closeModal = () => {
    this.setState({
      historyModal: false,
    });
  };

  OpenModal = (value) => async () => {
    this.setState({
      orderDetail: [value],
      historyModal: true,
    });
  };

  inside() {
    let { t } = this.props;
    let { list, orderDetail } = this.state;
    if (this.state.loading) return <Page.Loader />;
    if (!list) {
      return (
        <Page.Wrap>
          <Input
            value={this.state.search}
            name="search"
            onChange={this.onChange}
            placeholder={"Collection ID"}
            style={{ marginRight: "15px" }}
          />
          <Icon
            style={{ cursor: "pointer", background: "#fcfcfc" }}
            name="search"
            onClick={this.loadValue}
          ></Icon>
          <Message
            css={`
              text-align: center;
            `}
            info
          >
            <Message.Header>{"Satış bulunamadı."}</Message.Header>
          </Message>
        </Page.Wrap>
      );
    }
    if (list && list.length > 0) {
      return (
        <Page.Wrap>
          <Input
            value={this.state.search}
            name="search"
            onChange={this.onChange}
            placeholder={"Arama Yap"}
            style={{ marginRight: "15px" }}
          />
          <Icon
            style={{ cursor: "pointer", background: "#fcfcfc" }}
            name="search"
            onClick={this.loadValue}
          ></Icon>
          <ResultTable list={this.state.list} getThis={this} />
        </Page.Wrap>
      );
    } else {
      return (
        <Page.Wrap>
          <Input
            value={this.state.search}
            name="search"
            onChange={this.onChange}
            placeholder={"Influencer ID"}
            style={{ marginRight: "15px" }}
          />
          <Icon
            style={{ cursor: "pointer", background: "#fcfcfc" }}
            name="search"
            onClick={this.loadValue}
          ></Icon>
          <Message
            css={`
              text-align: center;
            `}
            info
          >
            <Message.Header>{"Sonuç bulunamadı..."}</Message.Header>
          </Message>
        </Page.Wrap>
      );
    }
  }

  render() {
    let { t, access } = this.props;
    let { inf_id } = this.state;
    return (
      <Page
        icon="mobile alternate"
        title={"Influencer"}
        extra={
          <div
            css={`
              margin-left: auto;
            `}
          ></div>
        }
      >
        {/*Todo access.user.loggedUserObj.host === "inf.collectaction.com" */}
        {access.user.loggedUserObj.host === "influencer.collectaction.com" && (
          <>
            <GetInfluencerId id={inf_id} />
            <GetInfluencerTotalData state={this.state.influencerData} />
          </>
        )}
        {/*Todo access.user.loggedUserObj.host !== "inf.collectaction.com" */}
        {access.user.loggedUserObj.host === "app.collectaction.com" && (
          <GetAdminTotalData state={this.state.adminData} />
        )}
        {this.inside()}
        {/*Modal */}
        <Modal open={this.state.historyModal} onClose={this.closeModal}>
          <Button
            color="red"
            onClick={this.closeModal}
            style={{ position: "fixed", right: "0", margin: "10px" }}
          >
            <Icon name="x" /> {t("close")}
          </Button>
          <Header icon="edit" content={"Sipariş Detayı"} />
          <Modal.Content>
            <Table style={{ textAlign: "center" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="1">Sipariş Kodu</Table.HeaderCell>
                  <Table.HeaderCell width="2">Sipariş Ücreti</Table.HeaderCell>
                  <Table.HeaderCell width="3">Ürün Miktarı</Table.HeaderCell>
                  <Table.HeaderCell width="5">Ürünler</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(this.state.orderDetail, (it, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{it.order_code}</Table.Cell>
                    <Table.Cell>
                      {it.data.total}
                      {"₺"}
                    </Table.Cell>
                    <Table.Cell>{it.data.count}</Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {_.map(it.data.products, (a, index) => (
                        <div
                          style={{
                            border: "1px solid #b8b8b8",
                            padding: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <div>
                            <b>Ürün:</b> {a.name}
                          </div>
                          <div>
                            <b>Fiyat:</b> {a.price}
                            {"₺"}
                          </div>
                          <div>
                            <b>Miktar:</b> {a.quantity}
                          </div>
                        </div>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>
      </Page>
    );
  }
}

const stateToProps = (state) => ({
  access: state.access,
  product: state.product,
  location: state.router.location,
});

export default connect(stateToProps)(withTranslation()(Influencer));
