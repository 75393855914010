import React, { Component } from "react";
import Page from "../../../components/Page";
import _ from "lodash";
import moment from "moment";
import { post, log, logLive } from "../../../util/api";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Table } from "semantic-ui-react";

var getDateArray = function (start, end, format) {
  var arr = [],
    dt = new Date(start);

  while (dt <= end) {
    if (format) {
      arr.push(moment(new Date(dt)).format(format));
    } else arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }

  return arr;
};
class Analytic extends Component {
  state = {
    loading: true,
    startDate: this.props.start_date,
    endDate: this.props.end_date,
    showingdata: "click",
  };
  async componentDidMount() {
    await this.postGeneral();
    await this.exportExcel2();
  }
  async componentDidUpdate(oldProps, oldState) {
    const check_list = ["newDataToggle" /* "end_date", "start_date", */];

    for (let i = 0; i < check_list.length; i++) {
      const key = check_list[i];
      if (
        key === "start_date" ||
        key === "end_date" ||
        key === "newDataToggle"
      ) {
        if (this.props[key] !== oldProps[key]) {
          this.setState({ loading: true });
          await this.postGeneral();
          await this.exportExcel2();
          break;
        }
      }
      if (!_.isArray(this.state[key])) {
        if (this.state[key] !== oldState[key]) {
          await this.postGeneral();
          await this.exportExcel2();
          break;
        }
      } else if (!_.isEqual(this.state[key], oldState[key])) {
        await this.postGeneral();
        await this.exportExcel2();
        break;
      }
    }
  }
  async postGeneral() {
    let { start_date, end_date, user } = this.props;
    let liveProduct =
      user && user.products && _.find(user.products, { product_type: "live" });
    let personalizeProduct =
      user &&
      user.products &&
      _.find(user.products, { product_type: "personalize" });
    let token =
      (liveProduct &&
        liveProduct.settings &&
        liveProduct.settings.live.token) ||
      (personalizeProduct &&
        personalizeProduct.settings &&
        personalizeProduct.settings.live_token) ||
      "";

    const [generalData, generalData_v2, forwardData, openData] =
      await Promise.all([
        post("/personalize/bot/analytic", {
          startDate: moment(start_date).add("3", "h").valueOf(),
          endDate: moment(end_date).valueOf(),
        }),
        post("/personalize/bot/analytic", {
          startDate: moment(start_date).add("3", "h").valueOf(),
          endDate: moment(end_date).valueOf(),
        }),
        logLive("/live/getBotForwardInfo", {
          pid: token,
          start_date: moment(start_date).valueOf(),
          end_date: moment(end_date).valueOf(),
          platform: "whatsapp",
        }),
        logLive("/live/getEventInfoGeneral", {
          start_date: moment(start_date).valueOf(),
          end_date: moment(end_date).valueOf(),
          filter: {
            pid: token,
            platform: "whatsapp",
          },
          labels: ["opened"],
        }),
      ]);
    let openData_daily = _.groupBy(openData.data.result.result, (it) =>
      moment(it.date).dayOfYear()
    );
    let total_openData = _.map(openData_daily, (it) => ({
      name: "Total Opened",
      analytic: {
        [moment(it[0].date).format("YYYY-MM-DD")]: _.sumBy(it, "count"),
      },
      date: moment(it[0].date).format("YYYY-MM-DD"),
      count: _.sumBy(it, "count"),
    }));
    this.setState({
      dataGeneral: generalData.data.result || [],
      dataGeneral_v2: generalData_v2.data.result || [],
      dataForward: forwardData.data.result || [],
      openData: total_openData,
    });
  }
  handleToggle = (value) => {
    this.setState({ showingdata: value });
  };
  exportExcel = async (table_data) => {
    this.setState({ buttonLoading: true });
    let { start_date, end_date, user } = this.props;
    let personalizeProduct =
      user && user.products && _.find(user.products, { product_type: "live" });
    let pid = (personalizeProduct && personalizeProduct.id) || "";
    let x = ["name", "count"];
    let sheets = [
      {
        name: "watsons_analytic",
        x,
        datasets: table_data,
      },
    ];
    let filename =
      moment(start_date).format("DD-MM-YYYY") +
      "_" +
      moment(end_date).format("DD-MM-YYYY") +
      "_watsons_analytic_" +
      pid;

    await fetch("https://log.yapaytech.com/analytic/download", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filename,
        sheets,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataurl = data.url;
        var a = document.createElement("a");
        a.href = dataurl;
        a.setAttribute("download", dataurl);
        a.click();
        this.setState({ buttonLoading: false });
      });
  };
  exportExcel2 = async () => {
    let { start_date, end_date, user } = this.props;
    let personalizeProduct =
      user && user.products && _.find(user.products, { product_type: "live" });
    let pid = (personalizeProduct && personalizeProduct.id) || "";

    const { dataGeneral_v2, dataForward, openData } = this.state;
    let fData = _.flatten(dataGeneral_v2);
    let fNewData = _.filter(fData, (it) => it.type || it.feedback);
    let fSubData = _.map(
      _.groupBy(
        _.filter(fData, (it) => !it.type),
        "name"
      ),
      (it) => it
    );
    let newList = [];
    _.map(fNewData, (it) => {
      let obj = _.findIndex(newList, { name: it.name });
      if (obj < 0) {
        newList.push(it);
      } else {
        _.assign(newList[obj].analytic, it.analytic);
      }
      it.list &&
        _.map(it.list, (o) => {
          let sObj = _.find(newList, { name: it.name });
          let ssObj = _.findIndex(sObj.list, { name: o.name });
          if (ssObj < 0) {
            [sObj.list].push(o);
          } else {
            _.assign(sObj.list[ssObj].analytic, o.analytic);
          }
          o.list &&
            _.map(o.list, (oo) => {
              let sObj = _.find(newList, { name: it.name });
              let ssObj = _.find(sObj.list, { name: o.name });
              let sssObj =
                (ssObj && _.findIndex(ssObj.list, { name: oo.name })) || -1;
              if (ssObj && ssObj.list)
                if (sssObj < 0) {
                  ssObj.list.push(oo);
                } else {
                  _.assign(ssObj.list[sssObj].analytic, oo.analytic);
                }
            });
        });
    });
    let new_yeterli_cevap = {
      feedback: true,
      name: "Yeterli Cevap",
      analytic: {},
    };
    let yetersiz_cevap_list = _.find(newList, { name: "Yetersiz Cevap" });
    let yeterli_cevap_list = _.findIndex(newList, { name: "Yeterli Cevap" });
    let yeterli_cevap_flist = _.findIndex(
      fSubData,
      (it) => it[0].name === "Yeterli Cevap"
    );

    _.map(openData, (it) => {
      let selected_yetersiz =
        (yetersiz_cevap_list &&
          yetersiz_cevap_list.analytic &&
          yetersiz_cevap_list.analytic[it.date]) ||
        0;
      new_yeterli_cevap.analytic[it.date] = {
        totalClick: it.count - selected_yetersiz.totalClick,
        conversationCount: it.count - selected_yetersiz.conversationCount,
      };
    });
    let yeterli_agent = fSubData[yeterli_cevap_flist];
    newList[yeterli_cevap_list] = new_yeterli_cevap;
    _.map(fSubData[yeterli_cevap_flist], (o, i) => {
      return _.map(o.analytic, (oo, oi) => {
        yeterli_agent[i].name = "Yeterli Cevap(Agent Dahil)";
        let forward_data = _.find(
          dataForward,
          (it) => it && it.day && moment(it.day).format("YYYY-MM-DD") === oi
        );
        let forward_data_total =
          (forward_data && parseInt(forward_data.total)) || 0;

        let _body = {
          totalClick:
            (new_yeterli_cevap &&
              new_yeterli_cevap.analytic &&
              new_yeterli_cevap.analytic[oi] &&
              new_yeterli_cevap.analytic[oi].totalClick) ||
            0 - forward_data_total,
          conversationCount:
            (new_yeterli_cevap &&
              new_yeterli_cevap.analytic &&
              new_yeterli_cevap.analytic[oi] &&
              new_yeterli_cevap.analytic[oi].conversationCount) -
            forward_data_total,
        };

        fSubData[yeterli_cevap_flist][i].analytic[oi] =
          new_yeterli_cevap.analytic[oi];
        yeterli_agent[i].analytic[oi] = _body;
        return;
      });
    });
    var dateArr = getDateArray(start_date, end_date, "YYYY-MM-DD");
    let newForwardData = {
      total: 0,
      name: "Agent'a Yönlendirilen",
    };
    if (yeterli_agent && yeterli_agent[0]) newList.push(yeterli_agent[0]);
    fSubData.push(yeterli_agent);
    let ds_clicks = [],
      ds_conversations = [];
    let fn = (data, prefix = "- ") => {
      _.map(data, ({ name, analytic, list }) => {
        let clicks = {
          name: prefix + name,
          total: 0,
        };
        let conversation = {
          name: prefix + name,
          total: 0,
        };
        _.each(dateArr, (d) => {
          clicks[d] = 0;
          conversation[d] = 0;
        });
        let checkSubArray = _.find(
          fSubData,
          (it) => it && it[0] && it[0].name === name
        );
        if (checkSubArray) {
          let temp_check = [];
          _.map(checkSubArray, (o) =>
            _.map(o.analytic, (z, zi) => {
              temp_check.push({ [zi]: z });
            })
          );
          let temp_result = {};

          temp_check.forEach((item) => {
            const date = Object.keys(item)[0];
            const values = Object.values(item)[0];
            temp_result[date] = values;
          });
          _.each(temp_result, ({ totalClick, conversationCount }, k) => {
            clicks[k] = totalClick || 0;
            clicks.total = clicks.total + (totalClick || 0);
            conversation[k] = conversationCount || 0;
            conversation.total = conversation.total + conversationCount;
          });
        } else {
          _.each(analytic, ({ totalClick, conversationCount }, k) => {
            clicks[k] = totalClick || 0;
            clicks.total = clicks.total + (totalClick || 0);
            conversation[k] = conversationCount || 0;
            conversation.total = conversation.total + conversationCount;
          });
        }
        if (!/Yeterli Cevap|Yetersiz Cevap/.test(clicks.name)) {
          ds_clicks.push(clicks);
          ds_conversations.push(conversation);
          if (list) {
            fn(list, "-" + prefix);
          }
        } else {
          ds_conversations.push(conversation);
          if (list) {
            fn(list, "-" + prefix);
          }
        }
      });
    };
    fn(newList, "- ");
    let _iletisim_click = _.find(
      ds_clicks,
      (it) => it.name === "---- İletişim Uzmanına Bağlan"
    );
    let _iletisim_conv = _.find(
      ds_conversations,
      (it) => it.name === "---- İletişim Uzmanına Bağlan"
    );
    /* if (nn >= 0) {
     
      _iletisim.name = "--- İletişim Uzmanına Bağlan ";
      console.log("nnn", nn, ds_clicks[nn], _iletisim);
      if (_iletisim) ds_clicks[nn] = _iletisim;
      //ds_clicks[nn].name = "--- İletişim Uzmanına Bağlan 2";
    } */
    /* if (nnc >= 0) {
      console.log("nnn", nn);
      let _iletisim = _.find(
        ds_conversations,
        (it) => it.name === "---- İletişim Uzmanına Bağlan"
      );
      if (_iletisim) ds_conversations[nnc] = _iletisim;
      ds_conversations[nnc].name = "--- İletişim Uzmanına Bağlan ";
    } */
    ds_clicks = _.filter(ds_clicks, (it) => !/----/.test(it.name));

    _.each(_.filter(dataForward, "total"), ({ total, day }) => {
      newForwardData[moment(day).format("YYYY-MM-DD")] = parseInt(total);
      newForwardData.total = newForwardData.total + parseInt(total);
    });

    let x = ["name", "total"];
    _.each(dateArr, (d) => {
      x.push(d);
    });
    let sheets = [
      {
        name: "bot_click",
        x,
        datasets: _.concat(ds_clicks, newForwardData),
      },
      {
        name: "bot_conversation",
        x,
        datasets: _.concat(ds_conversations, newForwardData),
      },
    ];
    this.setState({ loading: false, sheets });
  };
  rate(all, count) {
    let rate = ((parseInt(count) * 100) / parseInt(all)).toFixed(2);
    return rate;
  }
  render() {
    if (this.state.loading) return <Page.Loader />;
    const { dataGeneral, showingdata, dataForward, openData, sheets } =
      this.state;
    const { t, start_date, end_date } = this.props;
    var dateArr = getDateArray(start_date, end_date);
    let showing = showingdata === "click" ? "totalClick" : "conversationCount";
    let AllData = _.map(dataGeneral, ({ analytic }) =>
      _.each(analytic, (it) => _.keys(it, (key) => key))
    );
    let total_opendata = _.sumBy(openData, "count");
    let AllDataByDay = {};
    let totalCl = 0;
    let totalCo = 0;
    _.map(dateArr, function (o) {
      let date = moment(o).format("YYYY-MM-DD");
      let sumtotalClick = _.sumBy(AllData, function (it) {
        if (it && it[date]) return it[date].totalClick;
      });
      let sumconversationCount = _.sumBy(AllData, function (it) {
        if (it && it[date]) return it[date].conversationCount;
      });
      totalCl = totalCl + (sumtotalClick || 0);
      //console.log('sumtotalClick', sumtotalClick);
      totalCo = totalCo + sumconversationCount;
      _.assign(AllDataByDay, {
        [date]: {
          conversationCount: sumconversationCount,
          totalClick: sumtotalClick,
        },
      });
    });
    if (this.state.loading) return <Page.Loader />;
    let newDataArray = _.orderBy(
      _.groupBy(_.flatten(dataGeneral), "name"),
      (it) => it[0].order
    );

    let subnewDataArray = _.filter(newDataArray, (it) => !it[0].type);
    newDataArray = _.filter(
      newDataArray,
      (it) =>
        it[0].type ||
        it[0].name === "Başka Sorum Var" ||
        it[0].name === "Yeterli Cevap" ||
        it[0].name === "Yetersiz Cevap"
    );
    newDataArray = _.map(newDataArray, (it) =>
      _.filter(it, (o) => o.type || o.feedback)
    );
    let totalData = _.flatten(dataGeneral);

    let click_data = sheets[0].datasets;
    let conversation_data = sheets[1].datasets;
    let showing_data = showingdata === "click" ? click_data : conversation_data;
    let yeterli_olmayan =
      (_.find(conversation_data, (it) => /Yetersiz Cevap/.test(it.name)) &&
        _.find(conversation_data, (it) => /Yetersiz Cevap/.test(it.name))
          .total) ||
      0;

    let agent_yonlendirilen =
      (_.find(conversation_data, (it) => /Yönlendirilen/.test(it.name)) &&
        _.find(conversation_data, (it) => /Yönlendirilen/.test(it.name))
          .total) ||
      0;
    let table_data = [
      { name: "Toplam Oluşan Konuşma", count: total_opendata },
      {
        name: "Botta Yeterli Olmayan Konuşma",
        count: yeterli_olmayan,
      },
      {
        name: "Botta Yeterli Olmayan Konuşma (Başarı %)",
        count: this.rate(total_opendata, yeterli_olmayan),
      },
      {
        name: "Agenta Yönlendirilen Konuşma",
        count: agent_yonlendirilen,
      },
      {
        name: "Agenta Yönlendirilen Konuşma (Başarı %)",
        count: this.rate(total_opendata, agent_yonlendirilen),
      },
      {
        name: "Botta Çözülen Konuşma",
        count: total_opendata - agent_yonlendirilen - yeterli_olmayan,
      },
      {
        name: "Bot Başarı Performansı",
        count: this.rate(total_opendata, total_opendata - yeterli_olmayan),
      },
      {
        name: "Bot Başarı Performansı (Agenta Yönlendirilen Dahil)",
        count: this.rate(
          total_opendata,
          total_opendata - yeterli_olmayan - agent_yonlendirilen
        ),
      },
    ];
    console.log("conversation_data", conversation_data);
    return (
      <div>
        <div className="myContainer d-flex">
          <div
            className="d-flex"
            style={{ marginLeft: "auto", marginBottom: "10px" }}
          >
            <Button
              style={{ display: "flex", margin: "auto" }}
              icon
              size="small"
              positive
              onClick={() => this.exportExcel(table_data)}
              loading={this.state.buttonLoading}
              disabled={this.state.buttonLoading}
            >
              {t("DownloadExcel")}
            </Button>
          </div>
        </div>
        <div className="myContainer scroll">
          <div className="watsons-analytics">
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>İsim</Table.HeaderCell>
                  <Table.HeaderCell>
                    {moment(start_date).format("DD.MM.YYYY") +
                      " - " +
                      moment(end_date).format("DD.MM.YYYY")}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(table_data, (it) => (
                  <Table.Row>
                    <Table.Cell>{it.name}</Table.Cell>
                    <Table.Cell>{it.count}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
  state,
}))(withTranslation()(Analytic));
