import React, { Component } from "react";
import Page from "../../../components/Page";
import _ from "lodash";
import moment from "moment";
import { post, log, logLive } from "../../../util/api";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

var getDateArray = function (start, end, format) {
  var arr = [],
    dt = new Date(start);

  while (dt <= end) {
    if (format) {
      arr.push(moment(new Date(dt)).format(format));
    } else arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }

  return arr;
};
class Analytic extends Component {
  state = {
    loading: true,
    startDate: this.props.start_date,
    endDate: this.props.end_date,
    showingdata: "click",
  };
  componentDidMount() {
    this.postGeneral();
  }
  componentDidUpdate(oldProps, oldState) {
    const check_list = ["end_date", "start_date"];

    for (let i = 0; i < check_list.length; i++) {
      const key = check_list[i];
      if (key === "start_date" || key === "end_date") {
        if (this.props[key] !== oldProps[key]) {
          this.setState({ loading: true });
          this.postGeneral();
          break;
        }
      }
      if (!_.isArray(this.state[key])) {
        if (this.state[key] !== oldState[key]) {
          this.postGeneral();
          break;
        }
      } else if (!_.isEqual(this.state[key], oldState[key])) {
        this.postGeneral();
        break;
      }
    }
  }
  async postGeneral() {
    let { start_date, end_date, user } = this.props;
    let liveProduct =
      user && user.products && _.find(user.products, { product_type: "live" });
    let personalizeProduct =
      user &&
      user.products &&
      _.find(user.products, { product_type: "personalize" });
    let token =
      (liveProduct &&
        liveProduct.settings &&
        liveProduct.settings.live.token) ||
      (personalizeProduct &&
        personalizeProduct.settings &&
        personalizeProduct.settings.live_token) ||
      "";

    const [generalData, generalData_v2, forwardData] = await Promise.all([
      post("/personalize/bot/analytic", {
        startDate: moment(start_date).add("3", "h").valueOf(),
        endDate: moment(end_date).valueOf(),
      }),
      post("/personalize/bot/analytic", {
        startDate: moment(start_date).add("3", "h").valueOf(),
        endDate: moment(end_date).valueOf(),
      }),
      logLive("/live/getBotForwardInfo", {
        pid: token,
        start_date: moment(start_date).valueOf(),
        end_date: moment(end_date).valueOf(),
      }),
    ]);
    this.setState({
      loading: false,
      dataGeneral: generalData.data.result || [],
      dataGeneral_v2: generalData_v2.data.result || [],
      dataForward: forwardData.data.result || [],
    });
  }
  handleToggle = (value) => {
    this.setState({ showingdata: value });
  };
  exportExcel = async () => {
    this.setState({ buttonLoading: true });
    let { start_date, end_date, user } = this.props;
    let personalizeProduct =
      user && user.products && _.find(user.products, { product_type: "live" });
    let pid = (personalizeProduct && personalizeProduct.id) || "";

    const { dataGeneral_v2, dataForward } = this.state;
    let fData = _.flatten(dataGeneral_v2);
    let fNewData = _.filter(fData, (it) => it.type || it.feedback);
    let fSubData = _.map(
      _.groupBy(
        _.filter(fData, (it) => !it.type),
        "name"
      ),
      (it) => it
    );
    let newList = [];
    _.map(fNewData, (it) => {
      let obj = _.findIndex(newList, { name: it.name });
      if (obj < 0) {
        newList.push(it);
      } else {
        _.assign(newList[obj].analytic, it.analytic);
      }
      it.list &&
        _.map(it.list, (o) => {
          let sObj = _.find(newList, { name: it.name });
          let ssObj = _.findIndex(sObj.list, { name: o.name });
          if (ssObj < 0) {
            [sObj.list].push(o);
          } else {
            _.assign(sObj.list[ssObj].analytic, o.analytic);
          }
          o.list &&
            _.map(o.list, (oo) => {
              let sObj = _.find(newList, { name: it.name });
              let ssObj = _.find(sObj.list, { name: o.name });
              let sssObj = _.findIndex(ssObj.list, { name: oo.name });
              if (sssObj < 0) {
                ssObj.list.push(oo);
              } else {
                _.assign(ssObj.list[sssObj].analytic, oo.analytic);
              }
            });
        });
    });
    var dateArr = getDateArray(start_date, end_date, "YYYY-MM-DD");
    let newForwardData = {
      total: 0,
      name: "Agent'a Yönlendirilen",
    };
    let ds_clicks = [],
      ds_conversations = [];
    let fn = (data, prefix = "- ") => {
      _.map(data, ({ name, analytic, list }) => {
        let clicks = {
          name: prefix + name,
          total: 0,
        };
        let conversation = {
          name: prefix + name,
          total: 0,
        };
        _.each(dateArr, (d) => {
          clicks[d] = 0;
          conversation[d] = 0;
        });
        let checkSubArray = _.find(fSubData, (it) => it[0].name === name);
        if (checkSubArray) {
          let temp_check = [];
          _.map(checkSubArray, (o) =>
            _.map(o.analytic, (z, zi) => {
              temp_check.push({ [zi]: z });
            })
          );
          let temp_result = {};

          temp_check.forEach((item) => {
            const date = Object.keys(item)[0];
            const values = Object.values(item)[0];
            temp_result[date] = values;
          });
          _.each(temp_result, ({ totalClick, conversationCount }, k) => {
            clicks[k] = totalClick || 0;
            clicks.total = clicks.total + (totalClick || 0);
            conversation[k] = conversationCount || 0;
            conversation.total = conversation.total + conversationCount;
          });
        } else {
          _.each(analytic, ({ totalClick, conversationCount }, k) => {
            clicks[k] = totalClick || 0;
            clicks.total = clicks.total + (totalClick || 0);
            conversation[k] = conversationCount || 0;
            conversation.total = conversation.total + conversationCount;
          });
        }
        ds_clicks.push(clicks);
        ds_conversations.push(conversation);
        if (list) {
          fn(list, "-" + prefix);
        }
      });
    };
    fn(newList, "- ");
    ds_clicks = _.filter(ds_clicks, (it) => !/----/.test(it.name));
    ds_conversations = _.filter(
      ds_conversations,
      (it) => !/----/.test(it.name)
    );
    _.each(_.filter(dataForward, "total"), ({ total, day }) => {
      newForwardData[moment(day).format("YYYY-MM-DD")] = parseInt(total);
      newForwardData.total = newForwardData.total + parseInt(total);
    });
    let ds_click_main = _.filter(ds_clicks, function (it) {
      return /^-{1} /.test(it.name);
    });
    let ds_click_total = {
      name: "Toplam",
      total: _.sumBy(ds_click_main, "total"),
    };
    _.map(
      dateArr,
      (it) =>
        (ds_click_total[moment(it).format("YYYY-MM-DD")] = _.sumBy(
          ds_click_main,
          "" + moment(it).format("YYYY-MM-DD")
        ))
    );
    //console.log(ds_clicks, ds_click_total);
    let x = ["name", "total"];
    _.each(dateArr, (d) => {
      x.push(d);
    });
    let sheets = [
      {
        name: "bot_click",
        x,
        datasets: _.concat(ds_click_total, ds_clicks, newForwardData),
      },
      {
        name: "bot_conversation",
        x,
        datasets: _.concat(ds_conversations, newForwardData),
      },
    ];
    let filename =
      moment(start_date).format("DD-MM-YYYY") +
      "_" +
      moment(end_date).format("DD-MM-YYYY") +
      "_bot_info_" +
      pid;

    await fetch("https://log.yapaytech.com/analytic/download", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filename,
        sheets,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataurl = data.url;
        var a = document.createElement("a");
        a.href = dataurl;
        a.setAttribute("download", dataurl);
        a.click();
        this.setState({ buttonLoading: false });
      });
  };
  render() {
    let { t } = this.props;
    const { dataGeneral, showingdata, dataForward } = this.state;
    const { start_date, end_date } = this.props;
    var dateArr = getDateArray(start_date, end_date);
    let showing = showingdata === "click" ? "totalClick" : "conversationCount";
    let AllData = _.map(dataGeneral, ({ analytic }) =>
      _.each(analytic, (it) => _.keys(it, (key) => key))
    );
    let AllDataByDay = {};
    let totalCl = 0;
    let totalCo = 0;
    _.map(dateArr, function (o) {
      let date = moment(o).format("YYYY-MM-DD");
      let sumtotalClick = _.sumBy(AllData, function (it) {
        if (it && it[date]) return it[date].totalClick;
      });
      let sumconversationCount = _.sumBy(AllData, function (it) {
        if (it && it[date]) return it[date].conversationCount;
      });
      totalCl = totalCl + (sumtotalClick || 0);
      //console.log('sumtotalClick', sumtotalClick);
      totalCo = totalCo + sumconversationCount;
      _.assign(AllDataByDay, {
        [date]: {
          conversationCount: sumconversationCount,
          totalClick: sumtotalClick,
        },
      });
    });
    if (this.state.loading) return <Page.Loader />;
    let newDataArray = _.orderBy(
      _.groupBy(_.flatten(dataGeneral), "name"),
      (it) => it[0].order
    );

    let subnewDataArray = _.filter(newDataArray, (it) => !it[0].type);
    newDataArray = _.filter(
      newDataArray,
      (it) => it[0].type || it[0].name === "Başka Sorum Var"
    );
    newDataArray = _.map(newDataArray, (it) =>
      _.filter(it, (o) => o.type || o.feedback)
    );
    let totalData = _.flatten(dataGeneral);
    return (
      <div>
        <div className="myContainer d-flex">
          <div className="period">
            <div
              id="click"
              className={showingdata === "click" ? "active" : ""}
              onClick={() => this.handleToggle("click")}
            >
              {t("Click")}
            </div>
            <div
              id="conversation"
              className={showingdata === "conversation" ? "active" : ""}
              onClick={() => this.handleToggle("conversation")}
            >
              {t("Conversation")}
            </div>
          </div>
          <div className="d-flex">
            <Button
              style={{ display: "flex", margin: "auto" }}
              icon
              size="small"
              positive
              onClick={() => this.exportExcel()}
              loading={this.state.buttonLoading}
              disabled={this.state.buttonLoading}
            >
              {t("DownloadExcel")}
            </Button>
          </div>
        </div>
        <div className="myContainer scroll">
          <div className="bot-analytic-box border">
            <div className="list" key="head">
              <div className="header-box d-flex">
                <div className="wid">
                  <div className="name">{t("Name")}</div>
                </div>
                <div className="number">{t("Total")}</div>
                {_.map(dateArr, function (it, i) {
                  return (
                    <div className="number" key={"header-" + i}>
                      {moment(it).format("DD-MM")}
                    </div>
                  );
                })}
              </div>
            </div>
            {showing === "totalClick" && (
              <div className="list">
                <div className="main-box d-flex">
                  <div className={"wid total"}>
                    <div className="name">Toplam</div>
                  </div>
                  <div className={"number total"}>
                    {_.sumBy(
                      _.map(
                        totalData,
                        (sm) =>
                          sm &&
                          sm.analytic &&
                          sm.analytic[_.keys(sm.analytic)[0]]
                      ),
                      "" + [showing]
                    )}
                  </div>
                  {_.map(dateArr, function (d, di) {
                    let filterData = _.filter(
                      totalData,
                      (it) => it.analytic[moment(d).format("YYYY-MM-DD")]
                    );
                    let totalDay = _.sumBy(
                      filterData,
                      (it) =>
                        it.analytic[moment(d).format("YYYY-MM-DD")][showing]
                    );
                    return (
                      <div className={"number total"} key={"main-" + di}>
                        {totalDay || 0}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {_.map(newDataArray, function (o, i) {
              let allList = _.orderBy(
                _.groupBy(_.flatten(_.map(o, (it) => it.list)), "id"),
                (it) => it && it[0] && it[0].order
              );
              //	let newDataArray = _.orderBy(_.groupBy(_.flatten(dataGeneral), 'name'), (it) => it[0].order);
              return (
                <div className="list" key={"list-" + i}>
                  <div className="main-box d-flex">
                    <div className={"wid" + (o[0].feedback ? " feedback" : "")}>
                      <div className="name">{o[0].name}</div>
                    </div>
                    <div
                      className={"number" + (o[0].feedback ? " feedback" : "")}
                    >
                      {_.sumBy(
                        _.map(o, (sm) => sm.analytic[_.keys(sm.analytic)[0]]),
                        "" + [showing]
                      ) || 0}
                    </div>
                    {_.map(o, (it, di) => {
                      return (
                        <div
                          className={
                            "number" + (it.feedback ? " feedback" : "")
                          }
                          key={"main-" + di}
                        >
                          {(it.analytic[_.keys(it.analytic)[0]] &&
                            it.analytic[_.keys(it.analytic)[0]][showing]) ||
                            0}
                        </div>
                      );
                    })}
                  </div>

                  {allList &&
                    allList[0] &&
                    _.map(allList, function (o, oi) {
                      let subList = _.orderBy(
                        _.groupBy(
                          _.flatten(_.map(o, (it) => it && it.list)),
                          "name"
                        ),
                        (it) => it && it[0] && it[0].order
                      );
                      if (o && o[0] && o[0].name)
                        return (
                          <div key={"sub-" + oi}>
                            <div className="sub-box d-flex">
                              <div className="swid">
                                <div className="name">
                                  {o && o.length && o[o.length - 1].name}
                                </div>
                              </div>
                              <div className="number">
                                {/* 		{(o.analytic && _.sumBy(_.keys(o.analytic), (key) => o.analytic[key][showing])) || '0'} */}
                                {_.sumBy(
                                  _.map(
                                    o,
                                    (sm) =>
                                      sm &&
                                      sm.analytic &&
                                      sm.analytic[_.keys(sm.analytic)[0]]
                                  ),
                                  "" + [showing]
                                ) || 0}
                              </div>
                              {_.map(o, (it, di) => {
                                return (
                                  <div className="number" key={"subnum-" + di}>
                                    {(it.analytic &&
                                      _.keys(it.analytic) &&
                                      it.analytic[_.keys(it.analytic)[0]] &&
                                      it.analytic[_.keys(it.analytic)[0]][
                                        showing
                                      ]) ||
                                      0}
                                  </div>
                                );
                              })}
                            </div>

                            {subList &&
                              subList[0] &&
                              subList[0][0] &&
                              _.map(subList, function (otail, otaili) {
                                let checkSubArray = _.find(
                                  subnewDataArray,
                                  (it) =>
                                    _.lowerCase(it[0].name) ===
                                    _.lowerCase(otail[0].name)
                                );
                                if (checkSubArray) {
                                  return (
                                    <div
                                      className="sub-tail-box d-flex"
                                      key={"sub-tail-" + otaili}
                                    >
                                      <div className="swid">
                                        <div className="name">
                                          {otail[0].name}
                                        </div>
                                      </div>
                                      <div className="number">
                                        {/* {otail.analytic &&
                                          _.sumBy(_.keys(otail.analytic), (key) => otail.analytic[key][showing])} */}
                                        {_.sumBy(
                                          _.map(
                                            checkSubArray,
                                            (sm) =>
                                              sm &&
                                              sm.analytic &&
                                              sm.analytic[
                                                _.keys(sm.analytic)[0]
                                              ]
                                          ),
                                          "" + [showing]
                                        ) || 0}
                                      </div>
                                      {_.map(dateArr, function (d, di) {
                                        let obj = _.find(
                                          checkSubArray,
                                          (it) => {
                                            return (
                                              it.analytic &&
                                              it.analytic[
                                                moment(d).format("YYYY-MM-DD")
                                              ]
                                            );
                                          }
                                        );
                                        return (
                                          <div className="number" key={di}>
                                            {(obj &&
                                              obj.analytic[
                                                moment(d).format("YYYY-MM-DD")
                                              ][showing]) ||
                                              0}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className="sub-tail-box d-flex"
                                    key={"sub-tail-" + otaili}
                                  >
                                    <div className="swid">
                                      <div className="name">
                                        {otail[0].name}
                                      </div>
                                    </div>
                                    <div className="number">
                                      {/* {otail.analytic &&
																				_.sumBy(_.keys(otail.analytic), (key) => otail.analytic[key][showing])} */}
                                      {_.sumBy(
                                        _.map(
                                          otail,
                                          (sm) =>
                                            sm &&
                                            sm.analytic &&
                                            sm.analytic[_.keys(sm.analytic)[0]]
                                        ),
                                        "" + [showing]
                                      ) || 0}
                                    </div>
                                    {_.map(dateArr, function (d, di) {
                                      let obj = _.find(otail, (it) => {
                                        return (
                                          it.analytic &&
                                          it.analytic[
                                            moment(d).format("YYYY-MM-DD")
                                          ]
                                        );
                                      });
                                      return (
                                        <div className="number" key={di}>
                                          {(obj &&
                                            obj.analytic[
                                              moment(d).format("YYYY-MM-DD")
                                            ][showing]) ||
                                            0}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                          </div>
                        );
                    })}
                </div>
              );
            })}
            <div className="list">
              <div className="main-box d-flex">
                <div className={"wid forward"}>
                  <div className="name">{t("Forward to Agent")}</div>
                </div>
                <div className={"number forward"}>
                  {dataForward &&
                    _.sumBy(dataForward, (item) => Number(item.total))}
                </div>
                {_.map(dateArr, function (d, i) {
                  return (
                    <div className={"number forward"} key={"forward-" + i}>
                      {(dataForward &&
                        dataForward.length &&
                        _.find(dataForward, function (data) {
                          return (
                            moment(data.day).format("DD-MM") ===
                            moment(d).format("DD-MM")
                          );
                        }) &&
                        _.find(dataForward, function (data) {
                          return (
                            moment(data.day).format("DD-MM") ===
                            moment(d).format("DD-MM")
                          );
                        }).total) ||
                        0}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
  state,
}))(withTranslation()(Analytic));
