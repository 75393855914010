import React from "react";
import { YtMissing, Section, SubSection } from "./FormParts";
import { Formik, Form, FastField as Field } from "formik";
import { CreateForm } from "./FormHelper";
import { MyDivider } from "./FormikInput";
import "./FormEditors";
import _ from "lodash";
import { Icon, Button } from "semantic-ui-react";
import { Prompt } from "react-router-dom";

const Card = ({ open, data, index }) => (
  <Button
    type="button"
    color="orange"
    icon
    basic
    style={{ width: 200, marginBottom: 10 }}
    className="cursor-pointer"
    onClick={() => open(data, index)}
  >
    <Icon name="edit" /> Open Screen Editor
  </Button>
);

export class PopEditor extends React.PureComponent {
  state = { open: false };
  open = () => {
    this.setState({ open: true });
    if (this.props._page.setPage)
      this.props._page.setPage(this.save, this.cancel);
  };

  save = () => {
    const { data } = this.props.data;
    let newvalue = this._save && this._save();
    this.form.setFieldValue(data.path, newvalue);
    this.cancel();
  };

  cancel = () => {
    this.setState({ open: false });
    if (this.props._page.setPage) this.props._page.setPage();
  };

  setSave = (save) => {
    this._save = save;
  };

  render() {
    const { data, label, multi, card } = this.props.data;
    if (multi) {
      console.warn("TODO");
    }
    return (
      <div>
        {label && <MyDivider name={label.name} icon={label.icon} />}
        <Field name={data.path}>
          {({ field, form }) => {
            this.form = form;
            this.field = field;
            let cls = card && card(field.value);
            return <Card data={field.value} cls={cls} open={this.open} />;
          }}
        </Field>
        {this.state.open && (
          <Editor
            data={{ data }}
            value={this.field && this.field.value}
            _editor={this}
          />
        )}
      </div>
    );
  }
}

export class Editor extends React.PureComponent {
  render() {
    const { data, label, multi, type } = this.props.data;
    if (type === "pop-editor")
      return <PopEditor ref={this.props._page._editor} {...this.props} />;
    if (type === "group-editor")
      return (
        <div>
          {label && <MyDivider name={label.name} icon={label.icon} />}
          <SubSection
            form={this.props.form}
            path={data.path || data.__name || ""}
            deep={multi ? 0 : 2}
            list={data}
          />
        </div>
      );
    return (
      <div>
        {label && <MyDivider name={label.name} icon={label.icon} />}
        <Section
          form={this.props.form}
          path={data.path || data.__name}
          deep={multi ? 0 : 2}
          data={data}
          value={this.props.value}
          _editor={this.props._editor}
        />
      </div>
    );
  }
}
export class FormBuilder extends React.PureComponent {
  form = CreateForm(this.props.type);

  get current() {
    const { current } = this.props;
    let def = this.form.__default;
    if (
      current &&
      current.tags &&
      current.tags.parameters &&
      def &&
      def.tags &&
      def.tags.parameters
    ) {
      let ret = {};
      _.each(def.tags.parameters, (val, key) => (ret[key] = val));
      _.each(current.tags.parameters, (val, key) => (ret[key] = val));
      current.tags.parameters = ret;
    }
    if (current && def) {
      let ret = {};
      _.each(def, (val, key) => (ret[key] = val));
      _.each(current, (val, key) => (ret[key] = val));
      return ret;
    }
    return current || def;
  }

  render() {
    if (!this.form) return <YtMissing />;
    const { _page = {}, _form, handleSubmit } = this.props;
    return (
      <Formik
        innerRef={_form}
        onSubmit={handleSubmit}
        initialValues={this.current}
      >
        {(form) => {
          return (
            <Form
              autoComplete="off"
              css={`
                background: inherit;
                max-height: 100%;
                min-height: 100%;
                overflow: auto;
              `}
            >
              <Prompt
                when={form.dirty}
                message={(location) =>
                  `Are you sure you want to go to ${location.pathname} without saving...`
                }
              />
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                className={"d-none"}
              />
              {_.map(this.form.editors, (editor, i) => (
                <Editor data={editor} key={i} _page={_page} form={this.form} />
              ))}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

//<pre>#{JSON.stringify(form.values, null, 2)}</pre>
