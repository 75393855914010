import React, { Component } from "react";
import { Form, Segment, Table, Select } from "semantic-ui-react";
import Page from "../../components/Page";
import _ from "lodash";
import { get } from "../../util/api";
import moment from "moment";
import { withTranslation } from "react-i18next";

class ActionTable extends Component {
  state = {
    loading: true,
    showTest: false,
    actionListHere: [],
  };
  actionList = () => {
    get("/campaign/template/list").then((data) => {
      let response = _.map(data.data, (it, i) => {
        return it;
      });
      this.setState({ actionListHere: response });
    });
  };
  componentDidMount() {
    this.actionList();
    get("/campaign/report").then((data) => {
      this.setState({
        data: data.result,
        copieddata: data.result,
        loading: false,
      });
    });
  }

  actionFilterr = (e, { name, value }) => {
    let allActions = this.state.copieddata;
    let copydata = allActions;
    if (!this.state.showTest) {
      allActions = _.filter(allActions, (it) => !/test/.test(it.email));
    } else {
      allActions = _.filter(copydata, (it) => !/test/.test(it.email));
    }

    let temporarydata;
    temporarydata = _.filter(copydata, (obj, i) => {
      if (value === obj.key) {
        return obj;
      } else if (value === "all") {
        return obj;
      }
    });
    if (temporarydata) {
      this.setState({ data: temporarydata });
    }
  };
  render() {
    let { data, loading, showTest, actionListHere } = this.state;
    let { t } = this.props;
    if (loading) {
      return <Page.Loader></Page.Loader>;
    }
    if (!showTest) {
      data = _.filter(data, (it) => !/test/.test(it.email));
    }
    let actionDropwDown = _.map(actionListHere, (it, i) => {
      return {
        value: it.id,
        key: it.id,
        text: it.title,
      };
    });
    let all = {
      value: "all",
      key: "all",
      text: t('all'),
    };
    actionDropwDown.unshift(all);
    return (
      <Segment style={{ overflowY: "auto" }}>
        <Form>
          <Form.Checkbox
            label={t("TestMail")}
            name="showTest"
            checked={showTest}
            onClick={() => this.setState({ showTest: !showTest })}
          ></Form.Checkbox>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Select
              width={6}
              placeholder={t('all')}
              options={actionDropwDown}
              onChange={this.actionFilterr}
            />
          </div>
        </Form>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={5}>Action</Table.HeaderCell>
              <Table.HeaderCell width={6}>Email</Table.HeaderCell>
              <Table.HeaderCell>Ecommerce Type</Table.HeaderCell>
              <Table.HeaderCell width={2}>Status</Table.HeaderCell>
              <Table.HeaderCell width={3}>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(data, (it, i) => (
              <Table.Row>
                <Table.Cell>{it.title || it.key}</Table.Cell>
                <Table.Cell>{it.email}</Table.Cell>
                <Table.Cell>{it.type}</Table.Cell>
                <Table.Cell>{it.status}</Table.Cell>
                <Table.Cell>
                  {moment(it.date).format("DD-MM-YYYY HH:mm")}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default withTranslation()(ActionTable);
