import React, { Component, useState } from 'react';
import { Segment, Button, Table, Icon, Message } from 'semantic-ui-react';
import { get } from '../../util/api';
import Page from '../../components/Page';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next';
// eslint-disable-next-line
import styled from 'styled-components/macro';

function RestoreButton(props) {
	let [loading, setLoading] = useState(false);

	async function onclick() {
		setLoading(true);
		await get('/personalize/bot/restore/' + props.id);
		props.refresh();
	}
	let { t } = useTranslation();
	return (
		<Button color="green" size="small" loading={loading} onClick={onclick}>
			{t('Restore')}
		</Button>
	);
}

class BotPublishScreen extends Component {
	state = { loading: true };
	componentDidMount() {
		this.load();
	}

	async load() {
		if (!this.state.loading) this.setState({ loading: true });
		let response = await get('/personalize/bot/restore/list');
		this.setState({ loading: false, list: (response && response.result) || [] });
	}

	publish = async () => {
		if (this.state.publishing) return;
		try {
			this.setState({ publishing: true });
			let data = await get('/personalize/bot/publish/live');
			if (data && data.success) {
				this.setState({ publishing: false, error: null });
				this.load();
			} else this.setState({ publishing: false, error: data && data.error });
		} catch (error) {
			this.setState({ publishing: false, error: error.message });
		}
	};

	render() {
		let { t } = this.props;
		if (this.state.loading)
			return (
				<Segment className="bot-publish-wrap">
					<Page.Loader />
				</Segment>
			);
		return (
			<Segment className="bot-publish-wrap">
				<div className="header-section">
					{this.state.error ? (
						<Message
							negative
							size="tiny"
							css={`
								margin: 0 5px 0 0 !important;
							`}
						>
							<p>{this.state.error}</p>
						</Message>
					) : null}
					<Button primary loading={this.state.publishing} onClick={this.publish}>
						{t('PublishNewVersion')}
					</Button>
				</div>
				<div className="publish-list">
					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell width={1} textAlign="center">
									#
								</Table.HeaderCell>
								<Table.HeaderCell>{t('PublishDate')}</Table.HeaderCell>
								<Table.HeaderCell width={1} textAlign="center">
									{t('Active')}
								</Table.HeaderCell>
								<Table.HeaderCell width={1} textAlign="center">
									{t('Actions')}
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{this.state.list && this.state.list.length ? (
								_.map(this.state.list, (it) => (
									<Table.Row key={it.id}>
										<Table.Cell textAlign="center">{it.id}</Table.Cell>
										<Table.Cell>{moment(it.created_at).format('LL - HH:mm')}</Table.Cell>
										<Table.Cell textAlign="center">{it.active ? <Icon color="green" name="checkmark" size="large" /> : null}</Table.Cell>
										<Table.Cell textAlign="center">
											{it.active ? null : <RestoreButton id={it.id} refresh={this.load.bind(this)} />}
										</Table.Cell>
									</Table.Row>
								))
							) : (
								<Table.Row>
									<Table.Cell colSpan="4" textAlign="center">
										{t('PublishNewVersion')}
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				</div>
			</Segment>
		);
	}
}

export default withTranslation()(BotPublishScreen);
