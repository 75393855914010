import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import Page from "../../components/Page";
import { get } from "../../util/api";
import _ from "lodash";
import { Link } from "react-router-dom";
import { MyDividerCenter } from "../../components/FormikInput";
//import TagList from './taglist.json';
import { withTranslation } from "react-i18next";

const TemplateCard = ({ language, item = {}, i, type }) => {
  item.rate = item.vote_count ? item.vote_amount / item.vote_count : 0;
  console.log("item ", item);
  //let nu = 1;
  //let otherlist = [];
  /* let filterList = TagList;
	if (type === 'flo') {
		filterList = [
			{
				text: 'Flo',
				key: 'flo',
				value: 'flo',
				color: 'blue',
			},
		];
	} */
  /* let filterIndex = _.findIndex(filterList, function (it) {
		return it.value === type;
	}); */
  let borderColor =
    item.config[type] || item.config.base ? "border-green" : "border-red";
  return (
    <div className="ac-card">
      <Segment
        as={Link}
        to={"/schedule?id=" + item.id}
        className={`template-card-menu ${borderColor}`}
      >
        <div className="box">
          <div className="icon-side">
            <img
              src={item.settings.image[language] || "/icons/layout.svg"}
              alt={item.id}
              width={120}
            />
          </div>
          <div className="template-body">
            <h2>{item.settings.title[language]}</h2>
            <div>
              <img
                src="/images/star.svg"
                alt="star"
                width={14}
                style={{ marginRight: "5px" }}
              ></img>
              <span>{item.rate || 3.2}</span>
            </div>
            <p className="max-lines">{item.settings.description[language]}</p>
            <br />
            {/* <div className="d-flex">
							{item.config[type] ? (
								<Label className="" color={filterList[filterIndex].color} key={filterList[filterIndex].value}>
									{filterList[filterIndex].text}
								</Label>
							) : (
								''
							)}
							{item.config[type]
								? function () {
										return nu++;
								  }
								: ''}
							{_.map(
								_.filter(filterList, function (o) {
									return o.value !== type;
								}),
								function (it, i) {
									if (item.config[it.key]) {
										nu++;
										if (nu <= 4) {
											return (
												<Label className="" color={it.color} key={i}>
													{it.text}
												</Label>
											);
										}
										if (nu > 4) {
											otherlist.push({ text: it.text });
										}
									}
								}
							)}
							{otherlist.length > 0 && (
								<Popup
									content={
										<div>
											{_.map(otherlist, function (a) {
												return <p>{a.text}</p>;
											})}
										</div>
									}
									position="bottom right"
									trigger={
										<Label className="" color="grey">
											...
										</Label>
									}
								/>
							)}
						</div>
				 */}{" "}
          </div>
        </div>
        <span className={"label-tag " + item.label}>{item.label || ""}</span>
      </Segment>
    </div>
  );
};
class Templates extends Component {
  state = {
    loading: true,
    listfilter:
      this.props.product && this.props.product.type === "flo" ? "flo" : "all",
    developer: (localStorage && localStorage.developer) || false,
  };
  componentDidMount() {
    get(
      "/campaign/template/listV2?type=campaign&developer=" +
        this.state.developer
    ).then((it) => {
      this.setState({
        list: _.orderBy(
          _.filter(it.data, { type: "schedule" }),
          "order",
          "asc"
        ),
        loading: false,
      });
    });
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  render() {
    let { loading, list, listfilter } = this.state;
    let { t, product } = this.props;
    let language = t("lang");

    let ecommerceType = product.type;
    /* list = _.sortBy(list, function (it) {
			return (it.config = { tsoft: true });
		}); */
    let canInstallList = _.filter(list, function (it) {
      return (
        (it && it.config && it.config[ecommerceType]) ||
        (it.config && it.config.base)
      );
    });
    let cantInstallList = _.filter(list, function (it) {
      return it.config && !it.config[ecommerceType] && !it.config.base;
    });
    console.log("canInstallList ", canInstallList);
    /* let filterOptions = _.concat(
			{
				text: 'Tümü',
				key: 'all',
				value: 'all',
				color: 'green',
			},
			TagList
		);
		if (ecommerceType === 'flo') {
			filterOptions = [
				{
					text: 'Flo',
					key: 'flo',
					value: 'flo',
					color: 'blue',
				},
			];
		} */
    if (loading) {
      return (
        <Page title={t("Schedules")} icon="calendar alternate outline">
          <Page.Loader></Page.Loader>
        </Page>
      );
    }
    if (listfilter !== "all") {
      list = _.filter(list, function (it) {
        return it.config[listfilter];
      });
    }
    return (
      <Page title={t("Schedules")} icon="calendar alternate outline">
        <Page.Wrap>
          <Segment>
            <div className="template-wrap-menu">
              {_.map(canInstallList, (item, i) => (
                <TemplateCard
                  language={language}
                  key={item.id}
                  item={item}
                  i={i}
                  type={ecommerceType}
                />
              ))}
            </div>
            {cantInstallList && cantInstallList.length ? (
              <div className="template-wrap-menu">
                <MyDividerCenter name="Eticaret Altyapınıza Uymayanlar" />
              </div>
            ) : (
              ""
            )}
            <div className="template-wrap-menu">
              {_.map(cantInstallList, (item, i) => (
                <TemplateCard
                  key={item.id}
                  item={item}
                  i={i}
                  type={ecommerceType}
                />
              ))}
            </div>
          </Segment>
          {/* 	{process.env.NODE_ENV !== 'production' && (
						<div>
							<pre>{JSON.stringify(list, null, 2)}</pre>
						</div>
					)} */}
        </Page.Wrap>
      </Page>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(Templates));
