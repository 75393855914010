import _ from "lodash";
import { get, getDialogtab } from "../util/api";
import { base_type_definition } from "../constants/definitions";

const extraSteps = {
  form: (data) => {
    if (data.sub) {
      let def = {};
      _.each(data.sub, (it) => {
        bootSub(it);
        if (it.__name)
          def[it.__name] = it.__default || it.def_obj || it.default;
        if (it.type === "tabs" || it.type === "accordion") {
          _.each(it.tabs, (tab) => {
            bootSub(tab);
            if (tab.__name)
              def[tab.__name] = tab.__default || tab.def_obj || tab.default;
          });
        }
        //else console.warn('Does not have key defined', it);
      });
      data.default = def;
    }
  },
};

/* extraSteps.array = (data) => {
	extraSteps.form(data);
	data.__default = data.default ? [data.default] : [];
}; */

extraSteps.array = (data) => {
  if (data.sub) {
    let def = {};
    _.each(data.sub, (it) => {
      bootSub(it);
      if (it.__name) def[it.__name] = it.__default;
    });
    data.__default = [def];
  }
};

extraSteps.object = (data) => {
  if (data.sub) {
    let def = {};
    _.each(data.sub, (it) => {
      bootSub(it);
      if (it.__name) def[it.__name] = it.__default;
    });
    data.__default = def;
  }
  //if (data.inline) data.className = data.className || 'inline-wrap';
};

export function clone(value) {
  return value && JSON.parse(JSON.stringify(value));
}

function bootTemplate(template) {
  let vars = {},
    vars_list = [];
  function findVariables(text) {
    return text && text.match(/{{\d+}}/g);
  }
  _.each(template.components, (it) => {
    switch (it.type) {
      case "BODY":
        let text = it.text;
        let match = findVariables(text);
        match = _.sortedUniq(match);
        _.each(match, (code) => {
          let ret = {
            type: "body",
            label: code,
            value: "",
            id: `${it.type}-${code}`,
          };
          vars_list.push(ret);
          vars[ret.id] = ret;
        });
        break;
      case "BUTTONS":
        _.each(it.buttons, (btn, i) => {
          if (btn.type === "URL") {
            let text = btn.text,
              url = btn.url;
            let match = findVariables(text);
            let match2 = findVariables(url);
            let merged = _.sortedUniq(_.concat(match, match2));
            _.each(merged, (code) => {
              if (!code) return;
              let ret = {
                type: "button",
                sub_type: btn.type,
                label: code,

                index: i,
                value: "",
                id: `${it.type}-${code}`,
              };
              vars_list.push(ret);
              vars[ret.id] = ret;
            });
          }
        });
        break;
      default:
        break;
    }
  });
  template.__build = {
    vars,
    vars_list,
  };
  template.sub = _.map(vars_list, (it) => {
    return {
      type: "__STRING__",
      __name: it.id,
      title: `${_.capitalize(it.type)} ${it.label}`,
    };
  });
  return template;
}

const INTEGRATIONS = {
  whatsapptemplate(data) {
    let entegrations = templateCache().entegrations;
    let list = entegrations && entegrations["whatsapp-business"];
    if (list) {
      data.type = "dropdown";
      data.list = _.map(list, (it) => {
        let templates = it.data && it.data.templates;
        return {
          text: it.shop,
          value: it.shop,
          data: it,
          sub: [
            {
              list: _.map(templates, (it) => {
                let template = bootTemplate(it);
                return {
                  text: it.name,
                  value: it.name,
                  data: template,
                  sub: template.sub,
                };
              }),
              title: "Template Name",
              type: "template",
              __name: "template",
            },
          ],
        };
      });
    }
  },
  whatsappnumber(data) {
    let entegrations = templateCache().entegrations;
    let list = entegrations && entegrations["whatsapp"];
    if (list) {
      data.type = "dropdown";
      data.list = _.map(list, (it) => {
        let number = it.data && it.data.number;
        return {
          text: number ? `${it.shop} (${number})` : it.shop,
          value: it.shop,
          data: it,
        };
      });
    }
  },
};

export const bootSub = (data, parentType) => {
  if (!data || data.__boot) return data;
  if (data.type === "condition") {
    bootSub(data.definition);
    data.__boot = "true";
    return data;
  }
  if (INTEGRATIONS[data.type]) INTEGRATIONS[data.type](data);
  if (data.type) {
    if (!defGen[data.type]) {
      let type_definition = templateCache().type_definition;
      let dataType = type_definition[data.type];
      let keep = _.pick(data, ["default"]);
      if (dataType) _.assignIn(data, dataType, keep);
    }
    if (extraSteps[data.type]) extraSteps[data.type](data);
  }

  if (data.sub && data.type !== "array") {
    let _def = (data.__default = {
      value: data.value,
      params: {},
    });
    _.each(data.sub, (it) => {
      bootSub(it, data.type);
      let _key = it.__name || it.key;
      if (_key) _def.params[_key] = it.__default;
      if (it.type === "tabs" || it.type === "accordion") {
        _.each(it.tabs, (tab) => {
          bootSub(tab);
          if (tab.__name)
            _def.params[tab.__name] =
              tab.__default || tab.def_obj || tab.default;
        });
      }
    });
  }
  if (data.list) {
    if (data.validation) {
      let entegrations = templateCache().entegrations;
      let list = entegrations[data.validation.type] || [];
      let has = {};
      _.each(list, (it) => (has[it.shop] = true));
      data.list = _.orderBy(data.list, (it) => {
        it.isValid = !!has[it.value];
        return it.isValid ? 0 : 1;
      });
    }
    _.each(data.list, (it) => {
      if (!it.key && it.value) it.key = it.value;
      bootSub(it, data.type);
    });

    let def_obj =
      (data.default && _.find(data.list, { value: data.default })) ||
      data.list[0];
    if (!data.__default) {
      data.__default = def_obj.__default;
    }
  }
  if (data.params) {
    let def = {};
    _.each(data.params, (it) => {
      bootSub(it, data.type);
      if (it.key) def[it.key] = it.default;
      else console.warn("Does not have key defined", it);
    });
    data.default = def;
  }

  if (parentType === "popup") {
    data.__default = {
      type: data.value,
      props: data.props || {},
      component: data.component,
      parameters: data.__default.params,
    };
  }

  if (data.type && defGen[data.type]) data.default = defGen[data.type](data);
  if (!data.__default)
    data.__default = data.def_obj || {
      value: data.default !== undefined ? data.default : data.value || null,
    };

  data.__boot = "true";
  return data;
};

const defaultOrEmpty = (data) => data.default || "";

const defGen = {
  string: defaultOrEmpty,
  cssinput: defaultOrEmpty,
  richtext: defaultOrEmpty,
  code: defaultOrEmpty,
  multistring: (data) => data.default || [],
  datetime: (data) => data.default || new Date(),
  number: (data) => (data.default !== undefined ? data.default : 1),
  dropdown: (data) =>
    data.default ||
    _.get(data, "list[0].default") ||
    _.get(data, "list[0].value"),
  template: (data) =>
    data.default ||
    _.get(data, "list[0].default") ||
    _.get(data, "list[0].value"),
  button: (data) => {
    if (!data.def_obj)
      data.def_obj = {
        title: data.default || "",
        onclick: { __type: "url", payload: "" },
      };
    if (!data.def_obj.__type) data.def_obj.__type = "onclick";
    data.__default = data.def_obj;
    return data.def_obj;
  },
  screen: (data) =>
    data.default ||
    _.get(data, "list[0].default") ||
    _.get(data, "list[0].value"),
  boolean: (data) => (data.default !== undefined ? data.default : false),
  tabs: () => undefined,
  accordion: () => undefined,
  array: () => undefined,
};

const bootData = (data) => {
  data.options = {
    type: "dropdown",
    default: data.default || "all",
    root: true,
    list: data.options,
    compact: false,
  };
  return data;
};
const bootForm = (data, cache) => {
  bootSub(data.options);
  if (data.multi && data.options.__default && data.options.__default.params) {
    data.options.__default = clone(data.options.__default);
    data.options.__default.params = [data.options.__default.params];
  }
  cache[data.save] = [data.options.__default];
};
const createCache = (fn) => {
  let cache,
    cache_fn = function () {
      if (!cache) cache = fn();

      return cache;
    };
  cache_fn.clear = () => (cache = null);
  return cache_fn;
};

export const getFilterOptions = createCache(() => {
  let { Forms } = templateCache();
  let preData = {};
  _.each(Forms, (data) => bootForm(data, preData));
  const ratio_conf = {
    options: {
      type: "number",
      size: "60",
      //title: 'A/B Testing Ratio',
      className: "form-fix",
      pre_text: "Control group ratio is ",
      post_text: "percent.",
      placeholder: "10",
      default: 10,
      max: "100",
      min: "0",
    },
  };
  bootForm(ratio_conf, preData);
  return {
    trigger: Forms.trigger,
    frequency: Forms.frequency,
    ratio: ratio_conf.options,
    options: [Forms.who, Forms.where, Forms.when],
    Forms,
    preData,
  };
});

export const getPageOptions = createCache(() => {
  let { Recomendation, strategy } = templateCache();
  let preData = {};
  _.each(Recomendation, (data) => bootForm(data, preData));
  return { pagetype: Recomendation[0], preData, strategy };
});

let cache, LAST_DEFINITION_TYPE;

async function BOOT_Actions() {
  let data = await get("/campaign/definitions");
  let type_definition = _.clone(base_type_definition);
  if (data && data.success && data.definitions) {
    let {
      who: WhoOptions,
      when: WhenOptions,
      where: WhereOptions,
      trigger: TriggerOptions,
      frequency: FrequencyOptions,
      action: actionList,
      widget: widgetList,
      recommendation,
      recommendationstate,
      strategy,
      extra,
      recommend_widget,
    } = data.definitions;

    /* 
		actionList = [
			actionList[0],
			{
				sub: [
					{ type: '__STRING__', divider: { name: 'New Text', icon: 'edit' }, __name: 'customHtml' },
					{
						divider: { name: 'Replaced Element Selector', icon: 'map marker alternate' },
						type: 'cssinput',
						selector: 'full',
						__name: 'css',
						default: '',
					},
				],
				text: 'Modify Page',
				type: 'form',
				image: '/images/modify_action.png',
				value: 'modifyPage',
				description: 'Modify a part of the page.',
			},
			actionList[1],
		]; */
    const Forms = {
      trigger: bootData({
        save: "trigger",
        question: "Trigger",
        options: TriggerOptions,
        default: "windowLoadedTrigger",
        andText: "or",
        showicon: true,
      }),
      frequency: bootData({
        save: "frequency",
        question: "Frequency",
        options: FrequencyOptions,
        default: "oncePerPageViewFrequency",
        mini: true,
      }),
      who: bootData({
        save: "who",
        question: "Who",
        attached: "top",
        color: "grey",
        options: WhoOptions,
        multi: true,
      }),
      where: bootData({
        save: "where",
        question: "Where",
        color: "grey",
        options: WhereOptions,
        multi: true,
      }),
      when: bootData({
        save: "when",
        question: "When",
        attached: "bottom",
        color: "grey",
        options: WhenOptions,
        multi: true,
      }),
    };
    let actionKeyMap = _.keyBy(actionList, "value");

    let Recomendation = [
      bootData({
        save: "pagetype",
        question: "PageType",
        options: recommendation,
        default: "typeHomePage",
      }),
    ];

    if (extra && Object.keys(extra).length)
      type_definition = _.assignIn({}, type_definition, extra);
    return {
      Forms,
      WhoOptions,
      recommendation,
      recommendationstate,
      WhereOptions,
      WhenOptions,
      TriggerOptions,
      type_definition,
      actionList,
      actionKeyMap,
      FrequencyOptions,
      widgetList: _.map(widgetList, (it) => {
        if (it.sub) {
          it.sub.push({
            type: "accordion",
            title: "Advanced",
            tabs: [
              {
                type: "number",
                min: 0,
                title: "Timeout Second",
                __name: "timeout",
                default: 0,
                placeholder: "Timeout Seconds",
              },
              {
                type: "string",
                __name: "className",
                title: "Html classname",
                default: "",
                placeholder: "classname",
              },
            ],
          });
          //it.sub.push({ type: 'number', min: 0, title: 'Timeout Second', __name: 'timeout', default: 0 });
        }
        return it;
      }),
      Recomendation,
      strategy,
      recommend_widget,
    };
  }
}

async function BOOT_Schedule(schedule_prepare) {
  let data = await get("/campaign/definitions?schedule=true");
  //let entegrations = await get("/campaign/integration/list");
  let entegrations = await getDialogtab(
    `https://app.dialogtab.com/api/widget/getIntegration?id=${schedule_prepare}`
  );

  let type_definition = _.clone(base_type_definition);
  if (data && data.success && data.definitions) {
    let {
      who: WhoOptions,
      when: WhenOptions,
      where: WhereOptions,
      trigger: TriggerOptions,
      action: actionList,
      extra,
      schedule_delay,
      cancel,
      push_mode,
    } = data.definitions;

    if (extra && Object.keys(extra).length)
      type_definition = _.assignIn({}, type_definition, extra);

    const Forms = {
      trigger: bootData({
        save: "trigger",
        question: "Trigger",
        options: TriggerOptions,
        attached: "top",
        default: "windowLoadedTrigger",
        multi: true,
        disable_add_button: true,
      }),
      who: bootData({
        save: "who",
        question: "Who",
        attached: "top",
        color: "grey",
        options: WhoOptions,
        multi: true,
      }),
      where: bootData({
        save: "where",
        question: "Where",
        color: "grey",
        options: WhereOptions,
        multi: true,
      }),
      when: bootData({
        save: "when",
        question: "When",
        attached: "bottom",
        color: "grey",
        options: WhenOptions,
        multi: true,
      }),
      cancel: bootData({
        save: "cancel",
        question: "Cancel Send",
        color: "grey",
        options: cancel,
        multi: true,
        disable_add_button: true,
      }),
      schedule_delay: bootData({
        save: "schedule_delay",
        question: "Options",
        attached: "bottom",
        color: "grey",
        options: schedule_delay,
        multi: true,
        disable_add_button: true,
      }),
      push_mode: bootData({
        save: "push_mode",
        question: "Action Mode",
        options: push_mode,
        default: "stopOnPush",
        mini: true,
      }),
    };
    let actionKeyMap = _.keyBy(actionList, "value");

    return {
      WhoOptions,
      WhereOptions,
      WhenOptions,
      TriggerOptions,
      type_definition,
      actionList,
      actionKeyMap,
      Forms,
      entegrations: _.groupBy(
        entegrations && entegrations.integrations,
        "type"
      ),
    };
  }
}

export const SetUp = async (DEFINITION_TYPE, schedule_prepare) => {
  if (cache) return cache;
  LAST_DEFINITION_TYPE = DEFINITION_TYPE;
  if (DEFINITION_TYPE === "schedule")
    cache = await BOOT_Schedule(schedule_prepare);
  else cache = await BOOT_Actions();
  return cache;
};

export const templateCache = () => {
  return cache;
};

export const templateCacheControl = (DEFINITION_TYPE) => {
  if (LAST_DEFINITION_TYPE === DEFINITION_TYPE) return cache;
  else {
    getFilterOptions.clear();
    getPageOptions.clear();
    cache = null;
  }
};
