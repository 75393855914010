import React, { Component } from 'react';
import { Button, Icon, Modal, Input, Form, Message } from 'semantic-ui-react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { listen, send } from '../util/listen';
//import _ from 'lodash';

const sourceControl = 'yapaytech-personalize';
const subFrame = 'insert-preview';

class CssSelector extends Component {
	state = { url: this.props.url || '', css: this.props._css || '', status: 'loading', loading: true, _value: this.props.url || '' };
	ifr = React.createRef();

	send(data = {}) {
		data.source = sourceControl;
		data.type = 'css-selector';
		send(this.ifr, data);
	}

	componentDidMount() {
		listen(sourceControl, data => {
			if (data.status === 'ready') return this.send({ load: 'insert-script' });
			this.process(data);
		});
	}

	componentWillUnmount() {
		this.send({ method: 'remove' });
		listen(sourceControl, null);
	}

	process(data) {
		if (data.status === 'inserter-loaded') {
			const preview = this.props.preview && this.props.preview();
			this.send({ method: 'set', css: this.state.css, preview, selector: this.selector });
		} else this.setState({ status: data.status, css: data.css || this.state.css });
	}

	onSelect = () => {
		let selected = this.state.css;
		if (this.props.onSelect && selected) {
			this.props.onSelect({ value: selected, _url: this.state.url });
			this.props.onClose();
		}
	};

	onStart = () => {
		this.send({ method: 'start' });
	};

	onStop = () => {
		this.send({ method: 'stop' });
	};

	go = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		let format = url => {
			if (/^http/.test(url)) return url;
			return null;
		};
		let next = format(this.state._value);
		let current = format(this.state.url);
		let diff = next !== current;
		this.setState({ url: this.state._value, status: diff ? 'loading' : this.state.status, loading: diff });
	};

	onLoad = () => {
		this.setState({ loading: false });
		if (this.state.status === 'loading') {
			this.setState({ status: 'loaded' });
			setTimeout(() => {
				if (this.state.status === 'loaded') {
					this.setState({ status: 'missing' });
				}
			}, 1000);
		}
	};

	get url() {
		let current = this.state.url;
		if (/^http/.test(current)) return current;
		return null;
	}

	get selector() {
		let data = this.props.data;
		return data && data.selector;
	}

	printError() {
		if (this.state.loading) return null;
		let current = this.state.url;
		if (!this.url)
			return (
				<div className="missing-line">
					<Message
						compact
						icon="linkify"
						header={
							current && !/^http/.test(current)
								? 'The url must start with http:// or https://'
								: 'Please type the url you would like to select from'
						}
						content="You can edit the url from top menu input"
					/>
				</div>
			);
		if (this.state.status === 'missing')
			return (
				<div className="missing-line">
					<Message
						warning
						icon="warning"
						header="Script is missing on this page."
						content={
							<span>
								You can find how to add script to your website from installation section from{' '}
								<a href="/settings/installation" target="_blank">
									Here
								</a>
							</span>
						}
					/>
				</div>
			);
		return null;
	}

	render() {
		return (
			<Modal dimmer="inverted" open={true} className="insert-wrap">
				<Modal.Header>
					<Button basic icon color="red" onClick={this.props.onClose}>
						<Icon name="close" />
					</Button>
					<Form className="css-selector" onSubmit={this.go}>
						<Input
							placeholder="https://yourwebsite.com"
							value={this.state._value}
							onChange={(e, { value }) => this.setState({ _value: !value || /^http|^(htt|ht|h)$/.test(value) ? value : 'https://' + value })}
							className="tiny"
							autoFocus={true}
							action={
								<Button basic icon loading={this.state.loading}>
									<Icon name="angle right" />
								</Button>
							}
						/>
						{/* <span className="css-current">{this.state.css}</span> */}
					</Form>
					<div className="ml-auto no-wrap">
						{this.state.status !== 'active' && (
							<Button basic icon color="blue" onClick={this.onStart}>
								<Icon name="lightning" /> Seçimi Başlat
							</Button>
						)}
						{this.state.status === 'active' && (
							<Button basic icon color="red" onClick={this.onStop}>
								<Icon name="stop circle outline" /> Stop Selector
							</Button>
						)}
						{this.state.status === 'selected' && (
							<Button icon color="green" onClick={this.onSelect}>
								<Icon name="save" /> Kaydet
							</Button>
						)}
					</div>
				</Modal.Header>
				<Modal.Content
					css={`
						position: relative;
					`}
				>
					{this.printError()}
					<iframe title={subFrame} onLoad={this.onLoad} ref={this.ifr} src={this.url || 'about:blank'} />
				</Modal.Content>
			</Modal>
		);
	}
}
export default ({ children, ...props }) => {
	const [open, setOpen] = React.useState(false);
	return (
		<>
			{open && (
				<CssSelector
					onClose={() => {
						setOpen(false);
					}}
					{...props}
				/>
			)}
			<div
				onClick={() => setOpen(true)}
				css={`
					display: flex;
					align-items: center;
				`}
			>
				{children}
			</div>
		</>
	);
};
