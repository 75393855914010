/**
 * Recommendation strategy - per slot
 * auto
 * similarity
 * viewed together
 * bought together
 * popularity
 * personalized
 * user affinity
 * recently viewed
 */
/**
 * Custom Segmentation
 *
 * User Type -is- -Returning User-
 * Device Type -is- -Table-
 * Weather Condition -is- -Thunderstorms- -in the next 4 days-
 * Lifetime Value -at least- -$1200-
 * Objective -Purchase-
 *
 */
/**
 * Site Personalization
 *	-Targeting
 *		-Dynamic Content
 *			-Weather
 * /
/**
 * Audience
 * 
 * -> Page visited on my site contains hoodie at least 1 times
 * -> Purchase made at least 2 times
 * -> Purchase value at least $100
 * 
 * 
 * => All conditions must be met within -30 days- for a visitor to belong to this Audience.
 * => Keep visitor within this Audience for -365- days past the timeframe in which they met all conditions.
 */

export const base_type_definition = {
  __STRING__: {
    type: "string",
  },
  __TEXTAREA__: {
    type: "textArea",
  },
  __MULTI_STRING__: {
    type: "multistring",
  },
  __NUMBER__: {
    type: "number",
    placeholder: "0",
    size: 60,
  },
  __DATE_PICKER__: {
    type: "datetime",
    d_time: false,
  },
  __COLOR_PICKER__: {
    type: "color",
  },
  __IMAGE__: {
    type: "image_file",
  },
  __EXCEL__: {
    type: "doc_file",
  },
  __YOUTUBE__: {
    type: "youtube_embed",
  },
  __TIME_PICKER__: {
    type: "datetime",
    d_date: false,
  },
  __DATETIME_PICKER__: {
    type: "datetime",
  },
  __BOOLEAN_OPERATIONS__: {
    type: "dropdown",
    size: 80,
    list: [
      {
        value: "equals",
        text: "is",
      },
      {
        value: "not_equals",
        text: "is not",
      },
    ],
    default: "equals",
  },
  __NUMERIC_OPERATIONS__: {
    type: "dropdown",
    list: [
      {
        value: "equals",
        text: "equal to",
      },
      {
        value: "not_equals",
        text: "not equal to",
      },
      {
        value: "greater_than",
        text: "greater than",
      },
      {
        value: "lower_than",
        text: "less than",
      },
      {
        value: "greater_than_or_equals",
        text: "at least",
      },
      {
        value: "lower_than_or_equals",
        text: "at most",
      },
    ],
    default: "greater_than_or_equals",
    size: 90,
  },
  __STRING_OPERATIONS__: {
    type: "dropdown",
    list: [
      {
        value: "equals",
        text: "matches with",
      },
      {
        value: "not_equals",
        text: "not matches with",
      },
      {
        value: "starts_with",
        text: "starts with",
      },
      {
        value: "not_starts_with",
        text: "not starts with",
      },
      {
        value: "ends_with",
        text: "ends with",
      },
      {
        value: "not_ends_with",
        text: "not ends with",
      },
      {
        value: "contains",
        text: "contains",
      },
      {
        value: "not_contains",
        text: "not contains",
      },
      {
        value: "exists",
        text: "exists",
      },
      {
        value: "not_exists",
        text: "not exists",
      },
    ],
    default: "contains",
  },
  __ALL_OPERATIONS__: {
    type: "dropdown",
    list: [
      {
        value: "equals",
        text: "matches with",
      },
      {
        value: "not_equals",
        text: "not matches with",
      },
      {
        value: "greater_than",
        text: "greater than",
      },
      {
        value: "lower_than",
        text: "less than",
      },
      {
        value: "greater_than_or_equals",
        text: "at least",
      },
      {
        value: "lower_than_or_equals",
        text: "at most",
      },
      {
        value: "starts_with",
        text: "starts with",
      },
      {
        value: "not_starts_with",
        text: "not starts with",
      },
      {
        value: "ends_with",
        text: "ends with",
      },
      {
        value: "not_ends_with",
        text: "not ends with",
      },
      {
        value: "contains",
        text: "contains",
      },
      {
        value: "not_contains",
        text: "not contains",
      },
      {
        value: "mode",
        text: "mode",
        sub: [
          {
            __name: "mode",
            type: "__NUMBER__",
          },
        ],
      },
    ],
    default: "equals",
  },
  __TIME_OPERATIONS__: {
    type: "dropdown",
    size: 100,
    list: [
      {
        value: "time_greater_than",
        text: "After",
      },
      {
        value: "time_equals",
        text: "At",
      },
      {
        value: "time_lower_than",
        text: "Before",
      },
    ],
    default: "time_greater_than",
  },
  __AUDIENCE_OPERATIONS__: {
    type: "dropdown",
    size: 150,
    list: [
      {
        value: "equals",
        text: "belongs to",
      },
      {
        value: "not_equals",
        text: "does not belongs to",
      },
    ],
    default: "equals",
  },
  __TRAFFIC_SOURCE__: {
    type: "dropdown",
    list: [
      {
        value: "direct",
        text: "Direct",
      },
      {
        value: "social",
        text: "Social",
      },
      {
        value: "referral",
        text: "Referral",
      },
      {
        value: "organic-search",
        text: "Organic Search",
      },
    ],
  },
  __DEVICE_CATEGORY__: {
    type: "dropdown",
    list: [
      {
        value: "desktop",
        text: "Desktop",
      },
      {
        value: "mobile",
        text: "Smartphone",
      },
      {
        value: "tablet",
        text: "Tablet",
      },
    ],
  },
  __USER_GENDER__: {
    type: "dropdown",
    list: [
      {
        value: "male",
        text: "Male",
      },
      {
        value: "female",
        text: "Female",
      },
    ],
  },
  __OPERATING_SYSTEM__: {
    type: "dropdown",
    list: [
      {
        value: "windows",
        text: "Windows",
      },
      {
        value: "mac-os",
        text: "Mac OS",
      },
      {
        value: "linux",
        text: "Linux",
      },
      {
        value: "android",
        text: "Android",
      },
      {
        value: "ios",
        text: "Ios",
      },
    ],
  },
  __DEVICE_BRAND__: {
    type: "dropdown",
    list: [
      {
        value: "apple",
        text: "Apple",
      },
      {
        value: "samsung",
        text: "Samsung",
      },
    ],
  },
  __BROWSER__: {
    type: "dropdown",
    list: [
      {
        value: "chrome",
        text: "Chrome",
      },
      {
        value: "firefox",
        text: "Firefox",
      },
      {
        value: "edge",
        text: "Edge",
      },
      {
        value: "ie",
        text: "Internet Explorer",
      },
      {
        value: "opera",
        text: "Opera",
      },
      {
        value: "safari",
        text: "Safari",
      },
    ],
  },
  __SCREEN_RESOLUTION__: {
    type: "dropdown",
    list: [
      {
        value: "small",
        text: "Small",
      },
      {
        value: "medium",
        text: "Medium",
      },
      {
        value: "large",
        text: "Large",
      },
    ],
  },
  __COUNTRY__: {
    type: "dropdown",
    default: "tr",
    list: [
      {
        key: "af",
        value: "af",
        text: "Afghanistan",
        flag: "af",
      },
      {
        key: "ax",
        value: "ax",
        text: "Aland Islands",
        flag: "ax",
      },
      {
        key: "al",
        value: "al",
        text: "Albania",
        flag: "al",
      },
      {
        key: "dz",
        value: "dz",
        text: "Algeria",
        flag: "dz",
      },
      {
        key: "as",
        value: "as",
        text: "American Samoa",
        flag: "as",
      },
      {
        key: "ad",
        value: "ad",
        text: "Andorra",
        flag: "ad",
      },
      {
        key: "ao",
        value: "ao",
        text: "Angola",
        flag: "ao",
      },
      {
        key: "ai",
        value: "ai",
        text: "Anguilla",
        flag: "ai",
      },
      {
        key: "ag",
        value: "ag",
        text: "Antigua",
        flag: "ag",
      },
      {
        key: "ar",
        value: "ar",
        text: "Argentina",
        flag: "ar",
      },
      {
        key: "am",
        value: "am",
        text: "Armenia",
        flag: "am",
      },
      {
        key: "aw",
        value: "aw",
        text: "Aruba",
        flag: "aw",
      },
      {
        key: "au",
        value: "au",
        text: "Australia",
        flag: "au",
      },
      {
        key: "at",
        value: "at",
        text: "Austria",
        flag: "at",
      },
      {
        key: "az",
        value: "az",
        text: "Azerbaijan",
        flag: "az",
      },
      {
        key: "bs",
        value: "bs",
        text: "Bahamas",
        flag: "bs",
      },
      {
        key: "bh",
        value: "bh",
        text: "Bahrain",
        flag: "bh",
      },
      {
        key: "bd",
        value: "bd",
        text: "Bangladesh",
        flag: "bd",
      },
      {
        key: "bb",
        value: "bb",
        text: "Barbados",
        flag: "bb",
      },
      {
        key: "by",
        value: "by",
        text: "Belarus",
        flag: "by",
      },
      {
        key: "be",
        value: "be",
        text: "Belgium",
        flag: "be",
      },
      {
        key: "bz",
        value: "bz",
        text: "Belize",
        flag: "bz",
      },
      {
        key: "bj",
        value: "bj",
        text: "Benin",
        flag: "bj",
      },
      {
        key: "bm",
        value: "bm",
        text: "Bermuda",
        flag: "bm",
      },
      {
        key: "bt",
        value: "bt",
        text: "Bhutan",
        flag: "bt",
      },
      {
        key: "bo",
        value: "bo",
        text: "Bolivia",
        flag: "bo",
      },
      {
        key: "ba",
        value: "ba",
        text: "Bosnia",
        flag: "ba",
      },
      {
        key: "bw",
        value: "bw",
        text: "Botswana",
        flag: "bw",
      },
      {
        key: "bv",
        value: "bv",
        text: "Bouvet Island",
        flag: "bv",
      },
      {
        key: "br",
        value: "br",
        text: "Brazil",
        flag: "br",
      },
      {
        key: "vg",
        value: "vg",
        text: "British Virgin Islands",
        flag: "vg",
      },
      {
        key: "bn",
        value: "bn",
        text: "Brunei",
        flag: "bn",
      },
      {
        key: "bg",
        value: "bg",
        text: "Bulgaria",
        flag: "bg",
      },
      {
        key: "bf",
        value: "bf",
        text: "Burkina Faso",
        flag: "bf",
      },
      {
        key: "mm",
        value: "mm",
        text: "Burma",
        flag: "mm",
      },
      {
        key: "bi",
        value: "bi",
        text: "Burundi",
        flag: "bi",
      },
      {
        key: "tc",
        value: "tc",
        text: "Caicos Islands",
        flag: "tc",
      },
      {
        key: "kh",
        value: "kh",
        text: "Cambodia",
        flag: "kh",
      },
      {
        key: "cm",
        value: "cm",
        text: "Cameroon",
        flag: "cm",
      },
      {
        key: "ca",
        value: "ca",
        text: "Canada",
        flag: "ca",
      },
      {
        key: "cv",
        value: "cv",
        text: "Cape Verde",
        flag: "cv",
      },
      {
        key: "ky",
        value: "ky",
        text: "Cayman Islands",
        flag: "ky",
      },
      {
        key: "cf",
        value: "cf",
        text: "Central African Republic",
        flag: "cf",
      },
      {
        key: "td",
        value: "td",
        text: "Chad",
        flag: "td",
      },
      {
        key: "cl",
        value: "cl",
        text: "Chile",
        flag: "cl",
      },
      {
        key: "cn",
        value: "cn",
        text: "China",
        flag: "cn",
      },
      {
        key: "cx",
        value: "cx",
        text: "Christmas Island",
        flag: "cx",
      },
      {
        key: "cc",
        value: "cc",
        text: "Cocos Islands",
        flag: "cc",
      },
      {
        key: "co",
        value: "co",
        text: "Colombia",
        flag: "co",
      },
      {
        key: "km",
        value: "km",
        text: "Comoros",
        flag: "km",
      },
      {
        key: "cd",
        value: "cd",
        text: "Congo",
        flag: "cd",
      },
      {
        key: "cg",
        value: "cg",
        text: "Congo Brazzaville",
        flag: "cg",
      },
      {
        key: "ck",
        value: "ck",
        text: "Cook Islands",
        flag: "ck",
      },
      {
        key: "cr",
        value: "cr",
        text: "Costa Rica",
        flag: "cr",
      },
      {
        key: "ci",
        value: "ci",
        text: "Cote Divoire",
        flag: "ci",
      },
      {
        key: "hr",
        value: "hr",
        text: "Croatia",
        flag: "hr",
      },
      {
        key: "cu",
        value: "cu",
        text: "Cuba",
        flag: "cu",
      },
      {
        key: "cy",
        value: "cy",
        text: "Cyprus",
        flag: "cy",
      },
      {
        key: "cz",
        value: "cz",
        text: "Czech Republic",
        flag: "cz",
      },
      {
        key: "dk",
        value: "dk",
        text: "Denmark",
        flag: "dk",
      },
      {
        key: "dj",
        value: "dj",
        text: "Djibouti",
        flag: "dj",
      },
      {
        key: "dm",
        value: "dm",
        text: "Dominica",
        flag: "dm",
      },
      {
        key: "do",
        value: "do",
        text: "Dominican Republic",
        flag: "do",
      },
      {
        key: "ec",
        value: "ec",
        text: "Ecuador",
        flag: "ec",
      },
      {
        key: "eg",
        value: "eg",
        text: "Egypt",
        flag: "eg",
      },
      {
        key: "sv",
        value: "sv",
        text: "El Salvador",
        flag: "sv",
      },
      {
        key: "gq",
        value: "gq",
        text: "Equatorial Guinea",
        flag: "gq",
      },
      {
        key: "er",
        value: "er",
        text: "Eritrea",
        flag: "er",
      },
      {
        key: "ee",
        value: "ee",
        text: "Estonia",
        flag: "ee",
      },
      {
        key: "et",
        value: "et",
        text: "Ethiopia",
        flag: "et",
      },
      {
        key: "eu",
        value: "eu",
        text: "Europeanunion",
        flag: "eu",
      },
      {
        key: "fk",
        value: "fk",
        text: "Falkland Islands",
        flag: "fk",
      },
      {
        key: "fo",
        value: "fo",
        text: "Faroe Islands",
        flag: "fo",
      },
      {
        key: "fj",
        value: "fj",
        text: "Fiji",
        flag: "fj",
      },
      {
        key: "fi",
        value: "fi",
        text: "Finland",
        flag: "fi",
      },
      {
        key: "fr",
        value: "fr",
        text: "France",
        flag: "fr",
      },
      {
        key: "gf",
        value: "gf",
        text: "French Guiana",
        flag: "gf",
      },
      {
        key: "pf",
        value: "pf",
        text: "French Polynesia",
        flag: "pf",
      },
      {
        key: "tf",
        value: "tf",
        text: "French Territories",
        flag: "tf",
      },
      {
        key: "ga",
        value: "ga",
        text: "Gabon",
        flag: "ga",
      },
      {
        key: "gm",
        value: "gm",
        text: "Gambia",
        flag: "gm",
      },
      {
        key: "ge",
        value: "ge",
        text: "Georgia",
        flag: "ge",
      },
      {
        key: "de",
        value: "de",
        text: "Germany",
        flag: "de",
      },
      {
        key: "gh",
        value: "gh",
        text: "Ghana",
        flag: "gh",
      },
      {
        key: "gi",
        value: "gi",
        text: "Gibraltar",
        flag: "gi",
      },
      {
        key: "gr",
        value: "gr",
        text: "Greece",
        flag: "gr",
      },
      {
        key: "gl",
        value: "gl",
        text: "Greenland",
        flag: "gl",
      },
      {
        key: "gd",
        value: "gd",
        text: "Grenada",
        flag: "gd",
      },
      {
        key: "gp",
        value: "gp",
        text: "Guadeloupe",
        flag: "gp",
      },
      {
        key: "gu",
        value: "gu",
        text: "Guam",
        flag: "gu",
      },
      {
        key: "gt",
        value: "gt",
        text: "Guatemala",
        flag: "gt",
      },
      {
        key: "gn",
        value: "gn",
        text: "Guinea",
        flag: "gn",
      },
      {
        key: "gw",
        value: "gw",
        text: "Guinea-Bissau",
        flag: "gw",
      },
      {
        key: "gy",
        value: "gy",
        text: "Guyana",
        flag: "gy",
      },
      {
        key: "ht",
        value: "ht",
        text: "Haiti",
        flag: "ht",
      },
      {
        key: "hm",
        value: "hm",
        text: "Heard Island",
        flag: "hm",
      },
      {
        key: "hn",
        value: "hn",
        text: "Honduras",
        flag: "hn",
      },
      {
        key: "hk",
        value: "hk",
        text: "Hong Kong",
        flag: "hk",
      },
      {
        key: "hu",
        value: "hu",
        text: "Hungary",
        flag: "hu",
      },
      {
        key: "is",
        value: "is",
        text: "Iceland",
        flag: "is",
      },
      {
        key: "in",
        value: "in",
        text: "India",
        flag: "in",
      },
      {
        key: "io",
        value: "io",
        text: "Indian Ocean Territory",
        flag: "io",
      },
      {
        key: "id",
        value: "id",
        text: "Indonesia",
        flag: "id",
      },
      {
        key: "ir",
        value: "ir",
        text: "Iran",
        flag: "ir",
      },
      {
        key: "iq",
        value: "iq",
        text: "Iraq",
        flag: "iq",
      },
      {
        key: "ie",
        value: "ie",
        text: "Ireland",
        flag: "ie",
      },
      {
        key: "il",
        value: "il",
        text: "Israel",
        flag: "il",
      },
      {
        key: "it",
        value: "it",
        text: "Italy",
        flag: "it",
      },
      {
        key: "jm",
        value: "jm",
        text: "Jamaica",
        flag: "jm",
      },
      {
        key: "sj",
        value: "sj",
        text: "Jan Mayen",
        flag: "sj",
      },
      {
        key: "jp",
        value: "jp",
        text: "Japan",
        flag: "jp",
      },
      {
        key: "jo",
        value: "jo",
        text: "Jordan",
        flag: "jo",
      },
      {
        key: "kz",
        value: "kz",
        text: "Kazakhstan",
        flag: "kz",
      },
      {
        key: "ke",
        value: "ke",
        text: "Kenya",
        flag: "ke",
      },
      {
        key: "ki",
        value: "ki",
        text: "Kiribati",
        flag: "ki",
      },
      {
        key: "kw",
        value: "kw",
        text: "Kuwait",
        flag: "kw",
      },
      {
        key: "kg",
        value: "kg",
        text: "Kyrgyzstan",
        flag: "kg",
      },
      {
        key: "la",
        value: "la",
        text: "Laos",
        flag: "la",
      },
      {
        key: "lv",
        value: "lv",
        text: "Latvia",
        flag: "lv",
      },
      {
        key: "lb",
        value: "lb",
        text: "Lebanon",
        flag: "lb",
      },
      {
        key: "ls",
        value: "ls",
        text: "Lesotho",
        flag: "ls",
      },
      {
        key: "lr",
        value: "lr",
        text: "Liberia",
        flag: "lr",
      },
      {
        key: "ly",
        value: "ly",
        text: "Libya",
        flag: "ly",
      },
      {
        key: "li",
        value: "li",
        text: "Liechtenstein",
        flag: "li",
      },
      {
        key: "lt",
        value: "lt",
        text: "Lithuania",
        flag: "lt",
      },
      {
        key: "lu",
        value: "lu",
        text: "Luxembourg",
        flag: "lu",
      },
      {
        key: "mo",
        value: "mo",
        text: "Macau",
        flag: "mo",
      },
      {
        key: "mk",
        value: "mk",
        text: "Macedonia",
        flag: "mk",
      },
      {
        key: "mg",
        value: "mg",
        text: "Madagascar",
        flag: "mg",
      },
      {
        key: "mw",
        value: "mw",
        text: "Malawi",
        flag: "mw",
      },
      {
        key: "my",
        value: "my",
        text: "Malaysia",
        flag: "my",
      },
      {
        key: "mv",
        value: "mv",
        text: "Maldives",
        flag: "mv",
      },
      {
        key: "ml",
        value: "ml",
        text: "Mali",
        flag: "ml",
      },
      {
        key: "mt",
        value: "mt",
        text: "Malta",
        flag: "mt",
      },
      {
        key: "mh",
        value: "mh",
        text: "Marshall Islands",
        flag: "mh",
      },
      {
        key: "mq",
        value: "mq",
        text: "Martinique",
        flag: "mq",
      },
      {
        key: "mr",
        value: "mr",
        text: "Mauritania",
        flag: "mr",
      },
      {
        key: "mu",
        value: "mu",
        text: "Mauritius",
        flag: "mu",
      },
      {
        key: "yt",
        value: "yt",
        text: "Mayotte",
        flag: "yt",
      },
      {
        key: "mx",
        value: "mx",
        text: "Mexico",
        flag: "mx",
      },
      {
        key: "fm",
        value: "fm",
        text: "Micronesia",
        flag: "fm",
      },
      {
        key: "md",
        value: "md",
        text: "Moldova",
        flag: "md",
      },
      {
        key: "mc",
        value: "mc",
        text: "Monaco",
        flag: "mc",
      },
      {
        key: "mn",
        value: "mn",
        text: "Mongolia",
        flag: "mn",
      },
      {
        key: "me",
        value: "me",
        text: "Montenegro",
        flag: "me",
      },
      {
        key: "ms",
        value: "ms",
        text: "Montserrat",
        flag: "ms",
      },
      {
        key: "ma",
        value: "ma",
        text: "Morocco",
        flag: "ma",
      },
      {
        key: "mz",
        value: "mz",
        text: "Mozambique",
        flag: "mz",
      },
      {
        key: "na",
        value: "na",
        text: "Namibia",
        flag: "na",
      },
      {
        key: "nr",
        value: "nr",
        text: "Nauru",
        flag: "nr",
      },
      {
        key: "np",
        value: "np",
        text: "Nepal",
        flag: "np",
      },
      {
        key: "nl",
        value: "nl",
        text: "Netherlands",
        flag: "nl",
      },
      {
        key: "an",
        value: "an",
        text: "Netherlandsantilles",
        flag: "an",
      },
      {
        key: "nc",
        value: "nc",
        text: "New Caledonia",
        flag: "nc",
      },
      {
        key: "pg",
        value: "pg",
        text: "New Guinea",
        flag: "pg",
      },
      {
        key: "nz",
        value: "nz",
        text: "New Zealand",
        flag: "nz",
      },
      {
        key: "ni",
        value: "ni",
        text: "Nicaragua",
        flag: "ni",
      },
      {
        key: "ne",
        value: "ne",
        text: "Niger",
        flag: "ne",
      },
      {
        key: "ng",
        value: "ng",
        text: "Nigeria",
        flag: "ng",
      },
      {
        key: "nu",
        value: "nu",
        text: "Niue",
        flag: "nu",
      },
      {
        key: "nf",
        value: "nf",
        text: "Norfolk Island",
        flag: "nf",
      },
      {
        key: "kp",
        value: "kp",
        text: "North Korea",
        flag: "kp",
      },
      {
        key: "mp",
        value: "mp",
        text: "Northern Mariana Islands",
        flag: "mp",
      },
      {
        key: "no",
        value: "no",
        text: "Norway",
        flag: "no",
      },
      {
        key: "om",
        value: "om",
        text: "Oman",
        flag: "om",
      },
      {
        key: "pk",
        value: "pk",
        text: "Pakistan",
        flag: "pk",
      },
      {
        key: "pw",
        value: "pw",
        text: "Palau",
        flag: "pw",
      },
      {
        key: "ps",
        value: "ps",
        text: "Palestine",
        flag: "ps",
      },
      {
        key: "pa",
        value: "pa",
        text: "Panama",
        flag: "pa",
      },
      {
        key: "py",
        value: "py",
        text: "Paraguay",
        flag: "py",
      },
      {
        key: "pe",
        value: "pe",
        text: "Peru",
        flag: "pe",
      },
      {
        key: "ph",
        value: "ph",
        text: "Philippines",
        flag: "ph",
      },
      {
        key: "pn",
        value: "pn",
        text: "Pitcairn Islands",
        flag: "pn",
      },
      {
        key: "pl",
        value: "pl",
        text: "Poland",
        flag: "pl",
      },
      {
        key: "pt",
        value: "pt",
        text: "Portugal",
        flag: "pt",
      },
      {
        key: "pr",
        value: "pr",
        text: "Puerto Rico",
        flag: "pr",
      },
      {
        key: "qa",
        value: "qa",
        text: "Qatar",
        flag: "qa",
      },
      {
        key: "re",
        value: "re",
        text: "Reunion",
        flag: "re",
      },
      {
        key: "ro",
        value: "ro",
        text: "Romania",
        flag: "ro",
      },
      {
        key: "ru",
        value: "ru",
        text: "Russia",
        flag: "ru",
      },
      {
        key: "rw",
        value: "rw",
        text: "Rwanda",
        flag: "rw",
      },
      {
        key: "sh",
        value: "sh",
        text: "Saint Helena",
        flag: "sh",
      },
      {
        key: "kn",
        value: "kn",
        text: "Saint Kitts and Nevis",
        flag: "kn",
      },
      {
        key: "lc",
        value: "lc",
        text: "Saint Lucia",
        flag: "lc",
      },
      {
        key: "pm",
        value: "pm",
        text: "Saint Pierre",
        flag: "pm",
      },
      {
        key: "vc",
        value: "vc",
        text: "Saint Vincent",
        flag: "vc",
      },
      {
        key: "ws",
        value: "ws",
        text: "Samoa",
        flag: "ws",
      },
      {
        key: "sm",
        value: "sm",
        text: "San Marino",
        flag: "sm",
      },
      {
        key: "gs",
        value: "gs",
        text: "Sandwich Islands",
        flag: "gs",
      },
      {
        key: "st",
        value: "st",
        text: "Sao Tome",
        flag: "st",
      },
      {
        key: "sa",
        value: "sa",
        text: "Saudi Arabia",
        flag: "sa",
      },
      {
        key: "gb sct",
        value: "gb sct",
        text: "Scotland",
        flag: "gb sct",
      },
      {
        key: "sn",
        value: "sn",
        text: "Senegal",
        flag: "sn",
      },
      {
        key: "cs",
        value: "cs",
        text: "Serbia",
        flag: "cs",
      },
      {
        key: "rs",
        value: "rs",
        text: "Serbia",
        flag: "rs",
      },
      {
        key: "sc",
        value: "sc",
        text: "Seychelles",
        flag: "sc",
      },
      {
        key: "sl",
        value: "sl",
        text: "Sierra Leone",
        flag: "sl",
      },
      {
        key: "sg",
        value: "sg",
        text: "Singapore",
        flag: "sg",
      },
      {
        key: "sk",
        value: "sk",
        text: "Slovakia",
        flag: "sk",
      },
      {
        key: "si",
        value: "si",
        text: "Slovenia",
        flag: "si",
      },
      {
        key: "sb",
        value: "sb",
        text: "Solomon Islands",
        flag: "sb",
      },
      {
        key: "so",
        value: "so",
        text: "Somalia",
        flag: "so",
      },
      {
        key: "za",
        value: "za",
        text: "South Africa",
        flag: "za",
      },
      {
        key: "kr",
        value: "kr",
        text: "South Korea",
        flag: "kr",
      },
      {
        key: "es",
        value: "es",
        text: "Spain",
        flag: "es",
      },
      {
        key: "lk",
        value: "lk",
        text: "Sri Lanka",
        flag: "lk",
      },
      {
        key: "sd",
        value: "sd",
        text: "Sudan",
        flag: "sd",
      },
      {
        key: "sr",
        value: "sr",
        text: "Suriname",
        flag: "sr",
      },
      {
        key: "sz",
        value: "sz",
        text: "Swaziland",
        flag: "sz",
      },
      {
        key: "se",
        value: "se",
        text: "Sweden",
        flag: "se",
      },
      {
        key: "ch",
        value: "ch",
        text: "Switzerland",
        flag: "ch",
      },
      {
        key: "sy",
        value: "sy",
        text: "Syria",
        flag: "sy",
      },
      {
        key: "tw",
        value: "tw",
        text: "Taiwan",
        flag: "tw",
      },
      {
        key: "tj",
        value: "tj",
        text: "Tajikistan",
        flag: "tj",
      },
      {
        key: "tz",
        value: "tz",
        text: "Tanzania",
        flag: "tz",
      },
      {
        key: "th",
        value: "th",
        text: "Thailand",
        flag: "th",
      },
      {
        key: "tl",
        value: "tl",
        text: "Timorleste",
        flag: "tl",
      },
      {
        key: "tg",
        value: "tg",
        text: "Togo",
        flag: "tg",
      },
      {
        key: "tk",
        value: "tk",
        text: "Tokelau",
        flag: "tk",
      },
      {
        key: "to",
        value: "to",
        text: "Tonga",
        flag: "to",
      },
      {
        key: "tt",
        value: "tt",
        text: "Trinidad",
        flag: "tt",
      },
      {
        key: "tn",
        value: "tn",
        text: "Tunisia",
        flag: "tn",
      },
      {
        key: "tr",
        value: "tr",
        text: "Turkey",
        flag: "tr",
        sub: [
          {
            __name: "city",
            type: "__TURKEY_CITIES__",
          },
        ],
      },
      {
        key: "tm",
        value: "tm",
        text: "Turkmenistan",
        flag: "tm",
      },
      {
        key: "tv",
        value: "tv",
        text: "Tuvalu",
        flag: "tv",
      },
      {
        key: "ae",
        value: "ae",
        text: "U.A.E.",
        flag: "ae",
      },
      {
        key: "ug",
        value: "ug",
        text: "Uganda",
        flag: "ug",
      },
      {
        key: "ua",
        value: "ua",
        text: "Ukraine",
        flag: "ua",
      },
      {
        key: "gb",
        value: "gb",
        text: "United Kingdom",
        flag: "gb",
      },
      {
        key: "us",
        value: "us",
        text: "United States",
        flag: "us",
      },
      {
        key: "uy",
        value: "uy",
        text: "Uruguay",
        flag: "uy",
      },
      {
        key: "um",
        value: "um",
        text: "US Minor Islands",
        flag: "um",
      },
      {
        key: "vi",
        value: "vi",
        text: "US Virgin Islands",
        flag: "vi",
      },
      {
        key: "uz",
        value: "uz",
        text: "Uzbekistan",
        flag: "uz",
      },
      {
        key: "vu",
        value: "vu",
        text: "Vanuatu",
        flag: "vu",
      },
      {
        key: "va",
        value: "va",
        text: "Vatican City",
        flag: "va",
      },
      {
        key: "ve",
        value: "ve",
        text: "Venezuela",
        flag: "ve",
      },
      {
        key: "vn",
        value: "vn",
        text: "Vietnam",
        flag: "vn",
      },
      {
        key: "gb wls",
        value: "gb wls",
        text: "Wales",
        flag: "gb wls",
      },
      {
        key: "wf",
        value: "wf",
        text: "Wallis and Futuna",
        flag: "wf",
      },
      {
        key: "eh",
        value: "eh",
        text: "Western Sahara",
        flag: "eh",
      },
      {
        key: "ye",
        value: "ye",
        text: "Yemen",
        flag: "ye",
      },
      {
        key: "zm",
        value: "zm",
        text: "Zambia",
        flag: "zm",
      },
      {
        key: "zw",
        value: "zw",
        text: "Zimbabwe",
        flag: "zw",
      },
    ],
  },
  __TURKEY_CITIES__: {
    type: "dropdown",
    default: "all",
    list: [
      {
        key: "all",
        value: "all",
        text: "All",
      },
      {
        key: "Adana",
        value: "Adana",
        text: "Adana",
      },
      {
        key: "Adiyaman",
        value: "Adiyaman",
        text: "Adiyaman",
      },
      {
        key: "Afyonkarahisar",
        value: "Afyonkarahisar",
        text: "Afyonkarahisar",
      },
      {
        key: "Aksaray",
        value: "Aksaray",
        text: "Aksaray",
      },

      {
        key: "Amasya",
        value: "Amasya",
        text: "Amasya",
      },
      {
        key: "Ankara",
        value: "Ankara",
        text: "Ankara",
      },
      {
        key: "Antalya",
        value: "Antalya",
        text: "Antalya",
      },
      {
        key: "Ardahan",
        value: "Ardahan",
        text: "Ardahan",
      },
      {
        key: "Artvin",
        value: "Artvin",
        text: "Artvin",
      },
      {
        key: "Aydın",
        value: "Aydın",
        text: "Aydın",
      },
      {
        key: "Ağrı",
        value: "Ağrı",
        text: "Ağrı",
      },
      {
        key: "Balıkesir",
        value: "Balıkesir",
        text: "Balıkesir",
      },
      {
        key: "Bartın",
        value: "Bartın",
        text: "Bartın",
      },
      {
        key: "Batman",
        value: "Batman",
        text: "Batman",
      },
      {
        key: "Bayburt",
        value: "Bayburt",
        text: "Bayburt",
      },
      {
        key: "Bilecik",
        value: "Bilecik",
        text: "Bilecik",
      },
      {
        key: "Bingöl",
        value: "Bingöl",
        text: "Bingöl",
      },
      {
        key: "Bitlis",
        value: "Bitlis",
        text: "Bitlis",
      },
      {
        key: "Bolu",
        value: "Bolu",
        text: "Bolu",
      },
      {
        key: "Burdur",
        value: "Burdur",
        text: "Burdur",
      },
      {
        key: "Bursa",
        value: "Bursa",
        text: "Bursa",
      },
      {
        key: "Canakkale",
        value: "Canakkale",
        text: "Canakkale",
      },
      {
        key: "Denizli",
        value: "Denizli",
        text: "Denizli",
      },
      {
        key: "Diyarbakir",
        value: "Diyarbakir",
        text: "Diyarbakir",
      },
      {
        key: "Duezce",
        value: "Duezce",
        text: "Duezce",
      },
      {
        key: "Edirne",
        value: "Edirne",
        text: "Edirne",
      },
      {
        key: "Elazığ",
        value: "Elazığ",
        text: "Elazığ",
      },
      {
        key: "Erzincan",
        value: "Erzincan",
        text: "Erzincan",
      },
      {
        key: "Erzurum",
        value: "Erzurum",
        text: "Erzurum",
      },
      {
        key: "Eskişehir",
        value: "Eskişehir",
        text: "Eskişehir",
      },
      {
        key: "Gaziantep",
        value: "Gaziantep",
        text: "Gaziantep",
      },
      {
        key: "Giresun",
        value: "Giresun",
        text: "Giresun",
      },
      {
        key: "Guemueshane",
        value: "Guemueshane",
        text: "Guemueshane",
      },
      {
        key: "Hakkâri",
        value: "Hakkâri",
        text: "Hakkâri",
      },
      {
        key: "Hatay",
        value: "Hatay",
        text: "Hatay",
      },
      {
        key: "Isparta",
        value: "Isparta",
        text: "Isparta",
      },
      {
        key: "Istanbul",
        value: "Istanbul",
        text: "Istanbul",
      },
      {
        key: "Izmir",
        value: "Izmir",
        text: "Izmir",
      },
      {
        key: "Iğdır",
        value: "Iğdır",
        text: "Iğdır",
      },
      {
        key: "Kahramanmaraş",
        value: "Kahramanmaraş",
        text: "Kahramanmaraş",
      },
      {
        key: "Karabuek",
        value: "Karabuek",
        text: "Karabuek",
      },
      {
        key: "Karaman",
        value: "Karaman",
        text: "Karaman",
      },
      {
        key: "Kars",
        value: "Kars",
        text: "Kars",
      },
      {
        key: "Kastamonu",
        value: "Kastamonu",
        text: "Kastamonu",
      },
      {
        key: "Kayseri",
        value: "Kayseri",
        text: "Kayseri",
      },
      {
        key: "Kilis",
        value: "Kilis",
        text: "Kilis",
      },
      {
        key: "Kocaeli",
        value: "Kocaeli",
        text: "Kocaeli",
      },
      {
        key: "Konya",
        value: "Konya",
        text: "Konya",
      },
      {
        key: "Kütahya",
        value: "Kütahya",
        text: "Kütahya",
      },
      {
        key: "Kırklareli",
        value: "Kırklareli",
        text: "Kırklareli",
      },
      {
        key: "Kırıkkale",
        value: "Kırıkkale",
        text: "Kırıkkale",
      },
      {
        key: "Kırşehir",
        value: "Kırşehir",
        text: "Kırşehir",
      },
      {
        key: "Malatya",
        value: "Malatya",
        text: "Malatya",
      },
      {
        key: "Manisa",
        value: "Manisa",
        text: "Manisa",
      },
      {
        key: "Mardin",
        value: "Mardin",
        text: "Mardin",
      },
      {
        key: "Mersin",
        value: "Mersin",
        text: "Mersin",
      },
      {
        key: "Muğla",
        value: "Muğla",
        text: "Muğla",
      },
      {
        key: "Muş",
        value: "Muş",
        text: "Muş",
      },
      {
        key: "Nevsehir",
        value: "Nevsehir",
        text: "Nevsehir",
      },
      {
        key: "Nigde",
        value: "Nigde",
        text: "Nigde",
      },
      {
        key: "Ordu",
        value: "Ordu",
        text: "Ordu",
      },
      {
        key: "Osmaniye",
        value: "Osmaniye",
        text: "Osmaniye",
      },
      {
        key: "Rize",
        value: "Rize",
        text: "Rize",
      },
      {
        key: "Sakarya",
        value: "Sakarya",
        text: "Sakarya",
      },
      {
        key: "Samsun",
        value: "Samsun",
        text: "Samsun",
      },
      {
        key: "Siirt",
        value: "Siirt",
        text: "Siirt",
      },
      {
        key: "Sinop",
        value: "Sinop",
        text: "Sinop",
      },
      {
        key: "Sivas",
        value: "Sivas",
        text: "Sivas",
      },
      {
        key: "Tekirdağ",
        value: "Tekirdağ",
        text: "Tekirdağ",
      },
      {
        key: "Tokat",
        value: "Tokat",
        text: "Tokat",
      },
      {
        key: "Trabzon",
        value: "Trabzon",
        text: "Trabzon",
      },
      {
        key: "Tunceli",
        value: "Tunceli",
        text: "Tunceli",
      },
      {
        key: "Uşak",
        value: "Uşak",
        text: "Uşak",
      },
      {
        key: "Van",
        value: "Van",
        text: "Van",
      },
      {
        key: "Yalova",
        value: "Yalova",
        text: "Yalova",
      },
      {
        key: "Yozgat",
        value: "Yozgat",
        text: "Yozgat",
      },
      {
        key: "Zonguldak",
        value: "Zonguldak",
        text: "Zonguldak",
      },
      {
        key: "Çankırı",
        value: "Çankırı",
        text: "Çankırı",
      },
      {
        key: "Çorum",
        value: "Çorum",
        text: "Çorum",
      },
      {
        key: "Şanlıurfa",
        value: "Şanlıurfa",
        text: "Şanlıurfa",
      },
      {
        key: "Şırnak",
        value: "Şırnak",
        text: "Şırnak",
      },
    ],
  },

  __WEATHER_STATUS__: {
    type: "dropdown",
    default: "Clear",
    list: [
      {
        key: "Thunderstorm",
        value: "Thunderstorm",
        text: "Thunderstorm",
      },
      {
        key: "Rain",
        value: "Rain",
        text: "Rainy",
      },
      {
        key: "Drizzle",
        value: "Drizzle",
        text: "Drizzle",
      },
      {
        key: "Snow",
        value: "Snow",
        text: "Snowy",
      },
      {
        key: "Mist",
        value: "Mist",
        text: "Misty",
      },
      {
        key: "Smoke",
        value: "Smoke",
        text: "Smoke",
      },
      {
        key: "Haze",
        value: "Haze",
        text: "Haze",
      },
      {
        key: "Dust",
        value: "Dust",
        text: "Dust",
      },
      {
        key: "Fog",
        value: "Fog",
        text: "Foggy",
      },
      {
        key: "Sand",
        value: "Sand",
        text: "Sandy",
      },
      {
        key: "Ash",
        value: "Ash",
        text: "Ashy",
      },
      {
        key: "Squall",
        value: "Squall",
        text: "Squall",
      },
      {
        key: "Tornado",
        value: "Tornado",
        text: "Tornado",
      },
      {
        key: "Clear",
        value: "Clear",
        text: "Clear",
      },
      {
        key: "Clouds",
        value: "Clouds",
        text: "Cloudy",
      },
    ],
  },
  __USER_TYPE__: {
    type: "dropdown",
    list: [
      {
        value: "new",
        text: "New User",
      },
      {
        value: "returning",
        text: "Returning User",
      },
    ],
  },
  __USER_INFO__: {
    type: "dropdown",
    list: [
      {
        value: "name",
        text: "Name",
      },
      {
        value: "email",
        text: "Email",
      },
      {
        value: "phone",
        text: "Phone",
      },
    ],
  },
  __PAGE_TYPE__: {
    type: "dropdown",
    list: [
      {
        value: "home",
        text: "Home Page",
      },
      {
        value: "product",
        text: "Product Page",
      },
      {
        value: "category",
        text: "Category Page",
      },
      {
        value: "cart",
        text: "Cart Page",
      },
    ],
  },
  __TIME_ZONE__: {
    type: "dropdown",
    list: [
      {
        value: "site-time-zone",
        text: "Site Time Zone",
      },
      {
        value: "gtm",
        text: "GTM",
      },
    ],
  },
  __TIME__: {
    type: "dropdown",
    list: [
      {
        value: "seconds",
        text: "second(s)",
      },
      {
        value: "minutes",
        text: "minute(s)",
      },
      {
        value: "hours",
        text: "hour(s)",
      },
      {
        value: "days",
        text: "day(s)",
      },
      {
        value: "weeks",
        text: "week(s)",
      },
      {
        value: "months",
        text: "month(s)",
      },
      {
        value: "years",
        text: "year(s)",
      },
    ],
    default: "days",
  },
  __DAYS__: {
    type: "dropdown",
    list: [
      {
        value: "monday",
        text: "Monday",
      },
      {
        value: "tuesday",
        text: "Tuesday",
      },
      {
        value: "wednesday",
        text: "Wednesday",
      },
      {
        value: "thursday",
        text: "Thursday",
      },
      {
        value: "friday",
        text: "Friday",
      },
      {
        value: "saturday",
        text: "Saturday",
      },
      {
        value: "sunday",
        text: "Sunday",
      },
    ],
  },
  __SCROLL_TYPE__: {
    type: "dropdown",
    list: [
      {
        value: "percentage",
        text: "Percentage",
      },
      {
        value: "pixel",
        text: "Pixels",
      },
    ],
    default: "percentage",
  },
  __POPUP_POSITION__: {
    type: "screen",
    __name: "position",
    title: "Position",
    default: "top-right",
    list: [
      {
        value: "top-left",
        text: "Sol Üst",
      },
      {
        value: "top-center",
        text: "Üst",
      },
      {
        value: "top-right",
        text: "Sağ Üst",
      },
      {
        value: "center-left",
        text: "Sol",
      },
      {
        value: "center-center",
        text: "Orta",
      },
      {
        value: "center-right",
        text: "Sağ",
      },
      {
        value: "bottom-left",
        text: "Sol Alt",
      },
      {
        value: "bottom-center",
        text: "Alt",
      },
      {
        value: "bottom-right",
        text: "Sağ Alt",
      },
    ],
  },
  __STRATEGY__: {
    type: "boxselect",
    list: [
      {
        icon: "star",
        text: "Popularity",
        value: "popularity",
        __params: { type: "typePopularity" },
      },
      {
        icon: "pause",
        text: "Similarity",
        value: "similarity",
        __params: { type: "typeSimilarity" },
      },
      {
        icon: "eye",
        text: "Viewed Together",
        value: "viewedTogether",
        __params: { type: "typeViewedTogether" },
      },
      {
        icon: "in cart",
        text: "Bought Together",
        value: "boughtTogether",
        __params: { type: "typeBoughtTogether" },
      },
      {
        icon: "road",
        text: "User Affinity",
        value: "userAffinity",
        __params: { type: "typeUserAffinity" },
      },
      {
        icon: "eye",
        text: "Recently Viewed",
        value: "recentlyViewed",
        __params: { type: "typeRecentlyViewed" },
      },
      {
        icon: "eye",
        text: "Popularity",
        value: "popularityByCategory",
        __params: { type: "typePopularityByCategory" },
      },
      {
        icon: "eye",
        text: "Newests",
        value: "Newests",
        __params: { type: "typeNewests" },
      },
      {
        icon: "eye",
        text: "Same Products",
        value: "sameProduct",
        __params: { type: "typeSameProduct" },
      },
      {
        icon: "star",
        text: "Most Comments",
        value: "mostComments",
        __params: { type: "typeMostComments" },
      },
      {
        icon: "eye",
        text: "Trendyol Video",
        value: "trendyolVideo",
        __params: { type: "typeTrendyolVideo" },
      },
    ],
    divider: { name: "Strategy", icon: "sitemap" },
  },
};
