import { put, call, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../constants/actionTypes';
import { get } from '../util/api';
import lGet from 'lodash/get';
import * as Sentry from '@sentry/browser';

function* product(action) {
	try {
		let type = action.product_type || 'personalize';
		const response = yield call(get, `/${type}/product`);
		if (!response || !response.success) return put({ type: ActionTypes.PRODUCT_FAILED, error: 'Load Failed.' });
		if (response.product)
			Sentry.configureScope(scope => {
				scope.setUser({ pid: response.product.id, type: response.product.product_type });
			});
		yield put({ type: ActionTypes.PRODUCT_SUCCEEDED, product: response.product });
	} catch (error) {
		if (lGet(error.response, 'data')) {
			yield put({ type: ActionTypes.PRODUCT_FAILED, error: error.response.data });
		} else {
			yield put({ type: ActionTypes.PRODUCT_FAILED, error });
		}
	}
}

export function* watchProduct() {
	yield takeLatest(ActionTypes.PRODUCT_REQUESTED, product);
}
