import React from "react";
import { Button, Icon, Header, Image, Form, Message } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { getProfile } from "../actions/access.actions";
import { connect } from "react-redux";
import { login, register, logout } from "../actions/access.actions";
import toggleLogin from "../actions/modals.actions";
import * as Yup from "yup";
import {
  FormikInput,
  FormikCheckbox,
  TrimmedFormikInput,
} from "../components/FormikInput";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { post } from "../util/api";
import { useTranslation, withTranslation } from "react-i18next";
import { Formik } from "formik";

const RegisterFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("Geçerli bir e-mail girin.")
    .required("E-mail zorunlu!"),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      "En az 8 ve bir büyük, küçük, sayı ve özel karakter olmalıdır."
    )
    .required("Şifre zorunlu!"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Şifreler aynı değil!")
    .required("Şifreyi doğrula zorunludur."),
  consent: Yup.bool()
    .test(
      "consent",
      "Şartlar ve Koşullarımızı kabul etmelisiniz!",
      (value) => value === true
    )
    .required("Şartlar ve Koşullarımızı kabul etmelisiniz!"),
});

const LoginFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  password: Yup.string()
    .min(6, "Password has to be longer than 6 characters!")
    .required("Password is required!"),
});

const ForgotFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
});
const ResetFormValidation = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      "En az 8 ve bir büyük, küçük, sayı ve özel karakter olmalıdır."
    )
    .required("Şifre zorunlu!"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Şifreler aynı değil!")
    .required("Şifreyi doğrula zorunludur."),
});
/* 
const preState = props => (
	<pre
		css={`
			position: absolute;
			bottom: 0;
			left: 100px;
			right: 0;
			background: white;
			border: 1px solid red;
			margin: 0;
		`}
	>
		{JSON.stringify(props, null, 2)}
	</pre>
); */

const RegisterForm = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();
  if (props.userObj && props.userObj.isAuthenticated)
    return <Redirect to={{ pathname: "/" }} />;
  return (
    <div
      css={`
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      `}
    >
      <a
        style={{
          right: 0,
          position: "fixed",
          top: 0,
          width: "30px",
          height: "30px",
          marginRight: "5px",
          cursor: "pointer",
        }}
        onClick={() => {
          i18n.changeLanguage(i18n.language === "tr" ? "en" : "tr");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }}
        flag={i18n.language === "tr" ? "tr" : "gb"}
        name={i18n.language === "tr" ? "TR" : "EN"}
      >
        <img
          src={`/icons/flags/${i18n.language === "tr" ? "tr" : "gb"}.png`}
          alt=""
          style={{ width: "30px", height: "30px" }}
        ></img>
      </a>
      <div
        css={`
          max-width: 100%;
          width: 450px;
          .ui.header {
            margin-top: 10px !important;
            padding: 12px;
          }
        `}
      >
        <Button as={Link} to="/login" color="teal" compact>
          <Icon name="arrow left" />
          {t("gotoLoginPage")}
        </Button>
        <Header as="h2" textAlign="center">
          <div>{t("registerPage")}</div>
          <Image
            src="/images/collectaction.png"
            style={{ width: "6rem", margin: "10px" }}
          />
          <div style={{ fontSize: "20px" }}>
            <p style={{ margin: 0 }}>CollectAction</p>
          </div>
        </Header>
        {props.error && (
          <Message error header={"The mail already used."}>
            <p>
              {t("ifForgetPassword")}{" "}
              <Link to="/forgot">{t("youResetPassword")}</Link>
            </p>
          </Message>
        )}
        <Formik
          initialValues={{
            email: "",
            password: "",
            passwordConfirmation: "",
            consent: false,
          }}
          validationSchema={RegisterFormValidation}
          onSubmit={(values) =>
            props.registerUser(values.email, values.password)
          }
        >
          {(state) => {
            return (
              <Form onSubmit={state.handleSubmit}>
                <TrimmedFormikInput
                  label={t("workEmail")}
                  name="email"
                  iconPosition="left"
                  icon="mail"
                  placeholder="user@mail.com"
                />
                <FormikInput
                  label={t("fullName")}
                  name="name"
                  iconPosition="left"
                  icon="user"
                  placeholder={t("fullName")}
                />
                <FormikInput
                  label={t("password")}
                  name="password"
                  iconPosition="left"
                  icon="lock"
                  placeholder={t("password")}
                  type="password"
                />
                <FormikInput
                  label={t("confirmPassword")}
                  name="passwordConfirmation"
                  iconPosition="left"
                  icon="lock"
                  placeholder={t("confirmPassword")}
                  type="password"
                />
                <FormikCheckbox
                  label={
                    <label>
                      {t("iagreetothe")}{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://collectaction.com/legal/terms"
                        pass
                      >
                        {t("TermsofService")}
                      </a>{" "}
                      {t("and")}{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://collectaction.com/legal/privacy"
                      >
                        {t("PrivacyPolicy")}
                      </a>
                    </label>
                  }
                  name="consent"
                />
                <Button primary fluid type="submit">
                  {t("register")}
                </Button>
                {/* 
								<pre>{JSON.stringify(state, null, 2)}</pre> */}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const LoginForm = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();
  if (props.userObj && props.userObj.isAuthenticated)
    return <Redirect to={{ pathname: "/" }} />;
  return (
    <div
      css={`
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      `}
    >
      <a
        style={{
          right: 0,
          position: "fixed",
          top: 0,
          width: "30px",
          height: "30px",
          marginRight: "5px",
          cursor: "pointer",
        }}
        onClick={() => {
          i18n.changeLanguage(i18n.language === "tr" ? "en" : "tr");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }}
        flag={i18n.language === "tr" ? "tr" : "gb"}
        name={i18n.language === "tr" ? "TR" : "EN"}
      >
        <img
          src={`/icons/flags/${i18n.language === "tr" ? "tr" : "gb"}.png`}
          alt=""
          style={{ width: "30px", height: "30px" }}
        ></img>
      </a>
      <div
        css={`
          max-width: 100%;
          width: 450px;
          display: flex;
          flex-direction: column;
          cd .ui.header {
            margin-top: 10px !important;
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
            padding: 12px;
          }
        `}
      >
        <Button
          as={Link}
          to="/register"
          style={{ marginLeft: "auto" }}
          color="teal"
          compact
        >
          {t("gotoRegisterPage")}
          <Icon name="arrow right" />
        </Button>
        <Header as="h2" textAlign="center">
          <div>{t("loginPage")}</div>
          <Image
            src="/images/collectaction.png"
            style={{ width: "6rem", margin: "10px" }}
          />
          <div style={{ fontSize: "20px" }}>
            <p style={{ margin: 0 }}>CollectAction</p>
          </div>
        </Header>
        {props.error && (
          <Message
            error
            header={"Login Error..."}
            content="The mail or password is wrong.Please double-check and try again."
          />
        )}
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginFormValidation}
          onSubmit={(values) => props.loginUser(values.email, values.password)}
        >
          {(state) => {
            return (
              <Form onSubmit={state.handleSubmit}>
                <TrimmedFormikInput
                  label={t("workEmail")}
                  name="email"
                  iconPosition="left"
                  icon="mail"
                  placeholder="user@mail.com"
                />
                <FormikInput
                  label={t("password")}
                  name="password"
                  iconPosition="left"
                  icon="lock"
                  placeholder={t("password")}
                  type="password"
                />
                <p>
                  {t("forgotpasswordQ")}{" "}
                  <Link to="/forgot">{t("resetpassword")}</Link>
                </p>
                <Button
                  primary
                  loading={props.loading === "login"}
                  type="submit"
                  fluid
                >
                  {t("login")}
                </Button>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://yapaytech.com/legal/terms"
                  >
                    {t("TermsofService")}
                  </a>{" "}
                  {t("and")}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://yapaytech.com/legal/privacy"
                  >
                    {t("PrivacyPolicy")}.
                  </a>
                </p>
                {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export const ForgetPasswordPage = () => {
  const { t, i18n } = useTranslation();
  const [sending, setSending] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [err, setError] = React.useState(null);
  const handleSubmitForgot = (values) => {
    if (sending) return;
    setSending(true);
    post("/mailer/resetPassword", {
      email: values.email,
      from: "collectaction",
    })
      .then((data) => {
        setSending(false);
        if (data.data.success) {
          setRedirect(values.email);
        } else setError(data.data.message || "Error..!");
      })
      .catch((err) => {
        setSending(false);
        setError(err.message);
      });
  };
  if (redirect)
    return (
      <Redirect
        to={{ pathname: "/forgot/checkyourmail", search: "?mail=" + redirect }}
      />
    );
  return (
    <div
      css={`
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      `}
    >
      <a
        style={{
          right: 0,
          position: "fixed",
          top: 0,
          width: "30px",
          height: "30px",
          marginRight: "5px",
          cursor: "pointer",
        }}
        onClick={() => {
          i18n.changeLanguage(i18n.language === "tr" ? "en" : "tr");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }}
        flag={i18n.language === "tr" ? "tr" : "gb"}
        name={i18n.language === "tr" ? "TR" : "EN"}
      >
        <img
          src={`/icons/flags/${i18n.language === "tr" ? "tr" : "gb"}.png`}
          alt=""
          style={{ width: "30px", height: "30px" }}
        ></img>
      </a>
      <div
        css={`
          max-width: 100%;
          width: 450px;
          display: flex;
          flex-direction: column;
          cd .ui.header {
            margin-top: 10px !important;
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
            padding: 12px;
          }
        `}
      >
        <Button
          as={Link}
          to="/login"
          style={{ marginRight: "auto" }}
          color="teal"
          compact
        >
          <Icon name="arrow left" />
          {t("gotoLoginPage")}
        </Button>
        <Header as="h2" textAlign="center">
          <div>{t("forgotPassword")}</div>
          <Image
            src="/images/collectaction.png"
            style={{ width: "6rem", margin: "10px" }}
          />
          <div style={{ fontSize: "20px" }}>
            <p style={{ margin: 0 }}>CollectAction</p>
          </div>
        </Header>
        {err && (
          <Message
            error
            header={err}
            content="Please try searching for your email again."
          />
        )}
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ForgotFormValidation}
          onSubmit={handleSubmitForgot}
        >
          {(state) => {
            const { handleSubmit } = state;
            return (
              <Form onSubmit={handleSubmit}>
                <TrimmedFormikInput
                  label={t("workEmail")}
                  name="email"
                  iconPosition="left"
                  icon="mail"
                  placeholder="user@mail.com"
                />
                <Button primary loading={sending} type="submit" fluid>
                  {t("sendMail")}
                </Button>
                {/*<pre>{JSON.stringify(state, null, 2)}</pre>*/}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export const ResetPasswordPage = () => {
  const { t, i18n } = useTranslation();
  const [sending, setSending] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [setError] = React.useState(null);
  //TODO ERROR
  const handleSubmitReset = (values) => {
    if (sending) return;
    setSending(true);
    post("/verify/resetPassword", {
      password: values.password,
      token: window.location.pathname.replace("/verify/resetPassword/", ""),
    })
      .then((data) => {
        setSending(false);
        if (data.data.success) {
          setRedirect(values.password);
        } else setError(data.data.message || "Error..!");
      })
      .catch((err) => {
        setSending(false);
        setError(err.message);
      });
  };
  if (redirect) return <Redirect to={{ pathname: "/login" }} />;
  return (
    <div
      css={`
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      `}
    >
      <a
        style={{
          right: 0,
          position: "fixed",
          top: 0,
          width: "30px",
          height: "30px",
          marginRight: "5px",
          cursor: "pointer",
        }}
        onClick={() => {
          i18n.changeLanguage(i18n.language === "tr" ? "en" : "tr");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }}
        flag={i18n.language === "tr" ? "tr" : "gb"}
        name={i18n.language === "tr" ? "TR" : "EN"}
      >
        <img
          src={`/icons/flags/${i18n.language === "tr" ? "tr" : "gb"}.png`}
          alt=""
          style={{ width: "30px", height: "30px" }}
        ></img>
      </a>
      <div
        css={`
          max-width: 100%;
          width: 450px;
          display: flex;
          flex-direction: column;
          cd .ui.header {
            margin-top: 10px !important;
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
            padding: 12px;
          }
        `}
      >
        <Button
          as={Link}
          to="/login"
          style={{ marginLeft: "auto" }}
          color="teal"
          compact
        >
          {t("gotoLoginPage")}
          <Icon name="arrow right" />
        </Button>
        <Header as="h2" textAlign="center">
          <div>{t("resetpassword")}</div>
          <Image
            src="/images/collectaction.png"
            style={{ width: "6rem", margin: "10px" }}
          />
          <div style={{ fontSize: "20px" }}>
            <p style={{ margin: 0 }}>CollectAction</p>
          </div>
        </Header>
        <Formik
          initialValues={{ password: "", passwordConfirmation: "" }}
          validationSchema={ResetFormValidation}
          onSubmit={handleSubmitReset}
        >
          {(state) => {
            const { handleSubmit } = state;
            return (
              <Form onSubmit={handleSubmit}>
                <FormikInput
                  label={t("newPassword")}
                  name="password"
                  iconPosition="left"
                  icon="key"
                  placeholder={t("enterNewPassword")}
                  type="password"
                />
                <FormikInput
                  label={t("confirmPassword")}
                  name="passwordConfirmation"
                  iconPosition="left"
                  icon="key"
                  placeholder={t("enterNewPasswordAgain")}
                  type="password"
                />
                <Button
                  loading={sending}
                  type="submit"
                  fluid
                  style={{ background: "#499DF3", color: "white" }}
                >
                  Şifremi Yenile
                </Button>
                {/*<pre>{JSON.stringify(state, null, 2)}</pre>*/}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userObj: state.access.user,
  error: state.access.error,
  loading: state.access.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  logoutUser: () => dispatch(logout()),
  loginUser: (email, password) => dispatch(login(email, password)),
  toggleLogin: (newState) => dispatch(toggleLogin(newState)),
});
export const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginForm));

export const RegisterPage = connect(mapStateToProps, (dispatch) => ({
  registerUser: (email, password) => dispatch(register(email, password)),
}))(withTranslation()(RegisterForm));
