import React, { Component } from "react";
import { Icon, Segment, Button, Loader } from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { MiniContainer, MyDivider } from "../../../components/FormikInput";
import { get } from "../../../util/api";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// eslint-disable-next-line
import styled from "styled-components/macro";

class Shopify extends Component {
  state = { loading: true };

  componentDidMount() {
    this.loadStatus();
  }

  async install() {
    this.setState({ loading: true });
    try {
      await get("/auth/shopify/api/install");
      this.loadStatus();
    } catch (error) {
      this.setState({ loading: false, error: error.message });
    }
  }
  async remove() {
    this.setState({ loading: true });
    try {
      await get("/auth/shopify/api/remove");
      this.loadStatus();
    } catch (error) {
      this.setState({ loading: false, error: error.message });
    }
  }

  async loadStatus() {
    if (!this.state.loading) this.setState({ loading: true });
    try {
      let data = await get("/auth/shopify/api/status");
      let installed = data && data.data && data.data.length;
      this.setState({ loading: false, installed: !!installed });
    } catch (error) {
      this.setState({ loading: false, error: error.message });
    }
  }
  render() {
    if (this.state.loading)
      return (
        <div>
          <h4>
            <Loader inline="centered" content="Loading Shopify Status" active />
          </h4>
        </div>
      );
    return (
      <div>
        <h4>
          1. Install your Personalize{" "}
          {this.state.installed && <Icon name="check" color="green" />}
        </h4>

        {this.state.installed ? (
          <div>
            <div>
              Click to button for remove Personalize on your Shopify Website.
            </div>
            <h4 style={{ marginTop: "40px" }}>
              Personalize Already Installed on Your Shopify Website
            </h4>
            <Button color="red" onClick={this.remove.bind(this)}>
              Remove from your website.
            </Button>
          </div>
        ) : (
          <div>
            <div>
              Click to button for install Personalize on your Shopify Website.
            </div>
            <h4 style={{ marginTop: "40px" }}>
              Personalize isn't Installed on Your Shopify Website Yet
            </h4>
            <Button color="blue" onClick={this.install.bind(this)}>
              Install Personalize on your shopify website.
            </Button>
          </div>
        )}

        <div style={{ marginTop: "40px" }}>
          <h4>2. Congratulations!</h4>
          <div>
            You now have the full-power of Yapaytech Personalize behind you!
            Let’s get started with <Link to="/actions">Actions</Link>!
          </div>
          <div style={{ padding: "10px 0" }}>
            If you have any problems please contact us at
            <a
              href="mailto:info@yapaytech.com?Subject=Widget Problems"
              target="_top"
            >
              {" "}
              info@yapaytech.com
            </a>
            <span> so we can help you get Personalize up and running.</span>
          </div>
        </div>
      </div>
    );
  }
}

class ScriptPage extends Component {
  state = {
    copied: false,
  };
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  onCopy = () => {
    if (!this.state.copied) this.setState({ copied: true });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  };
  render() {
    let { user, product, t } = this.props;
    const id = product.id || user.id;
    const status = product && product.settings && product.settings.loaded_once;
    let product_type = (product && product.type) || "";
    const code = `<!--ytag--> <script>(function (d,s,i) { var j = d.createElement('script'); j.async = true; j.id = 'ytag'; j.src = 'https://cdn.yapaytech.com/ytag/script.js'; j.setAttribute('pid',i); d.head.appendChild(j); })( document, 'script', '${id}');</script> <!-- End ytag -->`;
    return (
      <Segment>
        <MiniContainer>
          {product_type === "shopify" ? (
            <div
              css={`
                margin-bottom: 40px;
              `}
            >
              <MyDivider name="Shopify" icon="shop" />
              <Shopify />
            </div>
          ) : (
            <div>
              <MyDivider name="Website" icon="world" />
              <div>
                <h4>1. {t("InstallyourWidget")}</h4>
                <div>{t("CopyPasteScript")}</div>
              </div>
              <Segment>
                <h4>Script</h4>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    padding: "20px",
                    marginBottom: "10px",
                    wordWrap: "break-word",
                  }}
                >
                  {code}
                </div>
                <CopyToClipboard text={code} onCopy={this.onCopy}>
                  <Button fluid color={this.state.copied ? "teal" : "blue"}>
                    <Icon name={this.state.copied ? "check" : "copy"} />
                    {this.state.copied ? t("Copied") : t("Copy")}
                  </Button>
                </CopyToClipboard>
              </Segment>
              <Segment>
                <h4>{t("scriptStatus")}</h4>
                {status ? (
                  <div
                    style={{
                      backgroundColor: "rgb(94 207 94)",
                      borderRadius: "5px",
                      padding: "20px",
                      marginBottom: "10px",
                      wordWrap: "break-word",
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                  >
                    <div style={{ paddingTop: "7px" }}>
                      <Icon
                        name="thumbs up"
                        size="large"
                        style={{ color: "white", marginBottom: "8px" }}
                      />
                      <a style={{ color: "white", fontSize: "17px" }}>
                        {t("scriptSuccess")}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      padding: "20px",
                      marginBottom: "10px",
                      wordWrap: "break-word",
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                  >
                    <div style={{ paddingTop: "7px" }}>
                      <Icon
                        name="exclamation"
                        size="large"
                        style={{ color: "white", marginBottom: "8px" }}
                      />
                      <a style={{ color: "white", fontSize: "17px" }}>
                        {t("scriptUnSuccess")}
                      </a>
                    </div>
                    <div style={{ paddingTop: "7px" }}>
                      <a
                        href="https://yapaytech.gitbook.io/collectaction-uygulama-marketi/giris/baslangic"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        style={{ color: "white", fontSize: "12px" }}
                      >
                        {t("scriptFollow")}
                      </a>
                    </div>
                  </div>
                )}
              </Segment>
              {status && (
                <div>
                  <h4>2. {t("Congratulations")}</h4>
                  <div>
                    {t("YouHaveFullPower") + " " + t("LetsStartWith")}{" "}
                    <Link to="/actions">{t("Actions")}</Link>!
                  </div>
                  <div style={{ padding: "10px 0" }}>
                    {t("IfYouHaveAnyProblems")}
                    <a
                      href="mailto:info@yapaytech.com?Subject=Widget Problems"
                      target="_top"
                    >
                      {" "}
                      info@yapaytech.com
                    </a>
                    <span> {t("weCanHelpYou")}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </MiniContainer>
      </Segment>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.personalize,
}))(withTranslation()(ScriptPage));
