import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import 'semantic-ui-css/semantic.min.css';
import './scss/app.scss';
import App from './App';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configureStore';
import ErrorMessage from './components/ErrorMessage';
import * as Sentry from '@sentry/browser';
import Page from './components/Page';
//import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production')
	Sentry.init({
		dsn: 'https://07f761f48c3f480db87b9c90e674852a@sentry.io/1446287',
	});

//<a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a>

const store = configureStore();
class LogicApp extends React.Component {
	state = {
		error: null,
		errorInfo: null,
	};

	componentDidCatch(error, errorInfo) {
		console.warn(error, errorInfo);
		this.setState({ error, errorInfo });
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		const { error } = this.state;

		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
					{error ? (
						<ErrorMessage
							code="500"
							title="There was some errors while loading the page."
							list={['Please reload the page and try again.', 'If this error is persisting please contact us from info@yapaytech.com']}
						/>
					) : (
						<Suspense fallback={<Page.Loader></Page.Loader>}>
							<App />
						</Suspense>
					)}
				</ConnectedRouter>
			</Provider>
		);
	}
}

ReactDOM.render(<LogicApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
