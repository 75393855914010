import React, { Component } from "react";
import { Segment, Table } from "semantic-ui-react";
import Page from "../../components/Page";
import _ from "lodash";
import { get } from "../../util/api";
import { withTranslation } from "react-i18next";

class ActionTable extends Component {
  state = {
    loading: true,
    mockData: [
      {
        type: "akinon",
        email: "beytullah@yapaytech.com",
        action: "Sepet Sayfasında Kargo Bedava",
      },
      {
        type: "base",
        email: "a2la8l_i@meantinc.com",
        action: "WhatsApp Kulakçığı",
      },
      {
        type: "tsoft",
        email: "tsoft_test@yapaytech.com",
        action: "Sepetini Paylaş",
      },
      { type: "ticimax", email: "emrllh.kcbs@gmail.com", action: "Çarkıfelek" },
    ],
  };
  componentDidMount() {
    get("/survey/list").then((data) => {
      this.setState({ data: data.result, loading: false });
    });
  }
  render() {
    let { data, loading } = this.state;
    const { t } = this.props;
    if (loading) {
      return <Page.Loader></Page.Loader>;
    }
    return (
      <Segment style={{ overflowY: "auto" }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Email")}</Table.HeaderCell>
              <Table.HeaderCell>{t('Infrastructure')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Action')}</Table.HeaderCell>
              {/* <Table.HeaderCell></Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(data, function (it, i) {
              return (
                <Table.Row>
                  <Table.Cell>{it.data.email}</Table.Cell>
                  <Table.Cell>{it.data.type}</Table.Cell>
                  <Table.Cell>{it.data.action}</Table.Cell>
                  {/* <Table.Cell>
										<Button icon color="red">
											<Icon name="trash"></Icon>
										</Button>
									</Table.Cell> */}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default withTranslation()(ActionTable);
