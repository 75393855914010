import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../../components/Page';
import { Menu, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Script from './script';
//import Subs from './subscription';
import Product from './productSetting';

export default () => {
	let { t } = useTranslation();
	return (
		<Page title={t('Settings')} icon="setting" subtitle={t('ManageProductSettings')}>
			<Page.Wrap>
				<Menu className="settings" pointing secondary>
					<Menu.Item as={NavLink} exact to="/settings/product">
						<Icon name="asterisk" />
						{t('ProductSettings')}
					</Menu.Item>
					<Menu.Item as={NavLink} to="/settings/installation">
						<Icon name="code" />
						{t('Installation')}
					</Menu.Item>
					{/* <Menu.Item as={NavLink} to="/settings/subscription">
						<Icon name="shop" />
						{t('Subscription')}
					</Menu.Item> */}
				</Menu>
				<Switch>
					<Route path="/settings/product" component={Product} />
					<Route path="/settings/installation" component={Script} />
					{/* <Route path="/settings/subscription" component={Subs} /> */}
					<Redirect to="/settings/product" />
				</Switch>
			</Page.Wrap>
		</Page>
	);
};
