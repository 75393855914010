import * as ActionTypes from '../constants/actionTypes';

export const login = (username, password) => ({
	type: ActionTypes.LOGIN_REQUESTED,
	username,
	password,
});

export const register = (email, password) => ({
	type: ActionTypes.REGISTER_REQUESTED,
	email,
	password,
});

export const logout = () => ({
	type: ActionTypes.LOGOUT_REQUESTED,
});

export const getProfile = () => ({
	type: ActionTypes.PROFILE_REQUESTED,
});

export const updateProfile = user => ({
	type: ActionTypes.PROFILE_UPDATED,
	user,
});

export const updateProductSettings = (key, settings) => ({
	type: ActionTypes.PRODUCT_SETTINGS_UPDATED,
	key,
	settings,
});
