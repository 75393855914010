import React, { Component } from 'react';
import { Form, Segment, Icon } from 'semantic-ui-react';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/access.actions';
import { Formik } from 'formik';
import { FormikInput, MiniContainer } from '../../components/FormikInput';
import * as Yup from 'yup';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

function customizer(objValue, srcValue) {
	return _.isUndefined(objValue) || objValue === null ? srcValue : objValue;
}

var defaults = _.partialRight(_.assignWith, customizer);
class UserPage extends Component {
	state = {
		userInfo: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		userPassword: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		newpassword: '',
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	handleSubmit = (values) => {
		if (this.state.userInfo.sending) return;
		this.setState({ userInfo: { sending: true } });
		this.props.updateUser(values);
		this.setState({
			userInfo: { sending: false, errormessage: '', saved: 'Saved', color: 'teal', icon: 'check' },
		});
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userInfo: { icon: 'save', color: 'blue', saved: 'Save' } });
		}, 2000);
		this.postData(values);
	};
	handleSubmitPassword = (values) => {
		if (this.state.userPassword.sending) return;
		this.setState({ userPassword: { sending: true } });
		this.props.updateUser(values);
		this.postPassword(values);
		if (this.state.passwordError) {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: 'Unsaved', color: 'red', icon: 'x' },
			});
		} else {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: 'Saved', color: 'teal', icon: 'check' },
			});
		}

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userPassword: { icon: 'save', color: 'blue', saved: 'Save' } });
		}, 2000);
	};
	postData(values) {
		post('/auth/updateInfo', {
			name: values.name,
			phone: values.phone,
			company: values.company,
		});
	}
	postPassword(values) {
		post('/auth/updatePassword', {
			newpassword: values.newpassword,
		}).then((data) => {
			this.setState({ passwordErrorMessage: data.data.message, passwordError: data.data.success });
		});
	}
	render() {
		const initials = defaults({}, this.props.user, { name: '', company: '', phone: '', newpassword: '', confirmpassword: '' });
		let { t } = this.props;
		return (
			<Segment>
				<MiniContainer>
					<h3>
						<Icon name="user" />
						{t('PersonalInformation')}
					</h3>
					<Segment>
						<Formik
							initialValues={initials}
							onSubmit={this.handleSubmit}
							validationSchema={Yup.object().shape({
								name: Yup.string().required(t('QueryIsRequied', { query: t('Name') })),
								company: Yup.string().required(t('QueryIsRequied', { query: t('Company') })),
								//phone: Yup.string().matches(/^(\+90|0)?(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/, 'Phone number is not valid!'),
							})}
						>
							{(props) => {
								const { values, handleChange, handleBlur, handleSubmit } = props;
								return (
									<Form onSubmit={handleSubmit}>
										<FormikInput
											id="name"
											label={t('Name')}
											name="name"
											icon="user"
											iconPosition="left"
											placeholder={t('EnteryourPlaceholder', { query: t('Name') })}
											type="text"
											value={values.name}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											id="company"
											label={t('Company')}
											icon="building"
											name="company"
											placeholder={t('EnteryourPlaceholder', { query: t('Company') })}
											iconPosition="left"
											type="text"
											value={values.company}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											id="email"
											label="Email"
											name="email"
											icon="at"
											iconPosition="left"
											placeholder={t('EnteryourPlaceholder', { query: 'Email' })}
											type="text"
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											readOnly
										/>
										<FormikInput
											id="phone"
											label={t('Phone')}
											iconPosition="left"
											name="phone"
											icon="phone"
											placeholder={t('EnteryourPlaceholder', { query: t('Phone') })}
											type="phone"
											value={values.phone}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<Form.Button
											fluid
											icon={this.state.userInfo.icon}
											type="submit"
											color={this.state.userInfo.color}
											content={t(this.state.userInfo.saved)}
											loading={this.state.userInfo.sending}
										/>
									</Form>
								);
							}}
						</Formik>
					</Segment>
					<h3 style={{ marginTop: '5rem' }}>
						<Icon name="lock" />
						{t('Password')}
					</h3>
					<Segment>
						<Formik
							initialValues={initials}
							onSubmit={this.handleSubmitPassword}
							validationSchema={Yup.object().shape({
								newpassword: Yup.string()
									.required(t('QueryIsRequied', { query: t('Password') }))
									.min('6', t('TooShort6')),
								confirmpassword: Yup.string().oneOf([Yup.ref('newpassword'), null], t('PasswordsMustMatch')),
							})}
						>
							{(props) => {
								const { handleChange, handleBlur, handleSubmit } = props;
								return (
									<Form onSubmit={handleSubmit}>
										<FormikInput
											label={t('NewPassword')}
											icon="key"
											iconPosition="left"
											name="newpassword"
											type="password"
											placeholder={t('EnteryourPlaceholder', { query: t('NewPassword') })}
											id="newpassword"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											label={t('ConfirmPassword')}
											icon="key"
											iconPosition="left"
											name="confirmpassword"
											type="password"
											placeholder={t('EnterNewPassword')}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<Form.Button
											fluid
											icon={this.state.userPassword.icon}
											type="submit"
											color={this.state.userPassword.color}
											content={t(this.state.userPassword.saved)}
											loading={this.state.userPassword.sending}
										/>
									</Form>
								);
							}}
						</Formik>
					</Segment>
				</MiniContainer>
			</Segment>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj }),
	(dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(withTranslation()(UserPage));
