import axios from "axios";
const API_ADDRESS = process.env.REACT_APP_API_URL || "/";
const LOG_ADDRESS =
  process.env.REACT_APP_LOG_URL || "https://log.collectaction.com";
const LOG_ADDRESS1 = "https://log.yapaytech.com";

export function get(path, params) {
  const url = `${API_ADDRESS}${path}`;

  return axios({
    method: "get",
    url,
    params,
    withCredentials: true,
  }).then((resp) => resp.data);
}

export function getDialogtab(path, params) {
  const url = `${path}`;

  return axios({
    method: "get",
    url,
    params,
  }).then((resp) => resp.data);
}

export function post(path, data, params) {
  const url = `${API_ADDRESS}${path}`;
  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url,
    data: "__json__=" + encodeURIComponent(JSON.stringify(data)),
    params,
    withCredentials: true,
  });
}

export function postlocal(path, data, params) {
  const url = `${path}`;
  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url,
    data: "__json__=" + encodeURIComponent(JSON.stringify(data)),
    params,
  });
}

export function logLocal(path, data, params) {
  const url = `http://localhost:4201${path}`;
  console.log("log ", data);
  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url,
    data: "__json__=" + encodeURIComponent(JSON.stringify(data)),
    params,
  });
}

export function log(path, data, params) {
  const url = `${LOG_ADDRESS}${path}`;
  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url,
    data: "__json__=" + encodeURIComponent(JSON.stringify(data)),
    params,
  });
}
export function logLive(path, data, params) {
  const url = `${LOG_ADDRESS1}${path}`;
  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url,
    data: "__json__=" + encodeURIComponent(JSON.stringify(data)),
    params,
  });
}

export function formData(path, data, params) {
  const url = `${LOG_ADDRESS}${path}`;
  return axios({
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url,
    data,
    params,
  });
}
