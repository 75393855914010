import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { Menu, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Users from './users';

export default () => {
	let { t } = useTranslation();
	return (
		<Page title={t('MyAccount')} icon="user" subtitle={t('ManageAccountSettings')}>
			<Page.Wrap>
				<Menu className="settings" pointing secondary>
					<Menu.Item as={NavLink} exact to="/account">
						<Icon name="setting" />
						{t('AccountSettings')}
					</Menu.Item>
				</Menu>
				<Switch>
					<Route path="/account" exact component={Users} />
					<Redirect to="/account" />
				</Switch>
			</Page.Wrap>
		</Page>
	);
};
