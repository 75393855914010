import React, { Component } from 'react';
import { Container, Embed } from 'semantic-ui-react';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line
import styled from 'styled-components/macro';

const videos = [
	{
		title: 'CreateCouponCampaign',
		id: 'uVtG0CXye1c',
	},
	{
		title: 'CreateShoppingCart',
		id: 'iELtdgnhoQU',
	},
	{
		title: 'HowToExitIntent',
		id: '3wVtwa_bsrg',
	},
];

const Video = (props) => (
	<div
		css={`
			text-align: center;
			margin-bottom: 60px;
		`}
	>
		<h3>{props.t(props.title)}</h3>
		<div
			css={`
				max-width: 100%;
				width: 720px;
				margin: 0 auto;
			`}
		>
			<Embed
				css={`
					border-radius: 6px;
				`}
				iframe={{
					allowFullScreen: true,
					style: {},
					src: `//www.youtube.com/embed/${props.id}?autohide=true&amp;amp;autoplay=0&amp;amp;color=%23444444&amp;amp;hq=true&amp;amp;jsapi=false&amp;amp;modestbranding=false&amp;amp;rel=1`,
				}}
				defaultActive
			/>
		</div>
	</div>
);

class HelpVideos extends Component {
	render() {
		let { t } = this.props;
		return (
			<Page title="Tutorials" icon="video">
				<Container style={{ marginTop: '50px', padding: '10px' }}>
					{videos.map((it, i) => (
						<Video key={i} {...it} t={t} />
					))}
				</Container>
			</Page>
		);
	}
}

export default withTranslation()(HelpVideos);
