import React, { Component } from "react";
import {
  Button,
  Segment,
  Progress,
  Dropdown,
  Icon,
  TextArea,
  Modal,
  Input,
  Checkbox,
} from "semantic-ui-react";
import {
  Switch,
  Route,
  NavLink,
  Prompt,
  Link,
  Redirect,
} from "react-router-dom";
import { Formik, Form, Field, FastField } from "formik";
import _ from "lodash";
import Page from "../../components/Page";
//import { MaterialInput } from '../../components/Helpers';
import { get, post } from "../../util/api";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { MyDivider, FastFieldArray } from "../../components/FormikInput";
import BotTest from "./bot_test";
import { useTranslation, withTranslation } from "react-i18next";

function Placeholder() {
  let { t } = useTranslation();
  return <div className="placeholder">{t("PleaseSelectEditCreate")}</div>;
}

const Gen = {
  get default_category() {
    return {
      name: "",
      data: {
        text: "",
      },
      type: "category",
    };
  },
  get default_action() {
    return {
      name: "",
      data: {
        actions: [{ type: "text", text: "Message text" }],
      },
      type: "action",
    };
  },
};

/* function TextEditor(props) {
	return (
		<Field name={props.name}>
			{({ field }) => {
				return (
					<MaterialInput
						css={`
							margin: 9px 0;
							max-width: 100%;
							height: 22px;
						`}
						type="text"
						name={field.name}
						placeholder={props.placeholder}
						value={field.value || ''}
						onChange={field.onChange}
					/>
				);
			}}
		</Field>
	);
} */

const Actions = {};
let list_cache;

async function load_cache() {
  if (list_cache) return list_cache;
  let response = await get("/personalize/whatsapp-bot/actionlist");
  if (response && response.result && response.result.length)
    list_cache = response.result;
  _.each(list_cache, (it) => {
    Actions[it.value] = it.data;
  });
}

function LiveCategoryInput(props) {
  let list = props._list;
  return (
    <div className="livems">
      <div className="editable">
        <Field name={props.name}>
          {({ field }) => {
            return (
              <TextArea
                css={`
                  margin: 9px 0;
                  width: 360px;
                  max-width: 100%;
                  height: 22px;
                `}
                type="text"
                name={field.name}
                placeholder={props.placeholder}
                value={field.value || ""}
                onChange={field.onChange}
                className="livems-input"
              />
            );
          }}
        </Field>
      </div>
      {list && list.length ? (
        <div className="livems-buttons">
          {_.map(list, (it) => (
            <div key={it.id} className="livems-button-line mock">
              {it.name}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

function StatingMessageInput(props) {
  let list = props._list;
  return (
    <div className="livems">
      <div className="editable">
        <Field name={props.name}>
          {({ field }) => {
            return (
              <TextArea
                css={`
                  margin: 9px 0;
                  width: 360px;
                  max-width: 100%;
                  height: 220px;
                `}
                type="text"
                name={field.name}
                placeholder={props.placeholder}
                value={field.value || ""}
                onChange={field.onChange}
                className="livems-input"
              />
            );
          }}
        </Field>
      </div>
      {list && list.length ? (
        <div className="livems-buttons">
          {_.map(list, (it) => (
            <div key={it.id} className="livems-button-line mock">
              {it.name}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

function NameEditor(props) {
  const config = props.config || {};
  const type = props.data && props.data.type;
  const order = props.data.order;
  let { t } = useTranslation();
  let text =
    (props.parent && props.parent.data && props.parent.data.text) ||
    t("whichProcess");
  if (type === "category") text = config.start_text || t("welcomeSupport");
  let buttons = props._buttons || [];
  const { pre_buttons, post_buttons } = React.useMemo(() => {
    if (buttons && buttons.length) {
      let pre_buttons = [];
      let post_buttons = [];
      for (let i = 0; i < buttons.length; i++) {
        const it = buttons[i];
        if (it.order > order) post_buttons.push(it);
        else pre_buttons.push(it);
      }
      let max = 1;
      if (!pre_buttons.length || !post_buttons.length) max = 2;
      pre_buttons = _.takeRight(pre_buttons, max);
      post_buttons = _.take(post_buttons, max);
      return { pre_buttons, post_buttons };
    }
    return {
      pre_buttons: [{ id: "mock1", name: `${_.capitalize(type)} 1` }],
      post_buttons: [{ id: "mock2", name: `${_.capitalize(type)} 3` }],
    };
  }, [buttons, order, type]);

  return (
    <div className="livems">
      <div className="livems-opac">{text}</div>
      <div className="livems-buttons">
        {_.map(pre_buttons, (it) => (
          <div key={it.id} className="livems-button-line mock">
            {it.name}
          </div>
        ))}

        <div className="livems-button-line editable">
          <Field name={"name"}>
            {({ field }) => {
              return (
                <Input
                  type="text"
                  name={field.name}
                  placeholder={t("Name") + "..."}
                  value={field.value || ""}
                  onChange={field.onChange}
                  className="livems-button-input"
                />
              );
            }}
          </Field>
        </div>
        {_.map(post_buttons, (it) => (
          <div key={it.id} className="livems-button-line mock">
            {it.name}
          </div>
        ))}
      </div>
    </div>
  );
}

const YtMiniButton = (props) => {
  return (
    <Button
      icon
      className="yt-button"
      basic
      compact
      size="mini"
      type="button"
      onClick={props.onClick}
    >
      <Icon size="small" name={props.icon} /> {props.text || ""}
    </Button>
  );
};

function ActionEditorInner(props) {
  let { type, sub } = props;
  let { t } = useTranslation();
  function handleChangePhone(form, value) {
    form.setFieldValue("data.actions.0.buttons.0.phone", value);
  }
  if (type === "text")
    return (
      <div className="livems">
        <div className="editable">
          <FastField name={"data.actions.0.text"}>
            {({ field }) => (
              <TextArea
                type="text"
                name={field.name}
                placeholder={t("EnterText")}
                value={field.value || ""}
                onChange={field.onChange}
                className="livems-input"
              />
            )}
          </FastField>
        </div>
      </div>
    );
  else if (type === "button") {
    const max = 3;
    return (
      <div className="livems">
        <div className="editable">
          <FastField name={`data.actions.0.text`}>
            {({ field, form }) => (
              <TextArea
                type="text"
                name={field.name}
                placeholder={t("Text")}
                value={field.value || ""}
                onChange={field.onChange}
                className="livems-input"
              />
            )}
          </FastField>
        </div>
        <FastFieldArray name={"data.actions.0.buttons"}>
          {({ helper, list }) => {
            return (
              <div>
                {list.map((data, i) => {
                  const path = `${helper.name}.${i}`;
                  return (
                    <div className="d-flex" key={i}>
                      <div className="livems-buttons">
                        <div className="livems-button-line editable">
                          <FastField name={`${path}.title`}>
                            {({ field }) => (
                              <Input
                                type="text"
                                name={field.name}
                                placeholder={t("ButtonTitle")}
                                value={field.value || ""}
                                onChange={field.onChange}
                                className="livems-button-input"
                              />
                            )}
                          </FastField>
                        </div>
                        <div className="editable">
                          <FastField name={`${path}.url`}>
                            {({ field }) => (
                              <Input
                                type="text"
                                name={field.name}
                                placeholder={"https://..."}
                                label="Url:"
                                value={field.value || ""}
                                onChange={field.onChange}
                              />
                            )}
                          </FastField>
                        </div>
                      </div>
                      {i > 0 ? (
                        <YtMiniButton
                          right
                          icon="remove"
                          onClick={() => helper.remove(i)}
                        />
                      ) : null}
                    </div>
                  );
                })}
                {max > list.length && (
                  <YtMiniButton
                    text="Add Button"
                    icon="add"
                    onClick={() => {
                      helper.push({ title: "Go", type: "web_url", url: "" });
                    }}
                  />
                )}
              </div>
            );
          }}
        </FastFieldArray>
      </div>
    );
    /* return (
			<div className="livems">
				<div className="editable">
					<FastField name={'data.actions.0.text'}>
						{({ field }) => (
							<TextArea
								type="text"
								name={field.name}
								placeholder={t('Text')}
								value={field.value || ''}
								onChange={field.onChange}
								className="livems-input"
							/>
						)}
					</FastField>
				</div>
				<div className="livems-buttons">
					<div className="livems-button-line editable">
						<FastField name={'data.actions.0.buttons.0.title'}>
							{({ field }) => (
								<Input
									type="text"
									name={field.name}
									placeholder={t('ButtonTitle')}
									value={field.value || ''}
									onChange={field.onChange}
									className="livems-button-input"
								/>
							)}
						</FastField>
					</div>
					<div className="editable">
						<FastField name={'data.actions.0.buttons.0.url'}>
							{({ field }) => (
								<Input
									type="text"
									name={field.name}
									placeholder={'https://...'}
									label="Url:"
									value={field.value || ''}
									onChange={field.onChange}
								/>
							)}
						</FastField>
					</div>
				</div>
			</div>
		); */
  } else if (type === "agent")
    return (
      <div className="livems">
        <div className="editable">
          <FastField name={"data.actions.0.text"}>
            {({ field }) => {
              return (
                <TextArea
                  type="text"
                  name={field.name}
                  placeholder={"Text..."}
                  value={field.value || ""}
                  onChange={field.onChange}
                  className="livems-input"
                />
              );
            }}
          </FastField>
        </div>
        <div className="livems-buttons">
          <div className="livems-button-line editable">
            <FastField name={"data.actions.0.buttons.0.title"}>
              {({ field }) => (
                <Input
                  type="text"
                  name={field.name}
                  placeholder={t("ButtonTitle")}
                  value={field.value || ""}
                  onChange={field.onChange}
                  className="livems-button-input"
                />
              )}
            </FastField>
          </div>
        </div>
      </div>
    );
  else if (type === "agent_time")
    return (
      <div className="livems">
        <div className="editable">
          <FastField name={"data.actions.0.text"}>
            {({ field }) => {
              return (
                <TextArea
                  type="text"
                  name={field.name}
                  placeholder={"Text..."}
                  value={field.value || ""}
                  onChange={field.onChange}
                  className="livems-input"
                />
              );
            }}
          </FastField>
        </div>
        <div className="livems-buttons">
          <div className="livems-button-line editable">
            <FastField name={"data.actions.0.buttons.0.title"}>
              {({ field }) => (
                <Input
                  type="text"
                  name={field.name}
                  placeholder={t("ButtonTitle")}
                  value={field.value || ""}
                  onChange={field.onChange}
                  className="livems-button-input"
                />
              )}
            </FastField>
          </div>
        </div>
      </div>
    );
  else if (type === "group")
    return (
      <div className="livems">
        <div className="editable">
          <FastField name={"data.actions.0.text"}>
            {({ field }) => {
              return (
                <TextArea
                  type="text"
                  name={field.name}
                  placeholder={t("Text")}
                  value={field.value || ""}
                  onChange={field.onChange}
                  className="livems-input"
                />
              );
            }}
          </FastField>
        </div>
        <div className="livems-buttons">
          <div className="livems-button-line editable">
            <FastField name={"data.actions.0.button_text"}>
              {({ field }) => (
                <Input
                  type="text"
                  name={field.name}
                  placeholder={t("ButtonTitle")}
                  value={field.value || ""}
                  onChange={field.onChange}
                  className="livems-button-input"
                />
              )}
            </FastField>
          </div>
        </div>
        <div className="editable">
          <FastField name={"data.actions.0.group"}>
            {({ field }) => (
              <Input
                type="text"
                name={field.name}
                label={t("Group") + ":"}
                placeholder={t("GroupKey")}
                value={field.value || ""}
                onChange={field.onChange}
              />
            )}
          </FastField>
        </div>
      </div>
    );
  else if (type === "whatsapp_button_card")
    return (
      <div className="livems">
        <div className="editable">
          <FastField name={`data.actions.0.text`}>
            {({ field, form }) => (
              <TextArea
                type="text"
                name={field.name}
                placeholder={t("Text")}
                value={field.value || ""}
                onChange={field.onChange}
                className="livems-input"
              />
            )}
          </FastField>
        </div>
        <FastFieldArray name={"data.actions.0.buttons.0"}>
          {({ helper }) => {
            const path = `${helper.name}`;
            return (
              <div className="d-flex">
                <div className="livems-buttons">
                  <div className="livems-button-line editable">
                    <FastField name={`${path}.title`}>
                      {({ field }) => (
                        <Input
                          type="text"
                          name={field.name}
                          placeholder={t("ButtonTitle")}
                          value={field.value || ""}
                          onChange={field.onChange}
                          className="livems-button-input"
                        />
                      )}
                    </FastField>
                  </div>
                  <div className="editable">
                    <FastField name={`${path}.phone`}>
                      {({ field, form }) => (
                        <Input
                          type="text"
                          name={field.name}
                          placeholder={"WhatsApp Numarası"}
                          label="Telefon:"
                          value={field.value || ""}
                          onChange={(e, { value }) =>
                            handleChangePhone(form, value)
                          }
                        />
                      )}
                    </FastField>
                  </div>
                  <div className="editable">
                    <FastField name={`${path}.text`}>
                      {({ field, form }) => (
                        <Input
                          type="text"
                          name={field.name}
                          placeholder={"WhatsApp Mesajı"}
                          label="Mesaj:"
                          value={field.value || ""}
                          onChange={field.onChange}
                        />
                      )}
                    </FastField>
                  </div>
                  <div className="edit-border">
                    <FastField name={`${path}.product_url`}>
                      {({ field, form }) => (
                        <Checkbox
                          toggle
                          name={field.name}
                          label={t("ProductUrl")}
                          checked={field.value || ""}
                          onChange={(e, { checked }) => {
                            form.setFieldValue(field.name, checked);
                          }}
                        />
                      )}
                    </FastField>
                  </div>
                </div>
              </div>
            );
          }}
        </FastFieldArray>
      </div>
    );
  else if (type === "url")
    return (
      <div className="livems">
        <div className="livems-buttons">
          <div className="editable">
            <FastField name={"data.actions.0.url"}>
              {({ field }) => (
                <Input
                  type="text"
                  name={field.name}
                  placeholder={"https://..."}
                  label="Url:"
                  value={field.value || ""}
                  onChange={field.onChange}
                />
              )}
            </FastField>
          </div>
        </div>
      </div>
    );
  if (type && type.startsWith("scenario")) return null;
  return <div>unknown editor</div>;
}

function ActionGenEditor(props) {
  let { t } = useTranslation();
  return (
    <FastField name={"data.actions.0"}>
      {({ field, form }) => {
        let { type, _type } = field.value;
        let real_type = _type || type;
        function handleChange(e, { value }) {
          if (value !== real_type && Actions[value]) {
            let data = JSON.parse(JSON.stringify(Actions[value]));
            data._type = value;
            form.setFieldValue(field.name, data);
          }
        }
        return (
          <div>
            <MyDivider icon="lightning" name={t("Action")} />
            <div className="wrapper action-type">
              <Dropdown
                options={list_cache}
                onChange={handleChange}
                value={real_type}
                selection
              />
            </div>
            <div className="wrapper">
              <ActionEditorInner type={real_type} sub={_type} />
            </div>
          </div>
        );
      }}
    </FastField>
  );
}

function CategoryEditor(props) {
  let { t } = useTranslation();
  return (
    <div>
      <MyDivider icon="lightning" name={t("ActionText")} />
      <div className="wrapper">
        <LiveCategoryInput
          name="data.text"
          placeholder={t("Message Text")}
          _list={props._list}
        />
      </div>
    </div>
  );
}

function ActionEditor(props) {
  let { t } = useTranslation();
  const feedbacks = [
    { key: "none", value: "none", text: t("None") },
    { key: "feedback", value: "feedback", text: "Show Cevap Yeterli/Yetersiz" },
    {
      key: "anotherquestion",
      value: "anotherquestion",
      text: "Başka Sorum Var Göster",
    },
    {
      key: "forwardagentwithanother",
      value: "forwardagentwithanother",
      text: "Müşteri Temsilcisi",
    },
    {
      key: "timeforwardagentwithanother",
      value: "timeforwardagentwithanother",
      text: "Müşteri Temsilcisi (Saat Kontrol)",
    },
  ];

  return (
    <div>
      <ActionGenEditor />
      <MyDivider icon="thumbs up outline" name={t("Feedback")} />
      <div className="wrapper">
        <FastField name={"data.feedback"}>
          {({ field, form }) => {
            function handleChange(e, { value }) {
              form.setFieldValue(field.name, value);
            }
            return (
              <Dropdown
                options={feedbacks}
                selection
                onChange={handleChange}
                value={field.value || "none"}
              />
            );
          }}
        </FastField>
      </div>
    </div>
  );
}

function StartEditor(props) {
  let { t } = useTranslation();
  return (
    <div>
      <MyDivider icon="bars" name={t("DefaultIntent")} />
      <div className="wrapper">
        <LiveCategoryInput
          name="data.start_text"
          placeholder={t("DefaultIntent")}
          _list={props._list}
        />
      </div>
    </div>
  );
}
function StartingEditor(props) {
  let { t } = useTranslation();
  return (
    <div>
      <MyDivider icon="envelope outline" name={t("StartingMessage")} />
      <div className="wrapper">
        <StatingMessageInput
          name="data.starting_text"
          placeholder={t("StartingMessage")}
        />
      </div>
    </div>
  );
}

let x = 0;

function DeleteButton(props) {
  const [sending, setSending] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  let id = props.data && props.data.id;
  let type = props.data && props.data.type;
  let { t } = useTranslation();
  if (!id || type === "start") return null;
  async function onDelete() {
    if (sending) return;
    setSending(true);
    await get("/personalize/whatsapp-bot/delete/" + id);
    setSending(false);
    setOpen(false);
    props.refresh();
    props.history.replace(`/whatsappbot/edit`);
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={
        <Button type="button" color="red" icon size="small">
          <Icon name="trash" />
        </Button>
      }
    >
      <Modal.Content>
        <Modal.Description>{t("AreYouSureDelete")}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          {t("Cancel")}
        </Button>
        <Button
          content={t("Delete")}
          icon="trash"
          loading={sending}
          color="red"
          onClick={onDelete}
        />
      </Modal.Actions>
    </Modal>
  );
}

function MoveButtons(props) {
  const [sending, setSending] = React.useState(false);
  let id = props.data && props.data.id;
  let type = props.data && props.data.type;
  if (!id || type === "start") return null;
  async function onMove(direction) {
    if (sending) return;
    setSending(true);
    await post("/personalize/whatsapp-bot/move/" + id, { direction });
    setSending(false);
    props.refresh();
  }

  return (
    <Button.Group basic size="small">
      <Button loading={sending} type="button" icon onClick={() => onMove(true)}>
        <Icon name="angle up" />
      </Button>
      <Button
        loading={sending}
        type="button"
        icon
        onClick={() => onMove(false)}
      >
        <Icon name="angle down" />
      </Button>
    </Button.Group>
  );
}

class BotEditor extends Component {
  state = {
    loading: !!(
      this.props.match.params.id || this.props.match.params.parent_id
    ),
  };
  _form = React.createRef();
  x = x++;
  save = () => {
    this._form.current.submitForm();
  };

  componentDidMount() {
    this._mount = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    let { id, parent_id } = this.props.match.params;
    let get_id = id || parent_id;
    await load_cache();
    if (get_id) {
      if (!this.state.loading) this.setState({ loading: true });
      let response = await get("/personalize/whatsapp-bot/get/" + get_id);
      if (!this._mount) return;
      if (id) {
        this.setState({
          loading: false,
          data: _.pick(response && response.data, [
            "id",
            "pid",
            "name",
            "type",
            "data",
            "parent",
            "order",
          ]),
          list: response && response.data && response.data._list,
          _buttons: response && response.data && response.data._buttons,
          parent: response && response.data && response.data._parent,
        });
      } else if (parent_id) {
        let data = Gen.default_action;
        let parent = response && response.data;
        data.parent = parent && parent.id;
        this.setState({
          loading: false,
          data,
          parent,
          _buttons: response && response.data && response.data._list,
        });
      }
    }
  }

  async handleSubmit(data, actions) {
    actions.setSubmitting(true);
    let response = await post(
      "/personalize/whatsapp-bot/save",
      _.omit(data, ["order"])
    );
    if (!data.id) {
      data.id = response.data && response.data.data.id;
      actions.setValues(data);
      actions.resetForm(data);
      this.setState({ data });
      this.props.history.replace(`/whatsappbot/edit/${data.id}`);
      if (this.props.refresh) this.props.refresh();
    } else {
      this.setState({ data });
      actions.setValues(data);
      actions.resetForm(data);
      if (this.props.refresh) this.props.refresh();
    }
  }

  render() {
    let { t } = this.props;
    if (this.state.loading) return <Page.Loader />;
    let data = this.state.data || Gen.default_category;
    return (
      <Formik
        key={data.id || "create"}
        initialValues={data}
        innerRef={this._form}
        onSubmit={this.handleSubmit.bind(this)}
      >
        {(form) => {
          let { values, dirty, isSubmitting } = form;
          const is_start = values.type === "start";
          const is_starting = values.type === "starting";
          const SAVE_TEXT =
            values.type === "category"
              ? "Category"
              : is_starting
              ? "StartingMessage"
              : is_start
              ? "DefaultIntent"
              : "Action";
          return (
            <Form
              autoComplete="off"
              css={`
                background: inherit;
                max-height: 100%;
                min-height: 100%;
                overflow: auto;
                overflow-x: hidden;
              `}
            >
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                className={"d-none"}
              />
              <Prompt
                when={dirty}
                message={(location) =>
                  t("AreYouSureWithoutSaving", { pathname: location.pathname })
                }
              />
              <div className="button-wrap">
                <Button primary size="small" loading={isSubmitting}>
                  {t("SaveText", { text: t(SAVE_TEXT) })}
                  {/* <span className="hide-xs"> {SAVE_TEXT}</span> */}
                </Button>

                <DeleteButton {...this.props} data={values} />

                <MoveButtons {...this.props} data={values} />
              </div>

              {is_start || is_starting ? null : (
                <div className="button-line">
                  <MyDivider icon="bars" name={t("Button")} />
                  <div className="button-text">
                    <NameEditor
                      config={this.props.config}
                      data={values}
                      parent={this.state.parent}
                      _buttons={this.state._buttons || this.props.list}
                    />
                  </div>
                </div>
              )}
              {values.type === "category" ? (
                <CategoryEditor _list={this.state.list} />
              ) : values.type === "action" ? (
                <ActionEditor />
              ) : is_start ? (
                <StartEditor _list={this.state.list} />
              ) : is_starting ? (
                <StartingEditor />
              ) : null}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

function EditorWrap(props) {
  let { t } = props;
  let { id, parent_id } = props.match.params;
  return <BotEditor key={id || "create-" + parent_id} {...props} t={t} />;
}

function ActionLine(props) {
  let { data } = props;
  let { t } = useTranslation();
  return (
    <div className="category-wrap">
      <NavLink className="category-item" to={`/whatsappbot/edit/${data.id}`}>
        <span>{data.name || `${t("Action")} (${data.id})`}</span>
      </NavLink>
    </div>
  );
}

function CategoryLine(props) {
  let { data } = props;
  let { t } = useTranslation();
  return (
    <div className="category-wrap">
      <NavLink className="category-item" to={`/whatsappbot/edit/${data.id}`}>
        <span>{data.name || `${t("Campaign")} (${data.id})`}</span>
      </NavLink>

      {data.disable_create ? null : (
        <div className="category-sub">
          {data.list &&
            _.map(data.list, (it) => <ActionLine key={it.id} data={it} />)}
          <Button
            as={Link}
            to={`/whatsappbot/edit/create-${data.id}`}
            fluid
            color="twitter"
            size="small"
          >
            {t("CreateAction")}
          </Button>
        </div>
      )}
    </div>
  );
}

class BotEditScreen extends Component {
  state = { loading: true, refresh: false };

  componentDidMount() {
    this.loadList();
  }

  async loadList() {
    let data = await get("/personalize/whatsapp-bot/get");
    this.setState({ loading: false, list: data && data.list });
  }

  refresh = async () => {
    this.setState({ refresh: true });
    await this.loadList();
    this.setState({ refresh: false });
  };

  render() {
    let { t } = this.props;
    const settings_line = {
      id: "starting",
      name: t("StartingMessage"),
      disable_create: true,
    };
    const mainmenu = {
      id: "start",
      name: t("DefaultIntent"),
      disable_create: true,
    };
    return (
      <div className="bot-edit-wrap">
        <Segment className="list-section" style={{ overflowY: "auto" }}>
          {this.state.refresh && !this.state.loading && (
            <Progress percent={100} indicating attached="top" />
          )}
          <CategoryLine key={"starting"} data={settings_line} />
          <CategoryLine key={"start"} data={mainmenu} />
          <hr />

          <div className="list-wrap">
            {this.state.loading ? (
              <Page.Loader />
            ) : (
              _.map(this.state.list, (it) => (
                <CategoryLine key={it.id} data={it} />
              ))
            )}
          </div>
          <Button
            as={Link}
            to="/whatsappbot/edit/create"
            color="green"
            size="small"
            fluid
          >
            {t("CreateCategory")}
          </Button>
        </Segment>
        <Segment className="editor-section">
          <Switch>
            <Route path="/whatsappbot/edit" exact component={Placeholder} />
            <Route
              path="/whatsappbot/edit/create"
              exact
              render={(props) => (
                <EditorWrap
                  {...props}
                  refresh={this.refresh}
                  config={this.props.config}
                  list={this.state.list}
                  t={t}
                />
              )}
            />
            <Route
              path="/whatsappbot/edit/create-:parent_id"
              render={(props) => (
                <EditorWrap
                  {...props}
                  refresh={this.refresh}
                  config={this.props.config}
                  t={t}
                />
              )}
            />
            <Route
              path="/whatsappbot/edit/:id"
              render={(props) => (
                <EditorWrap
                  {...props}
                  refresh={this.refresh}
                  config={this.props.config}
                  t={t}
                />
              )}
            />
            <Redirect to="/whatsappbot/edit" />
          </Switch>
        </Segment>
        <Segment className="bot-section">
          <BotTest config={this.props.config} />
        </Segment>
      </div>
    );
  }
}

export default withTranslation()(BotEditScreen);
