import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import sagas from './sagas';
import createSagaMiddleware from 'redux-saga';
import { getProfile } from './actions/access.actions';

export const history = createBrowserHistory();

history.listen(function(location) {
	/* if (window.ga) {
		window.ga('set', 'page', location.pathname + location.search);
		window.ga('send', 'pageview');
	} */
	if (window.gtag) {
		window.gtag('config', 'UA-43334566-3', {
			page_title: document.title,
			page_location: window.location.href,
			page_path: location.pathname,
		});
	}
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || (it => it);

export default function configureStore(preloadedState) {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(
		createRootReducer(history),
		preloadedState,
		composeEnhancers(compose(applyMiddleware(routerMiddleware(history), sagaMiddleware)))
	);
	sagaMiddleware.run(sagas);

	store.dispatch(getProfile());
	return store;
}
