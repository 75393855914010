import React from "react";
import Page from "../../components/Page";
import {
  Message,
  Icon,
  Dropdown,
  Label,
  Button,
  Table,
  Header,
  Responsive,
  Modal,
} from "semantic-ui-react";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { get, post } from "../../util/api";
import { MaterialInput } from "../../components/Helpers";
import { Goto } from "../../components/Url";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "moment/locale/en-in";
import "moment/locale/tr";

const options = [
  { key: "active", icon: "check", text: "Active", value: false },
  //	{ key: 'hidden', icon: 'hide', text: 'Hidden', value: 'hidden' },
  { key: "disabled", icon: "close", text: "Disabled", value: true },
];

const status = {
  active: { text: "Active", color: "green" },
  disabled: { text: "Disabled", color: "grey" },
};

const DateLabel = styled(Label)`
  margin: 0 0 5px 0 !important;
  width: 100%;
  text-align: right;
  .detail {
    text-align: right;
    width: 40px;
  }
`;

const getName = (data = {}) => data.name || data.key || `Variable #${data.id}`;

class RecList extends React.Component {
  state = {
    loading: true,
    results: [],
    search: "",
    searching: false,
    remove: false,
    column: null,
    data: "",
    direction: null,
    open: false,
  };
  _first = React.createRef();
  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };
  componentDidMount() {
    this.getList();
  }
  async getList() {
    let data = await get("/personalize/variable/get");
    let list = data.data || [];
    this.setState({
      loading: false,
      list,
      results: _.fill(Array(list.length), true),
    });
  }
  onChange = (e, { name, value }) => {
    if (name === "search") {
      this.setState({ search: value, searching: true });

      clearTimeout(this._search);
      if (!value)
        return this.setState({
          searching: false,
          search: "",
          results: _.fill(Array(this.state.list.length), true),
        });
      else this.setState({ search: value, searching: true });
      this._search = setTimeout(() => {
        const re = new RegExp(_.escapeRegExp(value), "i");
        const isMatch = (result) => re.test(result.name) || re.test(result.id);

        this.setState({
          searching: false,
          results: _.map(this.state.list, isMatch),
        });
      }, 300);
    } else this.setState({ [name]: value });
  };

  _toggle = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    if (!data) return;
    let { value, index } = data;
    let it;
    this.setState({
      list: _.map(this.state.list, (data, i) => {
        if (i !== index) return data;
        it = data;
        data.statusLoading = true;
        return data;
      }),
    });
    let obj = { id: it.id, disabled: value };
    post("/personalize/variable/save", { data: obj }).then(() => {
      this.setState({
        list: _.map(this.state.list, (data, i) => {
          if (i !== index) return data;
          data.statusLoading = false;
          data.disabled = value;
          return data;
        }),
      });
    });
  };
  handleRemove = () => {
    let selected = this.state.selected;
    this.setState({ remove: true });
    post("/personalize/variable/delete", {
      id: selected.id,
    }).then(() => {
      this.close();
      this.getList();
    });
  };
  closeConfigShow = (id) => () => {
    let selected = _.find(this.state.list, { id });
    this.setState({ open: true, remove: false, selected });
  };
  close = () => this.setState({ open: false });
  inside() {
    if (this.state.loading) return <Page.Loader />;
    const len = this.state.list.length;
    //const checkActive = _.findIndex(this.state.list, { status: 'active' });
    const { open, results } = this.state;
    const { t } = this.props;
    if (len)
      return (
        <Page.Wrap>
          <MaterialInput
            value={this.state.search}
            onChange={this.onChange}
            name={t("Search")}
            loading={this.state.searching}
            icon="search"
            placeholder={t("SearchPlaceholder")}
          />
          <Modal open={open} onClose={this.close}>
            <Header
              icon="trash"
              content={t("Delete") + ` ${getName(this.state.selected)}`}
            />
            <Modal.Content>
              <p>{t("AreYouSureDelete")}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={this.close}>
                <Icon name="remove" /> {t("no")}
              </Button>
              <Button
                loading={this.state.remove}
                color="green"
                onClick={this.handleRemove}
              >
                <Icon name="checkmark" /> {t("yes")}
              </Button>
            </Modal.Actions>
          </Modal>
          <Table selectable compact>
            <Responsive
              as={Table.Header}
              minWidth={Responsive.onlyTablet.minWidth}
            >
              <Table.Row>
                <Table.HeaderCell width="8">{t("Name")}</Table.HeaderCell>
                <Table.HeaderCell width="5">{t("Updated")}</Table.HeaderCell>
                <Table.HeaderCell width="3" textAlign="center">
                  {t("Status")}
                </Table.HeaderCell>
              </Table.Row>
            </Responsive>
            <Table.Body>
              {_.includes(results, true) ? (
                _.map(this.state.list, (data, index) => {
                  if (!results[index]) {
                    return null;
                  }
                  let current_status = data.disabled ? "disabled" : "active";
                  return (
                    <Table.Row key={data.id}>
                      <Table.Cell selectable>
                        <Link
                          css={`
                            text-transform: capitalize;
                            @media (max-width: ${Responsive.onlyTablet
                                .minWidth - 1}px) {
                              padding-left: 0 !important;
                            }
                          `}
                          to={`/variable/${data.id}`}
                        >
                          {getName(data)}
                        </Link>
                        <Responsive
                          {...Responsive.onlyMobile}
                          css={`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            .ui.label {
                              margin: 0 !important;
                            }
                          `}
                        >
                          <Link
                            css={`
                              padding-left: 0 !important;
                              color: #333;
                            `}
                            to={`/variable/${data.id}`}
                          >
                            {t("update")}:{" "}
                            {moment(data.updated_at)
                              .locale(t("lang"))
                              .format("LL - HH:mm")}
                          </Link>
                          <Dropdown
                            onChange={this._toggle}
                            size="tiny"
                            direction="left"
                            index={index}
                            options={options}
                            value={data.disabled}
                            icon={false}
                            trigger={
                              <DateLabel
                                color={status[current_status].color}
                                size="tiny"
                              >
                                {data.statusLoading && (
                                  <Icon name="asterisk" loading />
                                )}

                                {t(status[current_status].text)}
                              </DateLabel>
                            }
                          />
                          <Button
                            color="red"
                            icon="trash"
                            alt={data.id}
                            onClick={this.closeConfigShow(data.id)}
                            style={{
                              margin: "0 0 0 4px",
                              fontSize: "10px",
                              padding: ".5833em .833em",
                            }}
                          />
                        </Responsive>
                      </Table.Cell>
                      <Responsive
                        as={Table.Cell}
                        minWidth={Responsive.onlyTablet.minWidth}
                      >
                        {moment(data.updated_at)
                          .locale(t("lang"))
                          .format("LL - HH:mm")}
                      </Responsive>

                      <Responsive
                        as={Table.Cell}
                        minWidth={Responsive.onlyTablet.minWidth}
                        textAlign="right"
                      >
                        <Dropdown
                          onChange={this._toggle}
                          size="big"
                          direction="left"
                          index={index}
                          options={options}
                          value={data.disabled}
                          icon={false}
                          trigger={
                            <Button
                              size="tiny"
                              color={status[current_status].color}
                            >
                              {data.statusLoading && (
                                <Icon name="asterisk" loading />
                              )}
                              {t(status[current_status].text)}
                              {index === 0 && (
                                <span className="ref-span" ref={this._first} />
                              )}
                            </Button>
                          }
                        />

                        <Button
                          color="red"
                          icon="trash"
                          size="tiny"
                          alt={data.id}
                          onClick={this.closeConfigShow(data.id)}
                        />
                      </Responsive>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell style={{ textAlign: "center" }} colSpan="3">
                    {t("noVariable")}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Page.Wrap>
      );
    return (
      <Page.Wrap>
        <Message
          css={`
            text-align: center;
          `}
          info
        >
          <Message.Header>{t("noCreateVariable")}</Message.Header>
          <p>
            <Goto to={`/variable/create`}>
              <Button primary>{t("createVariable")}</Button>
            </Goto>
          </p>
        </Message>
      </Page.Wrap>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Page
        icon="js"
        title="Variable"
        extra={
          <div
            css={`
              margin-left: auto;
            `}
          >
            <Button
              size="small"
              href={`https://yapaytech.gitbook.io/collectaction-uygulama-marketi/filtre-ayarlari/gelismis-ayarlar/degiskenler`}
              target={"_blank"}
              color="#63a1d0"
            >
              <Icon name="book" style={{ margin: "0" }} />
            </Button>

            <Button size="small" as={Link} to={`/variable/create`} color="blue">
              {t("createVariable")}
            </Button>
          </div>
        }
      >
        {this.inside()}
      </Page>
    );
  }
}

const stateToProps = (state) => ({
  access: state.access,
  location: state.router.location,
  product: state.product.personalize,
});
export default connect(stateToProps)(withTranslation()(RecList));
